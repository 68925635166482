import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import CircularLoading from '../../../../CircularLoading';
import ModalHeader from '../../../../Modal/ModalHeader';
import {
  HubSpotPipeline,
  HubSpotPipelineStep,
} from '../../../../../types/IHubSpot';
import { CrmSetting } from '../../../../../types/ICrm';
import { Brand } from '../../../../../types';
import { getHubSpotPipelines } from '../../../../../services/hubspot';
import { orderArrayOfObject } from '../../../../../utils/arrayFormatter';
import { FloatingButton } from '../../../../Buttons';
import { useDispatch } from 'react-redux';
import { errorMessageParser } from '../../../../../utils/helpers/ToastHelper';
import { toggleAlert } from '../../../../../redux/actions';
import { createOrUpdateCrmSettings } from '../../../../../services/crm';

interface HubSpotPipelinesFormProps {
  formValues: CrmSetting;
  setFormValues: Dispatch<SetStateAction<CrmSetting>>;
  setCrmSettings: Dispatch<SetStateAction<CrmSetting>>;
  brand: Brand;
  onClose: () => void;
}

const HubSpotPipelinesForm: React.FC<HubSpotPipelinesFormProps> = ({
  formValues,
  setFormValues,
  setCrmSettings,
  brand,
  onClose,
}) => {
  const dispatch = useDispatch();
  const isPipelineExisted = !!formValues?.hubspotSettings?.pipelineId;
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [hubspotPipelines, setHubspotPipelines] = useState<HubSpotPipeline[]>(
    [],
  );
  const [selectedPipeline, setSelectedPipeline] =
    useState<HubSpotPipeline>(null);

  useEffect(() => {
    if (brand) getPipelines();
  }, [brand]);

  const getPipelines = async () => {
    try {
      setFetchLoading(true);

      const response = await getHubSpotPipelines(brand?._id);

      const filteredResponse: HubSpotPipeline[] = response.data.filter(
        (pipeline: HubSpotPipeline) => !pipeline.archived,
      );

      setHubspotPipelines(filteredResponse);

      if (!isPipelineExisted) {
        const defaultPipeline =
          filteredResponse?.find(
            (pipeline: HubSpotPipeline) => pipeline.id === 'default',
          ) || filteredResponse[0];

        setSelectedPipeline(defaultPipeline);

        setFormValues({
          ...formValues,
          hubspotSettings: {
            ...formValues?.hubspotSettings,
            pipelineId: defaultPipeline.id,
          },
        });
      } else {
        const currentPipelineId = formValues?.hubspotSettings?.pipelineId;

        const pipeline = filteredResponse?.find(
          (pipeline: HubSpotPipeline) => pipeline.id === currentPipelineId,
        );

        setSelectedPipeline(pipeline);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const handleOnSubmit = async () => {
    try {
      setLoading(true);

      const response = await createOrUpdateCrmSettings(brand?._id, formValues);

      setCrmSettings(response.data);
      setFormValues(response.data);
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Sales cycle updated successfully',
        }),
      );
      onClose();
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const handleOnSelectSalesCycle = (value: HubSpotPipeline) => {
    setFormValues({
      ...formValues,
      hubspotSettings: {
        ...formValues?.hubspotSettings,
        pipelineId: value.id,
      },
    });
  };

  return (
    <Box sx={{ width: '300px' }}>
      <CircularLoading loading={fetchLoading} />

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12}>
          <ModalHeader title="Pipelines Setup" />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            id="pipelines"
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.label}
                </li>
              );
            }}
            options={hubspotPipelines}
            value={
              hubspotPipelines?.find(
                (contact: HubSpotPipeline) =>
                  contact.id === formValues?.hubspotSettings?.pipelineId,
              ) || null
            }
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Pipeline"
                fullWidth
                size="small"
                placeholder="Select a pipeline"
                required
                InputLabelProps={{ shrink: true }}
              />
            )}
            disableClearable
            onChange={(_, value: any) => handleOnSelectSalesCycle(value)}
          />
        </Grid>

        <Grid item xs={12}>
          {!fetchLoading
            ? orderArrayOfObject(
                selectedPipeline?.stages || [],
                'displayOrder',
              )?.map((pipelineStep: HubSpotPipelineStep) => {
                return (
                  <Grid item xs={12} key={pipelineStep.id} mt={1}>
                    <Card>
                      <CardContent>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{ textAlign: 'center' }}
                          >
                            <Typography variant="body1">
                              {pipelineStep.label}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              flexDirection: 'column',
                              display: 'flex',
                              textAlign: 'center',
                            }}
                          >
                            <Typography
                              variant="caption"
                              sx={{ fontWeight: 'bold' }}
                            >
                              Close Ratio
                            </Typography>

                            <Typography variant="body1">
                              {`${
                                parseFloat(pipelineStep.metadata.probability) *
                                100
                              }%`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })
            : null}
        </Grid>
      </Grid>

      {formValues?.hubspotSettings?.pipelineId ? (
        <FloatingButton
          title="Save"
          onClick={handleOnSubmit}
          loading={loading}
          bottom="0px"
          right="0px"
          sticky
          stickyPosition="right"
        />
      ) : null}
    </Box>
  );
};

export default HubSpotPipelinesForm;
