import { GridColDef } from '@mui/x-data-grid-premium';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { Tooltip, Typography } from '@mui/material';
import { defaultProps } from '../../../utils/helpers/TableHelpers';

export const facebookLeadFormColumns = (isMobile: boolean) => {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerClassName: styles.header,
      headerName: 'Form ID',
      width: 150,
      sortingOrder: ['desc', 'asc'],
      renderCell: (params: any) => {
        const { id } = params.row;

        return (
          <Tooltip
            title={<Typography variant="body2">{id}</Typography>}
            disableInteractive
          >
            <div className={styles.name}>{id}</div>
          </Tooltip>
        );
      },
    },
    {
      ...defaultProps(isMobile, 180),
      field: 'name',
      headerClassName: styles.header,
      headerName: 'Name',
      sortingOrder: ['desc', 'asc'],
      renderCell: (params: any) => {
        const { name } = params.row;

        return (
          <Tooltip
            title={<Typography variant="body2">{name}</Typography>}
            disableInteractive
          >
            <div className={styles.name}>{name}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'status',
      headerClassName: styles.header,
      headerName: 'Status',
      width: 100,
      sortingOrder: ['desc', 'asc'],
      renderCell: (params: any) => {
        const { status } = params.row;

        return (
          <Tooltip
            title={<Typography variant="body2">{status}</Typography>}
            disableInteractive
          >
            <div className={styles.name}>{status}</div>
          </Tooltip>
        );
      },
    },
  ];

  return columns;
};
