import React, { useEffect, useState } from 'react';
import {
  FBLeadGenQuestionForm,
  FBLeadQuestionOption,
  LeadFormData,
} from '../../../../../types';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ModalHeader from '../../../../Modal/ModalHeader';
import { PrimaryButton } from '../../../../Buttons';
import { useNavigate } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { constantStringToHumanized } from '../../../../../utils/stringModifier';
import { ThankYouPageButtonType } from '../../../../../utils/helpers/LeadHelpers';
import ArchiveIcon from '@mui/icons-material/Archive';
import { DEFAULT_COLOR_THEME } from '../../../../../utils/Styling';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { TbUserQuestion } from 'react-icons/tb';

interface LeadFormPreviewProps {
  leadForm: LeadFormData;
  onClose: () => void;
  onArchive: () => void;
  loading: boolean;
}

const LeadFormPreview: React.FC<LeadFormPreviewProps> = ({
  leadForm,
  onClose,
  onArchive,
  loading,
}) => {
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const navigate = useNavigate();
  const [customQuestions, setCustomQuestions] = useState<
    FBLeadGenQuestionForm[]
  >([]);
  const [prefillQuestions, setPrefillQuestions] = useState<
    FBLeadGenQuestionForm[]
  >([]);
  const [confirmArchive, setConfirmArchive] = useState<boolean>(false);

  useEffect(() => {
    if (leadForm) {
      const customs = leadForm?.fields?.questions?.filter(
        (question: FBLeadGenQuestionForm) => question.type === 'CUSTOM',
      );

      const prefills = leadForm?.fields?.questions?.filter(
        (question: FBLeadGenQuestionForm) => question.type !== 'CUSTOM',
      );

      setCustomQuestions(customs);
      setPrefillQuestions(prefills);
    }
  }, [leadForm]);

  return (
    <Box
      sx={{
        width: xsOnly ? '100%' : '400px',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
          width: '10px',
        },
        '&::-webkit-scrollbar-track': {
          // background: "#F1F0F0",
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
        },

        '&::-webkit-scrollbar-thumb': {
          background: DEFAULT_COLOR_THEME,
          borderRadius: '18px',

          '&:hover': {
            background: DEFAULT_COLOR_THEME,
          },
        },
        padding: '20px',
      }}
    >
      <Grid container spacing={1}>
        {xsOnly ? (
          <Grid
            item
            xs={12}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton onClick={onClose} type="button">
              <CloseIcon />
            </IconButton>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <ModalHeader title="Lead Form Preview" />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            flexDirection: 'row',
            display: 'flex',
            width: '100%',
          }}
        >
          <Typography variant="body2" mr={1} sx={{ fontWeight: 'bold' }}>
            Lead Form ID:
          </Typography>

          <Typography variant="body2">{leadForm?.id}</Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            flexDirection: 'row',
            display: 'flex',
            width: '100%',
          }}
        >
          <Typography variant="body2" mr={1} sx={{ fontWeight: 'bold' }}>
            Name:
          </Typography>

          <Typography variant="body2">{leadForm?.name}</Typography>
        </Grid>

        {customQuestions?.length > 0 ? (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                flexDirection: 'row',
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Typography variant="body2" mr={1} sx={{ fontWeight: 'bold' }}>
                Custom Questions
              </Typography>
            </Grid>

            {customQuestions?.map(
              (question: FBLeadGenQuestionForm, index: number) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      flexDirection: 'column',
                    }}
                    key={`custom-question-${index + 1}`}
                  >
                    <Typography
                      variant="body2"
                      mr={1}
                      sx={{ fontWeight: 'bold' }}
                      component="div"
                    >
                      {`Custom Questions #${index + 1}`}
                    </Typography>

                    <Typography variant="body2" mr={1} component="div">
                      {question.label}
                    </Typography>

                    {question?.options?.length > 0
                      ? question.options.map(
                          (
                            option: FBLeadQuestionOption,
                            optionIndex: number,
                          ) => {
                            return (
                              <Typography
                                variant="body2"
                                ml={2}
                                component="li"
                                key={`question-option-${index}-${optionIndex}`}
                              >
                                {option.value}
                              </Typography>
                            );
                          },
                        )
                      : null}
                  </Grid>
                );
              },
            )}
          </>
        ) : null}

        {prefillQuestions?.length > 0 ? (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                flexDirection: 'row',
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Typography variant="body2" mr={1} sx={{ fontWeight: 'bold' }}>
                Prefill Questions
              </Typography>
            </Grid>

            {prefillQuestions.map(
              (question: FBLeadGenQuestionForm, index: number) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      flexDirection: 'column',
                    }}
                    key={`custom-question-${index + 1}`}
                  >
                    <Typography
                      variant="body2"
                      mr={1}
                      key={`prefill-question-${index + 1}`}
                      component="li"
                    >
                      {`${constantStringToHumanized(question?.type || '')}`}
                    </Typography>
                  </Grid>
                );
              },
            )}
          </>
        ) : null}

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            flexDirection: 'row',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Typography variant="body2" mr={1} sx={{ fontWeight: 'bold' }}>
            Privacy Policy
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="body2"
            mr={1}
            sx={{ fontWeight: 'bold' }}
            component="div"
          >
            Privacy Policy Link Text
          </Typography>

          <Typography variant="body2" mr={1} component="div">
            {leadForm?.fields?.privacy_policy?.link_text}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="body2"
            mr={1}
            sx={{ fontWeight: 'bold' }}
            component="div"
          >
            Privacy Policy URL
          </Typography>

          <Typography variant="body2" mr={1} component="div">
            {leadForm?.fields?.privacy_policy?.url}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            flexDirection: 'row',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Typography variant="body2" mr={1} sx={{ fontWeight: 'bold' }}>
            CTA Action
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="body2"
            mr={1}
            sx={{ fontWeight: 'bold' }}
            component="div"
          >
            Headline
          </Typography>

          <Typography variant="body2" mr={1} component="div">
            {leadForm?.fields?.thank_you_page?.title}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="body2"
            mr={1}
            sx={{ fontWeight: 'bold' }}
            component="div"
          >
            Body
          </Typography>

          <Typography variant="body2" mr={1} component="div">
            {leadForm?.fields?.thank_you_page?.body}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="body2"
            mr={1}
            sx={{ fontWeight: 'bold' }}
            component="div"
          >
            CTA Button Type
          </Typography>

          <Typography variant="body2" mr={1} component="div">
            {constantStringToHumanized(
              leadForm?.cta ||
                leadForm?.fields?.thank_you_page?.button_type ||
                '',
            )}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="body2"
            mr={1}
            sx={{ fontWeight: 'bold' }}
            component="div"
          >
            CTA Button Text
          </Typography>

          <Typography variant="body2" mr={1} component="div">
            {leadForm?.fields?.thank_you_page?.button_text}
          </Typography>
        </Grid>

        {leadForm?.fields?.thank_you_page?.button_type ===
        ThankYouPageButtonType.VIEW_WEBSITE ? (
          <Grid
            item
            xs={12}
            sx={{
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="body2"
              mr={1}
              sx={{ fontWeight: 'bold' }}
              component="div"
            >
              Website URL
            </Typography>

            <Typography variant="body2" mr={1} component="div">
              {leadForm?.fields?.thank_you_page?.website_url}
            </Typography>
          </Grid>
        ) : null}

        {leadForm?.fields?.thank_you_page?.button_type ===
        ThankYouPageButtonType.CALL_BUSINESS ? (
          <>
            <Grid
              item
              xs={12}
              sx={{
                flexDirection: 'column',
              }}
            >
              <Typography
                variant="body2"
                mr={1}
                sx={{ fontWeight: 'bold' }}
                component="div"
              >
                Country Code
              </Typography>

              <Typography variant="body2" mr={1} component="div">
                {leadForm?.fields?.thank_you_page?.country_code}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                flexDirection: 'column',
              }}
            >
              <Typography
                variant="body2"
                mr={1}
                sx={{ fontWeight: 'bold' }}
                component="div"
              >
                Phone Number
              </Typography>

              <Typography variant="body2" mr={1} component="div">
                {leadForm?.fields?.thank_you_page?.business_phone_number}
              </Typography>
            </Grid>
          </>
        ) : null}

        <Grid
          item
          xs={12}
          sx={{
            position: 'sticky',
            bottom: 0,
          }}
        >
          <Grid
            container
            spacing={1}
            sx={{ justifyContent: 'center', display: 'flex' }}
          >
            <Grid item>
              <PrimaryButton
                title="Duplicate"
                type="button"
                handleOnClick={() =>
                  navigate(`/assetz/form/duplicate/${leadForm?._id}`)
                }
                marginRight5
                startIcon={<ContentCopyIcon />}
              />
            </Grid>

            <Grid item>
              <PrimaryButton
                title={confirmArchive ? 'Really Archive?' : 'Archive'}
                type="button"
                handleOnClick={() => {
                  if (confirmArchive) {
                    onArchive();
                  } else {
                    setConfirmArchive(true);
                  }
                }}
                marginRight5
                startIcon={<ArchiveIcon />}
                theme={confirmArchive ? 'red' : DEFAULT_COLOR_THEME}
                loading={loading}
              />
            </Grid>

            <Grid item>
              <PrimaryButton
                title="Notification"
                type="button"
                handleOnClick={() =>
                  navigate(`/assetz/form/send-lead/${leadForm?._id}`)
                }
                marginRight5
                startIcon={<NotificationsActiveOutlinedIcon />}
                loading={loading}
              />
            </Grid>

            {customQuestions?.length > 0 ? (
              <Grid item>
                <PrimaryButton
                  title="Q-Leads"
                  type="button"
                  handleOnClick={() =>
                    navigate(`/assetz/form/q-leads/${leadForm?._id}`)
                  }
                  marginRight5
                  startIcon={<TbUserQuestion />}
                  loading={loading}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LeadFormPreview;
