import axios from 'axios';
import { IUser } from '../types';
import { apiBaseUrl } from './api';

export const createUser = async (obj: IUser) => {
  const data = await axios.post(`${apiBaseUrl}/user/create`, obj);

  return data?.data;
};

export const login = async (obj: IUser) => {
  const data = await axios.post(`${apiBaseUrl}/user/login`, obj);

  return data?.data;
};

export const banUser = async (id: string) => {
  const data = await axios.post(`${apiBaseUrl}/user/ban`, { id });

  return data?.data;
};

export const unbanUser = async (id: string) => {
  const data = await axios.post(`${apiBaseUrl}/user/unban`, { id });

  return data?.data;
};

export const forgotPassword = async (obj: IUser) => {
  const data = await axios.post(`${apiBaseUrl}/user/forgot-password`, obj);

  return data?.data;
};

export const resetPassword = async (obj: IUser) => {
  const data = await axios.post(`${apiBaseUrl}/user/reset-password`, obj);

  return data?.data;
};

export const chnagePassword = async (obj: IUser) => {
  const data = await axios.post(`${apiBaseUrl}/user/change-password`, obj);

  return data?.data;
};

export const facebookLogin = async (
  token: string,
  role: string,
  diy: boolean,
) => {
  const data = await axios.post(`${apiBaseUrl}/user/facebook-login`, {
    token,
    role,
    diy,
  });

  return data?.data;
};

export const getLoggedInUser = async () => {
  try {
    const data = await axios.get(`${apiBaseUrl}/user/get`);
    localStorage.setItem('userRole', data?.data.role);

    return data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getUserByRoleId = async (
  roleBasedId: string,
  provider: string,
) => {
  try {
    const data = await axios.get(`${apiBaseUrl}/user/get/role-based`, {
      params: {
        roleBasedId,
        provider,
      },
    });

    return data?.data;
  } catch (error: any) {
    console.log(error.message);
  }
};

export const googleLogin = async (code: string, role: string, diy: boolean) => {
  const data = await axios.post(`${apiBaseUrl}/user/google-login`, {
    code,
    role,
    diy,
  });

  return data?.data;
};

export const connectFacebookAccount = async (
  token: string,
  role: string,
  userId: string,
  diy: boolean,
) => {
  const data = await axios.post(`${apiBaseUrl}/user/facebook/connect`, {
    role,
    token,
    userId,
    diy,
  });

  return data?.data;
};
