import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  List,
  ListItem,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  GoogleAssetsContainer,
  googleResourceLimits,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import { MdDeleteOutline } from 'react-icons/md';
import { FloatingButton } from '../../../Buttons';
import axios from 'axios';

interface GoogleTemplatesWebsitesProps {
  submitForm: any;
  templateForm: any;
  setIsDirty: any;
}

const GoogleTemplatesWebsites: React.FC<GoogleTemplatesWebsitesProps> = ({
  submitForm,
  templateForm,
  setIsDirty,
}) => {
  const [websiteInput, setWebsiteInput] = useState('');
  const [websites, setWebsites] = useState([]);
  useEffect(() => {
    if (templateForm) {
      setWebsiteInput(templateForm.join('\n'));
      setWebsites(templateForm);
    }
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        gap: 4,
      }}
    >
      <TextField
        label="Websites"
        variant="outlined"
        value={websiteInput}
        sx={{
          width: '100%',
          maxWidth: 500,
          ...scrollBarGreen,
        }}
        onChange={(e) => {
          setWebsiteInput(e.target.value);
          setWebsites(
            e.target.value
              .split('\n')
              .map((w) => w.trim().toLowerCase())
              .filter((w) => w.trim() !== ''),
          );

          submitForm(
            e.target.value
              .split('\n')
              .map((w) => w.trim().toLowerCase())
              .filter((w) => w.trim() !== ''),
          );
        }}
        multiline
        rows={20}
      />
      <Box sx={{}}>
        {websites.length > 0 ? (
          <>
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', color: DEFAULT_COLOR_THEME }}
            >
              Total Websites: [{websites.length}]
            </Typography>
            <Box sx={{ columns: 2 }}>
              <List>
                {websites.map((w, index) => {
                  return (
                    <ListItem
                      disableGutters
                      key={index}
                      sx={{
                        paddingLeft: 2,
                        paddingY: 0,
                      }}
                    >
                      • {w}
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default GoogleTemplatesWebsites;
