import { MenuItem, Box, Button, Typography } from '@mui/material';
import React from 'react';
import { ChangeEventType } from '../../types';
import { humanizeString } from '../../utils/stringModifier';
import { DEFAULT_COLOR_THEME } from '../../utils';
import { MdCheckCircle } from 'react-icons/md';

interface GoogleSelectMultipleProps {
  title?: string;
  options:
    | string[]
    | {
        row?: string;
        row1?: string;
        row2?: string;
        row3?: string;
        data: any;
        value: any;
      }[];
  onSelect: any;
  selected: any;
  selectAll?: boolean;
}

const GoogleSelectMultiple: React.FC<GoogleSelectMultipleProps> = ({
  options,
  onSelect,
  title,
  selected,
  selectAll = false,
}) => {
  const [selectedOption, setSelectedOption] = React.useState<any>(
    selected || [],
  );
  React.useEffect(() => {
    if (selected) {
      setSelectedOption(selected);
    }
  }, [selected]);

  React.useEffect(() => {
    console.log(`🚀 selected`);
    console.log(selected);

    if (options.length === 1) {
      //@ts-ignore
      setSelectedOption(options[0]?.data[options[0]?.value]);
      //@ts-ignore
      onSelect(options[0]?.data[options[0]?.value]);
    }

    if (selected?.length === 0 && selectAll) {
      setSelectedOption(
        options.map((option: any) => option.data[option.value]),
      );
      onSelect(options.map((option: any) => option.data[option.value]));
    } else {
      setSelectedOption(selected);
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: 1,
            color: DEFAULT_COLOR_THEME,
            fontWeight: 800,
            display: 'block',
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: 'white',
          }}
        >
          {title}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{
            paddingY: 0,
            paddingX: 2,
            height: 30,
            display: 'flex',
            gap: 1,
          }}
          onClick={() => {
            if (selectedOption?.length === options.length) {
              setSelectedOption([]);
              onSelect([]);
              return;
            }

            setSelectedOption(
              options.map((option: any) => option.data[option.value]),
            );
            onSelect(options.map((option: any) => option.data[option.value]));
          }}
        >
          {selectedOption?.length === options.length
            ? 'Deselect All'
            : 'Select All'}
        </Button>
      </Box>
      {options?.map((option: any, i: number) => (
        <Button
          key={`${option.value}-${i}`}
          sx={{
            ...styles.option,
            backgroundColor: selectedOption
              ? selectedOption?.includes(option.data[option.value])
                ? `#e6ffd4`
                : `#ffffff`
              : `#ffffff`,
            borderColor: selectedOption
              ? selectedOption?.includes(option.data[option.value])
                ? DEFAULT_COLOR_THEME
                : `#aaa`
              : `#aaa`,
          }}
          onClick={() => {
            if (selectedOption?.includes(option.data[option.value])) {
              setSelectedOption(
                selectedOption?.filter(
                  (item: any) => item !== option.data[option.value],
                ),
              );
              onSelect(
                selectedOption?.filter(
                  (item: any) => item !== option.data[option.value],
                ),
              );
              return;
            }

            setSelectedOption([
              ...(selectedOption ? selectedOption : []),
              option.data[option.value],
            ]);
            onSelect([
              ...(selectedOption ? selectedOption : []),
              option.data[option.value],
            ]);
          }}
        >
          <Box>
            {option.row ? <Box>{option.data[option.row]}</Box> : null}
            {option.row1 ? (
              <Box sx={styles.row1}>{option.data[option.row1]}</Box>
            ) : null}
            {option.row2 ? (
              <Box sx={styles.row2}>{option.data[option.row2]}</Box>
            ) : null}
            {option.row3 ? (
              <Box sx={styles.row3}>{option.data[option.row3]}</Box>
            ) : null}

            {!option.row && !option.row1 && !option.row2 && !option.row3 ? (
              <>{option.value}</>
            ) : null}
          </Box>

          {
            //selectedOption === option.data[option.value]
            selectedOption?.includes(option.data[option.value]) ? (
              <Box>
                <MdCheckCircle size={24} />
              </Box>
            ) : null
          }
        </Button>
      ))}
    </>
  );
};

const styles = {
  option: {
    width: `100%`,
    display: `flex`,
    justifyContent: `space-between`,
    textAlign: `left`,
    border: '2px solid #aaa',
    marginBottom: 1,
    textTransform: 'none',
    boxShadow: `0px 0px 5px 0px rgba(0,0,0,0.25)`,
  },
  row1: {
    fontWeight: 800,
  },
  row2: {
    fontWeight: 400,
  },
  row3: {
    fontWeight: 400,
    textDecoration: 'italic',
  },
};

export default GoogleSelectMultiple;
