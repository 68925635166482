import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  GoogleAssetsContainer,
  googleResourceLimits,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import { MdDeleteOutline } from 'react-icons/md';
import { FloatingButton } from '../../../Buttons';

interface GoogleTemplatesHeadlinesProps {
  submitForm: any;
  templateForm: any;
  setIsDirty: any;
  templateType: 'search' | 'display';
}

const GoogleTemplatesHeadlines: React.FC<GoogleTemplatesHeadlinesProps> = ({
  submitForm,
  templateForm,
  setIsDirty,
  templateType,
}) => {
  const [headlines, setHeadlines] = useState([]);
  const maxItems = googleResourceLimits.headlines[templateType].items;
  const maxLength = googleResourceLimits.headlines[templateType].length;

  useEffect(() => {
    if (templateForm && templateForm.length > 0) {
      setHeadlines(templateForm);
    }
  }, [templateForm]);

  useEffect(() => {
    submitForm(headlines);
  }, [headlines]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: `space-between`,
        alignItems: `start`,
      }}
    >
      <Box
        sx={{
          backgroundColor: `#eeeeee`,
          padding: `1rem 2rem`,
          maxWidth: 500,
          width: `100%`,
          borderRadius: 2,
        }}
      >
        <Box sx={{}}>
          <TextField
            multiline
            rows={17}
            sx={{
              backgroundColor: 'white',
              border: `2px solid ${DEFAULT_COLOR_THEME}`,
              borderRadius: 2,
            }}
            value={headlines.join('\n')}
            onChange={(e) => {
              setIsDirty(true);

              const temp = e.target.value.split('\n');
              if (temp.length > maxItems) {
                temp.splice(maxItems, temp.length - maxItems);
              }
              setHeadlines(temp);
            }}
            placeholder="Enter or paste headlines separated by new lines"
            fullWidth
          />
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: `#eeeeee`,
          padding: `1rem 2rem`,
          maxWidth: 500,
          width: `100%`,
          borderRadius: 2,
          display: headlines.length > 0 ? 'block' : 'none',
          ...scrollBarGreen,
        }}
      >
        <Typography
          variant="h6"
          fontWeight={800}
          mb={2}
          color={DEFAULT_COLOR_THEME}
        >
          Headlines [{headlines.length} of {maxItems}]
        </Typography>
        {headlines.map((headline, index) => (
          <Box
            sx={{
              textAlign: 'right',
              marginBottom: '1rem',
              maxWidth: 800,
              position: 'relative',
            }}
          >
            <TextField
              error={headline === '' || headline.length > maxLength}
              sx={{
                backgroundColor: 'white',
                '& input': {
                  paddingRight: `50px`,
                },
              }}
              id={`Headline-${index}`}
              label={`Headline ${index + 1}`}
              variant="outlined"
              name={`Headline-${index}`}
              fullWidth
              value={headline}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="delete button"
                      onClick={() => {
                        const temp = [...headlines];
                        temp.splice(index, 1);
                        setHeadlines(temp);
                        setIsDirty(true);
                      }}
                    >
                      <MdDeleteOutline />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setIsDirty(true);
                const updatedItem = [...headlines];
                updatedItem[index] = e.target.value;
                setHeadlines(updatedItem);
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color:
                    headline === '' || headline.length > maxLength
                      ? 'red'
                      : 'black',
                }}
              >
                {`${headline.length}/${maxLength}`}
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  color:
                    headline === '' || headline.length > maxLength
                      ? 'red'
                      : 'black',
                }}
              >
                {headline.length > maxLength ? 'Max length reached' : ''}
                {headline === '' ? 'Headlines cannot be empty' : ''}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default GoogleTemplatesHeadlines;
