import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ChatsListTable from '../../components/Tables/ChatsList/ChatsListTable';
import { getChatsAssociatedWithBrands } from '../../services/chats';
import { Brand, ChangeEventType, ChatUser } from '../../types';
import { AuthContext } from '../../context';
import {
  ADMIN,
  AGENCY,
  BRAND_MANAGER,
  SALESPERSON,
  SUPER_ADMIN,
} from '../../utils';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { CSVLink } from 'react-csv';
import { useSearchParams } from 'react-router-dom';
import { PrimaryButton } from '../../components/Buttons';
import { chatStatus } from '../../utils/constants/chatStatus';
import { io } from 'socket.io-client';
import { webSocketUrl } from '../../services/api';
import DesktopChatDialog from '../../components/Forms/Chats/DesktopChatDialog';
import { setChatSeen } from '../../utils/chatDialogHelper';
import styles from '../../assets/styles/pages/Chats.module.scss';
import PageHeader from '../../components/PageHeader';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CircularLoading from '../../components/CircularLoading';

const Chats: React.FC = () => {
  const [searchParams] = useSearchParams();
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const salespersonId: string = brand
    ? brand?.salesperson?._id
    : searchParams.get('salespersonId');
  const [chats, setChats] = useState<ChatUser[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { state } = useContext(AuthContext);
  const isAdmin = state.role === ADMIN;
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isSalesperson = state.role === SALESPERSON;
  const isAgency = state.role === AGENCY;
  const isBrandManager = state.role === BRAND_MANAGER;
  const [rowData, setRowData] = useState<number>(0);
  const [resultFilter, setResultFilter] = useState<string>('qualified');
  const [chat, setChat] = useState<ChatUser>(null);
  const [search, setSearch] = useState<string>('');
  const [selectedBrand, setSelectedBrand] = useState<Brand>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  const socket: any = useRef();
  const theme = useTheme();
  const mdAndDown = useMediaQuery(theme.breakpoints.down('md'));
  const isXsOnly = useMediaQuery(theme.breakpoints.only('xs'));

  useEffect(() => {
    if (location) return setSelectedBrand(location);

    if (brand) return setSelectedBrand(brand);
  }, [brand, location]);

  useEffect(() => {
    socket.current = io(webSocketUrl);
    socket.current.on('sayHi', (data: string) => {
      console.log('data', data);
    });
  }, []);

  useEffect(() => {
    if (selectedBrand) {
      setChats([]);
      getAllChats(
        paginationModel.page + 1,
        paginationModel.pageSize,
        resultFilter,
        search,
      );
    }
  }, [selectedBrand, paginationModel]);

  /**
   * Search Chat Hooks
   */
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getAllChats(
        paginationModel.page + 1,
        paginationModel.pageSize,
        resultFilter,
        search,
      );
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (chats && !loading) {
        if (isAdmin || isSuperAdmin) {
          socket.current.emit(
            'callChats',
            selectedBrand?._id,
            salespersonId,
            state.roleBasedId,
            null,
            paginationModel.page + 1,
            paginationModel.pageSize,
            resultFilter,
            search,
          );
        } else if (isSalesperson) {
          socket.current.emit(
            'callChats',
            selectedBrand?._id,
            state.roleBasedId,
            state.roleBasedId,
            null,
            paginationModel.page + 1,
            paginationModel.pageSize,
            resultFilter,
            search,
          );
        } else if (isAgency) {
          socket.current.emit(
            'callChats',
            selectedBrand?._id,
            null,
            state.roleBasedId,
            null,
            paginationModel.page + 1,
            paginationModel.pageSize,
            resultFilter,
            search,
          );
        }

        socket.current.on('getChats', (chats: any, roleBasedId: string) => {
          if (state.roleBasedId === roleBasedId) {
            setChats(chats);
          }
        });
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [selectedBrand, paginationModel, resultFilter, search]);

  useEffect(() => {
    const index = chats.findIndex((result: any) => {
      return result._id === chat?._id;
    });

    setChat(chats[index]);
  }, [chats]);

  const handleSrollPagination = async (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const bottom = Math.abs(scrollHeight - (scrollTop + clientHeight)) < 5;

    if (bottom) {
      if (chats.length !== rowData) {
        setPaginationModel({
          page:
            paginationModel.page === 0
              ? paginationModel.page + 2
              : paginationModel.page + 1,
          pageSize: paginationModel.pageSize,
        });
      }
    }
  };

  const getAllChats = async (
    pageNum: number,
    pageLimit: number,
    filter: string,
    search: string,
  ) => {
    try {
      setLoading(true);
      const data = await getChatsAssociatedWithBrands(
        selectedBrand?._id,
        pageNum,
        pageLimit,
        filter,
        search,
      );

      setChats([...chats, ...data.data]);
      setRowData(data.count);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const setFilter = async (e: string) => {
    setResultFilter(e);
    setPaginationModel({ page: 0, pageSize: 25 });
    getAllChats(1, 25, e, search);
  };

  const filteredRows = () => {
    return chats.filter((result) => {
      const qualified = () => {
        if (result.investment) {
          if (
            parseFloat(result.investment) >=
            result.brand.investmentRequirement?.num
          ) {
            return chatStatus.Qualified;
          }
        }

        return chatStatus.NotQualified;
      };

      return (
        result.result === 'LeadSentToBrand' ||
        result.result === 'CallScheduled' ||
        qualified() === chatStatus.Qualified
      );
    });
  };

  const onSelectedChat = async (chat: ChatUser) => {
    await setChat(chat);

    if (isSalesperson) {
      await setChatSeen(chat._id);
    }
  };

  const handleOnSearch = (e: ChangeEventType) => {
    setChat(null);
    setSearch(e.target.value);
    setPaginationModel({ page: 0, pageSize: 25 });
  };

  return (
    <div
      className={`${styles.chats} ${isXsOnly ? `${styles['-mobile']}` : ''}`}
    >
      <CircularLoading loading={loading} />

      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader title="Chats" marginBottom="0px" />

          {isAdmin || isSuperAdmin ? (
            <div className={styles.controls}>
              <RadioGroup
                row
                aria-labelledby="selectValueToRedirect"
                name="selectValueToRedirect"
                defaultValue="qualified"
                onChange={(e: ChangeEventType) => setFilter(e.target.value)}
              >
                <FormControlLabel
                  value="qualified"
                  control={<Radio size="small" />}
                  label="Qualified"
                />

                <FormControlLabel
                  value="all"
                  control={<Radio size="small" />}
                  label="All"
                />
              </RadioGroup>

              <Box>
                <CSVLink
                  data={
                    chats.length > 0
                      ? resultFilter === 'all'
                        ? chats
                        : filteredRows()
                      : []
                  }
                  filename={`chats_${moment().unix()}.csv`}
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                >
                  <PrimaryButton
                    variant="outlined"
                    type="button"
                    title="export"
                    startIcon={<FileDownloadOutlinedIcon />}
                  />
                </CSVLink>
              </Box>
            </div>
          ) : null}
        </div>

        {mdAndDown ? (
          <div>
            <ChatsListTable
              chats={chats}
              loading={loading}
              rowData={rowData}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
            />
          </div>
        ) : (
          <div>
            <DesktopChatDialog
              chats={chats}
              onSelectedChat={onSelectedChat}
              chat={chat}
              loading={loading}
              handleSrollPagination={handleSrollPagination}
              handleOnSearch={handleOnSearch}
              isAgency={isAgency}
              isBrandManager={isBrandManager}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Chats;
