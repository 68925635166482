import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { CustomAudience, FbTargetSearch } from '../../../types';
import FormHeader from '../../Forms/FormHeader';
import { constantStringToHumanized } from '../../../utils/stringModifier';
import Spinner from '../../Spinner';
import { numberShortener } from '../../../utils/numberFormatter';

interface FacebookAdTargetsProps {
  targeting: FbTargetSearch;
  loading: boolean;
  isTemplate: boolean;
  customAudiences: CustomAudience[];
  audienceSize: any;
  audienceSizeLoading: boolean;
  audienceSizeError: string;
}

const FacebookAdTargets: React.FC<FacebookAdTargetsProps> = ({
  targeting,
  loading,
  isTemplate,
  customAudiences = [],
  audienceSize,
  audienceSizeLoading,
  audienceSizeError,
}) => {
  const buildAddress = (value: any, geolocationKey: string) => {
    try {
      let address: string = '';

      const { name, region, key, country, radius, distance_unit } = value;

      switch (geolocationKey) {
        case 'cities':
          address = `${name}, ${region}, ${country} (${radius} ${distance_unit})`;
          break;
        case 'regions':
          address = `${name}, ${country}`;
          break;
        case 'geo_markets':
          address = `${key}, ${name}, ${country}`;
          break;
        case 'zips':
          address = `${name}, ${country}`;
          break;
        default:
          address = value;
          break;
      }

      return address;
    } catch (error: any) {
      console.log(error);
    }
  };

  const getGender = (genders: number[]) => {
    if (genders?.length > 1) {
      return 'All';
    }

    if (genders[0] === 2) {
      return 'Female';
    }

    return 'Male';
  };

  const renderDetailedTargets = (targets: any) => {
    return Object.keys(targets || {}).map((key) => {
      if (
        [
          'exclusions',
          'flexible_spec',
          'excluded_geo_locations',
          'geo_locations',
          'genders',
          'age_min',
          'age_max',
          'targeting_relaxation_types',
          'targeting_automation',
          'targeting_optimization',
          'custom_audiences',
          'device_platforms',
          'publisher_platforms',
          'facebook_positions',
          'instagram_positions',
          'brand_safety_content_filter_levels',
        ].includes(key)
      ) {
        return <></>;
      }

      return (
        <Grid item xs={12} sx={{ flexDirection: 'column' }}>
          <Typography variant="body1" fontWeight="bold">
            {constantStringToHumanized(key)}
          </Typography>

          {targets[key]?.map((target: any) => {
            return (
              <Typography variant="body2">
                {typeof target === 'object' ? target.name || target.id : target}
              </Typography>
            );
          })}
        </Grid>
      );
    });
  };

  const renderGeolocationTargets = (geolocation: any) => {
    return Object.keys(geolocation || {}).map((key: string) => {
      if (key === 'location_types') return <></>;

      return (
        <>
          <Grid item xs={12} sx={{ flexDirection: 'column' }}>
            <Typography variant="body1" fontWeight="bold" component="div">
              {constantStringToHumanized(key)}
            </Typography>

            {geolocation[key]?.map((location: any) => {
              return (
                <Typography variant="body2" component="div">
                  {`- ${buildAddress(location, key)}`}
                </Typography>
              );
            })}
          </Grid>
        </>
      );
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormHeader title="Audience" />
      </Grid>

      {loading ? (
        <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
          <Spinner />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sx={{ flexDirection: 'column' }}>
            <Typography variant="body1" fontWeight="bold">
              Audience Size
            </Typography>

            {audienceSizeLoading ? (
              <Box>
                <Spinner />
              </Box>
            ) : (
              <Typography variant="body2">
                {`${numberShortener(
                  audienceSize?.users_lower_bound || 0,
                )}-${numberShortener(audienceSize?.users_upper_bound || 0)}`}
              </Typography>
            )}

            {audienceSizeError ? (
              <Typography variant="caption" sx={{ color: 'red' }}>
                {audienceSizeError}
              </Typography>
            ) : null}
          </Grid>

          {targeting?.custom_audiences?.length > 0 ? (
            <>
              <Grid item xs={12} sx={{ flexDirection: 'column' }}>
                <Typography variant="body1" fontWeight="bold">
                  Custom Audiences
                </Typography>

                {targeting?.custom_audiences?.map((custom: any) => {
                  const customAudience = customAudiences?.find(
                    (customAudience: CustomAudience) =>
                      customAudience.id === custom.id,
                  );
                  return (
                    <Typography variant="body2">
                      {isTemplate
                        ? `${customAudience?.name} (${customAudience?.id})`
                        : `${custom.name} (${custom.id})`}
                    </Typography>
                  );
                })}
              </Grid>

              <Grid item xs={12} sx={{ flexDirection: 'column' }}>
                <Typography variant="body1" fontWeight="bold">
                  Advantage Lookalike
                </Typography>

                <Typography variant="body2">
                  {targeting?.targeting_relaxation_types?.lookalike === 1
                    ? 'Enabled'
                    : 'Disabled'}
                </Typography>
              </Grid>
            </>
          ) : null}

          <Grid item xs={12} sx={{ flexDirection: 'column' }}>
            <Typography variant="body1" fontWeight="bold">
              Detailed Targets
            </Typography>
          </Grid>

          {renderDetailedTargets(targeting)}

          {targeting?.flexible_spec?.length > 0 ? (
            <>
              <Grid item xs={12} sx={{ flexDirection: 'column' }}>
                <Typography variant="body1" fontWeight="bold">
                  Narrow Audience
                </Typography>
              </Grid>

              {targeting?.flexible_spec?.map((flexible: any) => {
                return (
                  <>
                    <Grid item xs={12} sx={{ flexDirection: 'column' }}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        textAlign="center"
                      >
                        AND
                      </Typography>
                    </Grid>

                    {renderDetailedTargets(flexible)}
                  </>
                );
              })}
            </>
          ) : null}

          {targeting?.exclusions ? (
            <>
              <Grid item xs={12} sx={{ flexDirection: 'column' }}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  textAlign="center"
                >
                  Excluded Targets
                </Typography>
              </Grid>

              {renderDetailedTargets(targeting?.exclusions)}
            </>
          ) : null}

          {targeting?.targeting_optimization ? (
            <Grid item xs={12} sx={{ flexDirection: 'column' }}>
              <Typography variant="body1" fontWeight="bold">
                Advantage Detailed Targeting
              </Typography>

              <Typography variant="body2">
                {targeting?.targeting_optimization === 'expansion_all'
                  ? 'Enabled'
                  : 'Disabled'}
              </Typography>
            </Grid>
          ) : null}

          <Grid item xs={12} sx={{ flexDirection: 'column' }}>
            <Typography variant="body1" fontWeight="bold">
              Age Range
            </Typography>

            <Typography variant="body2">
              {`${targeting?.age_min}-${targeting?.age_max}`}
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ flexDirection: 'column' }}>
            <Typography variant="body1" fontWeight="bold">
              Gender
            </Typography>

            <Typography variant="body2">
              {getGender(targeting?.genders || [])}
            </Typography>
          </Grid>

          {!isTemplate ? (
            <>
              <Grid item xs={12}>
                <FormHeader title="Target Geolocations" />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  textAlign="center"
                >
                  Included
                </Typography>
              </Grid>

              {renderGeolocationTargets(targeting?.geo_locations)}

              {targeting?.excluded_geo_locations ? (
                <>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      textAlign="center"
                    >
                      Excluded
                    </Typography>
                  </Grid>

                  {renderGeolocationTargets(targeting?.excluded_geo_locations)}
                </>
              ) : null}
            </>
          ) : null}
        </>
      )}
    </Grid>
  );
};

export default FacebookAdTargets;
