import React from 'react';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { syncFailedSummaryColumns } from './SyncSummaryTableColumns';

interface SyncFailedSummaryTableProps {
  rows: any[];
}

const SyncFailedSummaryTable: React.FC<SyncFailedSummaryTableProps> = ({
  rows,
}) => {
  return (
    <DataGridPremium
      rows={rows}
      columns={syncFailedSummaryColumns}
      autoHeight
      density="compact"
      hideFooterSelectedRowCount
      className={styles.table}
    />
  );
};

export default SyncFailedSummaryTable;
