import React, { Dispatch, SetStateAction } from 'react';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridGroupNode,
} from '@mui/x-data-grid-premium';
import {
  AdLead,
  ClientTetherSalesCycleReport,
  ClientTetherSalesCycleReportData,
} from '../../../types';
import {
  ctSalesCycleColumns,
  ctSalesCycleColumnsByAds,
  ctSalesCycleGroupingColumn,
} from './ClientTetherSalesCycleReportTableColumns';
import { CustomLoadingOverlay } from '../../Loading';

interface ClientTetherSalesCycleReportTableProps {
  data: ClientTetherSalesCycleReport;
  loading: boolean;
  onOpenLeadDetails: (lead: AdLead) => void;
  expandedKeys: string[];
  setExpandedKeys: Dispatch<SetStateAction<string[]>>;
  isByAds?: boolean;
  setSelectedAdId: Dispatch<SetStateAction<string>>;
  setOpenAdDetails: Dispatch<SetStateAction<boolean>>;
  commissionMode?: boolean;
}

const ClientTetherSalesCycleReportTable: React.FC<
  ClientTetherSalesCycleReportTableProps
> = ({
  data,
  loading,
  onOpenLeadDetails,
  isByAds,
  expandedKeys,
  setExpandedKeys,
  setSelectedAdId,
  setOpenAdDetails,
  commissionMode = false,
}) => {
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const getTreeDataPath: DataGridPremiumProps['getTreeDataPath'] = (row) =>
    row.group;

  const calculateSpend = (obj: ClientTetherSalesCycleReportData) => {
    if (obj.level === 'ad') {
      return obj.spend;
    }
    const adSetLeads = data?.data?.find(
      (o: ClientTetherSalesCycleReportData) =>
        o.level === 'ad' && o.id === obj.id.split('-')[1],
    );

    const spendPerLead =
      (adSetLeads?.leads || 0) > 0
        ? (adSetLeads?.spend || 0) / (adSetLeads?.leads || 0)
        : 0;

    return (obj?.leads || 0) * spendPerLead;
  };

  const calculateRoi = (
    data: ClientTetherSalesCycleReportData,
    spend: number,
  ) => {
    const totalSpend = !isByAds ? spend : data.spend;

    const roi = parseInt(
      (((data.value - totalSpend) / totalSpend) * 100).toFixed(0),
    );

    return roi >= 0 ? roi : 0;
  };

  const rows = (data?.data || [])?.map(
    (o: ClientTetherSalesCycleReportData) => {
      return {
        ...o,
        roi: calculateRoi(o, calculateSpend(o)),
        cpl: o.leads > 0 ? o.spend / o.leads : 0,
        spend: calculateSpend(o),
      };
    },
    [],
  );

  const cellBgFormatter = (params: any) => {
    if (isByAds) {
      if (params.row.level === 'ad') {
        return 'top';
      }

      if (params.row.level === 'sales_cycle') {
        return 'middle';
      }
    } else {
      if (params.row.level === 'sales_cycle') {
        return 'top';
      }
    }

    return '';
  };

  const renderColumns = () => {
    if (isByAds) {
      return ctSalesCycleColumnsByAds(onOpenLeadDetails);
    }

    return ctSalesCycleColumns(xsOnly, commissionMode);
  };

  return (
    <Box
      sx={{
        '& .top': {
          backgroundColor: '#B6c61a',
        },
        '& .middle': {
          backgroundColor: '#b9d870',
        },
      }}
    >
      <DataGridPremium
        initialState={{
          sorting: { sortModel: [{ field: 'order', sort: 'desc' }] },
        }}
        treeData
        groupingColDef={ctSalesCycleGroupingColumn(
          onOpenLeadDetails,
          isByAds,
          xsOnly,
          expandedKeys,
          setExpandedKeys,
          data?.data || [],
        )}
        getTreeDataPath={getTreeDataPath}
        rows={loading ? [] : rows}
        columns={renderColumns()}
        autoHeight
        density="compact"
        hideFooterSelectedRowCount
        hideFooterRowCount
        hideFooter
        loading={loading}
        className={styles.table}
        disableRowSelectionOnClick
        slots={{ loadingOverlay: CustomLoadingOverlay }}
        getRowId={(row) => row.id}
        getCellClassName={(params: any) => {
          return cellBgFormatter(params);
        }}
        isGroupExpandedByDefault={(row: GridGroupNode) => {
          return expandedKeys.includes(row.groupingKey as string);
        }}
        onCellClick={(params) => {
          if (params.row.level === 'lead' && !isByAds) {
            if (params.field === 'adName') {
              setSelectedAdId(params.row.adId);
              setOpenAdDetails(true);
            }

            if (params.field === '__tree_data_group__') {
              onOpenLeadDetails(params.row.lead);
            }
          }
        }}
        onRowClick={(params, event: any) => {
          if (isByAds && params.row.level === 'ad') {
            setSelectedAdId(params.row.id.split('-')[0]);
            setOpenAdDetails(true);
          }
        }}
      />
    </Box>
  );
};

export default ClientTetherSalesCycleReportTable;
