import { GridColDef } from '@mui/x-data-grid-premium';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { toCurrency } from '../../../utils/numberFormatter';

export const syncSuccessSummaryColumns: GridColDef[] = [
  {
    field: 'adset',
    headerClassName: styles.header,
    headerName: 'Adset Name',
    flex: 1,
  },
  {
    field: 'id',
    headerClassName: styles.header,
    headerName: 'Adset ID',
    flex: 1,
  },
  {
    field: 'oldDaily',
    headerClassName: styles.header,
    headerName: 'Previous Daily Budget',
    flex: 1,
    valueGetter: (_, row) => row.oldDaily,
    renderCell: (params) => {
      const { oldDaily } = params.row;

      return <div>{toCurrency('USD', oldDaily)}</div>;
    },
  },
  {
    field: 'currentDaily',
    headerClassName: styles.header,
    headerName: 'New Daily Budget',
    flex: 1,
    valueGetter: (_, row) => row.currentDaily,
    renderCell: (params) => {
      const { currentDaily } = params.row;

      return <div>{toCurrency('USD', currentDaily)}</div>;
    },
  },
];

export const syncFailedSummaryColumns: GridColDef[] = [
  {
    field: 'adset',
    headerClassName: styles.header,
    headerName: 'Adset Name',
    flex: 1,
  },
  {
    field: 'id',
    headerClassName: styles.header,
    headerName: 'Adset ID',
    flex: 1,
  },
  {
    field: 'error',
    headerClassName: styles.header,
    headerName: 'Error',
    flex: 1,
  },
];
