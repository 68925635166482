import axios from 'axios';
import { apiBaseUrl } from './api';
import { CrmSetting } from '../types/ICrm';
import { CustomCrmFieldMapForm } from '../types';

export const getCrmSettingsByBrand = async (brandId: string) => {
  const data = await axios.get(`${apiBaseUrl}/crm/settings`, {
    params: { brandId },
  });

  return data?.data;
};

export const getCustomFieldMapping = async (leadFormId: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/crm/custom-field-mapping/${leadFormId}`,
  );

  return data?.data;
};

export const createOrUpdateCustomFieldMapping = async (
  leadFormId: string,
  crmMapForms: CustomCrmFieldMapForm[],
) => {
  const data = await axios.post(
    `${apiBaseUrl}/crm/custom-field-mapping/${leadFormId}`,
    { crmMapForms },
  );

  return data?.data;
};

export const createOrUpdateCrmSettings = async (
  brandId: string,
  params: CrmSetting,
) => {
  const data = await axios.post(`${apiBaseUrl}/crm/settings`, params, {
    params: { brandId },
  });

  return data?.data;
};
