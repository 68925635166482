import { GridColDef } from '@mui/x-data-grid-premium';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { toCurrency } from '../../../utils/numberFormatter';
import { constantStringToHumanized } from '../../../utils/stringModifier';
import { Tooltip, Typography } from '@mui/material';
import { defaultProps } from '../../../utils/helpers/TableHelpers';

export const creditBalanceColumns = (isMobile: boolean) => {
  const columns: GridColDef[] = [
    {
      ...defaultProps(isMobile, 130),
      field: 'description',
      headerClassName: styles.header,
      headerName: 'Description',
      disableColumnMenu: true,
      renderCell: (params) => {
        const { description } = params.row;

        return (
          <div className={styles.name}>
            <Tooltip
              title={<Typography variant="body2">{description}</Typography>}
              disableInteractive
            >
              {description}
            </Tooltip>
          </div>
        );
      },
    },
    {
      ...defaultProps(isMobile, 130),
      field: 'date',
      headerClassName: styles.header,
      headerName: 'Credit Date',
      disableColumnMenu: true,
      renderCell: (params) => {
        const { date } = params.row;

        return <div className={styles.name}>{date}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 130),
      field: 'adjustmentType',
      headerClassName: styles.header,
      headerName: 'Adjustment Type',
      disableColumnMenu: true,
      renderCell: (params) => {
        const { type } = params.row;

        return (
          <div className={styles.name}>{constantStringToHumanized(type)}</div>
        );
      },
    },
    {
      ...defaultProps(isMobile, 130),
      field: 'type',
      headerClassName: styles.header,
      headerName: 'Type',
      disableColumnMenu: true,
      renderCell: (params) => {
        const { amount } = params.row;

        return (
          <div className={styles.name}>{amount < 0 ? 'Credit' : 'Debit'}</div>
        );
      },
    },
    {
      ...defaultProps(isMobile, 130),
      field: 'amount',
      headerClassName: styles.header,
      headerName: 'Amount',
      disableColumnMenu: true,
      renderCell: (params) => {
        const { amount } = params.row;

        return (
          <div className={styles.name}>
            {toCurrency('USD', Math.abs(amount / 100))}
          </div>
        );
      },
    },
  ];

  return columns;
};
