import {
  Box,
  Button,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GoogleAssetsContainer } from '../../../../utils/constants/google';
import {
  fetchGoogleCallouts,
  googleTestFunction,
} from '../../../../services/googleAds';
import CircularLoading from '../../../CircularLoading';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions/ToggleAlert';

interface AssetGoogleCalloutsProps {
  brand: any;
}

const AssetGoogleConversions: React.FC<AssetGoogleCalloutsProps> = ({
  brand,
}) => {
  const dispatch = useDispatch();
  const [conversionActions, setConversionActions] = useState<any[]>([]);
  const [callouts, setCallouts] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [calloutArea, setCalloutArea] = useState<string>('');
  const [newCallouts, setNewCallouts] = useState<string[]>([]);
  const handleInputChange = (e: any) => {
    setCalloutArea(e.target.value);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    dispatch(
      toggleAlert({
        toggle: true,
        message: 'Copied to clipboard',
        type: 'success',
      }),
    );
  };

  useEffect(() => {
    console.log(`🚀 brand`, brand);
    googleTestFunction({
      brandId: brand._id,
    }).then((res) => {
      console.log(
        'res',
        res[1].conversion_action.map((r: any) => r.conversionAction),
      );

      setConversionActions(
        res[1].conversion_action.map((r: any) => r.conversionAction),
      );
      // res.query.map(
      //   (r: any) =>
      //     `https://youtu.be/${r?.asset?.youtubeVideoAsset?.youtubeVideoId}`,
      // );
    });
  }, []);
  return (
    <Box sx={GoogleAssetsContainer}>
      <CircularLoading loading={isLoading} />
      <Typography
        variant="h5"
        fontWeight={800}
        textAlign={`center`}
        color={DEFAULT_COLOR_THEME}
      >
        Google Conversions
      </Typography>

      <Box sx={{ overflowX: 'auto' }}>
        {conversionActions.map((ca: any, i: number) => (
          <Box
            key={i}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              itemsAlign: 'start',
              marginBottom: 2,
              borderBottom: '1px solid #ccc',
              padding: 2,
            }}
          >
            <Box sx={{ flexShrink: 0 }}>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                {ca.name}
              </Typography>
              <Typography variant="body1">category: {ca.category}</Typography>
              <Typography variant="body1">type: {ca.type}</Typography>
              <Typography variant="body1">
                countingType: {ca.countingType}
              </Typography>
              <Typography variant="body1">status: {ca.status}</Typography>
              <Typography variant="body1">origin: {ca.origin}</Typography>
              <Typography variant="body1">{ca.id}</Typography>
            </Box>
            {ca.tagSnippets ? (
              <>
                {ca.tagSnippets.map((tag: any, i: number) => (
                  <Box
                    sx={{
                      padding: 2,
                      minWidth: `600px`,
                      minHeight: `200px`,
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: `#aeaeae`,
                        padding: 2,
                        border: `1px solid #ccc`,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                        itemsAlign: 'center',
                        gap: 4,
                      }}
                    >
                      <Typography variant="h6" color={`#fff`} fontWeight={600}>
                        {tag.type} [{tag.pageFormat}]
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: `#f5f5f5`,
                        padding: 2,
                        border: `1px solid #ccc`,
                        boxShadow: 2,
                        overflowX: 'auto',
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{
                          padding: `5px 10px`,
                          marginBottom: `10px`,
                        }}
                        onClick={() => copyToClipboard(tag.eventSnippet)}
                      >
                        Copy
                      </Button>
                      <pre>
                        <code style={{ wordWrap: 'break-word' }}>
                          {tag.eventSnippet}
                        </code>
                      </pre>
                    </Box>

                    <Box
                      sx={{
                        backgroundColor: `#f5f5f5`,
                        padding: 2,
                        border: `1px solid #ccc`,
                        boxShadow: 2,
                        overflowX: 'auto',
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{
                          padding: `5px 10px`,
                          marginBottom: `10px`,
                        }}
                        onClick={() => copyToClipboard(tag.globalSiteTag)}
                      >
                        Copy
                      </Button>
                      <pre>
                        <code style={{ wordWrap: 'break-word' }}>
                          {tag.globalSiteTag}
                        </code>
                      </pre>
                    </Box>
                  </Box>
                ))}
              </>
            ) : null}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default AssetGoogleConversions;
