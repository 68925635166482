import { Grid, Typography } from '@mui/material';
import React from 'react';
import {
  LeadFormData,
  LeadNotification,
  LeadNotificationBodyField,
} from '../../../types';
import FormHeader from '../../Forms/FormHeader';
import Spinner from '../../Spinner';
import { getPlaceholderValue } from '../../../utils/helpers/LeadHelpers';

interface LeadNotificationDetailsProps {
  form: LeadFormData;
  notification: LeadNotification;
  loading: boolean;
}

const LeadNotificationDetails: React.FC<LeadNotificationDetailsProps> = ({
  form,
  notification,
  loading,
}) => {
  const getPreviewValue = (fieldValue: string) => {
    const matches = fieldValue.match(/<<(.+?)>>/g);
    let value: string = fieldValue;

    if (matches) {
      matches.forEach((match) => {
        value = value.replace(match, getPlaceholderValue(match));
      });
    }

    return value;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormHeader title="Lead Notification" />
      </Grid>

      {loading ? (
        <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
          <Spinner />
        </Grid>
      ) : (
        <>
          {form?.enableSendLead ? (
            <>
              <Grid item xs={12}>
                <Typography variant="body1" fontWeight="bold">
                  Email
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1" fontWeight="bold">
                  Subject
                </Typography>

                <Typography variant="body2">{notification?.subject}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1" fontWeight="bold">
                  Recipients
                </Typography>

                {notification?.recipients?.map((recipient: string) => {
                  return <Typography variant="body2">{recipient}</Typography>;
                })}
              </Grid>

              {notification?.cc?.length > 0 ? (
                <Grid item xs={12}>
                  <Typography variant="body1" fontWeight="bold">
                    CC
                  </Typography>

                  {notification?.cc?.map((recipient: string) => {
                    return <Typography variant="body2">{recipient}</Typography>;
                  })}
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <Typography variant="body1" fontWeight="bold">
                  Body Preview
                </Typography>

                {notification?.bodyFields?.map(
                  (field: LeadNotificationBodyField, index: number) => {
                    return (
                      <Typography
                        variant="body2"
                        key={`preview-field-${index}`}
                      >
                        {`${field.label}: ${getPreviewValue(field.value)}`}
                      </Typography>
                    );
                  },
                )}
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="bold">
                Email
              </Typography>

              <Typography variant="body2">Disabled</Typography>
            </Grid>
          )}

          {form?.enableZapierWebhook ? (
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="bold">
                Zapier Webhook URL
              </Typography>

              <Typography variant="body2">
                {notification?.zapierWebhookUrl}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="bold">
                Zapier Webhook
              </Typography>

              <Typography variant="body2">Disabled</Typography>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default LeadNotificationDetails;
