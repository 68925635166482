import React from 'react';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { Box } from '@mui/material';
import momentTz from 'moment-timezone';
import { StripePayout } from '../../../types';
import { accountPayoutColumns } from './AccoutPayoutListTableColumns';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { CustomLoadingOverlay } from '../../Loading';
import { useSelector } from 'react-redux';

interface AccountPayoutListTableProps {
  payouts: StripePayout[];
  loading: boolean;
}

const AccountPayoutListTable: React.FC<AccountPayoutListTableProps> = ({
  payouts,
  loading,
}) => {
  const timezone: string = useSelector(
    (state: any) => state?.brandTimezone?.timezone,
  );

  const rows = payouts.map((payout, index) => {
    return {
      id: payout.id || `${index}`,
      amount: payout.amount / 100,
      arrivalDate: payout.arrival_date,
      formattedArrivalDate: momentTz
        .unix(payout.arrival_date)
        .tz(timezone)
        .format('LL'),
      status: payout.status,
      type: payout.type,
      createdAt: payout.created,
      formattedDate: momentTz.unix(payout.created).tz(timezone).format('LL'),
    };
  }, []);

  return (
    <Box>
      <DataGridPremium
        className={styles.table}
        density="compact"
        rows={rows}
        columns={accountPayoutColumns}
        autoHeight
        getRowHeight={() => 50}
        hideFooterSelectedRowCount
        loading={loading}
        slots={{ loadingOverlay: CustomLoadingOverlay }}
      />
    </Box>
  );
};

export default AccountPayoutListTable;
