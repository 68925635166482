import { Grid, TextField, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import {
  ChangeEventType,
  FBLeadGenForm,
  FBLeadGenQuestionForm,
  FBLeadQuestionOption,
} from '../../../../../types';

interface LeadFormQuestionFieldsFormProps {
  customQuestions: FBLeadGenQuestionForm[];
  prefillQuestions: FBLeadGenQuestionForm[];
  formValues: FBLeadGenForm;
  setFormValues: Dispatch<SetStateAction<FBLeadGenForm>>;
}

const LeadFormQuestionFieldsForm: React.FC<LeadFormQuestionFieldsFormProps> = ({
  customQuestions,
  prefillQuestions,
  formValues,
  setFormValues,
}) => {
  const handleOnChangeFields = (e: ChangeEventType, index: number) => {
    let temp: FBLeadGenQuestionForm[] = [...customQuestions];

    temp[index].key = e.target.value;

    setFormValues({
      ...formValues,
      questions: [...temp, ...prefillQuestions],
    });
  };

  const handleOnChangeOptionFields = (
    e: ChangeEventType,
    questionIndex: number,
    optionIndex: number,
  ) => {
    let temp: FBLeadGenQuestionForm[] = [...customQuestions];

    temp[questionIndex].options[optionIndex].key = e.target.value;

    setFormValues({
      ...formValues,
      questions: [...temp, ...prefillQuestions],
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bold">
            Change how your field names appear when you export files or build
            your lead notification.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            {customQuestions?.map(
              (question: FBLeadGenQuestionForm, index: number) => {
                return (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        variant="standard"
                        type="text"
                        key={`key-${index + 1}`}
                        name="key"
                        label={question.label}
                        onChange={(e: ChangeEventType) =>
                          handleOnChangeFields(e, index)
                        }
                        value={question.key}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        //error={errors?.agency ? true : false}
                        //helperText={errors?.agency ? errors.agency : ''}
                      />
                    </Grid>

                    {question?.options?.map(
                      (option: FBLeadQuestionOption, optionIndex: number) => {
                        return (
                          <Grid item xs={12} sx={{ marginLeft: '30px' }}>
                            <TextField
                              fullWidth
                              required
                              variant="standard"
                              type="text"
                              key={`option-${index + 1}-${optionIndex}`}
                              name="key"
                              label={option.value}
                              onChange={(e: ChangeEventType) =>
                                handleOnChangeOptionFields(
                                  e,
                                  index,
                                  optionIndex,
                                )
                              }
                              value={option.key}
                              InputLabelProps={{ shrink: true }}
                              size="small"
                              //error={errors?.agency ? true : false}
                              //helperText={errors?.agency ? errors.agency : ''}
                            />
                          </Grid>
                        );
                      },
                    )}
                  </>
                );
              },
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LeadFormQuestionFieldsForm;
