import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  ClientTetherSettingsSalesCycle,
  CrmSetting,
} from '../../../../types/ICrm';
import {
  Brand,
  ChangeEventType,
  ClientTetherSalesCycle,
} from '../../../../types';
import { FloatingButton } from '../../../Buttons';
import { createOrUpdateCrmSettings } from '../../../../services/crm';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions';
import ModalHeader from '../../../Modal/ModalHeader';
import {
  arrayObjectUniqueFilter,
  orderArrayOfObject,
} from '../../../../utils/arrayFormatter';
import { useForm } from 'react-hook-form';
import { fetchSalesCycles } from '../../../../services/clientTether';
import CircularLoading from '../../../CircularLoading';

interface SalesCycleContact {
  contactType: string;
  contactName: string;
}

interface ClientTetherSalesCycleFormProps {
  brand: Brand;
  formValues: CrmSetting;
  setFormValues: Dispatch<SetStateAction<CrmSetting>>;
  setCrmSettings: Dispatch<SetStateAction<CrmSetting>>;
  onClose: () => void;
}

const ClientTetherSalesCycleForm: React.FC<ClientTetherSalesCycleFormProps> = ({
  brand,
  formValues,
  setFormValues,
  setCrmSettings,
  onClose,
}) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: 'onSubmit' });
  const dispatch = useDispatch();
  const isContactTypeExisted =
    !!formValues?.clientTetherSettings?.salesCycleContactType;
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [salesCycleContacts, setSalesCycleContacts] = useState<
    SalesCycleContact[]
  >([]);
  const [salesCycles, setSalesCycles] = useState<ClientTetherSalesCycle[]>([]);

  useEffect(() => {
    if (
      brand &&
      formValues?.clientTetherSettings?.accessToken &&
      formValues?.clientTetherSettings?.webKey
    ) {
      getSalesCycles();
    }
  }, [
    brand,
    formValues?.clientTetherSettings?.accessToken,
    formValues?.clientTetherSettings?.webKey,
  ]);

  const getSalesCycles = async () => {
    try {
      setFetchLoading(true);

      const response = await fetchSalesCycles(
        brand?._id,
        formValues?.clientTetherSettings?.accessToken,
        formValues?.clientTetherSettings?.webKey,
      );

      const salesCycleData: ClientTetherSalesCycle[] = response.data;
      setSalesCycles(salesCycleData);

      const salesCyclesContacts: SalesCycleContact[] = salesCycleData.map(
        (salesCycle: ClientTetherSalesCycle) => {
          return {
            contactType: salesCycle.contact_type,
            contactName: salesCycle.contact_type_name,
          };
        },
      );

      const filteredSalesCycleContacts: SalesCycleContact[] =
        arrayObjectUniqueFilter(salesCyclesContacts, 'contactType');

      setSalesCycleContacts(filteredSalesCycleContacts);

      if (!isContactTypeExisted) {
        const initialContactType = filteredSalesCycleContacts[0]?.contactType;
        const filteredSalesCycles = getFilteredSalesCycles(
          initialContactType,
          salesCycleData,
        );

        const tempSalesCycles = mapSalesCycles(filteredSalesCycles);

        setFormValues({
          ...formValues,
          clientTetherSettings: {
            ...formValues?.clientTetherSettings,
            salesCycleContactType: filteredSalesCycleContacts[0]?.contactType,
            salesCycleContactName: filteredSalesCycleContacts[0]?.contactName,
            salesCycles: tempSalesCycles,
          },
        });
      } else {
        const currentContactType =
          formValues?.clientTetherSettings?.salesCycleContactType;
        const filteredSalesCycles = getFilteredSalesCycles(
          currentContactType,
          salesCycleData,
        );
        const tempSalesCycles = mapSalesCycles(filteredSalesCycles);

        const salesCycleIds = tempSalesCycles?.map(
          (salesCycle: ClientTetherSettingsSalesCycle) =>
            salesCycle.salesCycleId,
        );

        const filteredCurrentSalesCycles =
          formValues?.clientTetherSettings?.salesCycles?.filter(
            (salesCycle: ClientTetherSettingsSalesCycle) =>
              salesCycleIds.includes(salesCycle.salesCycleId),
          );

        const updatedSalesCycles = tempSalesCycles.map(
          (salesCycle: ClientTetherSettingsSalesCycle) => {
            const existingSalesCycle = filteredCurrentSalesCycles?.find(
              (currentSalesCycle) =>
                currentSalesCycle.salesCycleId === salesCycle.salesCycleId,
            );

            if (existingSalesCycle && salesCycle.closeRatio === 0) {
              salesCycle.closeRatio = existingSalesCycle.closeRatio;
            }

            return salesCycle;
          },
        );

        setFormValues({
          ...formValues,
          clientTetherSettings: {
            ...formValues?.clientTetherSettings,
            salesCycles: updatedSalesCycles,
          },
        });
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const getFilteredSalesCycles = (
    contactType: string,
    salesCycles: ClientTetherSalesCycle[],
  ) => {
    return salesCycles.filter(
      (salesCycle: ClientTetherSalesCycle) =>
        salesCycle.contact_type === contactType &&
        salesCycle.sales_cycle_active_status === '1',
    );
  };

  const mapSalesCycles = (salesCycles: ClientTetherSalesCycle[]) => {
    return salesCycles.map((salesCycle: ClientTetherSalesCycle) => ({
      salesCycleId: salesCycle.sales_cycle_id,
      salesCycleName: salesCycle.sales_cycle_name,
      closeRatio: parseFloat(salesCycle.likelihood_to_close),
      order: parseInt(salesCycle.sales_cycle_order),
    }));
  };

  const handleOnSelectSalesCycle = (value: SalesCycleContact) => {
    const filteredSalesCycles = salesCycles.filter(
      (salesCycle: ClientTetherSalesCycle) =>
        salesCycle.contact_type === value.contactType &&
        salesCycle.sales_cycle_active_status === '1',
    );

    const parsedSalesCycles: ClientTetherSettingsSalesCycle[] =
      filteredSalesCycles?.map((salesCycle: ClientTetherSalesCycle) => {
        return {
          salesCycleId: salesCycle.sales_cycle_id,
          salesCycleName: salesCycle.sales_cycle_name,
          closeRatio: parseFloat(salesCycle.likelihood_to_close),
          order: parseInt(salesCycle.sales_cycle_order),
        };
      });

    setFormValues({
      ...formValues,
      clientTetherSettings: {
        ...formValues?.clientTetherSettings,
        salesCycleContactName: value.contactName,
        salesCycleContactType: value.contactType,
        salesCycles: parsedSalesCycles,
      },
    });
  };

  const handleOnChange = (e: ChangeEventType, salesCycleId: string) => {
    clearErrors(salesCycleId);
    let temp: CrmSetting = { ...formValues };

    temp?.clientTetherSettings?.salesCycles?.forEach(
      (salesCycle: ClientTetherSettingsSalesCycle) => {
        if (salesCycle.salesCycleId === salesCycleId) {
          salesCycle.closeRatio = parseFloat(e.target.value);
        }
      },
    );

    setFormValues(temp);
  };

  const handleOnSubmit = async () => {
    try {
      setLoading(true);

      const response = await createOrUpdateCrmSettings(brand?._id, formValues);

      setCrmSettings(response.data);
      setFormValues(response.data);
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Sales cycle updated successfully',
        }),
      );
      onClose();
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ width: '300px' }}>
      <CircularLoading loading={fetchLoading} />

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12}>
          <ModalHeader title="Sales Cycle Setup" />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            id="salesCycleGroup"
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.contactType}>
                  {option.contactName}
                </li>
              );
            }}
            options={salesCycleContacts}
            value={
              salesCycleContacts?.find(
                (contact: SalesCycleContact) =>
                  contact.contactType ===
                  formValues?.clientTetherSettings?.salesCycleContactType,
              ) || null
            }
            getOptionLabel={(option) => option.contactName}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Sales Cycle Group"
                fullWidth
                size="small"
                placeholder="Select a sales cycle group"
                required
                InputLabelProps={{ shrink: true }}
              />
            )}
            disableClearable
            onChange={(_, value: any) => handleOnSelectSalesCycle(value)}
          />
        </Grid>

        {!fetchLoading
          ? orderArrayOfObject(
              formValues?.clientTetherSettings?.salesCycles || [],
              'order',
            )?.map((salesCycle: ClientTetherSettingsSalesCycle) => {
              return (
                <Grid item xs={12} key={salesCycle.salesCycleId}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: 'bold' }}
                          >
                            {salesCycle.salesCycleName}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            {...register(salesCycle.salesCycleId, {
                              required: true,
                              max: {
                                value: 100,
                                message: 'Max close ratio is 100%',
                              },
                              min: {
                                value: 0,
                                message: 'Min close ratio is 0%',
                              },
                            })}
                            variant="standard"
                            type="number"
                            id={salesCycle.salesCycleId}
                            name={salesCycle.salesCycleId}
                            label="Close Ratio"
                            onChange={(e: ChangeEventType) =>
                              handleOnChange(e, salesCycle.salesCycleId)
                            }
                            value={salesCycle?.closeRatio}
                            placeholder="Close ratio"
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                            sx={{
                              width: '100px',
                              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                                {
                                  display: 'none',
                                },
                              '& input[type=number]': {
                                MozAppearance: 'textfield',
                              },
                            }}
                            error={!!errors[salesCycle.salesCycleId]}
                            helperText={
                              errors?.[salesCycle.salesCycleId]?.message ||
                              (errors?.[salesCycle.salesCycleId] &&
                                'Close ratio is required')
                            }
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })
          : null}
      </Grid>

      {formValues?.clientTetherSettings?.salesCycleContactName ? (
        <FloatingButton
          title="Save"
          onClick={handleSubmit(handleOnSubmit)}
          loading={loading}
          bottom="0px"
          right="0px"
          sticky
          stickyPosition="right"
        />
      ) : null}
    </Box>
  );
};

export default ClientTetherSalesCycleForm;
