import React, { FormEvent, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Brand, ChangeEventType } from '../../types';
import { PrimaryButton } from '../Buttons';
import styles from '../../assets/styles/components/Forms/Form.module.scss';
import { Salesperson, SalespersonErrors } from '../../types';
import {
  createSalesperson,
  editSalesperson,
  getSingleSalesperson,
} from '../../services/salesperson';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CircularLoading from '../CircularLoading';
import PageHeader from '../PageHeader';
import { MuiTelInput } from 'mui-tel-input';
import { XsOnly } from '../../utils/breakpoints';
import { toggleAlert } from '../../redux/actions';
import ConfirmNavigationModal, { usePrompt } from '../Navigation/RoutePrompt';
import { IS_DIY_ADZ } from '../../utils';

const UserForm: React.FC = () => {
  const pathLocation = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathNames = pathLocation.pathname.split('/');
  const isEdit = pathNames.includes('edit');
  const isLocation = pathNames.includes('locations');
  const [searchParams] = useSearchParams();
  const salespersonId: string = searchParams.get('salespersonId') || null;
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);

  const [formValues, setFormValues] = useState<Salesperson>({
    name: '',
    firstname: '',
    lastname: '',
    email: '',
    cell: '',
    broker: false,
    investment: 10,
    role: 'salesperson',
    active: true,
    brand: isLocation ? brand?._id : null,
    capabilities: IS_DIY_ADZ
      ? {
          approveTemplate: false,
          createTemplate: false,
          createAds: false,
          viewReports: true,
          addLocations: false,
          addUser: false,
          createBrands: false,
          viewBudgets: false,
          editBudgets: false,
          agencyAdmin: false,
          receiveAlerts: false,
        }
      : null,
    diy: IS_DIY_ADZ,
  });
  const [errors, setErrors] = useState<SalespersonErrors>({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [openConfirmNavModal, setOpenConfirmNavModal] =
    useState<boolean>(false);
  const [autoUnblockingTx, setAutoUnblockingTx] = useState<any>(null);

  usePrompt(setOpenConfirmNavModal, setAutoUnblockingTx, isDirty);

  useEffect(() => {
    if (isEdit && salespersonId) getSalesperson();
  }, [isEdit, salespersonId]);

  const getSalesperson = async () => {
    setFetchLoading(true);
    try {
      const salesperson = await getSingleSalesperson(salespersonId);

      setFormValues({ ...salesperson });
      setFetchLoading(false);
    } catch (error: any) {
      setFetchLoading(false);
      console.log(error);
    }
  };

  const handleOnChange = (e: ChangeEventType) => {
    setIsDirty(true);
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnChangeNumber = (newValue: string) => {
    setIsDirty(true);
    setFormValues({ ...formValues, cell: newValue.split(' ').join('') });
  };

  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setIsDirty(false);
    try {
      let alertMsg: string = '';
      if (isEdit) {
        const { message } = await editSalesperson(
          { ...formValues },
          salespersonId,
        );
        alertMsg = message;
      } else {
        const { message } = await createSalesperson({ ...formValues });
        alertMsg = message;
      }

      setLoading(false);
      dispatch(
        toggleAlert({
          toggle: true,
          message: alertMsg,
        }),
      );

      navigate(IS_DIY_ADZ ? '/userz' : '/users');
    } catch (error: any) {
      setLoading(false);
      setIsDirty(true);
      console.log(error);
      if (error.response) {
        const err = error.response.data;
        setErrors({
          ...errors,
          firstName: err.firstname,
          lastName: err.lastname,
          email: err.email,
        });
      }
    }
  };

  const handleConfirmNavigation = () => {
    autoUnblockingTx.retry();
    setOpenConfirmNavModal(false);
  };

  const handleCancelNavigation = () => {
    setOpenConfirmNavModal(false);
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleOnSubmit}
      sx={{ width: '100%' }}
      className={`${styles.form} ${XsOnly() ? `${styles['-mobile']}` : ''}`}
    >
      <CircularLoading loading={fetchLoading} />

      <PageHeader title={isEdit ? 'Edit User' : 'Add New User'} />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoFocus
            fullWidth
            required
            variant="standard"
            type="text"
            id="firstname"
            name="firstname"
            label="First Name"
            onChange={handleOnChange}
            value={formValues.firstname}
            InputLabelProps={{ shrink: true }}
            size="small"
            error={errors?.firstName ? true : false}
            helperText={errors?.firstName ? errors.firstName : ''}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            type="text"
            id="lastname"
            name="lastname"
            label="Last Name"
            onChange={handleOnChange}
            value={formValues.lastname}
            InputLabelProps={{ shrink: true }}
            size="small"
            error={errors?.lastName ? true : false}
            helperText={errors?.lastName ? errors.lastName : ''}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            type="email"
            id="email"
            name="email"
            label="Email"
            onChange={handleOnChange}
            value={formValues.email}
            InputLabelProps={{ shrink: true }}
            size="small"
            error={errors?.email ? true : false}
            helperText={errors?.email ? errors.email : ''}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <MuiTelInput
            fullWidth
            required
            id="cell"
            name="cell"
            label="Phone Number"
            variant="standard"
            onChange={(newValue: string) => handleOnChangeNumber(newValue)}
            value={formValues?.cell}
            InputLabelProps={{ shrink: true }}
            size="small"
            onlyCountries={['US']}
            defaultCountry="US"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ marginTop: '15px' }}>
        <Grid item xs={12} md={4}>
          <FormControl>
            <FormLabel id="status">User Status</FormLabel>

            <FormControlLabel
              control={
                <Switch
                  checked={formValues.active}
                  name="active"
                  onChange={(e: any) => {
                    setFormValues({
                      ...formValues,
                      [e.target.name]: e.target.checked,
                    });
                  }}
                />
              }
              label={formValues.active ? 'Active' : 'Inactive'}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel id="role">Role</FormLabel>

            <RadioGroup
              row
              aria-labelledby="role-row"
              name="role"
              value={formValues.role}
              onChange={handleOnChange}
            >
              <FormControlLabel
                value="salesperson"
                control={<Radio size="small" />}
                label="Salesperson"
              />

              {!isLocation ? (
                <>
                  <FormControlLabel
                    value="admin"
                    control={<Radio size="small" />}
                    label="Admin"
                  />

                  <FormControlLabel
                    value="super-admin"
                    control={<Radio size="small" />}
                    label="Super admin"
                  />
                </>
              ) : null}

              <FormControlLabel
                value="brand-manager"
                control={<Radio size="small" />}
                label="Brand Manager"
              />

              <FormControlLabel
                value="marketing-manager"
                control={<Radio size="small" />}
                label="Marketing Manager"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>

      {IS_DIY_ADZ && formValues?.role === 'salesperson' ? (
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <Typography variant="body1">User Capabilities</Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  defaultChecked={formValues?.capabilities?.createAds}
                />
              }
              name="createAds"
              label="Create ads"
              value={formValues?.capabilities?.createAds}
              onChange={(e: any) => {
                setFormValues({
                  ...formValues,
                  capabilities: {
                    ...formValues?.capabilities,
                    [e.target.name]: e.target.checked,
                  },
                });
              }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  defaultChecked={formValues?.capabilities?.viewReports}
                />
              }
              name="viewReports"
              label="View reports"
              value={formValues?.capabilities?.viewReports}
              onChange={(e: any) => {
                setFormValues({
                  ...formValues,
                  capabilities: {
                    ...formValues?.capabilities,
                    [e.target.name]: e.target.checked,
                  },
                });
              }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  defaultChecked={formValues?.capabilities?.createTemplate}
                />
              }
              name="createTemplate"
              label="Create template"
              value={formValues?.capabilities?.createTemplate}
              onChange={(e: any) => {
                setFormValues({
                  ...formValues,
                  capabilities: {
                    ...formValues?.capabilities,
                    [e.target.name]: e.target.checked,
                  },
                });
              }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  defaultChecked={formValues?.capabilities?.approveTemplate}
                />
              }
              name="approveTemplate"
              label="Approve template"
              value={formValues?.capabilities?.approveTemplate}
              onChange={(e: any) => {
                setFormValues({
                  ...formValues,
                  capabilities: {
                    ...formValues?.capabilities,
                    [e.target.name]: e.target.checked,
                  },
                });
              }}
            />
          </Grid>
        </Grid>
      ) : null}

      <div className={`${styles.action} ${styles['-unsticky']}`}>
        <PrimaryButton
          title={isEdit ? 'Save' : 'Add'}
          loading={loading}
          marginRight5
          size="large"
          rounded
        />

        <PrimaryButton
          title="Cancel"
          type="button"
          handleOnClick={() => navigate(IS_DIY_ADZ ? '/userz' : '/users')}
          theme="red"
          variant="text"
          size="large"
        />
      </div>

      <ConfirmNavigationModal
        open={openConfirmNavModal}
        onClose={handleCancelNavigation}
        onConfirm={handleConfirmNavigation}
      />
    </Box>
  );
};

export default UserForm;
