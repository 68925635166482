import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  fetchFbAdAccountImages,
  fetchFbAdAccountVideos,
  uploadFbAdAccountImages,
  uploadFbAdAccountVideos,
} from '../../../services/ads';
import {
  AdImage,
  AdVideo,
  AdVideoThumbnail,
  Brand,
  ChangeEventType,
  FbAssetCarouselSpecMedia,
  FbAssetFeedSpec,
  FbAssetFeedSpecMedia,
  FbCustomizationSpec,
  ImageFilters,
  VideoFilters,
} from '../../../types';
import { Grid, Typography } from '@mui/material';
import CircularLoading from '../../CircularLoading';
import {
  adImageFiltering,
  adVideoFiltering,
} from '../../../utils/helpers/facebookAdsHelper';
import { PrimaryButton } from '../../Buttons';
import MediaList from '../Diy/MediaList';
import MediaPlacementForm from '../Diy/MediaPlacementForm';
import { buildImageCropObject } from '../../../utils/helpers/DiyHelpers';
import { AdsTemplate, DiySettings } from '../../../types/IDiy';
import PopupModal from '../../Modal';
import { arrayObjectUniqueFilter } from '../../../utils/arrayFormatter';
import { errorMessageParser } from '../../../utils/helpers/ToastHelper';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../redux/actions';

interface AdMediaFormProps {
  open: boolean;
  brand: Brand;
  accountId: string;
  format: string;
  mediaType: string;
  assetFeedSpec: FbAssetFeedSpec;
  setAssetFeedSpec?: Dispatch<SetStateAction<FbAssetFeedSpec>>;
  onClose: () => void;
  template: AdsTemplate;
  setTemplate: Dispatch<SetStateAction<AdsTemplate>>;
  edit?: boolean;
  ratio?: string | null;
  diy?: boolean;
  diySettings: DiySettings;
  objective: string;
  newVideoIds: string[];
  setNewVideoIds: Dispatch<SetStateAction<string[]>>;
  isEmployment: boolean;
}

const AdMediaForm: React.FC<AdMediaFormProps> = ({
  open,
  brand,
  accountId,
  format,
  mediaType,
  assetFeedSpec,
  setAssetFeedSpec,
  onClose,
  template,
  setTemplate,
  edit = false,
  ratio = null,
  diy = false,
  diySettings,
  objective,
  newVideoIds,
  setNewVideoIds,
  isEmployment,
}) => {
  const dispatch = useDispatch();
  const STEPS = ['Select Media', 'Setup Placements'];
  const [fbAdImages, setFbAdImages] = useState<AdImage[]>([]);
  const [fbAdVideos, setFbAdVideos] = useState<AdVideo[]>([]);
  const [filteredImages, setFilteredImages] = useState<AdImage[]>([]);
  const [filteredVideos, setFilteredVideos] = useState<AdVideo[]>([]);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const [activeStep, setActiveStep] = useState<string>(null);
  const [imageFilters, setImageFilters] = useState<ImageFilters>({
    full_horizontal: false,
    full_vertical: false,
    vertical: false,
    square: false,
    height_600: false,
    height_1080: false,
    width_600: false,
    width_1080: false,
  });
  const [videoFilters, setVideoFilters] = useState<VideoFilters>({
    seconds_15: false,
    seconds_60: false,
    seconds_60_more: false,
    full_horizontal: false,
    full_vertical: false,
    vertical: false,
    square: false,
    height_600_less: false,
    height_600_more: false,
    height_1080_more: false,
    width_600_less: false,
    width_600_more: false,
    width_1080_more: false,
  });
  const [replaceFormat, setReplaceFormat] = useState<string>(null);
  const [replace, setReplace] = useState<boolean>(false);
  const [replacedImage, setReplacedImage] = useState<AdImage>(null);
  const [replacedVideo, setReplaceVideo] = useState<AdVideo>(null);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [selectedVideos, setSelectedVideos] = useState<string[]>([]);
  const [mediaForm, setMediaForm] = useState<FbAssetFeedSpec>({
    // { text: '',  adlabels: [{ name: 'body_1' }] },
    bodies: [],
    call_to_action_types: [],
    // { text: '',  adlabels: [{ name: 'title_1' }] },
    titles: [],
    // { text: '',  adlabels: [{ name: 'description_1' }] },
    descriptions: [],
    // { website_url: '',  adlabels: [{ name: 'link_1' }] },
    link_urls: [
      {
        website_url: isEmployment
          ? diySettings?.defaultEmploymentUrl
          : diySettings?.defaultUrl,
        display_url: isEmployment
          ? diySettings?.defaultEmploymentUrl
          : diySettings?.defaultUrl,
      },
    ],
    ad_formats: [],
    asset_customization_rules: [
      // SQUARE
      {
        customization_spec: {
          publisher_platforms: ['facebook', 'instagram'],
          facebook_positions: ['feed', 'video_feeds', 'marketplace', 'search'],
          instagram_positions: [
            'stream',
            'ig_search',
            'profile_feed',
            'explore',
            'explore_home',
          ],
        },
      },
      // Vertical
      {
        customization_spec: {
          publisher_platforms: [
            'facebook',
            'instagram',
            'messenger',
            'audience_network',
          ],
          facebook_positions: ['story', 'facebook_reels'],
          instagram_positions: ['reels', 'story'],
          messenger_positions: ['story'],
        },
      },
      // Horizontal
      {
        customization_spec: {
          publisher_platforms: ['facebook'],
          facebook_positions: ['right_hand_column'],
        },
      },
    ],
  });
  const [openVideoProcessing, setOpenVideProcessing] = useState<boolean>(false);

  const isImage = mediaType === 'image';
  const isVideo = mediaType === 'video';

  useEffect(() => {
    const step = STEPS.find((value: string, index: number) => {
      return index === activeStepIndex;
    });

    setActiveStep(step);
  }, [activeStepIndex]);

  useEffect(() => {
    if (newVideoIds.length > 0) {
      const intervalId = setInterval(() => {
        try {
          getFbAdVideos(false);
        } catch (error: any) {
          console.log(error);
        }
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [newVideoIds]);

  useEffect(() => {
    if (open) {
      if (isImage) {
        getFbAdImages();
      } else {
        getFbAdVideos(true);
      }
    }
  }, [open, mediaType]);

  useEffect(() => {
    (async () => {
      if (edit && ratio && fbAdImages.length > 0) {
        let temp: ImageFilters = { ...imageFilters };
        switch (ratio) {
          case 'vertical':
            temp = { ...temp, full_vertical: true };
            break;
          default:
            temp = { ...temp, square: true };

            break;
        }

        const filtered: AdImage[] = await adImageFiltering(temp, fbAdImages);

        setFilteredImages(filtered);
        setImageFilters(temp);
      }
    })();
  }, [edit, ratio, fbAdImages]);

  useEffect(() => {
    if (search) {
      if (isImage) {
        const data = fbAdImages.filter((image: AdImage) =>
          image.name.toLowerCase().includes(search.toLowerCase()),
        );

        setFilteredImages(data);
      } else if (isVideo) {
        const data = fbAdVideos.filter((video: AdVideo) =>
          (video.title ? video.title : video.id)
            .toLowerCase()
            .includes(search.toLowerCase()),
        );

        setFilteredVideos(data);
      }
    } else {
      if (isImage) {
        setFilteredImages(fbAdImages);
      } else if (isVideo) {
        setFilteredVideos(fbAdVideos);
      }
    }
  }, [search, isVideo, isImage]);

  useEffect(() => {
    (async () => {
      const filtered: AdImage[] = await adImageFiltering(
        imageFilters,
        fbAdImages,
      );

      setFilteredImages(filtered);
    })();
  }, [imageFilters]);

  useEffect(() => {
    (async () => {
      const filtered: AdVideo[] = await adVideoFiltering(
        videoFilters,
        fbAdVideos,
      );

      setFilteredVideos(filtered);
    })();
  }, [videoFilters]);

  const getFbAdImages = async () => {
    setFetchLoading(true);
    try {
      const response = await fetchFbAdAccountImages(brand?._id);

      setFbAdImages(response.data);
      setFilteredImages(response.data);
      setFetchLoading(false);
    } catch (error: any) {
      setFetchLoading(false);
      console.log(error.message);
    }
  };

  const getFbAdVideos = async (loading: boolean) => {
    if (loading) setFetchLoading(true);
    try {
      const response = await fetchFbAdAccountVideos(brand?._id);

      let tempVideos: any[] = [...response.data];
      let newVideos: any[] = [];
      if (newVideoIds.length > 0) {
        response.data.forEach((video: any) => {
          if (newVideoIds.includes(video.id)) {
            newVideos = [...newVideos, video];

            setNewVideoIds([
              ...newVideoIds.filter((id: string) => id !== video.id),
            ]);
          }
        });
      }

      tempVideos = [...tempVideos, ...newVideos];

      const currentVideoIds = fbAdVideos.map((video: any) => video.id);
      const videoIds = tempVideos.map((video: any) => video.id);

      if (!isSameVideos(currentVideoIds, videoIds)) {
        const filteredVideos = arrayObjectUniqueFilter(tempVideos, 'id');
        setFbAdVideos(filteredVideos);
        setFilteredVideos(filteredVideos);
      }
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const isSameVideos = (currentVideoIds: string[], newVideoIds: string[]) => {
    if (currentVideoIds.length !== newVideoIds.length) return false;

    const sortedArr1 = currentVideoIds.slice().sort();
    const sortedArr2 = newVideoIds.slice().sort();

    return sortedArr1.every((element, index) => element === sortedArr2[index]);
  };

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);

    try {
      const file = e.target.files[0];

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        try {
          setLoading(true);
          const parsedFile = {
            file: reader.result,
            type: file.type,
            name: file.name,
            size: file.size,
          };

          if (isVideo) {
            const response = await uploadFbAdAccountVideos(
              brand?._id,
              parsedFile,
            );

            setNewVideoIds([...newVideoIds, response.data.id]);
            setOpenVideProcessing(true);

            getFbAdVideos(false);
          } else {
            await uploadFbAdAccountImages(brand?._id, parsedFile);

            getFbAdImages();
          }
        } catch (error: any) {
          const errorMsg = errorMessageParser(error);
          dispatch(
            toggleAlert({ toggle: true, message: errorMsg, type: 'error' }),
          );
        } finally {
          e.target.value = null;
          setLoading(false);
        }
      };
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOnSearch = (e: ChangeEventType) => {
    setSearch(e.target.value);
  };

  const handleSetImageFilters = (name: string, checked: boolean) => {
    setImageFilters({ ...imageFilters, [name]: checked });
  };

  const handleSetVideoFilters = (name: string, checked: boolean) => {
    setVideoFilters({ ...videoFilters, [name]: checked });
  };

  const handleNext = () => {
    setActiveStepIndex((prev) => prev + 1);
  };

  const handleBack = (replace: boolean, ratio?: string) => {
    if (replace) {
      setReplaceFormat(ratio);
      setReplace(replace);
    }

    setActiveStepIndex((prev) => prev - 1);
  };

  const buildTemplateImages = (image: AdImage, ratio: string) => {
    if (ratio === 'horizontal') return;

    setTemplate({
      ...template,
      images: {
        ...template?.images,
        [ratio as keyof AdsTemplate]: image?.permalink_url,
      },
    });
  };

  const buildTemplateVideo = (video: AdVideo, ratio: string) => {
    if (ratio === 'horizontal') return;

    setTemplate({
      ...template,
      videos: {
        ...template?.videos,
        [ratio]: video?.source,
      },
    });
  };

  const handleSelectMedia = (image?: AdImage, video?: AdVideo) => {
    const ASPECT_RATIOS = ['square', 'vertical'];
    const isSingle = format === 'media';
    const isCarousel = format === 'carousel';
    let tempSelectedImages: string[] = [];
    let selectedVideos: string[] = [];
    let tempAssetImages: FbAssetFeedSpecMedia[] = [];
    let tempAssetVideos: FbAssetFeedSpecMedia[] = [];
    let tempAssetCarousel: FbAssetCarouselSpecMedia[] = [];
    //let tempCarousel: FBCarouselTemplate[] = [...carouselData];
    let temp: FbAssetFeedSpec = { ...mediaForm };

    if (image) {
      if (isSingle) {
        if (edit) {
          let temp: FbAssetFeedSpec = { ...assetFeedSpec };
          tempSelectedImages = [image.hash];
          const rulesIndex = ratio === 'square' ? 0 : 1;

          const imageIndex = temp?.images?.findIndex(
            (image: FbAssetFeedSpecMedia) => {
              return image.adlabels[0].name === `image_${ratio}`;
            },
          );

          if (imageIndex < 0) {
            temp.images = [
              ...temp.images,
              {
                adlabels: [{ name: `image_${ratio}` }],
                hash: image.hash,
              },
            ];

            temp.asset_customization_rules[rulesIndex] = {
              ...temp?.asset_customization_rules[rulesIndex],
              image_label: { name: `image_${ratio}` },
            };
          } else {
            temp.images[imageIndex] = {
              adlabels: [{ name: `image_${ratio}` }],
              hash: image.hash,
            };
          }

          setAssetFeedSpec(temp);
          setTemplate({
            ...template,
            images: {
              ...template?.images,
              [ratio]: image?.permalink_url,
            },
          });
        } else {
          tempSelectedImages = [image.hash];
          tempAssetImages = [
            ...tempAssetImages,
            {
              adlabels: [{ name: `image_square` }],
              hash: image.hash,
            },
          ];

          [...ASPECT_RATIOS, 'horizontal'].forEach(
            (ratio: string, index: number) => {
              temp.asset_customization_rules[index] = {
                ...temp.asset_customization_rules[index],
                image_label: { name: 'image_square' },
              };
            },
          );

          temp.images = [...tempAssetImages];

          setTemplate({
            ...template,
            images: {
              ...template?.images,
              square: image?.permalink_url,
              vertical: image?.permalink_url,
            },
            videos: null,
          });
        }
      } else if (isCarousel) {
        tempSelectedImages = [...selectedImages, image.hash];

        ASPECT_RATIOS.forEach((ratio: string, index: number) => {
          const crop = buildImageCropObject(ratio, image);

          tempAssetImages = [
            ...temp.images,
            {
              adlabels: [{ name: `image_${image.id}_${ratio}` }],
              hash: image.hash,
              image_crops: { ...crop },
            },
          ];

          temp.carousels.forEach(() => {});

          temp.asset_customization_rules[index] = {
            ...temp.asset_customization_rules[index],
            image_label: { name: `image_${ratio}` },
          };
        });

        /*
        selectedImages = [...selectedImages, image.id];
        const data: FBCarouselTemplate = {
          picture: image.permalink_url,
          call_to_action: {
            type: 'APPLY_NOW',
            value: {
              link: '',
            },
          },
        };
        tempCarousel = [...tempCarousel, data];
        setCarouselData(tempCarousel);
        */
      }

      setSelectedImages(tempSelectedImages);
      if (!edit) setMediaForm(temp);
    } else if (video) {
      const { uri } = video.thumbnails.data.find((image: AdVideoThumbnail) => {
        return image.is_preferred;
      });

      if (isSingle) {
        if (edit) {
          let temp: FbAssetFeedSpec = { ...assetFeedSpec };
          selectedVideos = [video.id];
          buildTemplateVideo(video, ratio);
          const rulesIndex = ratio === 'square' ? 0 : 1;

          const videoIndex = temp?.videos?.findIndex(
            (video: FbAssetFeedSpecMedia) => {
              return video.adlabels[0].name === `video_${ratio}`;
            },
          );

          if (videoIndex < 0) {
            temp.videos = [
              ...temp.videos,
              {
                adlabels: [{ name: `video_${ratio}` }],
                video_id: video.id,
                thumbnail_url: uri,
              },
            ];

            temp.asset_customization_rules[rulesIndex] = {
              ...temp?.asset_customization_rules[rulesIndex],
              video_label: { name: `video_${ratio}` },
            };
          } else {
            temp.videos[videoIndex] = {
              adlabels: [{ name: `video_${ratio}` }],
              video_id: video.id,
              thumbnail_url: uri,
            };
          }

          setAssetFeedSpec(temp);
          setTemplate({
            ...template,
            videos: {
              ...template?.videos,
              [ratio]: video?.source,
            },
          });
        } else {
          selectedVideos = [video.id];
          tempAssetVideos = [
            ...tempAssetVideos,
            {
              adlabels: [{ name: `video_square` }],
              video_id: video.id,
              thumbnail_url: uri,
            },
          ];

          ASPECT_RATIOS.forEach((ratio: string, index: number) => {
            temp.asset_customization_rules[index] = {
              ...temp.asset_customization_rules[index],
              video_label: { name: `video_square` },
            };
          });

          temp.videos = [...tempAssetVideos];

          setTemplate({
            ...template,
            images: null,
            videos: {
              ...template?.videos,
              square: video?.source,
              vertical: video?.source,
            },
          });
        }
      } else if (isCarousel) {
        /*
        selectedVideos = [...selectedVideos, video.id];
        const data: FBCarouselTemplate = {
          video_id: video.id,
          picture: uri,
          call_to_action: {
            type: 'APPLY_NOW',
            value: {
              link: '',
            },
          },
        };
        tempCarousel = [...tempCarousel, data];
        setCarouselData(tempCarousel);
        */
      }

      setSelectedVideos(selectedVideos);
      if (!edit) setMediaForm(temp);
    }
  };

  const handleReplaceMedia = (
    mediaType: string,
    ratio: string,
    image?: AdImage,
    video?: AdVideo,
  ) => {
    let temp: FbAssetFeedSpec = { ...mediaForm };

    switch (mediaType) {
      case 'image':
        const imageIndex = ratio === 'square' ? 0 : 1;
        const existingImage = temp.images.find((i: any) => {
          return i.adlabels[0].name === `${mediaType}_${ratio}`;
        });

        if (existingImage) {
          temp.images[imageIndex].hash = image.hash;
        } else {
          temp.images = [
            ...temp.images,
            {
              adlabels: [{ name: `${mediaType}_${ratio}` }],
              hash: image.hash,
            },
          ];
        }

        buildTemplateImages(image, ratio);

        temp.asset_customization_rules[imageIndex] = {
          ...temp.asset_customization_rules[imageIndex],
          image_label: { name: `${mediaType}_${ratio}` },
        };

        break;
      default:
        const { uri } = video.thumbnails.data.find(
          (image: AdVideoThumbnail) => {
            return image.is_preferred;
          },
        );
        const videoIndex = ratio === 'square' ? 0 : 1;
        const existingVideo = temp.videos.find((i: any) => {
          return i.adlabels[0].name === `${mediaType}_${ratio}`;
        });

        if (existingVideo) {
          temp.videos[videoIndex].video_id = video.id;
          temp.videos[videoIndex].thumbnail_url = uri;
        } else {
          temp.videos = [
            ...temp.videos,
            {
              adlabels: [{ name: `${mediaType}_${ratio}` }],
              video_id: video.id,
              thumbnail_url: uri,
            },
          ];
        }

        buildTemplateVideo(video, ratio);

        temp.asset_customization_rules[videoIndex] = {
          ...temp.asset_customization_rules[videoIndex],
          video_label: { name: `${mediaType}_${ratio}` },
        };

        break;
    }

    setMediaForm(temp);
  };

  const handleSetMedia = () => {
    let temp: FbAssetFeedSpec = { ...assetFeedSpec };
    let tempTemplate: AdsTemplate = { ...template };
    const customizationRules = mediaForm.asset_customization_rules.filter(
      (rule: FbCustomizationSpec) => {
        return !rule.customization_spec.facebook_positions.includes(
          'right_hand_column',
        );
      },
    );

    if (isImage) {
      temp = {
        ...temp,
        images: [...(mediaForm?.images || [])],
        videos: [],
        ad_formats: ['SINGLE_IMAGE'],
        asset_customization_rules: [...customizationRules],
      };
    } else {
      temp = {
        ...temp,
        videos: [...(mediaForm?.videos || [])],
        images: [],
        ad_formats: ['SINGLE_VIDEO'],
        asset_customization_rules: [...customizationRules],
      };
    }

    if (diy) {
      tempTemplate.creativeTemplate.asset_feed_spec = temp;
      setTemplate(tempTemplate);
    } else {
      setAssetFeedSpec(temp);
    }
    onClose();
  };

  const handleCloseProcessVideo = () => {
    setOpenVideProcessing(false);
  };

  return (
    <Grid container spacing={2} sx={{ width: '1000px', height: '800px' }}>
      <CircularLoading loading={loading || fetchLoading} />

      {activeStep === 'Select Media' ? (
        <MediaList
          onFileUpload={handleFileUpload}
          isImage={isImage}
          isVideo={isVideo}
          format={format}
          mediaType={mediaType}
          images={filteredImages}
          videos={filteredVideos}
          selectedImages={selectedImages}
          selectedVideos={selectedVideos}
          onSelectMedia={handleSelectMedia}
          imageFilters={imageFilters}
          videoFilters={videoFilters}
          onImageFilter={handleSetImageFilters}
          onVideoFilter={handleSetVideoFilters}
          search={search}
          onSearch={handleOnSearch}
          replace={replace}
          ratio={replaceFormat}
          setReplaceImage={setReplacedImage}
          replacedImage={replacedImage}
          replacedVideo={replacedVideo}
          setReplacedVideo={setReplaceVideo}
        />
      ) : null}

      {activeStep === 'Setup Placements' ? (
        <MediaPlacementForm
          onBack={handleBack}
          mediaType={mediaType}
          template={template}
          brand={brand}
          diySettings={diySettings}
          objective={objective}
        />
      ) : null}

      <Grid
        item
        xs={12}
        sx={{
          justifyContent: 'flex-end',
          display: 'flex',
          paddingBottom: '20px',
          position: 'sticky',
          bottom: -25,
          height: '72px',
        }}
      >
        <PrimaryButton
          title={
            replace
              ? 'Replace'
              : activeStepIndex + 1 === STEPS.length || edit
              ? 'Done'
              : 'Next'
          }
          type="button"
          handleOnClick={() => {
            if (replace) {
              handleReplaceMedia(
                mediaType,
                replaceFormat,
                replacedImage,
                replacedVideo,
              );

              handleNext();
            } else {
              if (activeStepIndex + 1 === STEPS.length || edit) {
                if (edit) {
                  onClose();
                } else {
                  handleSetMedia();
                }
              } else {
                handleNext();
              }
            }

            setReplace(false);
          }}
        />
      </Grid>

      <PopupModal
        open={openVideoProcessing}
        handleClose={handleCloseProcessVideo}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Video Still in Process
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Facebook is processing your video upload to ensure it meets their
              quality standards. Please check back later for the completed
              upload.
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
            <PrimaryButton
              title="Close"
              type="button"
              handleOnClick={handleCloseProcessVideo}
            />
          </Grid>
        </Grid>
      </PopupModal>
    </Grid>
  );
};

export default AdMediaForm;
