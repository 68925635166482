import React, { Dispatch, SetStateAction } from 'react';
import { CustomAudience, SharedAccountInfo } from '../../../types/IAudience';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { DataGridPremium, GridRowParams } from '@mui/x-data-grid-premium';
import { CustomLoadingOverlay } from '../../Loading';
import { lookalikeAudienceColumns } from './LookalikeAudienceTableColumns';
import { DEFAULT_COLOR_THEME } from '../../../utils';

interface LookalikeAudienceTableProps {
  customAudiences: CustomAudience[];
  loading: boolean;
  share?: boolean;
  accountId?: string | null;
  setSelectedAudiences?: Dispatch<SetStateAction<CustomAudience[]>>;
}

const LookalikeAudienceTable: React.FC<LookalikeAudienceTableProps> = ({
  customAudiences,
  loading,
  share = false,
  accountId = null,
  setSelectedAudiences = null,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const rows = customAudiences.map((audience: CustomAudience) => {
    return {
      ...audience,
      id: parseInt(audience.id),
    };
  });

  const handleOnSelect = (value: any[]) => {
    const selectedAudiences = customAudiences.filter(
      (audience: CustomAudience) => value.includes(parseInt(audience.id)),
    );

    setSelectedAudiences(selectedAudiences);
  };

  return (
    <Box className={styles.table}>
      <DataGridPremium
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }],
          },
        }}
        density="compact"
        rows={rows}
        autoHeight
        hideFooterSelectedRowCount
        slots={{ loadingOverlay: CustomLoadingOverlay }}
        loading={loading}
        columns={lookalikeAudienceColumns(isMobile, share)}
        checkboxSelection={share}
        isRowSelectable={(params: GridRowParams) => {
          const sharedAccountIds = params.row.shared_account_info.data.map(
            (sharedAccount: SharedAccountInfo) => sharedAccount.account_id,
          );

          return (
            !sharedAccountIds.includes(accountId) &&
            !(params.row.account_id === accountId)
          );
        }}
        onRowSelectionModelChange={(value) => handleOnSelect(value)}
        sx={{
          '.MuiDataGrid-columnHeaderTitleContainer': {
            backgroundColor: DEFAULT_COLOR_THEME,
            color: 'white',
            '.MuiSvgIcon-root': {
              color: 'white',
            },
          },
        }}
      />
    </Box>
  );
};

export default LookalikeAudienceTable;
