import React from 'react';
import {
  AdImage,
  AdVideo,
  CampaignChangeLog,
  FbCreativeForm,
} from '../../../types';
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import FormHeader from '../../Forms/FormHeader';
import { DEFAULT_COLOR_THEME } from '../../../utils';
import { constantStringToHumanized } from '../../../utils/stringModifier';
import { dayJsInstance } from '../../../utils/dayjsHelper';
import { useSelector } from 'react-redux';
import { toCurrency } from '../../../utils/numberFormatter';

interface CampaignLogsProps {
  logs: CampaignChangeLog[];
  loading: boolean;
}

const CampaignLogs: React.FC<CampaignLogsProps> = ({ logs, loading }) => {
  const timezone: string = useSelector(
    (state: any) => state?.brandTimezone?.timezone,
  );

  const changesToText = (targeting: any) => {
    return Object.keys(targeting)
      .map((key: string) => {
        if (
          [
            'excluded_geo_locations',
            'geo_locations',
            'targeting_relaxation_types',
            'targeting_automation',
            'targeting_optimization',
          ].includes(key)
        ) {
          return '';
        }

        if (Array.isArray(targeting[key]) && key !== 'genders') {
          if (targeting[key].length > 0) {
            return `${constantStringToHumanized(key)}\n${targeting[key]
              .map(
                (target: any) =>
                  `${
                    typeof target === 'object'
                      ? target.name || target.id
                      : target
                  }`,
              )
              .join('\n')}`;
          }

          return '';
        }

        if (key === 'genders') {
          if (targeting[key].length > 1) {
            return `${constantStringToHumanized(key)}: All Genders`;
          }

          if (targeting[key][0] === 1) {
            return `${constantStringToHumanized(key)}: Male`;
          }

          return `${constantStringToHumanized(key)}: Female`;
        }

        if (
          key === 'total_budget' ||
          key === 'daily_budget' ||
          key === 'spend_limit'
        ) {
          return `${constantStringToHumanized(key)}: ${toCurrency(
            'USD',
            targeting[key],
          )}`;
        }

        return `${constantStringToHumanized(key)}: ${targeting[key]}`;
      })
      .filter((string) => string !== '')
      .join('\n');
  };

  const buildGeolocationAddress = (value: any, geolocationKey: string) => {
    try {
      let address: string = '';
      const { name, region, primary_city, key, country } = value;
      switch (geolocationKey) {
        case 'cities':
          address = `${name}, ${region}, ${country}`;
          break;
        case 'regions':
          address = `${name}, ${country}`;
          break;
        case 'geo_markets':
          address = `${key}, ${name}, ${country}`;
          break;
        case 'zip':
          address = `${name}, ${primary_city}, ${region}, ${country}`;
          break;
        default:
          address = value;
          break;
      }
      return address;
    } catch (error: any) {
      console.log(error);
    }
  };

  const getGeolocationLabel = (key: any) => {
    if (key === 'regions') {
      return 'States';
    }
    if (key === 'geo_markets') {
      return 'DMA';
    }
    if (key === 'custom_locations') {
      return 'Address';
    }

    return constantStringToHumanized(key);
  };

  const geolocationToText = (geolocations: any, included: boolean) => {
    let text: string = '';

    Object.keys(geolocations).forEach((key: string) => {
      if (key === 'location_types' || geolocations[key]?.length === 0) {
        return '';
      }

      if (key === 'cities') {
        const cityText = `${getGeolocationLabel(key)}\n${geolocations[key]
          .map(
            (geolocation: any) =>
              `${buildGeolocationAddress(geolocation, key)}${
                geolocation.radius
                  ? ` (${geolocation.radius} ${geolocation.distance_unit})`
                  : ''
              }`,
          )
          .join('\n')}`;

        text = `${text}${cityText}`;
      } else if (key === 'custom_locations') {
        const customLocationText = `${getGeolocationLabel(key)}\n${geolocations[
          key
        ]
          .map(
            (geolocation: any) =>
              `
                Latitude: ${geolocation.latitude},
                Longitude: ${geolocation.longitude},
                radius: ${geolocation.radius}${geolocation.distance_unit}
              `,
          )
          .join('\n')}`;

        text = `${text}${customLocationText}`;
      } else {
        const geolocationText = `${getGeolocationLabel(key)}\n${geolocations[
          key
        ]
          .map(
            (geolocation: any) =>
              `${buildGeolocationAddress(geolocation, key)}`,
          )
          .join('\n')}`;

        text = `${text}${geolocationText}`;
      }
    });

    return `${
      included ? 'Included Geolocations' : 'Excluded Geolocations'
    }\n${text}`;
  };

  const creativeToText = (creative: FbCreativeForm) => {
    let text: string = `Template Name: ${creative?.template_name || ''}\n`;

    Object.keys(creative.asset_feed_spec).forEach((key: string) => {
      if (key === 'ad_formats') {
        text = `${text}${constantStringToHumanized(
          key,
        )}: ${constantStringToHumanized(creative.asset_feed_spec[key][0])}\n`;
      }

      if (key === 'bodies') {
        text = `${text}Ad Copy: ${constantStringToHumanized(
          creative.asset_feed_spec[key][0].text,
        )}\n`;
      }

      if (key === 'call_to_action_types') {
        text = `${text}CTA: ${constantStringToHumanized(
          creative.asset_feed_spec[key][0],
        )}\n`;
      }

      if (key === 'link_urls') {
        text = `${text}URL: ${creative.asset_feed_spec[key][0].website_url}\n`;
      }

      if (key === 'titles') {
        text = `${text}Headline: ${creative.asset_feed_spec[key][0].text}\n`;
      }
    });

    return text;
  };

  const parseTargets = (action: string, changes: string) => {
    const parsedChanges = JSON.parse(changes);

    if (action === 'change_geographics') {
      let text: string = '';
      if (parsedChanges?.geo_locations) {
        text = `${geolocationToText(parsedChanges?.geo_locations, true)}\n`;
      }

      if (parsedChanges?.excluded_geo_locations) {
        text = `${text}\n${geolocationToText(
          parsedChanges?.excluded_geo_locations,
          false,
        )}\n`;
      }

      return text;
    }

    if (action === 'change_templates') {
      return creativeToText(parsedChanges);
    }

    return changesToText(parsedChanges);
  };

  const videos = (changes: string) => {
    const parsedChanges = JSON.parse(changes);

    if (parsedChanges.asset_feed_spec.ad_formats[0] === 'SINGLE_VIDEO') {
      const videos = parsedChanges.asset_feed_spec.videos;

      return (
        <>
          <Typography variant="body2">Videos</Typography>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {videos.map((video: AdVideo) => {
              return (
                <>
                  <Box
                    component="video"
                    src={video.source}
                    controls
                    poster={video.thumbnail_url}
                    sx={{ objectFit: 'cover', width: '200px' }}
                  />

                  <br />
                </>
              );
            })}
          </Box>
        </>
      );
    }

    return <></>;
  };

  const images = (changes: string) => {
    const parsedChanges = JSON.parse(changes);

    if (parsedChanges.asset_feed_spec.ad_formats[0] === 'SINGLE_IMAGE') {
      const images = parsedChanges.asset_feed_spec.images;

      return (
        <>
          <Typography variant="body2">Images</Typography>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {images.map((image: AdImage) => (
              <Box
                component="img"
                src={image.permalink_url}
                alt=""
                mr={1}
                sx={{
                  width: '100px',
                  objectFit: 'contain',
                  alignItems: 'center',
                  display: 'flex',
                }}
              />
            ))}
          </Box>

          <br />
        </>
      );
    }

    return <></>;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormHeader title="Change Logs" />
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            border: 'solid',
            backgroundColor: '#d9d9d933',
            minHeight: `calc(100vh - 240px)`,
            maxHeight: `calc(100vh - 240px)`,
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              width: '10px',
            },
            '&::-webkit-scrollbar-track': {
              borderTopRightRadius: '5px',
              borderBottomRightRadius: '5px',
            },

            '&::-webkit-scrollbar-thumb': {
              background: DEFAULT_COLOR_THEME,
              borderRadius: '18px',

              '&:hover': {
                background: DEFAULT_COLOR_THEME,
              },
            },
            display: loading || logs.length === 0 ? 'flex' : '',
            alignItems: loading || logs.length === 0 ? 'center' : '',
            justifyContent: loading || logs.length === 0 ? 'center' : '',
          }}
        >
          {loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : logs.length > 0 ? (
            <Grid container spacing={1} sx={{ padding: '5px' }}>
              {logs.map((log: CampaignChangeLog) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sx={{ flexDirection: 'column' }}
                    key={log._id}
                  >
                    <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                      Date and Time
                    </Typography>

                    <Typography variant="body2">
                      {dayJsInstance(log.createdAt)
                        .tz(timezone)
                        .format('MMM D, YYYY h:mm:ss A')}
                    </Typography>

                    <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                      Level
                    </Typography>

                    <Typography variant="body2">
                      {constantStringToHumanized(log.level)}
                    </Typography>

                    <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                      Action
                    </Typography>

                    <Typography variant="body2">
                      {constantStringToHumanized(log.action)}
                    </Typography>

                    {log?.changes?.from ? (
                      <>
                        <Typography
                          variant="caption"
                          sx={{ fontWeight: 'bold' }}
                        >
                          From
                        </Typography>

                        <Typography
                          variant="body2"
                          sx={{ whiteSpace: 'pre-wrap' }}
                        >
                          {parseTargets(log.action, log.changes.from)}
                        </Typography>

                        {log.action === 'change_templates' ? (
                          videos(log.changes.from)
                        ) : (
                          <></>
                        )}

                        {log.action === 'change_templates' ? (
                          images(log.changes.from)
                        ) : (
                          <></>
                        )}
                      </>
                    ) : null}

                    {log?.changes?.to ? (
                      <>
                        <Typography
                          variant="caption"
                          sx={{ fontWeight: 'bold' }}
                        >
                          To
                        </Typography>

                        <Typography
                          variant="body2"
                          sx={{ whiteSpace: 'pre-wrap' }}
                        >
                          {parseTargets(log.action, log.changes.to)}
                        </Typography>

                        {log.action === 'change_templates' ? (
                          videos(log.changes.to)
                        ) : (
                          <></>
                        )}

                        {log.action === 'change_templates' ? (
                          images(log.changes.to)
                        ) : (
                          <></>
                        )}
                      </>
                    ) : null}

                    <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                      User
                    </Typography>

                    <Typography variant="body2">
                      {log.automated
                        ? 'aiAdz'
                        : `${log.user.firstname} ${log.user.lastname}`}
                    </Typography>

                    <Divider />
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Typography variant="body2" sx={{ color: 'gray' }}>
              No Changes
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default CampaignLogs;
