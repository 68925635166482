import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import React, { FormEventHandler, useContext, useState } from 'react';
import styles from '../../../../assets/styles/components/Forms/AuthForm.module.scss';
import { ExpressThemeContext } from '../../../../context';
import chatzAppLogo from '../../../../assets/images/logo.png';
import GoogleLogin from '../../../Buttons/GoogleLogin';
import { PrimaryButton } from '../../../Buttons';
import FacebookIcon from '@mui/icons-material/Facebook';
import { ChangeEventType } from '../../../../types';
import { Link as RouterLink } from 'react-router-dom';
import { XsOnly } from '../../../../utils/breakpoints';
import { AGENCY, IS_DIY_ADZ, SUPER_ADMIN } from '../../../../utils';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface AuthError {
  email?: string;
  password?: string;
  oldPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
}

interface LoginFormProps {
  onSubmit: FormEventHandler<HTMLFormElement> | undefined;
  loading: boolean;
  onGoogleLogin: () => void;
  onFacebookLogin: () => void;
  errors?: AuthError;
  onChange: (e: ChangeEventType) => void;
  email: string;
  password: string;
  role: string;
}

const LoginForm: React.FC<LoginFormProps> = ({
  onSubmit,
  onGoogleLogin,
  loading,
  errors,
  onFacebookLogin,
  onChange,
  email,
  password,
  role,
}) => {
  const {
    state: { expressTheme },
  } = useContext(ExpressThemeContext);
  const isSuperAdmin = role === SUPER_ADMIN;
  const isAgency = role === AGENCY;
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleOnShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const logo = () => {
    return expressTheme.properties.logo?.url
      ? expressTheme.properties.logo?.url
      : chatzAppLogo;
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={onSubmit}
      className={styles.form}
    >
      <Grid container spacing={2} alignItems="center">
        {!IS_DIY_ADZ ? (
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Box
              component="img"
              width="281px"
              sx={{
                maxWidth: IS_DIY_ADZ ? '120px' : '218px',
              }}
              src={logo()}
              alt=""
            />
          </Grid>
        ) : (
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Box
              height="100px"
              sx={{
                maxWidth: IS_DIY_ADZ ? '120px' : '218px',
              }}
            />
          </Grid>
        )}

        {isSuperAdmin || isAgency ? (
          <Grid item xs={12} sm={6} mt={XsOnly() ? 0 : 2}>
            <PrimaryButton
              type="button"
              title="Facebook Login"
              handleOnClick={onFacebookLogin}
              startIcon={loading ? null : <FacebookIcon />}
              width={'100%'}
              theme="primary"
              loading={loading}
            />
          </Grid>
        ) : null}

        {isSuperAdmin || isAgency ? (
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ height: '50px' }}
            mt={XsOnly() ? 0 : 2}
          >
            <GoogleLogin
              handleOnClick={() => onGoogleLogin()}
              title="Sign in with Google"
              loading={loading}
            />
          </Grid>
        ) : null}

        {isSuperAdmin || isAgency ? (
          <Grid item xs={12} my={XsOnly() ? 0 : 1}>
            <Divider>
              <Typography mx={2} variant="body1" color="#707070">
                OR
              </Typography>
            </Divider>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="email"
            id="email"
            name="email"
            label="Email"
            onChange={onChange}
            value={email}
            placeholder="Email"
            error={errors?.email ? true : false}
            helperText={errors?.email ? errors.email : ''}
            InputProps={{ sx: { borderRadius: '49px' } }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type={showPassword ? 'text' : 'password'}
            id="password"
            name="password"
            label="Password"
            onChange={onChange}
            value={password}
            placeholder="Password"
            InputLabelProps={{ shrink: true }}
            error={errors?.password ? true : false}
            helperText={errors?.password ? errors.password : ''}
            sx={{
              '& .MuiInputBase-root': {
                '&:has(> input:-webkit-autofill)': {
                  backgroundColor: 'rgb(232, 240, 254)',
                },
              },
            }}
            InputProps={{
              sx: { borderRadius: '49px' },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleOnShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <PrimaryButton
            title="Log In"
            loading={loading}
            width={'100%'}
            rounded
            height="50px"
            marginTop="16px"
          />
        </Grid>

        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Link to="/forgot-password" underline="hover" component={RouterLink}>
            <Typography variant="body2" color="#096F4D">
              Forgot password?
            </Typography>
          </Link>
        </Grid>

        <Grid item xs={12} sx={{ textAlign: 'center' }} mt={XsOnly() ? 0 : 2}>
          <Link to="/privacy" component={RouterLink}>
            <Typography variant="body2" color="#096F4D" fontWeight="bold">
              Privacy Policy
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginForm;
