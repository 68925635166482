import ages from './ages.json';
import genders from './genders.json';
import income_ranges from './income-ranges.json';
import parents from './parents.json';
import products_services from './products-services.json';

export const googleConstants = {
  ages,
  genders,
  income_ranges,
  parents,
  products_services,
};
