import { Box, LinearProgress, Typography } from '@mui/material';
import React from 'react';
import { DEFAULT_COLOR_THEME } from '../../utils/Styling';

interface LinearProgressBarWithLabelProps {
  value: number;
}

const LinearProgressBarWithLabel: React.FC<LinearProgressBarWithLabelProps> = ({
  value,
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1, flexDirection: 'column' }}>
        <div>
          <Typography variant="caption" sx={{ color: DEFAULT_COLOR_THEME }}>
            Your progress
          </Typography>
        </div>

        <div>
          <Typography
            variant="body2"
            sx={{ color: DEFAULT_COLOR_THEME, fontWeight: 'bold' }}
          >
            {`${Math.round(value)}% completed`}
          </Typography>
        </div>

        <div>
          <LinearProgress
            variant="determinate"
            value={value}
            sx={{
              height: 10,
              borderRadius: '64px',
              '& .MuiLinearProgress-colorPrimary': {
                backgroundColor: DEFAULT_COLOR_THEME,
              },
              '& .MuiLinearProgress-barColorPrimary': {
                backgroundColor: DEFAULT_COLOR_THEME,
              },
              '& .MuiLinearProgress-dashedColorPrimary': {
                backgroundColor: DEFAULT_COLOR_THEME,
              },
            }}
          />
        </div>
      </Box>
    </Box>
  );
};

export default LinearProgressBarWithLabel;
