import {
  Box,
  Chip,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { AdVideo, Brand, FacebookAd } from '../../../types';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {
  constantStringToHumanized,
  copyToClipboard,
} from '../../../utils/stringModifier';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../redux/actions';
import { DEFAULT_COLOR_THEME, IS_DIY_ADZ } from '../../../utils';
import HTMLReactParser from 'html-react-parser';
import { AdsPreviewSquare } from '../../Forms/Diy/AdsPreview';
import { AdsTemplate, DiySettings } from '../../../types/IDiy';
import Spinner from '../../Spinner';
import { FbAdStatus } from '../../../utils/helpers/facebookAdsHelper';

interface FacebookAdPreviewProps {
  fbAd: FacebookAd;
  previewIframe: string;
  template: AdsTemplate;
  diySettings: DiySettings;
  brand: Brand;
  adVideo: AdVideo;
  loading: boolean;
  isTemplate: boolean;
  fbAdLoading: boolean;
}

const FacebookAdPreview: React.FC<FacebookAdPreviewProps> = ({
  fbAd,
  previewIframe,
  template,
  diySettings,
  brand,
  adVideo,
  loading,
  isTemplate,
  fbAdLoading,
}) => {
  const dispatch = useDispatch();

  const handleOpenToast = () => {
    dispatch(
      toggleAlert({
        toggle: true,
        message:
          'Copied to clipboard. Open a new tab and paste the link to see the preview.',
        vertical: 'top',
        horizontal: 'center',
      }),
    );
  };

  const chipTheme = (status: string) => {
    if (status === FbAdStatus.ACTIVE) {
      return DEFAULT_COLOR_THEME;
    }

    if (status === FbAdStatus.IN_PROCESS) {
      return 'blue';
    }

    return 'red';
  };

  return (
    <Grid container spacing={2}>
      {!isTemplate ? (
        fbAdLoading ? (
          <Grid
            item
            xs={12}
            sx={{
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Spinner />
          </Grid>
        ) : (
          <>
            <Grid
              item
              xs={12}
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                Status:{' '}
                <Box component="span">
                  <Chip
                    label={constantStringToHumanized(
                      fbAd?.effective_status || '',
                    )}
                    sx={{
                      backgroundColor: chipTheme(fbAd?.effective_status),
                      color: 'white',
                      height: '100%',
                    }}
                  />
                </Box>
              </Typography>
            </Grid>

            {!isTemplate ? (
              <Grid item xs={12}>
                <Typography variant="body2" fontWeight="bold">
                  Ad ID:{' '}
                  <Typography component="span" variant="body2">
                    {fbAd?.id}
                  </Typography>
                </Typography>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <TextField
                value={fbAd?.preview_shareable_link}
                disabled
                sx={{
                  width: '100%',
                  '& input.Mui-disabled': {
                    color: 'black',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => {
                        copyToClipboard(fbAd?.preview_shareable_link);
                        handleOpenToast();
                      }}
                    >
                      <ContentCopyOutlinedIcon sx={{ color: '#1976d2' }} />
                    </IconButton>
                  ),
                }}
                size="small"
              />
            </Grid>

            <Grid item xs={12} mb={2}>
              <Typography
                variant="body2"
                sx={{ textAlign: 'center', color: 'gray' }}
              >
                * This is only a mock preview. Click the copy button above to
                view the live ad.
              </Typography>
            </Grid>
          </>
        )
      ) : null}

      {IS_DIY_ADZ ? (
        loading ? (
          <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
            <Spinner />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <AdsPreviewSquare
              page={diySettings?.facebookPage}
              body={
                template?.creativeTemplate?.asset_feed_spec?.bodies[0]?.text
              }
              isImage={template?.media === 'image'}
              imageUrl={template?.images?.square}
              websiteUrl={
                template?.creativeTemplate?.asset_feed_spec?.link_urls[0]
                  ?.website_url
              }
              headline={
                template?.creativeTemplate?.asset_feed_spec?.titles[0]?.text
              }
              description={
                template?.creativeTemplate?.asset_feed_spec?.descriptions[0]
                  ?.text
              }
              cta={constantStringToHumanized(
                template?.creativeTemplate?.asset_feed_spec
                  ?.call_to_action_types[0] || '',
              )}
              videoUrl={adVideo?.source}
              objective={template?.campaignTemplate?.objective}
              brand={brand}
            />
          </Grid>
        )
      ) : null}

      {!IS_DIY_ADZ ? (
        loading && !previewIframe ? (
          <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
            <Spinner />
          </Grid>
        ) : (
          <Grid item xs={12}>
            {HTMLReactParser(previewIframe)}
          </Grid>
        )
      ) : null}
    </Grid>
  );
};

export default FacebookAdPreview;
