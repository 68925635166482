import {
  Badge,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import styles from '../../assets/styles/components/Navbar/SideNavBar.module.scss';
import { ExpressThemeContext } from '../../context';
import { Link, useLocation } from 'react-router-dom';
import chatzAppLogo from '../../assets/images/logo.png';
import moment from 'moment';
import { diyAdzDrawers, drawers } from './Drawers';
import { Brand } from '../../types';
import diyAdzLogo from '../../assets/images/aiadz-logo.png';
import { IS_DIY_ADZ } from '../../utils';
import { AnalyticTools } from '../../utils/helpers/BrandHelpers';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { DEFAULT_COLOR_THEME } from '../../utils/Styling';
import { UserCapability } from '../../context/authContext';
import { CrmSetting } from '../../types/ICrm';

interface SideNavBarProps {
  drawerWidth: number;
  onOpenDrawer: () => void;
  open: boolean;
  isAdmin: boolean;
  isAgency: boolean;
  brand: Brand;
  location: Brand;
  isFranchisee: boolean;
  crmSettings?: CrmSetting | null;
  isFacebook?: boolean;
  capabilities?: UserCapability | null;
  withAgencyAccess?: boolean;
}

const SideNavBar: React.FC<SideNavBarProps> = ({
  drawerWidth,
  onOpenDrawer,
  open,
  isAdmin,
  isAgency,
  brand,
  location,
  isFranchisee,
  isFacebook = false,
  capabilities = null,
  withAgencyAccess = false,
  crmSettings = null,
}) => {
  const {
    state: { expressTheme },
  } = useContext(ExpressThemeContext);

  const pathLocation = useLocation();
  const pathnames = pathLocation.pathname.split('/').filter((x) => x);
  const [collapse, setCollapse] = useState<boolean>(false);

  useEffect(() => {
    if (pathnames.length > 0 && pathnames[0] === 'reportz') {
      setCollapse(true);
    }
  }, [pathnames]);

  const navigate = useNavigate();

  const logo = () => {
    if (IS_DIY_ADZ) {
      if ((location || brand)?.agency?.logo?.url) {
        return (location || brand)?.agency?.logo?.url;
      }

      return diyAdzLogo;
    }

    return expressTheme.properties.logo?.url
      ? expressTheme.properties.logo?.url
      : chatzAppLogo;
  };

  const drawer = (
    <Paper
      className={styles.sidenav}
      elevation={8}
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
          width: '10px',
        },
        '&::-webkit-scrollbar-track': {
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
        },

        '&::-webkit-scrollbar-thumb': {
          background: DEFAULT_COLOR_THEME,
          borderRadius: '18px',

          '&:hover': {
            background: DEFAULT_COLOR_THEME,
          },
        },
      }}
    >
      <Toolbar
        sx={{ justifyContent: 'center', mt: '10px', flexDirection: 'column' }}
      >
        <Link to={IS_DIY_ADZ ? '/reportz/kpiz' : '/dashboard'}>
          <Box
            component="img"
            src={logo()}
            alt=""
            sx={{ width: IS_DIY_ADZ ? '90px' : '120px' }}
          />
        </Link>

        <div>
          <Typography variant="body1" sx={{ fontSize: '12px', mb: '5px' }}>
            {moment().utc().local().format('LL')}
          </Typography>
        </div>
      </Toolbar>

      <List component="nav">
        {(!IS_DIY_ADZ
          ? drawers(
              location ? location : brand,
              isAdmin,
              isAgency,
              isFranchisee,
            )
          : diyAdzDrawers(
              location || brand,
              isFacebook,
              crmSettings,
              isAdmin,
              capabilities,
              withAgencyAccess,
            )
        ).map((drawer) => (
          <Box key={drawer.text}>
            <ListItem
              className={styles.item}
              classes={{ selected: `${styles['-active']}` }}
              onClick={() => {
                if (drawer.collapsible) {
                  setCollapse((prev) => !prev);
                } else {
                  navigate(drawer.link);
                  onOpenDrawer();
                }
              }}
              selected={pathnames[0] === drawer.pathName}
            >
              <ListItemIcon className={styles.icon}>{drawer.icon}</ListItemIcon>

              <ListItemText
                primary={
                  <Fragment>
                    {drawer.text === 'Reportz' ? (
                      <Badge
                        badgeContent={(location || brand)?.activeAlertsCount}
                        color="secondary"
                        sx={{
                          '& .MuiBadge-badge': {
                            right: -20,
                            top: 13,
                            padding: '0 4px',
                            backgroundColor: DEFAULT_COLOR_THEME,
                            color: 'white',
                            fontWeight: 'bold',
                          },
                        }}
                      >
                        {`${drawer.text}`}
                      </Badge>
                    ) : (
                      `${drawer.text}`
                    )}
                  </Fragment>
                }
                primaryTypographyProps={{
                  sx: {
                    fontWeight: 'bold',
                    color: '#096F4D',
                  },
                }}
              />

              {drawer.collapsible ? (
                collapse ? (
                  <MdExpandLess size={25} />
                ) : (
                  <MdExpandMore size={25} />
                )
              ) : null}
            </ListItem>

            {drawer.items?.length > 0 ? (
              <Collapse in={collapse} timeout="auto" unmountOnExit>
                {drawer.items.map((item: any) => {
                  return (
                    <ListItem
                      className={styles.item}
                      classes={{ selected: `${styles['-active']}` }}
                      key={item.text}
                      onClick={() => {
                        navigate(item.link);
                        onOpenDrawer();
                      }}
                      selected={
                        pathnames[pathnames.length - 1] === item.pathName
                      }
                    >
                      <ListItemIcon
                        className={`${styles['icon']} ${styles['-subitem']}`}
                      >
                        {item.icon}
                      </ListItemIcon>

                      <ListItemText
                        primary={
                          <Fragment>
                            {item.text === 'Alertz' ? (
                              <Badge
                                badgeContent={
                                  (location || brand)?.activeAlertsCount
                                }
                                color="secondary"
                                sx={{
                                  '& .MuiBadge-badge': {
                                    right: -20,
                                    top: 13,
                                    padding: '0 4px',
                                    backgroundColor: DEFAULT_COLOR_THEME,
                                    color: 'white',
                                    fontWeight: 'bold',
                                  },
                                }}
                              >
                                {`${item.text}`}
                              </Badge>
                            ) : (
                              `${item.text}`
                            )}
                          </Fragment>
                        }
                        primaryTypographyProps={{
                          sx: {
                            fontWeight: 'bold',
                            color: '#096F4D',
                          },
                        }}
                      />
                    </ListItem>
                  );
                })}
              </Collapse>
            ) : null}
          </Box>
        ))}
      </List>
    </Paper>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, height: '100%' }}
    >
      <Drawer
        variant="temporary"
        open={open}
        onClose={onOpenDrawer}
        ModalProps={{
          // Better open performance on mobile.
          keepMounted: true,
        }}
        sx={{
          display: { sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>

      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default SideNavBar;
