import axios from 'axios';
import { apiBaseUrl } from './api';

export const inviteSalesperson = async (obj: {
  email: string;
  clientUri: string;
  diy: boolean;
  agencyId?: string;
}) => {
  const data = await axios.post(`${apiBaseUrl}/invite/salesperson`, obj);

  return data?.data;
};

export const inviteAgency = async (obj: {
  email: string;
  clientUri: string;
  diy: boolean;
}) => {
  const data = await axios.post(`${apiBaseUrl}/invite/agency`, obj);

  return data?.data;
};
