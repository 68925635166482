import React, { Dispatch, SetStateAction, useState } from 'react';
import { AdsTemplate, DiyError, DiySettings } from '../../../types/IDiy';
import {
  Brand,
  ChangeEventType,
  FbTargetSearch,
  FbTargetSearchCustomLocation,
} from '../../../types';
import {
  Autocomplete,
  Card,
  CardActions,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Slider,
  TextField,
  Typography,
} from '@mui/material';
import {
  buildAddress,
  getGeolocationFields,
  locationTypes,
} from '../../../utils/helpers/DiyHelpers';
import { humanizeString } from '../../../utils/stringModifier';
import GeolocationForm, { buildOptionName } from '../Ads/GeolocationForm';
import Maps from '../Maps';
import CountrySelectMenu from '../../Select/CountrySelectMenu';

interface DiyTargetMarketFormProps {
  diySettings: DiySettings;
  setDiySettings: Dispatch<SetStateAction<DiySettings>>;
  brand: Brand;
  geoLocationTemplate: any;
  setGeoLocationTemplate: Dispatch<SetStateAction<any>>;
  excludedGeolocationTemplate: any;
  setExcludedGeolocationTemplate: Dispatch<SetStateAction<any>>;
  selectedTemplate: AdsTemplate;
  setSelectedTemplate: Dispatch<SetStateAction<AdsTemplate>>;
  isSpecialAds: boolean;
  isEmployment: boolean;
  selectedAddress: any;
  setSelectedAddress: Dispatch<SetStateAction<any>>;
  getTargetReach: (targeting: FbTargetSearch) => void;
  excludeGeolocation: boolean;
  setExcludeGeolocation: Dispatch<SetStateAction<boolean>>;
  error: DiyError;
}

const DiyTargetMarketForm: React.FC<DiyTargetMarketFormProps> = ({
  diySettings,
  setDiySettings,
  brand,
  geoLocationTemplate,
  setGeoLocationTemplate,
  excludedGeolocationTemplate,
  setExcludedGeolocationTemplate,
  selectedTemplate,
  setSelectedTemplate,
  isSpecialAds,
  isEmployment,
  selectedAddress,
  setSelectedAddress,
  getTargetReach,
  excludeGeolocation,
  setExcludeGeolocation,
  error,
}) => {
  const [markers, setMarkers] = useState<any[]>([]);
  const [center, setCenter] = useState<any>(null);
  const [map, setMap] = useState<any>(null);

  const getCoordinates = async (address: string) => {
    const request = {
      query: address,
      fields: ['ALL'],
    };

    let service = new google.maps.places.PlacesService(map);

    return new Promise((resolve, reject) => {
      service.findPlaceFromQuery(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const coordinates = {
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
            placeId: results[0].place_id,
          };
          resolve(coordinates);
        } else {
          reject(new Error(`Place query failed with status: ${status}`));
        }
      });
    });
  };

  const selectedLocations = (type: string, exclude?: boolean) => {
    let temp: any[] = [];

    const geolocationObj = () => {
      if (exclude) {
        return selectedTemplate?.adSetTemplate?.targeting
          ?.excluded_geo_locations;
      }

      return selectedTemplate?.adSetTemplate?.targeting?.geo_locations;
    };

    switch (type) {
      case 'city':
        geolocationObj()?.cities?.forEach((city: any) => {
          if (
            [
              type,
              'subcity',
              'neighborhood',
              'subneighborhood',
              'metro_area',
            ].includes(city.type)
          ) {
            temp = [...temp, city];
          }
        });

        break;
      case 'county':
        geolocationObj()?.cities?.forEach((city: any) => {
          if (city.type === 'medium_geo_area') {
            temp = [...temp, city];
          }
        });

        break;
      case 'zip':
        geolocationObj()?.zips?.forEach((zip: any) => {
          if (zip.type === type) {
            temp = [...temp, zip];
          }
        });
        break;
      case 'state':
        geolocationObj()?.regions?.forEach((region: any) => {
          if (region.type === 'region') {
            temp = [...temp, region];
          }
        });
        break;
      case 'geo_market':
        geolocationObj()?.geo_markets?.forEach((dma: any) => {
          if (dma.type === 'geo_market') {
            temp = [...temp, dma];
          }
        });
        break;
      default:
        geolocationObj()?.countries?.forEach((country: any) => {
          if (country.type === 'country') {
            temp = [...temp, country];
          }
        });
        break;
    }

    return temp;
  };

  const buildMaxAddressRadius = (brand: Brand) => {
    if (isSpecialAds) {
      return 50;
    }

    return diySettings?.maxAddressRadius || 50;
  };

  const buildGeoLocationsOptions = (type: string, exclude?: boolean) => {
    let temp: any[] = [];

    const diySettingsObj = () => {
      if (exclude) {
        return diySettings?.excludedGeolocations;
      }

      return diySettings?.geolocation;
    };

    switch (type) {
      case 'city':
        diySettingsObj()?.cities?.forEach((city: any) => {
          if (
            [
              type,
              'subcity',
              'neighborhood',
              'subneighborhood',
              'metro_area',
            ].includes(city.type)
          ) {
            temp = [...temp, city];
          }
        });
        break;
      case 'county':
        diySettingsObj()?.cities?.forEach((city: any) => {
          if (city.type === 'medium_geo_area') {
            temp = [...temp, city];
          }
        });
        break;
      case 'zip':
        diySettingsObj()?.zips?.forEach((zip: any) => {
          if (zip.type === type) {
            temp = [...temp, zip];
          }
        });
        break;
      case 'state':
        diySettingsObj()?.regions?.forEach((region: any) => {
          if (region.type === 'region') {
            temp = [...temp, region];
          }
        });
        break;
      default:
        diySettingsObj()?.countries?.forEach((country: any) => {
          if (country.type === 'country') {
            temp = [...temp, country];
          }
        });
        break;
    }

    return temp;
  };

  const handleOnChangeRadius = (
    value: number,
    type: string,
    index: number,
    cityKey?: string,
  ) => {
    let temp: AdsTemplate = { ...selectedTemplate };
    switch (type) {
      case 'city':
      case 'large_geo_area':
      case 'medium_geo_area':
      case 'small_geo_area':
      case 'subcity':
      case 'neighborhood':
      case 'subneighborhood':
      case 'metro_area':
        let tempCities: any[] = [];
        temp.adSetTemplate.targeting.geo_locations.cities.forEach(
          (city: any) => {
            if (city.key === cityKey) {
              city.radius = value;
            }

            tempCities = [...tempCities, city];
          },
        );

        temp.adSetTemplate.targeting.geo_locations.cities = tempCities;

        break;
      case 'region':
        temp.adSetTemplate.targeting.geo_locations.regions[index].radius =
          value;

        break;
      case 'zip':
        temp.adSetTemplate.targeting.geo_locations.zips[index].radius = value;

        break;
      default:
        temp.adSetTemplate.targeting.geo_locations.custom_locations[
          index
        ].radius = value;

        break;
    }

    setSelectedTemplate(temp);

    setTimeout(() => {
      getTargetReach(temp?.adSetTemplate?.targeting);
    }, 1000);
  };

  const handleSelectLocation = async (
    values: any[],
    field: string,
    selectedValue: any,
    exclude: boolean,
  ) => {
    getCoordinates(buildAddress(selectedValue, true))
      .then((coordinates: any) => {
        let temp: any[] = [];

        const geolocationObj = () => {
          if (exclude) {
            return selectedTemplate?.adSetTemplate?.targeting
              ?.excluded_geo_locations;
          }

          return selectedTemplate?.adSetTemplate?.targeting?.geo_locations;
        };

        switch (field) {
          case 'cities':
            temp = [...(geolocationObj()?.cities || [])];
            break;
          case 'zips':
            temp = [...(geolocationObj()?.zips || [])];
            break;
          case 'regions':
            temp = [...(geolocationObj()?.regions || [])];
            break;
          case 'geo_markets':
            temp = [...(geolocationObj()?.geo_markets || [])];
            break;
          default:
            temp = [...(geolocationObj()?.countries || [])];
            break;
        }

        let params: any = { ...selectedValue, ...coordinates };

        if (selectedValue.type === 'city') {
          params = { ...params, radius: diySettings?.maxCityRadius };
        }

        temp = [...temp, params];

        if (exclude) {
          setExcludedGeolocationTemplate({
            ...excludedGeolocationTemplate,
            [field]: temp,
          });
        } else {
          setGeoLocationTemplate({ ...geoLocationTemplate, [field]: temp });
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const handleSelectGeolocation = (
    value: any[],
    field: string,
    selectedValue: any,
    exclude?: boolean,
  ) => {
    let temp: any[] = [];

    const geolocationObj = () => {
      if (exclude) {
        return selectedTemplate?.adSetTemplate?.targeting
          ?.excluded_geo_locations;
      }

      return selectedTemplate?.adSetTemplate?.targeting?.geo_locations;
    };

    switch (field) {
      case 'cities':
        temp = [...(geolocationObj()?.cities || [])];
        break;
      case 'zips':
        temp = [...(geolocationObj()?.zips || [])];
        break;
      case 'regions':
        temp = [...(geolocationObj()?.regions || [])];
        break;
      case 'geo_markets':
        temp = [...(geolocationObj()?.geo_markets || [])];
        break;
      default:
        temp = [...(geolocationObj()?.countries || [])];
        break;
    }

    temp = [...temp, selectedValue];

    if (exclude) {
      setExcludedGeolocationTemplate({
        ...excludedGeolocationTemplate,
        [field]: temp,
      });
    } else {
      setGeoLocationTemplate({ ...geoLocationTemplate, [field]: temp });
    }
  };

  const handleRemoveLocation = async (
    value: any,
    type: string,
    field: string,
    exclude?: boolean,
  ) => {
    let temp: any[] = [];
    let data: any[] = [];

    const geolocationObj = () => {
      if (exclude) {
        return selectedTemplate?.adSetTemplate?.targeting
          ?.excluded_geo_locations;
      }

      return selectedTemplate?.adSetTemplate?.targeting?.geo_locations;
    };

    switch (field) {
      case 'cities':
        data = [...(geolocationObj()?.cities || [])];
        break;
      case 'zips':
        data = [...(geolocationObj()?.zips || [])];
        break;
      case 'regions':
        data = [...(geolocationObj()?.regions || [])];
        break;
      case 'geo_markets':
        data = [...(geolocationObj()?.geo_markets || [])];
        break;
      default:
        data = [...(geolocationObj()?.countries || [])];
        break;
    }

    data.forEach((location: any) => {
      if (location.key !== value.key) {
        temp = [...temp, location];
      }
    });

    if (exclude) {
      setSelectedTemplate({
        ...selectedTemplate,
        adSetTemplate: {
          ...selectedTemplate.adSetTemplate,
          targeting: {
            ...selectedTemplate.adSetTemplate.targeting,
            excluded_geo_locations: {
              ...selectedTemplate.adSetTemplate.targeting
                .excluded_geo_locations,
              [field]: temp,
            },
          },
        },
      });

      setExcludedGeolocationTemplate({
        ...excludedGeolocationTemplate,
        [field]: temp,
      });
    } else {
      setSelectedTemplate({
        ...selectedTemplate,
        adSetTemplate: {
          ...selectedTemplate.adSetTemplate,
          targeting: {
            ...selectedTemplate.adSetTemplate.targeting,
            geo_locations: {
              ...selectedTemplate.adSetTemplate.targeting.geo_locations,
              [field]: temp,
            },
          },
        },
      });

      setGeoLocationTemplate({ ...geoLocationTemplate, [field]: temp });
    }
  };

  const handleRemoveLocationType = async (field: string) => {
    setSelectedTemplate({
      ...selectedTemplate,
      adSetTemplate: {
        ...selectedTemplate.adSetTemplate,
        targeting: {
          ...selectedTemplate.adSetTemplate.targeting,
          geo_locations: {
            ...selectedTemplate.adSetTemplate.targeting.geo_locations,
            [field]: [],
          },
        },
      },
    });

    setGeoLocationTemplate({ ...geoLocationTemplate, [field]: [] });
  };

  const handleOnSelectCountries = (e: ChangeEventType, value: any) => {
    setDiySettings({
      ...diySettings,
      targetCountries: value,
    });
  };

  return (
    <>
      {diySettings?.allowAllGeolocation &&
      ((brand?.__type === 'brand' && !brand?.allowLocations) ||
        brand?.__type === 'location') ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CountrySelectMenu
              value={
                diySettings?.targetCountries || [
                  {
                    code: 'US',
                    label: 'United States',
                    phone: '1',
                  },
                ]
              }
              onChange={handleOnSelectCountries}
              shrink
              multiple
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              Select the location types you want to target
            </Typography>

            <FormGroup sx={{ flexDirection: 'row' }}>
              {diySettings?.locationTypes
                ? locationTypes.map((type: string, index: number) => {
                    if (
                      type === 'address' &&
                      (!brand?.address?.lat || !brand?.address?.lng)
                    )
                      return <></>;

                    return (
                      <FormControlLabel
                        key={`location-type-${type}`}
                        control={
                          <Checkbox
                            size="small"
                            defaultChecked={diySettings?.locationTypes?.includes(
                              type,
                            )}
                          />
                        }
                        label={
                          type === 'geo_market' ? 'DMA' : humanizeString(type)
                        }
                        value={diySettings?.locationTypes?.includes(type)}
                        onChange={(e: any) => {
                          let temp: string[] = [];
                          if (e.target.checked) {
                            temp = [...diySettings?.locationTypes, type];
                          } else {
                            diySettings?.locationTypes?.forEach(
                              (locationType: string) => {
                                if (locationType !== type) {
                                  temp = [...temp, locationType];
                                }
                              },
                            );

                            const field = getGeolocationFields(type);

                            handleRemoveLocationType(field);
                          }

                          setDiySettings({
                            ...diySettings,
                            locationTypes: temp,
                          });
                        }}
                      />
                    );
                  })
                : null}
            </FormGroup>
          </Grid>

          {error?.geolocationError ? (
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ color: 'red' }}>
                {error?.geolocationError}
              </Typography>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            {diySettings?.locationTypes?.map((type: string) => {
              if (type === 'address') {
                return (
                  <Grid container spacing={2} key={type}>
                    {brand?.address ? (
                      <Grid item xs={12}>
                        <Autocomplete
                          renderOption={(props, option) => {
                            const { line1, city, state, zipcode, country } =
                              option;
                            const address = `${line1 ? `${line1}, ` : ''}${
                              city ? `${city}, ` : ''
                            }${state ? `${state}, ` : ''}${
                              zipcode ? `${zipcode}, ` : ''
                            }${country ? `${country}` : ''}`;

                            return (
                              <li {...props} key={option.lat}>
                                {address}
                              </li>
                            );
                          }}
                          options={[brand?.address]}
                          value={selectedAddress}
                          getOptionLabel={(option) => {
                            const { line1, city, state, zipcode, country } =
                              option;
                            const address = `${line1 ? `${line1}, ` : ''}${
                              city ? `${city}, ` : ''
                            }${state ? `${state}, ` : ''}${
                              zipcode ? `${zipcode}, ` : ''
                            }${country ? `${country}` : ''}`;

                            return address;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label="Select Address"
                              placeholder="Search Location"
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              autoComplete="off"
                            />
                          )}
                          sx={{ marginBottom: '10px' }}
                          onChange={(e: any, value: any, reason, detail) => {
                            let temp: FbTargetSearchCustomLocation[] = [];

                            if (reason === 'clear') {
                              setSelectedAddress(null);
                              setGeoLocationTemplate({
                                ...geoLocationTemplate,
                                custom_locations: [],
                              });
                            } else {
                              temp = [
                                {
                                  latitude: value.lat,
                                  longitude: value.lng,
                                  radius: diySettings?.maxAddressRadius,
                                  distance_unit: 'mile',
                                },
                              ];
                              setGeoLocationTemplate({
                                ...geoLocationTemplate,
                                custom_locations: temp,
                              });
                              setSelectedAddress(value);
                            }

                            setSelectedTemplate({
                              ...selectedTemplate,
                              adSetTemplate: {
                                ...selectedTemplate.adSetTemplate,
                                targeting: {
                                  ...selectedTemplate.adSetTemplate.targeting,
                                  geo_locations: {
                                    ...selectedTemplate.adSetTemplate.targeting
                                      .geo_locations,
                                    custom_locations: temp,
                                  },
                                },
                              },
                            });
                          }}
                        />
                      </Grid>
                    ) : null}

                    {selectedAddress ? (
                      <Grid item xs={12} md={6} sx={{ flexDirection: 'row' }}>
                        <Typography variant="caption">
                          {`${selectedTemplate?.adSetTemplate?.targeting?.geo_locations?.custom_locations[0]?.radius} miles`}
                        </Typography>

                        <Slider
                          defaultValue={isSpecialAds ? 15 : 1}
                          min={isSpecialAds ? 15 : 1}
                          max={buildMaxAddressRadius(brand)}
                          valueLabelDisplay="auto"
                          value={
                            selectedTemplate?.adSetTemplate?.targeting
                              ?.geo_locations?.custom_locations[0]?.radius
                          }
                          onChange={(e: any) =>
                            handleOnChangeRadius(
                              e.target.value,
                              'custom_address',
                              0,
                            )
                          }
                          marks
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                );
              }

              if (type === 'city') {
                let filteredCities: any[] = [];

                const { cities } =
                  selectedTemplate?.adSetTemplate?.targeting?.geo_locations ??
                  {};

                if (type === 'city') {
                  const data = cities?.filter(
                    (city: any) => city.type === 'city',
                  );

                  filteredCities = [...(data || [])];
                }

                return (
                  <Grid item xs={12} mb={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <GeolocationForm
                          type={type}
                          onSelectLocation={handleSelectLocation}
                          geolocations={selectedLocations(type)}
                          onRemoveLocation={handleRemoveLocation}
                          isAudience={false}
                          formValues={brand}
                          diySettings={diySettings}
                          setDiySettings={setDiySettings}
                          diy
                        />
                      </Grid>

                      {filteredCities?.map((city: any, index: number) => {
                        return (
                          <Grid item xs={12} sm={6} key={`cities-${index + 1}`}>
                            <Card>
                              <CardHeader
                                subheader={`${city.name}, ${city.region}, ${city.country_code}`}
                                subheaderTypographyProps={{
                                  color: '#096F4D',
                                  fontWeight: 'bold',
                                }}
                              />

                              <CardActions
                                sx={{
                                  paddingTop: 0,
                                  flexDirection: 'column',
                                  paddingX: '20px',
                                }}
                              >
                                <Typography variant="caption">
                                  {`${city.radius} miles`}
                                </Typography>

                                <Slider
                                  defaultValue={
                                    city.radius ||
                                    diySettings?.maxCityRadius ||
                                    10
                                  }
                                  min={10}
                                  max={diySettings?.maxCityRadius}
                                  valueLabelDisplay="auto"
                                  value={city.radius}
                                  onChange={(e: any) => {
                                    handleOnChangeRadius(
                                      e.target.value,
                                      city.type,
                                      index,
                                      city.key,
                                    );
                                  }}
                                  marks
                                />
                              </CardActions>
                            </Card>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                );
              }

              return (
                <Grid item xs={12}>
                  <GeolocationForm
                    type={type}
                    onSelectLocation={handleSelectLocation}
                    geolocations={selectedLocations(type)}
                    onRemoveLocation={handleRemoveLocation}
                    isAudience={false}
                    formValues={brand}
                    diySettings={diySettings}
                    setDiySettings={setDiySettings}
                    diy
                  />
                </Grid>
              );
            })}
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox size="small" defaultChecked={excludeGeolocation} />
              }
              name="excludeGeolocation"
              label="Do you want to exclude targeted territories?"
              value={excludeGeolocation}
              onChange={(e: any) => setExcludeGeolocation(e.target.checked)}
            />
          </Grid>

          {excludeGeolocation ? (
            <>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Select the excluded location types
                </Typography>

                <FormGroup sx={{ flexDirection: 'row' }}>
                  {diySettings?.excludedLocationTypes
                    ? locationTypes.map((type: string, index: number) => {
                        if (type === 'address' || type === 'country') {
                          return <></>;
                        }

                        return (
                          <FormControlLabel
                            key={`location-type-${type}`}
                            control={
                              <Checkbox
                                size="small"
                                defaultChecked={diySettings?.excludedLocationTypes?.includes(
                                  type,
                                )}
                              />
                            }
                            label={
                              type === 'geo_market'
                                ? 'DMA'
                                : humanizeString(type)
                            }
                            value={diySettings?.excludedLocationTypes?.includes(
                              type,
                            )}
                            onChange={(e: any) => {
                              let temp: string[] = [];
                              if (e.target.checked) {
                                temp = [
                                  ...diySettings?.excludedLocationTypes,
                                  type,
                                ];
                              } else {
                                diySettings?.excludedLocationTypes?.forEach(
                                  (locationType: string) => {
                                    if (locationType !== type) {
                                      temp = [...temp, locationType];
                                    }
                                  },
                                );
                              }

                              setDiySettings({
                                ...diySettings,
                                excludedLocationTypes: temp,
                              });
                            }}
                          />
                        );
                      })
                    : null}
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                {diySettings?.excludedLocationTypes?.map((type: string) => {
                  return (
                    <GeolocationForm
                      type={type}
                      onSelectLocation={handleSelectLocation}
                      geolocations={selectedLocations(type, true)}
                      onRemoveLocation={handleRemoveLocation}
                      isAudience={false}
                      formValues={brand}
                      diySettings={diySettings}
                      setDiySettings={setDiySettings}
                      exclude
                      diy
                    />
                  );
                })}
              </Grid>
            </>
          ) : null}

          <Grid item xs={12}>
            <Maps
              template={selectedTemplate}
              isAudience
              brand={brand}
              markers={markers}
              setMarkers={setMarkers}
              center={center}
              setCenter={setCenter}
              setParentMap={setMap}
            />
          </Grid>
        </Grid>
      ) : (
        <>
          {[
            ...(diySettings?.locationTypes || []),
            ...(isEmployment ? ['address'] : []),
          ]
            ?.filter((elem, index, self) => {
              return index === self.indexOf(elem);
            })
            ?.map((type: string, index: number) => {
              if (isSpecialAds && type !== 'address') return <></>;

              let filteredCities: any[] = [];

              const { cities } =
                selectedTemplate?.adSetTemplate?.targeting?.geo_locations ?? {};

              if (type === 'city') {
                const data = cities?.filter(
                  (city: any) => city.type === 'city',
                );

                filteredCities = [...(data || [])];
              }

              return (
                <Grid container spacing={2} key={`city-form-${index + 1}`}>
                  <Grid item xs={12}>
                    <Typography variant="body1" fontWeight="bold">
                      {type === 'geo_market' ? 'DMA' : humanizeString(type)}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    {type === 'address' ? (
                      <>
                        {brand?.address ? (
                          <Autocomplete
                            renderOption={(props, option) => {
                              const { line1, city, state, zipcode, country } =
                                option;
                              const address = `${line1 ? `${line1}, ` : ''}${
                                city ? `${city}, ` : ''
                              }${state ? `${state}, ` : ''}${
                                zipcode ? `${zipcode}, ` : ''
                              }${country ? `${country}` : ''}`;

                              return (
                                <li {...props} key={option.lat}>
                                  {address}
                                </li>
                              );
                            }}
                            options={[brand?.address]}
                            value={selectedAddress}
                            getOptionLabel={(option) => {
                              const { line1, city, state, zipcode, country } =
                                option;
                              const address = `${line1 ? `${line1}, ` : ''}${
                                city ? `${city}, ` : ''
                              }${state ? `${state}, ` : ''}${
                                zipcode ? `${zipcode}, ` : ''
                              }${country ? `${country}` : ''}`;

                              return address;
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                label="Select Address"
                                placeholder="Search Location"
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                autoComplete="off"
                              />
                            )}
                            sx={{ marginBottom: '10px' }}
                            onChange={(e: any, value: any, reason, detail) => {
                              let temp: FbTargetSearchCustomLocation[] = [];

                              if (reason === 'clear') {
                                setSelectedAddress(null);
                                setGeoLocationTemplate({
                                  ...geoLocationTemplate,
                                  custom_locations: [],
                                });
                              } else {
                                temp = [
                                  {
                                    latitude: value.lat,
                                    longitude: value.lng,
                                    radius: diySettings?.maxAddressRadius,
                                    distance_unit: 'mile',
                                  },
                                ];
                                setGeoLocationTemplate({
                                  ...geoLocationTemplate,
                                  custom_locations: temp,
                                });
                                setSelectedAddress(value);
                              }

                              setSelectedTemplate({
                                ...selectedTemplate,
                                adSetTemplate: {
                                  ...selectedTemplate.adSetTemplate,
                                  targeting: {
                                    ...selectedTemplate.adSetTemplate.targeting,
                                    geo_locations: {
                                      ...selectedTemplate.adSetTemplate
                                        .targeting.geo_locations,
                                      custom_locations: temp,
                                    },
                                  },
                                },
                              });
                            }}
                          />
                        ) : null}

                        {selectedAddress ? (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ flexDirection: 'row' }}
                          >
                            <Typography variant="caption">
                              {`${selectedTemplate?.adSetTemplate?.targeting?.geo_locations?.custom_locations[0]?.radius} miles`}
                            </Typography>

                            <Slider
                              defaultValue={isSpecialAds ? 15 : 1}
                              min={isSpecialAds ? 15 : 1}
                              max={buildMaxAddressRadius(brand)}
                              valueLabelDisplay="auto"
                              value={
                                selectedTemplate?.adSetTemplate?.targeting
                                  ?.geo_locations?.custom_locations[0]?.radius
                              }
                              onChange={(e: any) =>
                                handleOnChangeRadius(
                                  e.target.value,
                                  'custom_address',
                                  0,
                                )
                              }
                              marks
                            />
                          </Grid>
                        ) : null}
                      </>
                    ) : (
                      <Autocomplete
                        id={`geolocation-${type}`}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.key}>
                              {buildOptionName(option)}
                            </li>
                          );
                        }}
                        options={buildGeoLocationsOptions(type)}
                        value={selectedLocations(type)}
                        getOptionLabel={(option) => `${buildAddress(option)} `}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="Search Location"
                            placeholder="Search Location"
                            size="small"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                        disableClearable
                        getOptionDisabled={(option) =>
                          selectedLocations(type)
                            .map((location: any) => location.key)
                            .includes(option.key)
                        }
                        sx={{ marginBottom: '10px' }}
                        multiple
                        onChange={(e: any, values: any[], reason, detail) => {
                          const field = getGeolocationFields(type);

                          if (reason === 'removeOption') {
                            handleRemoveLocation(detail.option, type, field);
                          } else {
                            handleSelectGeolocation(
                              values,
                              field,
                              values.at(-1),
                            );
                          }
                        }}
                      />
                    )}
                  </Grid>

                  {type === 'city' ? (
                    <Grid item xs={12} mb={2}>
                      <Grid container spacing={2}>
                        {filteredCities?.map((city: any, index: number) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              key={`cities-${index + 1}`}
                            >
                              <Card>
                                <CardHeader
                                  subheader={`${city.name}, ${city.region}, ${city.country_code}`}
                                  subheaderTypographyProps={{
                                    color: '#096F4D',
                                    fontWeight: 'bold',
                                  }}
                                />
                                <CardActions
                                  sx={{
                                    paddingTop: 0,
                                    flexDirection: 'column',
                                    paddingX: '20px',
                                  }}
                                >
                                  <Typography variant="caption">
                                    {`${city.radius} miles`}
                                  </Typography>

                                  <Slider
                                    defaultValue={
                                      city.radius ||
                                      diySettings?.maxCityRadius ||
                                      10
                                    }
                                    min={10}
                                    max={diySettings?.maxCityRadius}
                                    valueLabelDisplay="auto"
                                    value={city.radius}
                                    onChange={(e: any) =>
                                      handleOnChangeRadius(
                                        e.target.value,
                                        city.type,
                                        index,
                                        city.key,
                                      )
                                    }
                                    marks
                                  />
                                </CardActions>
                              </Card>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              );
            })}

          {/**
           * Currently if Brand cannot target all geolocations,
           * they cannot remove the excluded geolocation.
           * TODO: Re-enable if allow this feature
           * 
           * diySettings?.excludeGeolocation ? (
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      defaultChecked={excludeGeolocation}
                    />
                  }
                  name="excludeGeolocation"
                  label="Do you want to exclude targeted territories?"
                  value={excludeGeolocation}
                  onChange={(e: any) => setExcludeGeolocation(e.target.checked)}
                />
              </Grid>

              {excludeGeolocation
                ? diySettings?.excludedLocationTypes?.map(
                    (type: string, index: number) => {
                      return (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="body1" fontWeight="bold">
                              {humanizeString(type)}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Autocomplete
                              id={`geolocation-${type}`}
                              renderOption={(props, option) => {
                                return (
                                  <li {...props} key={option.key}>
                                    {buildOptionName(option)}
                                  </li>
                                );
                              }}
                              options={buildGeoLocationsOptions(type, true)}
                              value={selectedLocations(type, true)}
                              getOptionLabel={(option) =>
                                `${buildAddress(option)} `
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required
                                  label="Search Location"
                                  placeholder="Search Location"
                                  size="small"
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                              disableClearable
                              getOptionDisabled={(option) =>
                                selectedLocations(type, true)
                                  .map((location: any) => location.key)
                                  .includes(option.key)
                              }
                              sx={{ marginBottom: '10px' }}
                              multiple
                              onChange={(
                                e: any,
                                values: any[],
                                reason,
                                detail,
                              ) => {
                                const field = () => {
                                  if (type === 'city') {
                                    return 'cities';
                                  }

                                  if (type === 'county') {
                                    return 'cities';
                                  }

                                  if (type === 'zip') {
                                    return 'zips';
                                  }

                                  if (type === 'state') {
                                    return 'regions';
                                  }

                                  return 'countries';
                                };
                                if (reason === 'removeOption') {
                                  handleRemoveLocation(
                                    detail.option,
                                    type,
                                    field(),
                                    true,
                                  );
                                } else {
                                  handleSelectGeolocation(
                                    values,
                                    field(),
                                    values.at(-1),
                                    true,
                                  );
                                }
                              }}
                            />
                          </Grid>
                        </>
                      );
                    },
                  )
                : null}
            </Grid>
          ) : null*/}

          <Grid container spacing={2} mb={2}>
            <Grid item xs={12}>
              <Maps
                template={selectedTemplate}
                isAudience
                brand={brand}
                markers={markers}
                setMarkers={setMarkers}
                center={center}
                setCenter={setCenter}
                setParentMap={setMap}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default DiyTargetMarketForm;
