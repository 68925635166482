import { Box } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { TiUpload } from 'react-icons/ti';

const baseStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  minHeight: '200px',
  cursor: 'pointer',
};

const focusedStyle = {
  borderColor: '#096f4d',
};

const acceptStyle = {
  borderColor: '#096f4d',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

interface GoogleLoginProps {
  setImages: any;
  sendRejects: any;
  validatorFunc: any;
  width?: string;
}

const ImageDropZone: React.FC<GoogleLoginProps> = ({
  setImages,
  sendRejects,
  validatorFunc,
  width = '100%',
}) => {
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: { 'image/jpeg,image/jpg,image/png,image/gif': [] },
    validator: validatorFunc,
  });

  const style = useMemo(
    () => ({
      width,
      height: `100%`,
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      console.log(acceptedFiles);
      setImages(acceptedFiles);
    }

    if (fileRejections.length > 0) {
      console.log(fileRejections);
      sendRejects(fileRejections);
    }
  }, [acceptedFiles, fileRejections]);

  return (
    <div {...getRootProps({ style })}>
      <Box textAlign={`center`}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
        <Box marginTop={2}>
          <TiUpload size={60} />
        </Box>
      </Box>
    </div>
  );
};

export default ImageDropZone;
