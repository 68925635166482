import {
  Autocomplete,
  Box,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Brand, StripeAddressForm } from '../../../types';
import {
  getBrands,
  getBrandsAssociatedLocations,
  getBrandsAssociatedWithSalesperson,
} from '../../../services/brand';
import ModalHeader from '../../Modal/ModalHeader';
import { PrimaryButton } from '../../Buttons';
import CircularLoading from '../../CircularLoading';
import { IS_DIY_ADZ } from '../../../utils';
import { AuthContext } from '../../../context';
import { fetchSingleStripeCustomer } from '../../../services/stripe/customer';
import { humanizeString } from '../../../utils/stringModifier';

interface LinkCardFormProps {
  brand: Brand;
  isAdmin: boolean;
  selectedBrand: Brand;
  setSelectedBrand: Dispatch<SetStateAction<Brand>>;
  onAttach: () => void;
  attachLoading: boolean;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
}

const LinkCardForm: React.FC<LinkCardFormProps> = ({
  brand,
  isAdmin,
  selectedBrand,
  setSelectedBrand,
  onAttach,
  attachLoading,
  error,
  setError,
}) => {
  const { state } = useContext(AuthContext);
  const roleBasedId = state.roleBasedId;
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const [brands, setBrands] = useState<Brand[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [customer, setCustomer] = useState<any>(null);
  const isLocation = brand?.__type === 'location';

  useEffect(() => {
    if (isAdmin) {
      if (isLocation) {
        getAllLocations(brand?.franchisor?._id);
      } else {
        getAllBrands();
      }
    } else {
      getSalespersonBrands();
    }
  }, [isAdmin]);

  useEffect(() => {
    if (selectedBrand) getSingleStripeCustomer();
  }, [selectedBrand]);

  const getAllBrands = async () => {
    setLoading(true);
    try {
      const response = await getBrands(
        1,
        1000,
        'active',
        undefined,
        undefined,
        IS_DIY_ADZ,
      );

      setBrands(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getAllLocations = async (brandId: string) => {
    setLoading(true);
    try {
      const response = await getBrandsAssociatedLocations(
        brandId,
        1,
        1000,
        'active',
      );

      setBrands(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getSalespersonBrands = async () => {
    const response = await getBrandsAssociatedWithSalesperson(
      roleBasedId,
      1,
      1000,
      'active',
      undefined,
      undefined,
      undefined,
      IS_DIY_ADZ,
    );

    let data: Brand[] = [];

    if (isLocation) {
      data = response.data?.filter(
        (item: Brand) =>
          item.franchisor?._id === brand?.franchisor?._id &&
          item?.__type === 'location',
      );
    } else {
      data = response.data?.filter((item: Brand) => item?.__type === 'brand');
    }

    setBrands(data);
  };

  const getSingleStripeCustomer = async () => {
    setLoading(true);
    try {
      const response = await fetchSingleStripeCustomer(
        selectedBrand?._id,
        'brand',
      );

      setCustomer({
        name: response.data.name,
        email: response.data.email,
        phone: response.data.phone,
        address: response.data.address,
      });
    } catch (error: any) {
      setCustomer(null);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const buildCustomerAddress = (address: StripeAddressForm) => {
    if (!address) return '';

    return `${address?.line1 ? address.line1 : ''} ${
      address?.line2 ? `${address?.line2},` : ''
    } ${address?.city ? `${address?.city},` : ''} ${
      address?.state ? `${address?.state},` : ''
    } ${address?.postal_code ? `${address?.postal_code},` : ''} ${
      address?.country
    }`;
  };

  return (
    <Grid container spacing={2} sx={{ width: xsOnly ? '100%' : '300px' }}>
      <CircularLoading loading={loading} />

      <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
        <ModalHeader title="Link Credit Card" />
      </Grid>

      <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
        <Typography variant="body1" sx={{ textAlign: 'center' }}>
          {`Select which credit card you want to link to your ${
            isLocation ? 'location' : 'brand'
          }`}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ justifyContent: 'center' }}>
        <Autocomplete
          id="brand"
          renderOption={(props, option) => {
            return (
              <li {...props} key={option._id}>
                {option.brand}
              </li>
            );
          }}
          options={brands
            ?.filter(
              (item: Brand) =>
                brand?._id !== item?._id &&
                item?.stripe?.customerId &&
                item?.allowSetupPayment,
            )
            .sort((a, b) => -b.brand?.localeCompare(a.brand))}
          value={selectedBrand}
          getOptionLabel={(option) => option.brand}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={`Select a ${humanizeString(brand?.__type)}`}
              fullWidth
              size="small"
              placeholder={`Select a ${humanizeString(brand?.__type)}`}
              required
              InputLabelProps={{ shrink: true }}
              error={!!error}
              helperText={error}
            />
          )}
          disableClearable
          onChange={async (e: any, value: any) => {
            setError(null);
            setSelectedBrand(value);
          }}
        />
      </Grid>

      {customer ? (
        <Grid item xs={12}>
          {Object.keys(customer || {}).map((key: string) => {
            if (!['name', 'email', 'phone', 'address'].includes(key))
              return <></>;

            const label =
              key === 'name' ? 'Brand Name: ' : `${humanizeString(key)}: `;
            const value =
              key === 'address'
                ? buildCustomerAddress(customer.address)
                : customer[key];

            return (
              <Box>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  {label}
                  <Typography variant="body2" component="span">
                    {value}
                  </Typography>
                </Typography>
              </Box>
            );
          })}
        </Grid>
      ) : null}

      <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
        <PrimaryButton
          title="Link Credit Card"
          type="button"
          handleOnClick={onAttach}
          loading={attachLoading}
        />
      </Grid>
    </Grid>
  );
};

export default LinkCardForm;
