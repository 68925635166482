import {
  Autocomplete,
  Card,
  CardActions,
  CardHeader,
  Checkbox,
  CircularProgress,
  Grid,
  Slider,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Brand, Country, TargetSearchKey } from '../../../types';
import {
  constantStringToHumanized,
  humanizeString,
} from '../../../utils/stringModifier';
import { darken, lighten, styled } from '@mui/system';
import { fetchTargetSearch } from '../../../services/ads';
import {
  buildAddress,
  getGeolocationFields,
} from '../../../utils/helpers/DiyHelpers';
import { DiySettings } from '../../../types/IDiy';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

interface GeolocationFormProps {
  type: string;
  geolocations: any[];
  onSelectLocation: (
    values: any[],
    field: string,
    detail: any,
    exclude: boolean,
  ) => void;
  onRemoveLocation: (
    value: any,
    type: string,
    field: string,
    exclude: boolean,
  ) => void;
  isAudience: boolean;
  disabled?: boolean;
  formValues: Brand;
  diySettings: DiySettings;
  setDiySettings: Dispatch<SetStateAction<DiySettings>>;
  exclude?: boolean;
  setIsDirty?: Dispatch<SetStateAction<boolean>>;
  isBrandForm?: boolean;
  diy?: boolean;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const GeolocationForm: React.FC<GeolocationFormProps> = ({
  type,
  geolocations,
  onSelectLocation,
  onRemoveLocation,
  isAudience,
  disabled = false,
  formValues,
  diySettings,
  setDiySettings,
  exclude = false,
  setIsDirty,
  isBrandForm = false,
  diy = false,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>(null);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!searchQuery) {
      setData([]);
    } else {
      if (!isAudience) {
        const delayDebounceFn = setTimeout(() => {
          getSearchedGeolocations();
        }, 500);

        return () => clearTimeout(delayDebounceFn);
      }
    }
  }, [searchQuery, isAudience]);

  useEffect(() => {
    if (diySettings && isAudience) {
      let temp: any[] = [];

      const diySettingsObj = () => {
        if (exclude) {
          return diySettings?.excludedGeolocations;
        }

        return diySettings?.geolocation;
      };

      switch (type) {
        case 'city':
          diySettingsObj()?.cities?.forEach((city: any) => {
            if (city.type === type) {
              temp = [...temp, city];
            }
          });
          break;
        case 'county':
          diySettingsObj()?.cities?.forEach((city: any) => {
            if (city.type === 'medium_geo_area') {
              temp = [...temp, city];
            }
          });
          break;
        case 'zip':
          diySettingsObj()?.zips?.forEach((zip: any) => {
            if (zip.type === type) {
              temp = [...temp, zip];
            }
          });
          break;
        case 'state':
          diySettingsObj()?.regions?.forEach((region: any) => {
            if (region.type === type) {
              temp = [...temp, region];
            }
          });
          break;
        default:
          diySettingsObj()?.countries?.forEach((country: any) => {
            if (country.type === type) {
              temp = [...temp, country];
            }
          });
          break;
      }

      setData(temp);
    }
  }, [isAudience, diySettings]);

  useEffect(() => {
    if (
      type === 'state' &&
      (diySettings?.locationTypes?.includes('state') ||
        diySettings?.excludedLocationTypes?.includes('state'))
    ) {
      getSearchedGeolocations();
    }
  }, [
    type,
    diySettings?.locationTypes,
    diySettings?.excludedLocationTypes,
    diySettings?.targetCountries,
  ]);

  const getSearchedGeolocations = async () => {
    setLoading(true);
    try {
      const countryCodes = diySettings?.targetCountries.map(
        (country: Country) => country.code,
      );
      const response = await fetchTargetSearch(
        type,
        countryCodes.join(','),
        searchQuery,
      );

      setData(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputValue = (value: string) => {
    if (value.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleSearchGeoLocation = (value: string) => {
    setSearchQuery(value);
  };

  const handleOnSetMaxRadius = (value: string) => {
    setDiySettings({ ...diySettings, maxCityRadius: parseInt(value) });

    if (isBrandForm && !isAudience) setIsDirty(true);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body1" fontWeight="bold">
          {type === 'geo_market' ? 'DMA' : humanizeString(type)}
        </Typography>
      </Grid>

      {/** Render option with checkbox */}
      {type === 'state' && formValues?.__type === 'brand' && !isAudience ? (
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id={`geolocation-${type}`}
            options={data}
            disableCloseOnSelect
            value={geolocations}
            isOptionEqualToValue={(option, value) => option.key === value.key}
            getOptionLabel={(option) => `${buildAddress(option)} `}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  sx={{ marginRight: 8 }}
                  checked={selected}
                />

                {buildOptionName(option)}
              </li>
            )}
            sx={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Search Location"
                placeholder="Search Location"
                size="small"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress
                          size={20}
                          sx={{ marginRight: '10px' }}
                        />
                      ) : null}
                    </>
                  ),
                }}
              />
            )}
            onChange={(e: any, values: any[], reason, detail) => {
              const field = 'regions';

              if (reason === 'removeOption') {
                onRemoveLocation(detail.option, type, field, exclude);
              } else {
                onSelectLocation(values, field, values.at(-1), exclude);
              }

              if (isBrandForm && !isAudience) setIsDirty(true);
            }}
            groupBy={(option) => option.country_name}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader sx={{ zIndex: 1 }}>{params.group}</GroupHeader>

                <GroupItems sx={{ zIndex: 1 }}>{params.children}</GroupItems>
              </li>
            )}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          {/** Render option without checkbox */}
          <Autocomplete
            open={open}
            onClose={() => setOpen(false)}
            id={`geolocation-${type}`}
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props} key={option.key}>
                  {buildOptionName(option)}
                </li>
              );
            }}
            options={data}
            groupBy={(option) =>
              type === 'country' ? null : option.country_name
            }
            value={geolocations}
            getOptionLabel={(option) => `${buildAddress(option)} `}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Search Location"
                placeholder="Search Location"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={searchQuery}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress
                          size={20}
                          sx={{ marginRight: '10px' }}
                        />
                      ) : null}
                    </>
                  ),
                }}
                onChange={(e: any) => {
                  handleInputValue(e.target.value);
                  handleSearchGeoLocation(e.target.value);
                }}
              />
            )}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>

                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            disableClearable
            sx={{ marginBottom: '10px' }}
            multiple
            onChange={(e: any, values: any[], reason, detail) => {
              const field = getGeolocationFields(type);

              if (reason === 'removeOption') {
                onRemoveLocation(detail.option, type, field, exclude);
              } else {
                onSelectLocation(values, field, values.at(-1), exclude);
              }

              if (isBrandForm && !isAudience) setIsDirty(true);
            }}
            disabled={disabled}
            //getOptionDisabled={(option) => disabledOptions(option, type)}
          />
        </Grid>
      )}

      {type === 'city' && !exclude && !diy ? (
        <Grid item xs={12} sm={4}>
          <Card>
            <CardHeader
              subheader={`${humanizeString(type)} Max Radius`}
              subheaderTypographyProps={{
                color: '#096F4D',
                fontWeight: 'bold',
              }}
            />
            <CardActions
              sx={{
                paddingTop: 0,
                flexDirection: 'column',
                paddingX: '20px',
              }}
            >
              <Typography variant="caption">{`${diySettings?.maxCityRadius} miles`}</Typography>

              <Slider
                defaultValue={50}
                min={10}
                max={50}
                valueLabelDisplay="auto"
                value={diySettings?.maxCityRadius}
                onChange={(e: any) => {
                  handleOnSetMaxRadius(e.target.value);

                  if (isBrandForm && !isAudience) setIsDirty(true);
                }}
              />
            </CardActions>
          </Card>
        </Grid>
      ) : null}
    </Grid>
  );
};

export const buildOptionName = (option: TargetSearchKey) => {
  if (['city', 'subcity', 'neighborhood'].includes(option.type)) {
    return `${option.name}, ${option.region}, ${
      option.country_code
    } (${constantStringToHumanized(option.type)})`;
  } else if (option.type === 'medium_geo_area') {
    return `${option.name}, ${option.region}, ${option.country_code} (County)`;
  } else if (option.type === 'state') {
    return `${option.name}, ${option.country_code} (${constantStringToHumanized(
      option.type,
    )})`;
  } else if (option.type === 'zip') {
    return `${option.name} (${option.primary_city}, ${option.region}, ${
      option.country_code
    }) (${constantStringToHumanized(option.type)})`;
  } else if (option.type === 'geo_market') {
    return `${option.name}, ${option.country_code} (${option.key})`;
  }

  return `${option.name}, ${option.country_code} (${constantStringToHumanized(
    option.type,
  )})`;
};

export default GeolocationForm;
