/**
 * DayJS Instance
 * @description Reusable instance of DayJS including all extended plugins
 * @author Angelo David <angelod@codev.com>
 * @reference https://day.js.org/en/
 */
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isYesterday from 'dayjs/plugin/isYesterday';

// https://day.js.org/docs/en/timezone/set-default-timezone#docsNav
dayjs.extend(timezone);
dayjs.extend(utc);
// https://day.js.org/docs/en/plugin/localized-format#docsNav
dayjs.extend(localizedFormat);
dayjs.extend(isYesterday);

export const dayJsInstance = dayjs;
