import { Typography } from '@mui/material';
import React from 'react';
import { DEFAULT_COLOR_THEME } from '../utils/Styling';

interface PageHeaderProps {
  title: string;
  isH6?: boolean;
  marginBottom?: string;
  textAlign?: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  isH6 = false,
  marginBottom = '24px',
  textAlign = 'center',
}) => {
  const renderHeader = () => {
    if (isH6) {
      return (
        <Typography
          variant="h6"
          sx={{
            color: DEFAULT_COLOR_THEME,
            textAlign,
            fontWeight: 'bold',
            marginBottom,
          }}
        >
          {title}
        </Typography>
      );
    }

    return (
      <Typography
        variant="h5"
        sx={{
          color: DEFAULT_COLOR_THEME,
          textAlign,
          fontWeight: 'bold',
          marginBottom,
        }}
      >
        {title}
      </Typography>
    );
  };

  return <div>{renderHeader()}</div>;
};

export default PageHeader;
