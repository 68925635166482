import React from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Paper, Table, TableBody, TableHead } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import { GoogleAdsMetricsData } from '../../../types';
import { buildGoogleMetricsData } from '../../../utils/helpers/BrandAnalyticsHelper';
import { toCurrency } from '../../../utils/numberFormatter';
import { DEFAULT_COLOR_THEME, StyledPaper } from '../../../utils/Styling';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: DEFAULT_COLOR_THEME,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.common.white,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface GoogleCampaignOverviewProps {
  rows: any[];
  data: GoogleAdsMetricsData[];
  isSummary?: boolean;
  isCampaign?: boolean;
  isAdGroup?: boolean;
  isAds?: boolean;
}

const GoogleCampaignOverview: React.FC<GoogleCampaignOverviewProps> = ({
  rows,
  data,
  isSummary = false,
  isCampaign = false,
  isAdGroup = false,
  isAds = false,
}) => {
  const columns = buildGoogleMetricsData(
    data,
    isSummary,
    isCampaign,
    isAdGroup,
    isAds,
  );

  return (
    <div>
      <Paper sx={StyledPaper()}>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {rows.map((row: any, index: number) => (
                  <StyledTableCell key={index} align="center">
                    {row}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {columns.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    No Records Found
                  </TableCell>
                </TableRow>
              ) : (
                columns.map((data: any, index: number) => {
                  return (
                    <StyledTableRow key={index}>
                      {!isSummary ? (
                        <StyledTableCell align="center">
                          {data.name}
                        </StyledTableCell>
                      ) : null}

                      <StyledTableCell align="center">
                        {data.clicks}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {toCurrency('USD', data.cpc)}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {data.impressions}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {toCurrency('USD', data.cpm)}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {data.conversions}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {toCurrency('USD', data.cpl)}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {toCurrency('USD', data.spend)}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default GoogleCampaignOverview;
