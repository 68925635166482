import React, { Fragment, useContext, useEffect, useState } from 'react';
import styles from '../../assets/styles/pages/Pages.module.scss';
import PageHeader from '../../components/PageHeader';
import { fetchBrandCampaigns } from '../../services/ads';
import { AdLead, Brand, Campaign, CampaignAd } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { adsProvider } from '../../utils/constants/facebookAds';
import {
  Autocomplete,
  Box,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  getLeadsByAdId,
  getLeadsByBrand,
  sendFacebookLeads,
  syncFbLeads,
} from '../../services/lead';
import LeadListTable from '../../components/Tables/LeadList/LeadListTable';
import { DrawerMenu } from '../../components/Menu';
import { PrimaryButton } from '../../components/Buttons';
import { MdSync } from 'react-icons/md';
import momentTz, { Moment } from 'moment-timezone';
import { AuthContext } from '../../context';
import { ADMIN, SUPER_ADMIN } from '../../utils';
import { orderArrayOfObject } from '../../utils/arrayFormatter';
import { setEndRange, setStartRange, toggleAlert } from '../../redux/actions';
import { errorMessageParser } from '../../utils/helpers/ToastHelper';
import DateRangePicker from '../../components/DateAndTimePickers/DateRangePicker';
import PopupModal from '../../components/Modal';
import SendLeadSummaryTable from '../../components/Tables/LeadList/SendLeadSummaryTable';
import { HtmlTooltip } from '../../components/Tooltip';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import {
  getEmail,
  getFirstName,
  getLastName,
  getLeadDetails,
  getPhoneNumber,
  isQualifiedLead,
} from '../../utils/helpers/LeadHelpers';
import LeadDetails from '../../components/Details/LeadDetails';
import DashboardItem from '../../components/Cards/Billing/DashboardItem';
import { CSVLink } from 'react-csv';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { dayJsInstance } from '../../utils/dayjsHelper';
import { constantStringToHumanized } from '../../utils/stringModifier';

const Leads: React.FC = () => {
  const dispatch = useDispatch();
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const timezone: string = useSelector(
    (state: any) => state?.brandTimezone?.timezone,
  );
  const startRange = useSelector((state: any) => state.startRange.date);
  const endRange = useSelector((state: any) => state.endRange.date);
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const { state } = useContext(AuthContext);
  const role = state.role;
  const isSuperAdmin = role === SUPER_ADMIN;
  const isAdmin = role === ADMIN;
  const withAgencyAccess = state?.withAgencyAccess;

  const [loading, setLoading] = useState<boolean>(false);
  const [syncLoading, setSyncLoading] = useState<boolean>(false);
  const [ads, setAds] = useState<CampaignAd[]>([]);
  const [selectedAd, setSelectedAd] = useState<CampaignAd>(null);
  const [leads, setLeads] = useState<AdLead[]>([]);
  const [openLeadDetails, setOpenLeadDetails] = useState<boolean>(false);
  const [selectedLead, setSelectedLead] = useState<AdLead>(null);
  const [analyticItems, setAnalyticItems] = useState<any[]>([
    {
      value: 0,
      label: 'Invesment QL Percentage',
    },
    {
      value: 0,
      label: 'Background QL Percentage',
    },
    {
      value: 0,
      label: 'Experience QL Percentage',
    },
    {
      value: 0,
      label: 'Qualified Percentage',
    },
  ]);
  const [selectedLeads, setSelectedLeads] = useState<AdLead[]>([]);
  const [sendLeadLoading, setSendLeadLoading] = useState<boolean>(false);
  const [openSendSummary, setOpenSendSummary] = useState<boolean>(false);
  const [summary, setSummary] = useState<any[]>([]);
  const [selectedLeadIds, setSelectedLeadIds] = useState<number[]>([]);

  useEffect(() => {
    if (location || brand) getCampaigns();
  }, [location, brand]);

  useEffect(() => {
    if ((location || brand) && startRange && endRange) {
      getLeads();
    }
  }, [selectedAd, location, brand, startRange, endRange]);

  useEffect(() => {
    buildLeadAnalyticItems();
  }, [leads]);

  const getCampaigns = async () => {
    try {
      const response = await fetchBrandCampaigns(
        (location || brand)?._id,
        adsProvider.FACEBOOK,
        true,
      );

      let tempAds: CampaignAd[] = [];

      response.data.forEach((campaign: Campaign) => {
        campaign?.ads.forEach((ad: CampaignAd) => {
          if (ad.archived) return;

          tempAds = [...tempAds, ad];
        });
      });

      setAds(tempAds);
    } catch (error: any) {
      console.log(error);
    }
  };

  const getLeads = async () => {
    try {
      setLoading(true);

      if (selectedAd) {
        const response = await getLeadsByAdId(
          (location || brand)?._id,
          selectedAd?.id,
          startRange,
          endRange,
        );

        setLeads(response.data);
      } else {
        const response = await getLeadsByBrand(
          (location || brand)?._id,
          startRange,
          endRange,
        );

        setLeads(response.data);
      }
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const syncLeads = async () => {
    try {
      setSyncLoading(true);

      const response = await syncFbLeads(
        (location || brand)?._id,
        selectedAd?.id,
      );

      if (response?.data?.leads?.length > 0) {
        let temp: AdLead[] = [];
        const leadIds = leads.map((lead: AdLead) => lead.id);

        (response?.data?.leads || []).forEach((lead: AdLead) => {
          if (!leadIds.includes(lead.id)) {
            temp = [...temp, lead];
          } else {
            let existingLead = leads.find(
              (existingLead: AdLead) => existingLead.id === lead.id,
            );

            lead = {
              ...existingLead,
              investmentPresent: lead.investmentPresent,
              backgroundPresent: lead.backgroundPresent,
              experienceQualifiedLead: lead.investmentPresent,
              investmentQualifiedLead: lead.investmentQualifiedLead,
              backgroundQualifiedLead: lead.backgroundQualifiedLead,
              experiencePresent: lead.experienceQualifiedLead,
            };

            temp = [...temp, lead];
          }
        });

        const sortedLeads = orderArrayOfObject(temp, 'leadCreatedAt', 'desc');

        setLeads(sortedLeads);

        dispatch(
          toggleAlert({
            toggle: true,
            message: `${response?.data?.leads?.length} new leads successfully synced`,
          }),
        );
      } else {
        dispatch(
          toggleAlert({
            toggle: true,
            message: `
              Currently, no leads were synced. If you expected new leads,
               please ensure you have the necessary access permissions for the ads and Facebook page
            `,
            type: 'error',
          }),
        );
      }

      if (selectedAd) {
        setSelectedAd({
          ...selectedAd,
          lastLeadSyncedAt: response?.data?.lastLeadSyncedAt,
        });
      } else {
        let temp: any = [];
        ads.forEach((ad) => {
          ad.lastLeadSyncedAt = response?.data?.lastLeadSyncedAt;

          temp = [...temp, ad];
        });

        setAds(temp);
      }
    } catch (error: any) {
      dispatch(
        toggleAlert({
          toggle: true,
          message: errorMessageParser(error),
          type: 'error',
        }),
      );
    } finally {
      setSyncLoading(false);
    }
  };

  const buildLeadAnalyticItems = () => {
    let fqlPercent: number = 0;
    let bqlPercent: number = 0;
    let eqlPercent: number = 0;
    let qlPercent: number = 0;
    if (leads.length > 0) {
      const leadCount = leads.length;
      const fqlCount = leads.filter(
        (lead: AdLead) => lead.investmentQualifiedLead,
      ).length;
      const bqlCount = leads.filter(
        (lead: AdLead) => lead.backgroundQualifiedLead,
      ).length;
      const eqlCount = leads.filter(
        (lead: AdLead) => lead.experienceQualifiedLead,
      ).length;
      const qlCount = leads.filter((lead: AdLead) => {
        return isQualifiedLead({
          investmentQualified:
            lead.investmentPresent && lead.investmentQualified,
          backgroundQualified:
            lead.backgroundPresent && lead.backgroundQualified,
          experienceQualified:
            lead.experiencePresent && lead.experienceQualified,
          investmentQualifiedLead: lead.investmentQualifiedLead,
          backgroundQualifiedLead: lead.backgroundQualifiedLead,
          experienceQualifiedLead: lead.experienceQualifiedLead,
        });
      }).length;

      fqlPercent = (fqlCount / leadCount) * 100;
      bqlPercent = (bqlCount / leadCount) * 100;
      eqlPercent = (eqlCount / leadCount) * 100;
      qlPercent = (qlCount / leadCount) * 100;
    }

    setAnalyticItems([
      {
        value: fqlPercent,
        label: 'Invesment QL Percentage',
      },
      {
        value: bqlPercent,
        label: 'Background QL Percentage',
      },
      {
        value: eqlPercent,
        label: 'Experience QL Percentage',
      },
      {
        value: qlPercent,
        label: 'Qualified Percentage',
      },
    ]);
  };

  const handleOpenLeadDetails = (lead: AdLead) => {
    setSelectedLead(lead);
    setOpenLeadDetails(true);
  };

  const handleCloseLeadDetails = () => {
    setSelectedLead(null);
    setOpenLeadDetails(false);
  };

  const disableSync = () => {
    if (!selectedAd?.lastLeadSyncedAt) return false;

    const lastSynced = momentTz.unix(selectedAd?.lastLeadSyncedAt).tz(timezone);
    const syncRange = lastSynced.clone().add(1, 'day');
    const today = momentTz().tz(timezone);

    return today <= syncRange;
  };

  const handleChangeDateRange = (dates: Moment[] | []) => {
    dispatch(setStartRange(dates[0]));
    dispatch(setEndRange(dates[1]));
  };

  const handleSelectLead = (value: number[]) => {
    const selectedLeads = leads?.filter((lead: AdLead) =>
      value.includes(lead.leadId),
    );

    setSelectedLeadIds(value);
    setSelectedLeads(selectedLeads);
  };

  const handleSendLeads = async () => {
    try {
      setSendLeadLoading(true);
      const response = await sendFacebookLeads(
        (location || brand)?._id,
        selectedLeads,
      );

      setSummary(response.data);
      setSelectedLeads([]);
      setSelectedLeadIds([]);
      handleOpenSendLeadSummary();
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setSendLeadLoading(false);
    }
  };

  const handleOpenSendLeadSummary = () => {
    setOpenSendSummary((prev) => !prev);
  };

  const buildExportData = () => {
    return orderArrayOfObject(leads, 'leadCreatedAt', 'desc')?.map(
      (lead: AdLead) => {
        const {
          id,
          brandName,
          brandIndustry,
          adId,
          adName,
          adsetId,
          adsetName,
          campaignId,
          campaignName,
          leadCreatedAt,
          fieldData,
          investmentPresent,
          investmentQualified,
          investmentQualifiedLead,
          backgroundPresent,
          backgroundQualified,
          backgroundQualifiedLead,
          experiencePresent,
          experienceQualified,
          experienceQualifiedLead,
        } = lead;

        const isQualified = isQualifiedLead({
          investmentQualified: investmentPresent && investmentQualified,
          backgroundQualified: backgroundPresent && backgroundQualified,
          experienceQualified: experiencePresent && experienceQualified,
          investmentQualifiedLead,
          backgroundQualifiedLead,
          experienceQualifiedLead,
        });

        const fields = fieldData?.map(
          (data: any) =>
            `${constantStringToHumanized(data.name)}: ${data.values[0]}`,
        );

        return {
          Date: `${dayJsInstance
            .unix(leadCreatedAt)
            .format('MM/DD/YYYY hh:mma')}`,
          ID: id,
          FirstName: getFirstName(lead),
          LastName: getLastName(lead),
          Email: getEmail(lead),
          PhoneNumber: getPhoneNumber(lead),
          IQL: investmentPresent
            ? investmentQualifiedLead
              ? 'Yes'
              : 'No'
            : '',
          BQL: backgroundPresent
            ? backgroundQualifiedLead
              ? 'Yes'
              : 'No'
            : '',
          EQL: experiencePresent
            ? experienceQualifiedLead
              ? 'Yes'
              : 'No'
            : '',
          Qualified: isQualified ? 'Yes' : 'No',
          QLeadsDetails: getLeadDetails(lead),
          Fields: fields.join('\n'),
          Brand: brandName,
          Industrty: brandIndustry,
          Campaign: `${campaignName}\n${campaignId}`,
          AdSet: `${adsetName}\n${adsetId}`,
          Ad: `${adName}\n${adId}`,
        };
      },
    );
  };

  const getExportFileName = () => {
    return `leads_${startRange.format('MM_DD_YYYY')}-${endRange.format(
      'MM_DD_YYYY',
    )}_${dayJsInstance().unix()}.csv`;
  };

  return (
    <div className={styles.page}>
      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader title="Leadz" />

          <Grid container spacing={1} mb={2}>
            <Grid
              item
              xs={12}
              sx={{
                justifyContent: 'center',
                display: 'flex',
                width: '100%',
              }}
            >
              <DateRangePicker
                toDate={endRange}
                fromDate={startRange}
                onChange={handleChangeDateRange}
                maxDate={momentTz()}
                separator="-"
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            justifyContent="center"
            columns={12}
            mb={2}
          >
            {analyticItems.map((item: any, index: number) => {
              return (
                <Grid item xs={10} sm={'auto'} key={`insights-${index + 1}`}>
                  <DashboardItem
                    endingValue={item.value}
                    label={item.label}
                    decimals={1}
                    isPercentage
                    isMobile={xsOnly}
                  />
                </Grid>
              );
            })}
          </Grid>

          <div className={styles.controls}>
            {ads?.length > 0 && (isSuperAdmin || isAdmin) ? (
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <PrimaryButton
                  type="button"
                  title="Sync Leads"
                  width="180px"
                  startIcon={<MdSync />}
                  handleOnClick={() => syncLeads()}
                  loading={syncLoading}
                  endIcon={
                    <HtmlTooltip
                      disableInteractive
                      title={
                        <Fragment>
                          <Box component="div" sx={{ fontWeight: 'bold' }}>
                            About Expired Leads
                          </Box>

                          <Box component="div" sx={{ marginBottom: '10px' }}>
                            Leads data is available for synching for 90 days
                            from the time a form is submitted. You cannot sync
                            leads after 90 days.
                          </Box>
                        </Fragment>
                      }
                    >
                      <InfoTwoToneIcon sx={{ fontSize: '18px' }} />
                    </HtmlTooltip>
                  }
                  // TODO: Re-enable once publicly available
                  //disabled={disableSync()}
                />

                {selectedAd?.lastLeadSyncedAt ? (
                  <Typography variant="caption">
                    {`Last synced at ${momentTz
                      .unix(selectedAd?.lastLeadSyncedAt)
                      .tz(timezone)
                      .format('MM/DD/YYYY hh:mm A')}`}
                  </Typography>
                ) : null}
              </Box>
            ) : null}

            <div className={styles.buttons}>
              <CSVLink
                data={buildExportData()}
                filename={getExportFileName()}
                target="_blank"
                style={{
                  textDecoration: 'none',
                  marginLeft: xsOnly ? 0 : '5px',
                }}
                onClick={(e: any) => {
                  if (buildExportData().length === 0) {
                    dispatch(
                      toggleAlert({
                        toggle: true,
                        message: 'No data to export',
                        type: 'error',
                      }),
                    );

                    return false;
                  }
                }}
              >
                <PrimaryButton
                  type="button"
                  title="Export"
                  startIcon={<FileDownloadOutlinedIcon />}
                  height="35px"
                  marginRight10
                />
              </CSVLink>

              {selectedLeads?.length > 0 &&
              (isSuperAdmin || isAdmin || withAgencyAccess) ? (
                <PrimaryButton
                  title="Send Leads"
                  type="button"
                  marginRight10
                  height="35px"
                  loading={sendLeadLoading}
                  handleOnClick={handleSendLeads}
                />
              ) : null}

              <Autocomplete
                id="ads"
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {`${option.alias} (${option.name})`}
                    </li>
                  );
                }}
                options={ads.sort((a, b) => -b.alias?.localeCompare(a.alias))}
                value={selectedAd}
                getOptionLabel={(option) => option.alias}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filter by ad"
                    fullWidth
                    size="small"
                    placeholder="All"
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '300px' }}
                  />
                )}
                onChange={async (e: any, value: any, reason: string) => {
                  switch (reason) {
                    case 'clear':
                      setSelectedAd(null);
                      break;
                    default:
                      setSelectedAd(value);
                      break;
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div>
          <LeadListTable
            leads={leads}
            loading={loading}
            onOpenLeadDetails={handleOpenLeadDetails}
            ads={ads}
            brand={location || brand}
            onSelectLead={handleSelectLead}
            selectedLeadIds={selectedLeadIds}
          />
        </div>
      </div>

      <DrawerMenu
        anchor="right"
        open={openLeadDetails}
        onClose={handleCloseLeadDetails}
      >
        <LeadDetails lead={selectedLead} onClose={handleCloseLeadDetails} />
      </DrawerMenu>

      <PopupModal
        open={openSendSummary}
        handleClose={handleOpenSendLeadSummary}
      >
        <SendLeadSummaryTable summary={summary} />
      </PopupModal>
    </div>
  );
};

export default Leads;
