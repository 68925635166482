import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  List,
  ListItem,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  GoogleAssetsContainer,
  googleResourceLimits,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import { MdDeleteOutline } from 'react-icons/md';
import { FloatingButton } from '../../../Buttons';
import axios from 'axios';

interface GoogleTemplatesLongHeadlineProps {
  submitForm: any;
  templateForm: any;
}

const GoogleTemplatesLongHeadline: React.FC<
  GoogleTemplatesLongHeadlineProps
> = ({ submitForm, templateForm }) => {
  const [businessName, setBusinessName] = useState('');
  const [longHeadline, setLongHeadline] = useState('');
  useEffect(() => {
    if (templateForm) {
      setLongHeadline(templateForm);
      submitForm(templateForm);
    }
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          marginBottom: 1,
          color: DEFAULT_COLOR_THEME,
          fontWeight: 800,
          display: 'block',
          position: 'sticky',
          top: 0,
          zIndex: 1,
          backgroundColor: 'white',
        }}
      >
        Set Long Headline
      </Typography>
      <Box
        sx={{
          maxWidth: 500,
        }}
      >
        <TextField
          label="Long Headline"
          variant="outlined"
          value={longHeadline}
          multiline
          rows={3}
          sx={{
            width: '100%',
          }}
          onInput={(e: any) => {
            setLongHeadline(e.target.value);
            submitForm(e.target.value);
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            fontWeight: 400,
            fontStyle: 'italic',
            fontSize: '0.7rem',
            paddingX: 1,
            color:
              longHeadline.length > googleResourceLimits.long_headline
                ? 'red'
                : 'black',
          }}
        >
          {longHeadline?.length || 0} / {googleResourceLimits.long_headline}
        </Box>
      </Box>
    </Box>
  );
};

export default GoogleTemplatesLongHeadline;
