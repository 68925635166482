import {
  Box,
  Button,
  Input,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MdDeleteOutline, MdTextSnippet } from 'react-icons/md';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { Brand } from '../../../../types';
import dayjs from 'dayjs';
import {
  biddingStrategies,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import GoogleSelect from '../../../Select/GoogleSelect';
import LinearProgressBarWithLabel from '../../../ProgressBar/LinearProgressBarWithLabel';
import { CheckBox, CheckBoxOutlineBlankOutlined } from '@mui/icons-material';
import GoogleSelectMultiple from '../../../Select/GoogleSelectMultiple';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions';
import { constantStringToHumanized } from '../../../../utils/stringModifier';
import { NumericCustomField } from '../../../TextField';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import GoogleTemplatesPreview from '../../Templatez/Google/GGLTemplatesPreview';
import GoogleTemplateSummary from '../../Templatez/Google/GGLTemplateSummary';
import { fetchGoogleTemplates } from '../../../../services/googleAds';

interface GGAdzMultiUnitCreationProps {
  brand: Brand;
  onCancel: () => void;
}

interface MultiCreationRowProps {
  row: {
    location: string;
    startdate: string;
    enddate: string;
    currentbudget: number;
    recurringspend: number;
    biddingstrategy: string;
  };
  i: number;
  onRemove?: (id: number) => void;
  updateRow?: (id: number, rowobject: any) => void;
  perpetual: boolean;
}

const templatelist = [
  {
    name: 'Search Ad Template',
    category: 'Responsive_Search_Ad',
  },
  {
    name: 'Display Ad Template',
    category: 'Responsive_Display_Ad',
  },
  {
    name: 'Performance Max Ad Template',
    category: 'Performance_Max_Ad',
  },
  {
    name: 'Video Ad Template',
    category: 'Responsive_Video_Ad',
  },
  {
    name: 'Shopping Ad Template',
    category: 'Responsive_Shopping_Ad',
  },
  {
    name: 'App Ad Template',
    category: 'Responsive_App_Ad',
  },
];

const locationlist = [
  {
    name: 'New York - Manhattan',
  },
  {
    name: 'Los Angeles - Downtown',
  },
  {
    name: 'Chicago - North Side',
  },
  {
    name: 'Houston - Midtown',
  },
  {
    name: 'Phoenix - Central City',
  },
  {
    name: 'San Francisco - Financial District',
  },
  {
    name: 'Miami - South Beach',
  },
  {
    name: 'Seattle - Capitol Hill',
  },
  {
    name: 'Boston - Back Bay',
  },
  {
    name: 'Denver - LoDo',
  },
];

const flow = ['template', 'location', 'dates', 'muticreatetable'];

const MultiCreationRow: React.FC<MultiCreationRowProps> = ({
  row,
  i,
  onRemove = () => {},
  updateRow = (i, rowobject) => {},
  perpetual,
}) => {
  console.log(`🚀 row`);
  console.log(row);

  return (
    <>
      <TableRow
        key={`row-${i}`}
        sx={{
          '&hover': {
            backgroundColor: `#f7f7f7`,
          },
        }}
      >
        <TableCell>{i + 1}</TableCell>
        <TableCell>
          <Select
            labelId="location-select-label"
            variant="standard"
            id="location-select"
            value={row.location}
            sx={{
              display: `none`,
              width: '100%',
            }}
            onChange={(e) =>
              updateRow(i, {
                ...row,
                location: e.target.value,
              })
            }
          >
            <MenuItem value={'n/a'} disabled>
              Select a Location
            </MenuItem>
            {locationlist.map((location) => (
              <MenuItem value={location.name}>{location.name}</MenuItem>
            ))}
          </Select>
          {row.location}
        </TableCell>
        <TableCell>
          <Input
            type="date"
            value={row.startdate}
            onChange={(e) => {
              updateRow(i, {
                ...row,
                startdate: e.target.value,
              });
            }}
          />
        </TableCell>
        <TableCell sx={{ display: perpetual ? 'none' : 'block' }}>
          <Input
            type="date"
            value={row.enddate}
            onChange={(e) =>
              updateRow(i, {
                ...row,
                enddate: e.target.value,
              })
            }
          />
        </TableCell>
        <TableCell>
          <NumericCustomField
            value={row.currentbudget}
            onChangeValue={(e: any) =>
              updateRow(i, {
                ...row,
                currentbudget: Number(e.value),
              })
            }
            id={`currentbudget-${i}`}
            name={`currentbudget-${i}`}
            label={``}
            fullWidth
          />
        </TableCell>
        <TableCell>
          <NumericCustomField
            value={row.recurringspend}
            onChangeValue={(e: any) =>
              updateRow(i, {
                ...row,
                recurringspend: Number(e.value),
              })
            }
            id={`recurringspend-${i}`}
            name={`recurringspend-${i}`}
            label={``}
            fullWidth
          />
        </TableCell>
        <TableCell>
          <Button
            onClick={() => {
              onRemove(i);
            }}
            sx={{
              color: `#ff0000`,
              '&:hover': {
                color: `#ff5555`,
              },
            }}
          >
            <MdDeleteOutline size={20} />
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

const MultiCreationTable: React.FC<{
  perpetual: boolean;
  updateRows: (e: any) => void;
  selectedTemplate: any;
  selectedLocations: any;
  setSelectedLocations: (e: any) => void;
  selectedStartDate: any;
  selectedEndDate: any;
}> = (
  {
    perpetual,
    updateRows,
    selectedTemplate,
    selectedLocations,
    setSelectedLocations,
    selectedStartDate,
    selectedEndDate,
  } = {
    perpetual: false,
    updateRows: (e: any) => {},
    selectedTemplate: null,
    selectedLocations: null,
    setSelectedLocations: (e: any) => {},
    selectedStartDate: null,
    selectedEndDate: null,
  },
) => {
  const [rows, setRows] = useState(
    selectedLocations.map((location: any) => {
      return {
        template: selectedTemplate,
        location: location,
        startdate: selectedStartDate,
        enddate: selectedEndDate,
        currentbudget: 0,
        recurringspend: 0,
        biddingstrategy: 'n/a',
      };
    }),
  );

  const handleRemoveRow = (i: number) => {
    setSelectedLocations(
      rows
        .filter((row: any, index: number) => {
          return index !== i;
        })
        .map((row: any) => row.location),
    );
    setRows(
      rows.filter((row: any, index: number) => {
        return index !== i;
      }),
    );
  };

  const handleUpdateRow = (i: number, rowobject: any) => {
    setRows(
      rows.map((row: any, index: number) => {
        if (index === i) {
          return rowobject;
        }
        return row;
      }),
    );
  };

  useEffect(() => {
    console.log(`🚀 rows`);
    console.log(rows);
    updateRows(rows);
  }, []);

  useEffect(() => {
    updateRows(rows);
  }, [rows]);

  return (
    <Box sx={{ paddingTop: 4, paddingX: 4 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2,
          marginBottom: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{
            paddingY: 0,
            paddingX: 2,
            height: 30,
            display: 'flex',
            gap: 1,
          }}
        >
          <MdTextSnippet /> Import CSV
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: `#096f4d`,
              }}
            >
              <TableCell></TableCell>
              <TableCell
                sx={{
                  color: `#ffffff`,
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                Locations
              </TableCell>
              <TableCell
                sx={{
                  color: `#ffffff`,
                  fontSize: 16,
                  fontWeight: 600,
                }}
                align="center"
              >
                Start Date
              </TableCell>
              <TableCell
                sx={{
                  color: `#ffffff`,
                  fontSize: 16,
                  fontWeight: 600,
                  display: perpetual ? 'none' : 'block',
                }}
                align="center"
              >
                End Date
              </TableCell>
              <TableCell
                sx={{
                  color: `#ffffff`,
                  fontSize: 16,
                  fontWeight: 600,
                }}
                align="center"
              >
                Current Month Budget
              </TableCell>
              <TableCell
                sx={{
                  color: `#ffffff`,
                  fontSize: 16,
                  fontWeight: 600,
                }}
                align="center"
              >
                Future Monthly Budget
              </TableCell>

              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any, i: number) => (
              <MultiCreationRow
                row={row}
                i={i}
                onRemove={() => {
                  handleRemoveRow(i);
                }}
                updateRow={handleUpdateRow}
                perpetual={perpetual}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const SelectTemplate: React.FC<{
  selectedTemplate: any;
  setSelectedTemplate: (e: any) => void;
}> = ({ selectedTemplate, setSelectedTemplate }) => {
  return (
    <>
      <Box
        sx={{
          display: 'block',
          gap: 4,
          marginTop: 4,
          maxWidth: 500,
        }}
      >
        <GoogleSelect
          title="Select a Template"
          onSelect={(template: any) => {
            console.log(`🚀 template`);
            console.log(template);
            setSelectedTemplate(template);
          }}
          selected={selectedTemplate}
          options={templatelist.map((template) => {
            return {
              row1: 'name',
              row2: 'category',
              value: 'name',
              data: template,
            };
          })}
        />
      </Box>
    </>
  );
};

const SelectLocation: React.FC<{
  selectedLocations: any;
  setSelectedLocations: (e: any) => void;
}> = ({ selectedLocations, setSelectedLocations }) => {
  return (
    <>
      <Box
        sx={{
          display: 'block',
          gap: 4,
          marginTop: 4,
          maxWidth: 500,
        }}
      >
        <GoogleSelectMultiple
          title="Select a Location"
          onSelect={(location: any) => {
            console.log(`🚀 location`);
            console.log(location);
            setSelectedLocations(location);
          }}
          selectAll={true}
          selected={selectedLocations || []}
          options={locationlist.map((location) => {
            return {
              row1: 'name',
              row2: 'category',
              value: 'name',
              data: location,
            };
          })}
        />
      </Box>
    </>
  );
};

const SelectDates: React.FC<{
  selectedStartDate: any;
  setSelectedStartDate: (e: any) => void;
  selectedEndDate: any;
  setSelectedEndDate: (e: any) => void;
  perpetual: boolean;
  setPerpetual: (e: any) => void;
  biddingStrategy: string;
  setBiddingStrategy: (e: any) => void;
}> = ({
  selectedStartDate,
  setSelectedStartDate,
  selectedEndDate,
  setSelectedEndDate,
  perpetual,
  setPerpetual,
  biddingStrategy,
  setBiddingStrategy,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'block',
          gap: 4,
          marginTop: 4,
          maxWidth: 500,
          height: 'calc(90vh - 230px)',
        }}
      >
        <Box>
          <Typography
            variant="h6"
            sx={{
              color: '#096f4d',
              fontWeight: 800,
              paddingY: 2,
            }}
          >
            Select a Bidding Strategy
          </Typography>
          <Select
            labelId="bidding-strategy-select-label"
            id="bidding-strategy-select"
            value={biddingStrategy}
            onChange={(e) => {
              setBiddingStrategy(e.target.value);
            }}
            sx={{
              width: '100%',
            }}
          >
            <MenuItem value={'n/a'} disabled>
              Select a Bidding Strategy
            </MenuItem>
            {biddingStrategies.map((strategy) => (
              <MenuItem value={strategy.biddingType}>
                {constantStringToHumanized(strategy.biddingType)}
              </MenuItem>
            ))}
          </Select>

          {biddingStrategies.map((strategy) => {
            if (strategy.biddingType === biddingStrategy) {
              return (
                <Box sx={{ paddingX: 1 }}>
                  <Typography variant="body2">
                    {strategy.description}
                  </Typography>
                </Box>
              );
            }
          })}
        </Box>
        <Box>
          <Typography
            variant="h6"
            sx={{
              color: '#096f4d',
              fontWeight: 800,
              paddingY: 2,
            }}
          >
            Set Start Date
          </Typography>
          <Input
            type="date"
            value={selectedStartDate}
            onChange={(e) => {
              console.log(`🚀 dayjs(e.target.value).isBefore(dayjs())`);
              console.log(``);
              if (dayjs(e.target.value).isBefore(dayjs(), 'day')) {
                return dispatch(
                  toggleAlert({
                    toggle: true,
                    message: 'Start date cannot be in the past',
                    type: 'error',
                  }),
                );
              }

              setSelectedStartDate(e.target.value);
            }}
            fullWidth
            sx={{
              input: {
                width: '100%',
              },
            }}
          />
        </Box>

        <Box sx={{ marginTop: 4 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2,
              alignItems: 'center',
              paddingY: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: '#096f4d',
                fontWeight: 800,
              }}
            >
              Set End Date
            </Typography>

            <Button
              sx={{
                display: 'flex',
                gap: 1,
                color: '#096f4d',
                fontWeight: 800,
              }}
              onClick={() => {
                if (perpetual) {
                  if (selectedEndDate === null) {
                    setSelectedEndDate(dayjs().format('YYYY-MM-DD'));
                  }
                }
                setPerpetual(!perpetual);
              }}
            >
              {perpetual ? <CheckBox /> : <CheckBoxOutlineBlankOutlined />}
              On Going Campaign
            </Button>
          </Box>
          <Input
            type="date"
            value={selectedEndDate}
            onChange={(e) => {
              //end date cannot be before start date

              if (
                dayjs(e.target.value).isBefore(dayjs(selectedStartDate), 'day')
              ) {
                return dispatch(
                  toggleAlert({
                    toggle: true,
                    message: 'End date cannot be before start date',
                    type: 'error',
                  }),
                );
              }

              setSelectedEndDate(e.target.value);
            }}
            fullWidth
            sx={{
              input: {
                width: '100%',
              },
              display: perpetual ? 'none' : 'block',
            }}
          />
        </Box>
      </Box>
    </>
  );
};

const GGAdzMultiUnitCreation: React.FC<GGAdzMultiUnitCreationProps> = ({
  brand,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [currentpage, setCurrentPage] = useState('template');
  const [selectedTemplate, setSelectedTemplate] = useState<any>('');
  const [selectedLocations, setSelectedLocations] = useState<any>([
    ...locationlist.map((location) => location.name),
  ]);

  const [templateCollection, setTemplateCollection] = useState<
    {
      _id: string;
      name: string;
      category: string;
    }[]
  >([]);

  const [selectedStartDate, setSelectedStartDate] = useState<any>(
    dayjs().format('YYYY-MM-DD'),
  );
  const [perpetual, setPerpetual] = useState(true);
  const [selectedEndDate, setSelectedEndDate] = useState<any>(null);
  const [biddingStrategy, setBiddingStrategy] = useState<any>(
    'MAXIMIZE_CONVERSIONS',
  );
  const [ads, setAds] = useState<any>(null);

  const fetchTemplates = async () => {
    const { data } = await fetchGoogleTemplates(brand?._id);
    console.log(`$ts`);
    console.log(data);

    setTemplateCollection(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const checkPage = () => {
    const handleErrors = () => {
      let errorMessage = '';
      if (currentpage === 'template') {
        if (!selectedTemplate) {
          errorMessage = 'Please select a template';
          return errorMessage;
        }

        if (selectedTemplate === 'Performance Max Ad Template') {
          setBiddingStrategy('MAXIMIZE_CONVERSIONS');
        } else {
          setBiddingStrategy('MAXIMIZE_CONVERSIONS');
        }
      }

      if (currentpage === 'location') {
        if (selectedLocations.length === 0) {
          errorMessage = 'Please select at least one location';
          return errorMessage;
        }
      }

      if (currentpage === 'dates') {
        if (!selectedStartDate) {
          errorMessage = 'Please select a start date';
          return errorMessage;
        } else if (dayjs(selectedStartDate).isBefore(dayjs(), 'day')) {
          errorMessage = 'Start date cannot be in the past';
          return errorMessage;
        }

        if (!perpetual && !selectedEndDate) {
          errorMessage = 'Please select an end date';
          return errorMessage;
        }
      }

      return errorMessage;
    };

    if (handleErrors() !== '') {
      dispatch(
        toggleAlert({ toggle: true, message: handleErrors(), type: 'error' }),
      );
    } else {
      window.scrollTo(0, 0);

      setCurrentPage(flow[flow.indexOf(currentpage) + 1]);
    }
  };

  const creationFooter = (
    <Box
      sx={{
        position: 'sticky',
        zIndex: 300,
        bottom: 0,
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1rem',
        padding: '1rem',
        width: '100%',
        paddingX: 4,
        paddingBottom: 2,
      }}
    >
      <Box
        sx={{
          width: '50%',
        }}
      >
        <LinearProgressBarWithLabel
          value={(flow.indexOf(currentpage) / (flow.length - 1)) * 100}
        />
      </Box>
      <Box
        sx={{
          width: '50%',
          display: 'flex',
          justifyContent: 'end',
          gap: '0.5rem',
          alignItems: 'end',
        }}
      >
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>

        <Button
          variant="outlined"
          sx={{
            display: flow.indexOf(currentpage) === 0 ? 'none' : 'block',
          }}
          onClick={() => {
            //if index of current page is 0, then we are at the first page
            if (flow.indexOf(currentpage) === 0) {
              return;
            }
            setCurrentPage(flow[flow.indexOf(currentpage) - 1]);
          }}
        >
          Back
        </Button>

        <Button
          variant="contained"
          sx={{
            display:
              flow.indexOf(currentpage) === flow.length - 1 ? 'none' : 'block',
          }}
          onClick={() => {
            checkPage();
          }}
        >
          Next
        </Button>

        <Button
          variant="contained"
          startIcon={<RocketLaunchIcon />}
          sx={{
            display:
              flow.indexOf(currentpage) === flow.length - 1 ? 'flex' : 'none',
          }}
        >
          Launch {ads?.length} Ads
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          paddingY: 4,
          maxHeight: 'calc(100% - 100px)',
          height: 'calc(100% - 100px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            paddingX: 4,
            paddingBottom: 4,
          }}
        >
          <Typography
            variant="h6"
            textAlign={'center'}
            fontWeight={'bold'}
            color={'#096f4d'}
          >
            Multi-unit Ad Creation
          </Typography>
          {/* {currentpage === 'template' && (
            <SelectTemplate
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
            />
          )} */}

          {currentpage === 'template' ? (
            <Box
              sx={{
                display: `flex`,
                justifyContent: `space-between`,
                gap: `2rem`,
                height: 'calc(90vh - 230px)',
              }}
            >
              <Box
                sx={{
                  flexShrink: 0,
                }}
              >
                <Typography
                  variant="h6"
                  color={`primary`}
                  sx={{ fontWeight: 800 }}
                >
                  Select a template to create a new Google Ad
                </Typography>
                <Box sx={{ maxWidth: 400, paddingLeft: 2, marginTop: 2 }}>
                  {templateCollection.length > 100 ? (
                    <GoogleSelect
                      options={templateCollection.map((t) => {
                        return {
                          data: t,
                          row1: 'name',
                          row2: 'category',
                          value: 'name',
                        };
                      })}
                      onSelect={(template: any) => {}}
                      selected={selectedTemplate?.name}
                    />
                  ) : null}
                  {templateCollection.map((t) => (
                    <Button
                      key={t._id}
                      sx={{
                        width: `100%`,
                        display: `block`,
                        textAlign: `left`,
                        border: '1px solid #aaa',
                        marginBottom: 1,
                        fontWeight: 800,
                        backgroundColor: selectedTemplate
                          ? selectedTemplate?._id === t._id
                            ? `#e6ffd4`
                            : `#ffffff`
                          : `#ffffff`,
                        borderColor: selectedTemplate
                          ? selectedTemplate?._id === t._id
                            ? DEFAULT_COLOR_THEME
                            : `#aaa`
                          : `#aaa`,
                      }}
                      onClick={() => {
                        console.log(t);
                        setSelectedTemplate(t);
                      }}
                    >
                      <Box>{t.name}</Box>
                      <Box
                        sx={{
                          fontSize: `0.8rem`,
                          color: `#666`,
                          fontWeight: 400,
                        }}
                      >
                        {t.category === 'search'
                          ? 'Search'
                          : t.category === 'display'
                          ? 'Display'
                          : t.category === 'performancemax'
                          ? 'Performance Max'
                          : ''}
                      </Box>
                    </Button>
                  ))}
                </Box>
              </Box>
              <Box
                sx={{
                  flexShrink: 1,
                  //maxWidth: 700,
                  maxWidth: 600,
                  width: `100%`,
                  overflowY: 'auto',
                  backgroundColor: selectedTemplate ? `#ebebeb` : `#ffffff`,
                  padding: `0.5rem`,
                  ...scrollBarGreen,
                }}
              >
                {selectedTemplate ? (
                  <Box
                    sx={{
                      backgroundColor: `#ffffff`,
                      position: `sticky`,
                      top: 0,
                      zIndex: 100,
                    }}
                  >
                    {Object.keys(selectedTemplate).length > 0 ? (
                      <Box sx={{ padding: 2 }}>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: `1.2rem`,
                          }}
                        >
                          Preview:
                        </Typography>
                        <GoogleTemplatesPreview
                          category={selectedTemplate?.category}
                          headlines={selectedTemplate?.headlines || []}
                          descriptions={selectedTemplate?.descriptions || []}
                          sitelinks={selectedTemplate?.sitelinks || []}
                          url={selectedTemplate?.final_url}
                          showToolbar={false}
                          center={true}
                          images={selectedTemplate?.images?.map(
                            (i: any) => i?.url,
                          )}
                          business_logo={selectedTemplate?.business_logo?.url}
                        />
                        <GoogleTemplateSummary
                          template={selectedTemplate}
                          ad={null}
                        />
                      </Box>
                    ) : null}
                  </Box>
                ) : null}
              </Box>
            </Box>
          ) : null}

          {currentpage === 'location' && (
            <SelectLocation
              selectedLocations={selectedLocations}
              setSelectedLocations={setSelectedLocations}
            />
          )}
          {currentpage === 'dates' && (
            <SelectDates
              selectedStartDate={selectedStartDate}
              setSelectedStartDate={setSelectedStartDate}
              selectedEndDate={selectedEndDate}
              setSelectedEndDate={setSelectedEndDate}
              perpetual={perpetual}
              setPerpetual={setPerpetual}
              biddingStrategy={biddingStrategy}
              setBiddingStrategy={setBiddingStrategy}
            />
          )}

          {currentpage === 'muticreatetable' && (
            <MultiCreationTable
              perpetual={perpetual}
              updateRows={setAds}
              selectedTemplate={selectedTemplate}
              selectedLocations={selectedLocations}
              setSelectedLocations={setSelectedLocations}
              selectedStartDate={selectedStartDate}
              selectedEndDate={selectedEndDate}
            />
          )}
        </Box>

        {creationFooter}
      </Box>
    </>
  );
};

export default GGAdzMultiUnitCreation;
