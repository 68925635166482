import React from 'react';

import { IoMdCloseCircle, IoMdCheckmarkCircle } from 'react-icons/io';

const GGLAssetsLocationTable = ({ locations, fullWidth, selected }: any) => {
  return (
    <div style={{ width: fullWidth ? '100%' : 'auto' }}>
      <table
        style={{
          width: fullWidth ? '100%' : 'auto',
        }}
      >
        <thead
          style={{
            position: 'sticky',
            top: '0',
            backgroundColor: selected ? '#e6ffd4' : '#ffffff',
          }}
        >
          <tr
            style={{
              backgroundColor: selected ? '#e6ffd4' : '#ffffff',
            }}
          >
            <td style={styles.header}>
              <strong>#</strong>
            </td>
            <td style={styles.header}>
              <strong>Location</strong>
            </td>
            <td style={styles.header}>
              <strong>Type</strong>
            </td>
            <td style={{ ...styles.header, textAlign: 'right' }}>
              <strong>Reach</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          {locations.map((location: any, index: number) => (
            <tr key={index}>
              <td
                style={{
                  padding: '0 10px',
                  fontSize: '0.7rem',
                  textAlign: 'center',
                }}
              >
                {index + 1}
              </td>
              <td style={styles.header}>{location.address}</td>
              <td style={styles.header}>{location.targetType}</td>
              <td style={{ ...styles.header, textAlign: 'right' }}>
                {location.reach}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const styles = {
  header: {
    padding: '0 10px',
  },
};

export default GGLAssetsLocationTable;
