import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { toCurrency } from '../../utils/numberFormatter';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

interface LineChartProps {
  chartData: any;
  // Each element will be render to the next line
  title: string[];
  // Each element will be render to the next line
  subtitle: string[];
  titleFontSize?: number;
  subtitleFontSize?: number;
  showLegend?: boolean;
  legendPosition?: string;
  isCurrency?: boolean;
  horizontal?: boolean;
}

const LineChart: React.FC<LineChartProps> = ({
  chartData,
  title,
  subtitle,
  titleFontSize = 14,
  subtitleFontSize = 12,
  legendPosition = 'top',
  showLegend = true,
  isCurrency = false,
  horizontal = false,
}) => {
  const buildTooltipPlugin = (isCurrency: boolean) => {
    let tooltip: any = { title: (value: any) => `${value[0].dataset.label}` };

    if (isCurrency) {
      tooltip = {
        ...tooltip,
        label: (value: any) => {
          if (isCurrency) {
            return `${value.dataset.label}: ${toCurrency(
              'USD',
              parseFloat(value.formattedValue),
            )}`;
          }

          return `${value.dataset.label}: ${value.dataset.data}`;
        },
      };
    }

    return tooltip;
  };
  const options = () => {
    let option: any = {
      responsive: true,
      plugins: {
        legend: {
          display: showLegend,
          position: legendPosition,
        },
        title: {
          display: true,
          text: title,
          font: {
            size: titleFontSize,
          },
        },
        subtitle: {
          display: true,
          text: subtitle,
          font: {
            size: subtitleFontSize,
          },
        },
        tooltip: {
          callbacks: {
            ...buildTooltipPlugin(isCurrency),
          },
        },
      },
      maintainAspectRatio: false,
    };

    if (horizontal) {
      option = { ...option, indexAxis: 'y' as const };
    }

    if (horizontal) {
      option = {
        ...option,
        scales: {
          y: {
            ticks: {
              callback: (value: any) => {
                if (isCurrency) return toCurrency('USD', value, 0);

                return value;
              },
            },
          },
          x: {
            ticks: {
              callback: (value: any) => {
                if (isCurrency) return toCurrency('USD', value, 0);

                return value;
              },
            },
          },
        },
      };
    }

    if (isCurrency && !horizontal) {
      option = {
        ...option,
        scales: {
          y: {
            ticks: {
              callback: (value: any) => {
                return toCurrency('USD', value, 0);
              },
            },
          },
        },
      };
    }

    return option;
  };

  return <Line options={options()} data={chartData} />;
};

export default LineChart;
