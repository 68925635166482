import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Brand, FBLeadGenData, LeadFormData } from '../../../types';
import { fetchFacebookGenForms } from '../../../services/ads';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { facebookLeadFormColumns } from './FacebookLeadFormListTableColumns';
import { DataGridPremium, GridRowParams } from '@mui/x-data-grid-premium';
import { CustomLoadingOverlay } from '../../Loading';

interface FacebookLeadFormListTableProps {
  brand: Brand;
  setSelectedForms: Dispatch<SetStateAction<FBLeadGenData[]>>;
  forms: LeadFormData[];
}

const FacebookLeadFormListTable: React.FC<FacebookLeadFormListTableProps> = ({
  brand,
  setSelectedForms,
  forms,
}) => {
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const [leadGenForms, setLeadGenForms] = useState<FBLeadGenData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (brand) getLeadGenForms();
  }, [brand]);

  const getLeadGenForms = async () => {
    setLoading(true);
    try {
      const response = await fetchFacebookGenForms(brand?._id);

      setLeadGenForms(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOnSelectForm = (value: any[]) => {
    const forms = leadGenForms?.filter((form: FBLeadGenData) =>
      value.includes(form.id),
    );

    setSelectedForms(forms);
  };

  const rows = leadGenForms?.map((form: FBLeadGenData) => {
    return {
      id: form.id,
      name: form.name,
      status: form.status,
      form,
    };
  });

  return (
    <Box>
      <DataGridPremium
        className={styles.table}
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }],
          },
        }}
        density="compact"
        rows={rows}
        autoHeight
        hideFooterSelectedRowCount
        loading={loading}
        columns={facebookLeadFormColumns(xsOnly)}
        checkboxSelection
        onRowSelectionModelChange={(params) => handleOnSelectForm(params)}
        isRowSelectable={(params: GridRowParams) =>
          !forms.map((form: LeadFormData) => form.id).includes(params.row.id)
        }
        slots={{ loadingOverlay: CustomLoadingOverlay }}
      />
    </Box>
  );
};

export default FacebookLeadFormListTable;
