import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { CrmProvider, DEFAULT_COLOR_THEME } from '../../utils';
import CloseIcon from '@mui/icons-material/Close';
import ModalHeader from '../Modal/ModalHeader';
import { constantStringToHumanized } from '../../utils/stringModifier';
import {
  AdLead,
  Brand,
  ChangeEventType,
  ClientTetherClientHistory,
} from '../../types';
import { fetchClientHistory } from '../../services/clientTether';
import { dayJsInstance } from '../../utils/dayjsHelper';
import { useSelector } from 'react-redux';
import { NumericCustomField } from '../TextField';
import { HtmlTooltip } from '../Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { getCrmSettingsByBrand } from '../../services/crm';
import { getDealsById } from '../../services/hubspot';

interface LeadDetailsProps {
  onClose: () => void;
  lead: AdLead;
  brand?: Brand | null;
  crm?: 'client-tether' | 'hubspot' | null;
  commissionMode?: boolean;
  onUpdate?: (leadId: string, brandId: string, formValues: AdLead) => void;
  updateLoading?: boolean;
  dealId?: string | null;
}

const LeadDetails: React.FC<LeadDetailsProps> = ({
  onClose,
  lead,
  brand = null,
  crm = null,
  commissionMode = false,
  onUpdate,
  updateLoading = false,
  dealId = null,
}) => {
  const timezone = useSelector((state: any) => state?.brandTimezone?.timezone);
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const isClientTether = crm === CrmProvider.CLIENT_TETHER;
  const isHubSpot = crm === CrmProvider.HUBSPOT;
  const [clientHistory, setClientHistory] = useState<
    ClientTetherClientHistory[]
  >([]);
  const [historyLoading, setHistoryLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<AdLead>({
    lifetimeValue: 0,
    commission: 0,
    units: 0,
    dealValue: 0,
  });
  const [editLifetimeValue, setEditLifetimeValue] = useState<boolean>(false);
  const [editCommissionValue, setEditCommissionValue] =
    useState<boolean>(false);
  const [editUnitSold, setEditUnitSold] = useState<boolean>(false);
  const [editDealValue, setEditDealValue] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [dealLoading, setDealLoading] = useState<boolean>(false);

  useEffect(() => {
    if ((isClientTether || isHubSpot) && brand) {
      getCrmSettings();
    }
  }, [isClientTether, isHubSpot, brand]);

  useEffect(() => {
    if (brand && lead?.clientTetherClientId) {
      getClientHistory();
    }
  }, [brand, lead?.clientTetherClientId]);

  useEffect(() => {
    if (isHubSpot && dealId && !lead?.dealValue && !fetchLoading) {
      getDeal();
    }
  }, [isHubSpot, dealId, lead?.dealValue, fetchLoading]);

  const getCrmSettings = async () => {
    try {
      setFetchLoading(true);
      const response = await getCrmSettingsByBrand(brand?._id);

      setFormValues({
        lifetimeValue:
          lead?.lifetimeValue > 0
            ? lead.lifetimeValue
            : response.data.averageLifetimeValue,
        commission:
          lead?.commission > 0
            ? lead.commission
            : response.data.averageCommission,
        units: lead?.units > 1 ? lead.units : response.data.averageUnitSold,
        dealValue: lead?.dealValue > 0 ? lead.dealValue : 0,
      });
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const getClientHistory = async () => {
    try {
      setHistoryLoading(true);

      const response = await fetchClientHistory(
        brand?._id,
        lead?.clientTetherClientId,
      );

      setClientHistory(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setHistoryLoading(false);
    }
  };

  const getDeal = async () => {
    try {
      setDealLoading(true);

      const response = await getDealsById(brand?._id, dealId);

      setFormValues({
        ...formValues,
        dealValue: parseFloat(response?.data?.properties?.amount || 0),
      });
    } catch (error: any) {
      console.log(error);
    } finally {
      setDealLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: xsOnly ? '100%' : '400px',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
          width: '10px',
        },
        '&::-webkit-scrollbar-track': {
          // background: "#F1F0F0",
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
        },

        '&::-webkit-scrollbar-thumb': {
          background: DEFAULT_COLOR_THEME,
          borderRadius: '18px',

          '&:hover': {
            background: DEFAULT_COLOR_THEME,
          },
        },
        padding: '20px',
      }}
    >
      <Grid container spacing={2}>
        {xsOnly ? (
          <Grid
            item
            xs={12}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton onClick={onClose} type="button">
              <CloseIcon />
            </IconButton>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <ModalHeader title="Lead Details" />
        </Grid>

        {isClientTether || isHubSpot ? (
          <Grid
            item
            xs={12}
            sx={{
              flexDirection: 'row',
              display: 'flex',
              width: '100%',
            }}
          >
            <Typography variant="body2" mr={1} sx={{ fontWeight: 'bold' }}>
              {isClientTether
                ? 'Client Tether Client ID:'
                : 'HubSpot Contact ID:'}
            </Typography>

            <Typography variant="body2">
              {isClientTether
                ? lead?.clientTetherClientId
                : lead?.hubSpotContactId}
            </Typography>
          </Grid>
        ) : null}

        <Grid
          item
          xs={12}
          sx={{
            flexDirection: 'row',
            display: 'flex',
            width: '100%',
          }}
        >
          <Typography variant="body2" mr={1} sx={{ fontWeight: 'bold' }}>
            Lead ID:
          </Typography>

          <Typography variant="body2">{lead?.id}</Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            flexDirection: 'row',
            display: 'flex',
            width: '100%',
          }}
        >
          <Typography variant="body2" mr={1} sx={{ fontWeight: 'bold' }}>
            Ad ID:
          </Typography>

          <Typography variant="body2">{lead?.adId}</Typography>
        </Grid>

        {isClientTether || isHubSpot ? (
          <>
            <Grid item xs={12}>
              <NumericCustomField
                value={formValues?.lifetimeValue}
                onChangeValue={(values) =>
                  setFormValues({
                    ...formValues,
                    lifetimeValue: values.floatValue,
                  })
                }
                id="lifetimeValue"
                name="lifetimeValue"
                label="Lifetime Value"
                fullWidth
                fixedDecimal
                disabled={!editLifetimeValue}
                endIcon={
                  updateLoading || fetchLoading ? (
                    <CircularProgress size={25} />
                  ) : !editLifetimeValue ? (
                    <IconButton
                      onClick={() => setEditLifetimeValue((prev) => !prev)}
                      disabled={
                        editUnitSold || editCommissionValue || editDealValue
                      }
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        onUpdate(lead.id, brand?._id, formValues);
                        setEditLifetimeValue(false);
                      }}
                      disabled={
                        editUnitSold || editCommissionValue || editDealValue
                      }
                    >
                      <SaveOutlinedIcon />
                    </IconButton>
                  )
                }
                helperText={
                  <Fragment>
                    <HtmlTooltip
                      disableInteractive
                      title={
                        <Fragment>
                          <Box
                            component="div"
                            sx={{
                              fontWeight: 'bold',
                            }}
                          >
                            Formula
                          </Box>

                          <div>AUV x Franchise Fee Percentage x Years</div>

                          <Box
                            component="div"
                            sx={{
                              fontWeight: 'bold',
                            }}
                          >
                            Example
                          </Box>

                          <Box
                            component="div"
                            sx={{
                              flexDirection: 'column',
                            }}
                          >
                            <Box
                              sx={{
                                justifyContent: 'flex-end',
                                display: 'flex',
                              }}
                            >
                              $1,000,000 (AUV)
                            </Box>

                            <Box
                              sx={{
                                justifyContent: 'flex-end',
                                display: 'flex',
                              }}
                            >
                              0.05 (5% Royalty Fee)
                            </Box>

                            <Box
                              sx={{
                                justifyContent: 'space-between',
                                display: 'flex',
                              }}
                            >
                              <div>x</div>

                              <div>10 (Years)</div>
                            </Box>

                            <div>
                              <Divider />
                            </div>

                            <Box
                              sx={{
                                justifyContent: 'flex-end',
                                display: 'flex',
                              }}
                            >
                              $500,000 (Average Lifetime Value)
                            </Box>
                          </Box>
                        </Fragment>
                      }
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          textDecoration: 'underline',
                          color: 'blue',
                          cursor: 'pointer',
                        }}
                      >
                        How to calculate your Average Lifetime Value?
                      </Typography>
                    </HtmlTooltip>
                  </Fragment>
                }
              />
            </Grid>

            {commissionMode ? (
              <>
                <Grid item xs={12}>
                  <NumericCustomField
                    value={formValues?.commission}
                    onChangeValue={(values) =>
                      setFormValues({
                        ...formValues,
                        commission: values.floatValue,
                      })
                    }
                    id="commission"
                    name="commission"
                    label="Commission"
                    fullWidth
                    fixedDecimal
                    disabled={!editCommissionValue}
                    endIcon={
                      updateLoading || fetchLoading ? (
                        <CircularProgress size={25} />
                      ) : !editCommissionValue ? (
                        <IconButton
                          onClick={() =>
                            setEditCommissionValue((prev) => !prev)
                          }
                          disabled={editUnitSold || editLifetimeValue}
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => {
                            onUpdate(lead.id, brand?._id, formValues);
                            setEditCommissionValue(false);
                          }}
                          disabled={
                            editUnitSold || editLifetimeValue || editDealValue
                          }
                        >
                          <SaveOutlinedIcon />
                        </IconButton>
                      )
                    }
                  />
                </Grid>

                {isHubSpot ? (
                  <Grid item xs={12}>
                    {!dealLoading ? (
                      <NumericCustomField
                        value={formValues?.dealValue}
                        onChangeValue={(values) =>
                          setFormValues({
                            ...formValues,
                            dealValue: values.floatValue,
                          })
                        }
                        id="dealValue"
                        name="dealValue"
                        label="Deal Value"
                        fullWidth
                        fixedDecimal
                        disabled={!editDealValue}
                        endIcon={
                          updateLoading || fetchLoading ? (
                            <CircularProgress size={25} />
                          ) : !editDealValue ? (
                            <IconButton
                              onClick={() => setEditDealValue((prev) => !prev)}
                              disabled={
                                editUnitSold ||
                                editLifetimeValue ||
                                editCommissionValue
                              }
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => {
                                onUpdate(lead.id, brand?._id, formValues);
                                setEditDealValue(false);
                              }}
                              disabled={
                                editUnitSold ||
                                editLifetimeValue ||
                                editCommissionValue
                              }
                            >
                              <SaveOutlinedIcon />
                            </IconButton>
                          )
                        }
                      />
                    ) : (
                      <CircularProgress color="inherit" size={20} />
                    )}
                  </Grid>
                ) : null}
              </>
            ) : null}

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                type="number"
                id="units"
                name="units"
                label="Units"
                onChange={(e: ChangeEventType) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: parseInt(e.target.value),
                  })
                }
                value={formValues?.units}
                placeholder="0"
                InputLabelProps={{ shrink: true }}
                size="small"
                sx={{
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                      display: 'none',
                    },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                }}
                disabled={!editUnitSold}
                InputProps={{
                  endAdornment:
                    updateLoading || fetchLoading ? (
                      <CircularProgress size={25} />
                    ) : !editUnitSold ? (
                      <IconButton
                        onClick={() => setEditUnitSold((prev) => !prev)}
                        disabled={
                          editCommissionValue ||
                          editLifetimeValue ||
                          editDealValue
                        }
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => {
                          onUpdate(lead.id, brand?._id, formValues);
                          setEditUnitSold(false);
                        }}
                        disabled={
                          editCommissionValue ||
                          editLifetimeValue ||
                          editDealValue
                        }
                      >
                        <SaveOutlinedIcon />
                      </IconButton>
                    ),
                }}
              />
            </Grid>
          </>
        ) : null}

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {lead?.fieldData?.length > 0 ? (
          <>
            {lead?.fieldData?.map((data: any) => {
              return (
                <Grid
                  item
                  xs={12}
                  sx={{
                    flexDirection: 'row',
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <Typography
                    variant="body2"
                    mr={1}
                    sx={{ fontWeight: 'bold' }}
                  >
                    {constantStringToHumanized(data?.name)}:
                  </Typography>

                  <Typography variant="body2">{data?.values[0]}</Typography>
                </Grid>
              );
            })}
          </>
        ) : (
          <Grid
            item
            xs={12}
            sx={{
              flexDirection: 'row',
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <Typography variant="body2" mr={1} sx={{ fontWeight: 'bold' }}>
              No other data to show
            </Typography>
          </Grid>
        )}

        {isClientTether ? (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 'bold', textAlign: 'center' }}
              >
                Client History
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  border: 'solid',
                  backgroundColor: '#d9d9d933',
                  minHeight: `500px`,
                  maxHeight: `500px`,
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  '&::-webkit-scrollbar': {
                    width: '10px',
                  },
                  '&::-webkit-scrollbar-track': {
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  },

                  '&::-webkit-scrollbar-thumb': {
                    background: DEFAULT_COLOR_THEME,
                    borderRadius: '18px',

                    '&:hover': {
                      background: DEFAULT_COLOR_THEME,
                    },
                  },
                  display: historyLoading ? 'flex' : '',
                  alignItems: historyLoading ? 'center' : '',
                  justifyContent: historyLoading ? 'center' : '',
                }}
              >
                {' '}
                {!historyLoading ? (
                  <Grid container spacing={1} sx={{ padding: '5px' }}>
                    {clientHistory.map((history: ClientTetherClientHistory) => {
                      if (history.type === 'Email sent') return <></>;

                      return (
                        <>
                          <Grid
                            item
                            xs={12}
                            sx={{ flexDirection: 'column' }}
                            key={history.id}
                          >
                            <Typography component="div" variant="caption">
                              {dayJsInstance(history.timestamp)
                                .tz(timezone)
                                .format('MMM D, YYYY h:mm A')}
                            </Typography>

                            <Typography
                              component="div"
                              variant="caption"
                              fontWeight="bold"
                            >
                              {history.type}
                            </Typography>

                            <Typography component="div" variant="caption">
                              {history.content}
                            </Typography>

                            <Typography
                              component="div"
                              variant="caption"
                              fontWeight="bold"
                            >
                              User:{' '}
                              <Typography variant="caption">
                                {history.user_name}
                              </Typography>
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                ) : (
                  <CircularProgress color="inherit" size={20} />
                )}
              </Box>
            </Grid>
          </>
        ) : null}
      </Grid>
    </Box>
  );
};

export default LeadDetails;
