import axios from 'axios';
import { apiBaseUrl } from './api';

export const saveGoogleCustomerID = async (params: {
  mcc: string[];
  brandId: string;
}) => {
  try {
    const { data } = await axios.patch(`${apiBaseUrl}/ads/google/account`, {
      params,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const fetchGoogleCustomers = async (refreshToken: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/ads/google/customers?refreshToken=${refreshToken}`,
  );

  return data?.data;
};

export const fetchGoogleKeywordIdeas = async (params: {
  keywords: string[];
  url: string;
  brandId: string;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/keywords/ideas`,
      params,
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const fetchGoogleAssetsAccounts = async (params: {
  brandId: string;
}) => {
  try {
    const { data } = await axios.get(`${apiBaseUrl}/ads/google/assets`, {
      params,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const deleteGoogleAssetsImage = async (params: {
  brandId: string;
  public_id: string;
}) => {
  try {
    const { data } = await axios.delete(
      `${apiBaseUrl}/ads/google/assets/image`,
      {
        params,
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const fetchGoogleCallouts = async (params: { brandId: string }) => {
  try {
    const { data } = await axios.get(
      `${apiBaseUrl}/ads/google/resource/callouts`,
      {
        params,
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const fetchGoogleAssets = async (params: {
  brandId: string;
  ps: string;
}) => {
  try {
    const { data } = await axios.get(`${apiBaseUrl}/ads/google/assets`, {
      params,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const fetchGoogleAssetsByBrand = async (brandId: string) => {
  // /assets/brand

  try {
    const { data } = await axios.get(`${apiBaseUrl}/ads/google/assets/brand`, {
      params: {
        brandId,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const setGoogleAssetsAccount = async (params: {
  brandId: string;
  customer_id: string;
  account_id: any;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/assets`,
      params,
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const uploadGoogleAssetImage = async (params: {
  brandId: string;
  resourceName: string;
  image: File | Blob | string;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/assets/image`,
      params,
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const modifyGoogleAdStatus = async (params: {
  brandId: string;
  ad: any;
  status: string;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/admodification`,
      {
        brandId: params.brandId,
        ad: params.ad,
        status: params.status,
        modify: 'status',
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const fetchUserInterestAndTopics = async (params: {
  brandId: string;
  type?: 'SEARCH' | 'DISPLAY';
}) => {
  try {
    if (!params.brandId) return { error: 'No brandId provided' };
    if (!params.type) params.type = 'DISPLAY';
    const { data } = await axios.get(
      `${apiBaseUrl}/ads/google/topicsandinterests`,
      {
        params,
      },
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const modifyGoogleResourceStatus = async (params: {
  brandId: string;
  resource_names: string[];
  type: 'campaign' | 'ad_group' | 'ad' | 'keyword';
  status: string;
}) => {
  try {
    const { data } = await axios.patch(
      `${apiBaseUrl}/ads/google/resource/status`,
      params,
    );

    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const fetchGoogleAccountDetails = async (params: {
  brandId: string;
}) => {
  try {
    const { data } = await axios.get(`${apiBaseUrl}/ads/google/account`, {
      params,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const fetchGoogleAccImages = async (params: { brandId: string }) => {
  try {
    const { data } = await axios.get(`${apiBaseUrl}/ads/google/images`, {
      params,
    });
    console.log(data);
    return data;
  } catch (error) {
    return error;
  }
};

export const fetchGoogleAccYoutubeVids = async (params: {
  brandId: string;
}) => {
  try {
    const { data } = await axios.get(`${apiBaseUrl}/ads/google/youtube`, {
      params,
    });
    console.log(data);
    return data;
  } catch (error) {
    return error;
  }
};

export const googleTestFunction = async (params: { brandId: string }) => {
  try {
    const { data } = await axios.get(`${apiBaseUrl}/ads/google/test`, {
      params,
    });
    console.log(`googleTestFunction res`);
    console.log(data);
    return data;
  } catch (error) {
    return error;
  }
};

export const fetchYTCheck = async (url: string) => {
  function convertShortsUrlToWatchUrl(shortsUrl: string) {
    if (
      !shortsUrl.includes('youtube.com/shorts/') &&
      !shortsUrl.includes('youtu.be/')
    ) {
      if (!shortsUrl.includes('.com')) {
        return `https://youtu.be/${shortsUrl}`;
      }
      return shortsUrl;
    }

    const url = new URL(shortsUrl);
    console.log('🚀 url', url);
    console.log('🚀 url.pathname', url.pathname.split('/'));
    const videoId =
      url.pathname.split('/').length > 2
        ? url.pathname.split('/')[2]
        : url.pathname.split('/')[1];
    console.log('🚀 videoId', videoId);
    const convertedUrl = `https://www.youtube.com/watch?v=${videoId}`;
    console.log('🚀 convertedUrl', convertedUrl);
    return convertedUrl;
  }

  const ok = await axios
    .post(`${apiBaseUrl}/ads/google/youtube`, {
      url: convertShortsUrlToWatchUrl(url),
    })
    .then((response) => {
      console.log('ytTest', response);
      return response;
    })
    .catch((error) => {
      console.log('ytTest', error);
      return error;
    });
  return ok;
};

export const getFromGAcc = async (params: { brandId: string }) => {
  try {
    const { data } = await axios.get(
      `${apiBaseUrl}/ads/google/gacc?brandId=${params.brandId}`,
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const patchtoGAcc = async (params: {
  brandId: string;
  command: any;
}) => {
  try {
    const { data } = await axios.patch(
      `${apiBaseUrl}/ads/google/gacc?brandId=${params.brandId}`,
      {
        command: params.command,
      },
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const removeFromAssetGAcc = async (params: {
  brandId: string;
  video?: string;
  image?: any;
}) => {
  try {
    const { data } = await axios.post(`${apiBaseUrl}/ads/google/gacc/`, {
      ...params,
      action: 'remove',
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const addToAssetGAcc = async (params: {
  brandId: string;
  video?: string;
  image?: any;
}) => {
  try {
    const { data } = await axios.post(`${apiBaseUrl}/ads/google/gacc/`, {
      ...params,
      action: 'insert',
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const fetchGoogleAds = async (brandId: string) => {
  try {
    const { data } = await axios.get(`${apiBaseUrl}/ads/google/ads`, {
      params: {
        brandId,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const fetchGoogleTemplates = async (brandId: string) => {
  try {
    const { data } = await axios.get(
      `${apiBaseUrl}/ads/google/templates?brandId=${brandId}`,
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const setGoogleTemplates = async (params: { brandId: string }) => {
  try {
    const { data } = await axios.put(
      `${apiBaseUrl}/ads/google/templates`,
      params,
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const createGoogleCampaign = async (params: any) => {
  const { brandId, campaign } = params;
  if (!campaign) return { error: 'No campaign data provided' };
  if (!brandId) return { error: 'No brandId provided' };
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/campaigns`,
      params,
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const pauseGoogleAdGroupAd = async (params: {
  brandId: string;
  ad: any;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/admodification`,
      {
        brandId: params.brandId,
        ad: params.ad,
        status: 'PAUSED',
        modify: 'status',
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const getGoogleCampaignAndAdgroupsAndAds = async (params: any) => {
  const { brandId } = params;
  if (!brandId) return { error: 'No brandId provided' };
  try {
    const { data } = await axios.get(`${apiBaseUrl}/ads/google/count`, {
      params,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const assembleGoogleAds = async (params: any) => {
  const { brandId, budget, budgetName } = params;
  if (!brandId) return { error: 'No brandId provided' };
  if (!budget) return { error: 'No budget provided' };
  if (!budgetName) return { error: 'No budgetName provided' };
  try {
    const data = await axios.post(
      `${apiBaseUrl}/ads/google/adcreation`,
      params,
    );
    console.log(`🚀 tried`, data);
    return data;

    // console.log(`🚀 tried`, params);
    // return params;
  } catch (error) {
    console.log(`🚀 catched`, error);

    return { error };
  }
};

export const fetchGoogle = async (params: { brandId: string; db: string }) => {
  const { brandId, db } = params;
  if (!brandId) return { error: 'No brandId provided' };
  if (!db) return { error: 'No db provided' };
  const { data } = await axios.get(`${apiBaseUrl}/ads/google/db`, {
    params,
  });
  return data;
};

export const fetchAssetLocationSet = async (params: { brandId: string }) => {
  const { brandId } = params;
  if (!brandId) return { error: 'No brandId provided' };
  try {
    const { data } = await axios.get(
      `${apiBaseUrl}/ads/google/assets/locations`,
      {
        params,
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const saveModifiedAdBudget = async (params: {
  brandId: string;
  ad: any;
  budget: any;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/admodification`,
      {
        brandId: params.brandId,
        ad: params.ad,
        budget: params.budget,
        modify: 'budget',
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const saveModifiedAdURL = async (params: {
  brandId: string;
  ad: any;
  url: any;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/admodification`,
      {
        brandId: params.brandId,
        ad: params.ad,
        url: params.url,
        modify: 'url',
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const saveModifiedAdLocations = async (params: {
  brandId: string;
  ad: any;
  locations: any;
  locationSet: any;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/admodification`,
      {
        brandId: params.brandId,
        ad: params.ad,
        locations: params.locations,
        locationSet: params.locationSet,
        modify: 'locations',
      },
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const saveModifiedAdKeywords = async (params: {
  brandId: string;
  ad: any;
  keywords: any;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/admodification`,
      {
        brandId: params.brandId,
        ad: params.ad,
        keywords: params.keywords,
        modify: 'keywords',
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const saveModifiedAdSitelinks = async (params: {
  brandId: string;
  ad: any;
  sitelinks: any;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/admodification`,
      {
        brandId: params.brandId,
        ad: params.ad,
        sitelinks: params.sitelinks,
        modify: 'sitelinks',
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const saveModifiedAdHeadlines = async (params: {
  brandId: string;
  ad: any;
  headlines: any;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/admodification`,
      {
        brandId: params.brandId,
        ad: params.ad,
        headlines: params.headlines,
        modify: 'headlines',
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const saveModifiedAdDescriptions = async (params: {
  brandId: string;
  ad: any;
  descriptions: any;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/admodification`,
      {
        brandId: params.brandId,
        ad: params.ad,
        descriptions: params.descriptions,
        modify: 'descriptions',
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const saveModifiedAdFinalUrls = async (params: {
  brandId: string;
  ad: any;
  finalurl: string;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/admodification`,
      {
        brandId: params.brandId,
        ad: params.ad,
        finalurl: params.finalurl,
        modify: 'finalurl',
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const saveModifiedAdImages = async (params: {
  brandId: string;
  ad: any;
  images?: any;
  businessImage?: any;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/admodification`,
      {
        brandId: params.brandId,
        ad: params.ad,
        images: params.images,
        businessImage: params.businessImage,
        modify: 'images',
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const saveModifiedAdCallouts = async (params: {
  brandId: string;
  ad: any;
  callouts: any;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/admodification`,
      {
        brandId: params.brandId,
        ad: params.ad,
        callouts: params.callouts,
        modify: 'callouts',
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const fetchAssetKeywordSets = async (params: { brandId: string }) => {
  try {
    const { data } = await axios.get(
      `${apiBaseUrl}/ads/google/assets/keywordsets`,
      {
        params,
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const saveAssetKeywordSet = async (params: {
  brandId: string;
  keywordSet: {
    brandId: string;
    name: string;
    productandservice: string;
    negativeset: boolean;
    keywords: any[];
  };
  isEditing: boolean;
}) => {
  try {
    if (params.isEditing) {
      const { data } = await axios.patch(
        `${apiBaseUrl}/ads/google/assets/keywordsets`,
        params,
      );

      return data;
    } else {
      const { data } = await axios.put(
        `${apiBaseUrl}/ads/google/assets/keywordsets`,
        params,
      );

      return data;
    }
  } catch (error) {
    return error;
  }
};

export const saveAssetLocationSet = async (params: {
  brandId: string;
  _id: string;
  name: string;
  locations: any[];
  isExisting: boolean;
  containingAds?: any[];
}) => {
  try {
    const { brandId, _id, name, locations, containingAds } = params;
    const { data } = await axios.put(
      `${apiBaseUrl}/ads/google/assets/locations`,
      {
        brandId,
        _id,
        name,
        locations,
      },
    );

    if (params.isExisting) {
      if (!containingAds.length) return data;

      const promises = Promise.all(
        containingAds.map((ad: any) => {
          return axios.post(`${apiBaseUrl}/ads/google/admodification`, {
            brandId,
            ad: ad,
            locations,
            modify: 'locations',
          });
        }),
      );

      const results = await promises;
      return results;
    }

    return data;
  } catch (error) {
    return error;
  }
};

export const fetchGoogleLocationSuggestions = async (params: {}) => {
  try {
    const { data } = await axios.get(`${apiBaseUrl}/ads/google/locations`, {
      params,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const setGoogleAssets = async (params: {
  brandId: string;
  product_and_service: string;
  data: any;
}) => {
  try {
    const { data } = await axios.put(`${apiBaseUrl}/ads/google/assets`, params);

    return data;
  } catch (error) {
    return error;
  }
};

export const fetchGoogleAdsAccount = async (
  brandId: string,
  refreshToken: string,
) => {
  const data = await axios.get(`${apiBaseUrl}/ads/google/accounts`, {
    params: {
      brandId,
      refreshToken,
    },
  });

  return data?.data;
};

export const syncCustomerIds = async (
  brandId: string,
  customerIds: string[],
) => {
  const data = await axios.post(`${apiBaseUrl}/ads/google/customers/sync`, {
    brandId,
    customerIds,
  });

  return data?.data;
};

export const fetchGoogleTrackingTemplate = async (brandId: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/ads/google/trackingtemplate?brandId=${brandId}`,
  );

  return data?.data;
};

export const fetchGoogleCampaigns = async (
  brandId: string,
  refreshToken?: string,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/ads/google/campaigns?brandId=${brandId}&refreshToken=${refreshToken}`,
  );

  return data?.data;
};

export const setGoogleKeywords = async (params: {
  brandId: string;
  matchType: string[];
  keyword: {
    match_type: string;
    text: string;
    resource_name: string;
    ad_group_resource_name: string;
    negative?: boolean | undefined;
  }[];
}) => {
  const { data } = await axios.post(
    `${apiBaseUrl}/report/brand/campaign/google/keywords`,
    {
      ...params,
    },
  );
  return data;
};
