import {
  DataGridPremiumProps,
  getDataGridUtilityClass,
  GridColDef,
  gridFilteredDescendantCountLookupSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid-premium';
import { Dispatch, SetStateAction } from 'react';
import { defaultProps } from '../../../utils/helpers/TableHelpers';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import {
  Badge,
  Box,
  IconButton,
  Tooltip,
  Typography,
  unstable_composeClasses as composeClasses,
} from '@mui/material';
import { arrayUniqueFilter } from '../../../utils/arrayFormatter';
import { LookalikeAudienceReport } from '../../../types/IAudience';
import { constantStringToHumanized } from '../../../utils/stringModifier';
import { DEFAULT_COLOR_THEME } from '../../../utils';

export const lookalikeAudienceReportGroupingColumn = (
  isMobile: boolean,
  expandedKeys: string[],
  setExpandedKeys: Dispatch<SetStateAction<string[]>>,
  rows: LookalikeAudienceReport[],
) => {
  const gridGroupColumn: DataGridPremiumProps['groupingColDef'] = {
    ...defaultProps(isMobile, 600),
    headerName: 'Campaign',
    headerClassName: `${styles.header}`,
    sortable: true,
    renderCell: (params) => {
      return (
        <CustomGridGroupColDef
          {...params}
          expandedKeys={expandedKeys}
          setExpandedKeys={setExpandedKeys}
          data={rows}
        />
      );
    },
  };

  return gridGroupColumn;
};

export const lookalikeAudienceReportColumns = () => {
  const columns: GridColDef[] = [
    {
      field: 'authorized',
      headerClassName: styles.header,
      headerName: 'Authorized',
      width: 100,
      renderCell: (params) => {
        const { isAuthorized, level } = params.row;

        const value = !isAuthorized ? 'No' : 'Yes';
        const isWithLevel = ['campaign', 'adset'].includes(level);

        return isWithLevel ? (
          <Tooltip
            title={<Typography variant="body2">{value}</Typography>}
            disableInteractive
          >
            <div
              className={`${styles.name} ${
                !isAuthorized ? `${styles['-error']}` : ''
              }`}
            >
              {value}
            </div>
          </Tooltip>
        ) : (
          <div className={styles.name} />
        );
      },
    },
    {
      field: 'level',
      headerClassName: styles.header,
      headerName: 'Level',
      width: 100,
      renderCell: (params) => {
        const { level } = params.row;

        return level && level !== 'unknown' ? (
          <Tooltip
            title={
              <Typography variant="body2">
                {constantStringToHumanized(level)}
              </Typography>
            }
            disableInteractive
          >
            <div className={styles.name}>
              {constantStringToHumanized(level)}
            </div>
          </Tooltip>
        ) : (
          <div className={styles.name} />
        );
      },
    },
    {
      field: 'lookalike',
      headerClassName: styles.header,
      headerName: 'Lookalike Audience',
      width: 400,
      groupingValueGetter: (_, row) => row.description,
      renderCell: (params) => {
        const { lookalikeAudienceName, level } = params.row;

        return level === 'adset' ? (
          <Tooltip
            title={
              <Typography variant="body2">{lookalikeAudienceName}</Typography>
            }
            disableInteractive
          >
            <div className={styles.name}>{lookalikeAudienceName}</div>
          </Tooltip>
        ) : (
          <div className={styles.name} />
        );
      },
    },
    {
      field: 'action',
      headerClassName: styles.header,
      headerName: 'Action',
      width: 100,
      renderCell: (params) => {
        const { level, isAuthorized, openConfirmStop, data, status, path } =
          params.row;

        return level === 'adset' &&
          !isAuthorized &&
          status === 'ACTIVE' &&
          path[0] !== 'Unknown Ad Accounts' ? (
          <IconButton
            onClick={() => openConfirmStop(data)}
            size="small"
            sx={{ fontSize: '13px', color: '#1976d2' }}
          >
            Stop
          </IconButton>
        ) : (
          <div className={styles.name} />
        );
      },
    },
  ];

  return columns;
};

type OwnerState = { classes: DataGridPremiumProps['classes'] };

const useUtilityClasses = (ownerState: OwnerState) => {
  const { classes } = ownerState;

  const slots = {
    root: ['treeDataGroupingCell'],
    toggle: ['treeDataGroupingCellToggle'],
  };

  return composeClasses(slots, getDataGridUtilityClass, classes);
};

export const CustomGridGroupColDef = (props: any) => {
  const {
    id,
    field,
    formattedValue,
    rowNode,
    offsetMultiplier = 2,
    row,
    expandedKeys,
    setExpandedKeys,
    data,
  } = props;

  const rootProps = useGridRootProps();
  const apiRef = useGridApiContext();
  const ownerState = { classes: rootProps.classes };
  const classes = useUtilityClasses(ownerState);
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  );

  const filteredDescendantCount =
    filteredDescendantCountLookup[rowNode.id] ?? 0;

  const Icon = (rowNode as any).childrenExpanded
    ? rootProps.slots.treeDataCollapseIcon
    : rootProps.slots.treeDataExpandIcon;

  const setCollapseKeys = () => {
    let groups: string[] = [...expandedKeys];

    const existingKey = groups.find(
      (key: any) => key === row.path[row.path.length - 1],
    );

    if (existingKey) {
      groups = groups.filter((key: string) => key !== existingKey);
    } else {
      (data || [])?.forEach((report: LookalikeAudienceReport) => {
        if (
          report.path.includes(row.path[0]) &&
          (row.level === 'agency' || row.level === 'unknown')
        ) {
          groups = [...groups, ...report.path];
        } else {
          groups = [...groups, row.path[row.path.length - 1]];
        }
      });
    }

    const filteredKeys = arrayUniqueFilter(groups);
    setExpandedKeys(filteredKeys);
  };

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    apiRef.current.setRowChildrenExpansion(
      id,
      !(rowNode as any).childrenExpanded,
    );
    apiRef.current.setCellFocus(id, field);
    setCollapseKeys();
    event.stopPropagation();
  };

  const value =
    formattedValue === undefined
      ? (rowNode as any).groupingKey
      : formattedValue;

  const removeLastParenthesisContent = (str: string): string => {
    return str.replace(/\s*\([^)]*\)\s*$/, '');
  };

  return (
    <Box className={classes.root} sx={{ ml: rowNode.depth * offsetMultiplier }}>
      <div className={classes.toggle}>
        {filteredDescendantCount > 0 && (
          <rootProps.slots.baseIconButton
            size="small"
            onClick={handleOnClick}
            tabIndex={-1}
            aria-label={
              (rowNode as any).childrenExpanded
                ? apiRef.current.getLocaleText('treeDataCollapse')
                : apiRef.current.getLocaleText('treeDataExpand')
            }
            {...rootProps?.slotProps?.baseIconButton}
          >
            <Icon fontSize="inherit" />
          </rootProps.slots.baseIconButton>
        )}
      </div>

      <div
        className={`${styles.name} ${
          (row?.status !== 'ACTIVE' &&
            ['adset', 'campaign'].includes(row?.level)) ||
          (!row?.isActive && ['brand', 'location'].includes(row?.level))
            ? styles['-error']
            : ''
        }`}
      >
        <Tooltip
          title={
            <Typography variant="body2">
              {removeLastParenthesisContent(value)}
            </Typography>
          }
          disableInteractive
        >
          {removeLastParenthesisContent(value) as any}
        </Tooltip>

        {formattedValue === 'Unknown Ad Accounts' || row?.level === 'agency' ? (
          <Badge
            badgeContent={rowNode?.children?.length || 0}
            color="secondary"
            sx={{
              '& .MuiBadge-badge': {
                right: -20,
                padding: '0 4px',
                backgroundColor: DEFAULT_COLOR_THEME,
                color: 'white',
                fontWeight: 'bold',
              },
              alignItems: 'center',
              display: 'flex',
            }}
          />
        ) : null}
      </div>
    </Box>
  );
};
