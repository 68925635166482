import { Box } from '@mui/system';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { Agency, InviteInfo } from '../../../types';
import { adminColumns, columns } from './AgencyListTableColumns';
import { useNavigate } from 'react-router-dom';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { useMediaQuery, useTheme } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { CustomLoadingOverlay } from '../../Loading';
import { AuthContext } from '../../../context';
import { IS_DIY_ADZ } from '../../../utils';

interface AgencyListTableProps {
  agencies: Agency[];
  handleOnClickEdit: (agency: Agency) => void;
  handleOnClickDelete: (id: string) => void;
  handleOnClickInviteBtn: (info: InviteInfo) => void;
  loading: boolean;
  handleBanAgency: (id: string) => void;
  handleUnbanAgency: (id: string) => void;
  rowData: number;
  isAdmin: boolean;
  onSort: (sortedField: string, sortType: string) => void;
  paginationModel: any;
  setPaginationModel: Dispatch<SetStateAction<any>>;
}

const AgencyListTable: React.FC<AgencyListTableProps> = ({
  agencies,
  handleOnClickEdit,
  handleOnClickDelete,
  handleOnClickInviteBtn,
  loading,
  handleBanAgency,
  handleUnbanAgency,
  rowData,
  isAdmin,
  onSort,
  paginationModel,
  setPaginationModel,
}) => {
  const { state } = useContext(AuthContext);
  const capabilities = state.capabilities;
  const navigate = useNavigate();
  const theme = useTheme();
  const isMdAndDown = useMediaQuery(theme.breakpoints.down('md'));

  const rows = agencies.slice().map((agency: Agency, index: number) => {
    return {
      agency: agency.agency,
      name: agency.name,
      email: agency.email,
      cell: agency.cell,
      url: agency.url,
      logo: agency.logo,
      user: agency.user,
      theme: {
        theme: agency.theme.theme,
        properties: { ...agency.theme.properties },
      },
      _id: agency._id,
      id: agency.agencyId,
      fbLoginEmails: agency.fbLoginEmails,
      page: {
        _id: agency.page?._id,
        name: agency.page?.name,
        accessToken: agency.page?.accessToken,
        fbPageId: agency.page?.fbPageId,
        imageUrl: agency.page?.imageUrl,
      },
      allowSetupPayment: agency.allowSetupPayment || false,
      handleOnClickEdit: handleOnClickEdit,
      handleOnClickDelete: handleOnClickDelete,
      handleOnClickInviteBtn: handleOnClickInviteBtn,
      navigate: navigate,
      handleBanAgency,
      handleUnbanAgency,
      withUser: !!agency.user,
    };
  });

  const renderColumns = () => {
    if (isAdmin) {
      return adminColumns(isMdAndDown);
    }

    return columns(isMdAndDown, capabilities.agencyAdmin, IS_DIY_ADZ);
  };

  return (
    <Box>
      <DataGridPremium
        className={styles.table}
        density="compact"
        rows={rows}
        columns={renderColumns()}
        hideFooterSelectedRowCount
        loading={loading}
        rowCount={rowData}
        onSortModelChange={(e: any) => {
          onSort(e[0]?.field || 'agency', e[0]?.sort || 'asc');
        }}
        paginationMode="server"
        sortingMode="server"
        pagination
        paginationModel={paginationModel}
        pageSizeOptions={[25, 50, 100]}
        onPaginationModelChange={setPaginationModel}
        slots={{ loadingOverlay: CustomLoadingOverlay }}
      />
    </Box>
  );
};

export default AgencyListTable;
