import React from 'react';

import { IoMdCloseCircle, IoMdCheckmarkCircle } from 'react-icons/io';

const CampaignCreationInstructions = () => {
  return (
    <div style={{ display: 'hidden' }}>
      <div style={styles.note}>
        <strong>Important:</strong> Make sure you have pre-created the necessary
        templates and locations.
      </div>
      <h1 style={{ color: `#096f4d` }}>Campaign Creation Instructions</h1>

      <h2 style={{ color: `#096f4d` }}>Pre-requisites</h2>
      <ul>
        <li style={styles.li}>
          <IoMdCheckmarkCircle size={30} color={`#096f4d`} display={`inline`} />
          Templates must be pre-created on the{' '}
          <a href="/templatez" style={styles.btn}>
            Templates Page
          </a>
          .
        </li>
        <li style={styles.li}>
          <IoMdCloseCircle size={30} color={`#ff0000`} display={`inline`} />{' '}
          Locations must be pre-created in the{' '}
          <a href="/assetz" style={styles.btn}>
            Assets Page
          </a>
          .
        </li>
      </ul>
    </div>
  );
};

const styles = {
  note: {
    backgroundColor: '#e6ffd4',
    padding: '10px',
    borderLeft: '4px solid #096f4d',
    marginBottom: '20px',
  },
  btn: {
    display: 'inline-block',
    padding: '2px 5px',
    backgroundColor: '#096f4d',
    color: '#fff',
    textDecoration: 'none',
    borderRadius: '5px',
    transition: 'background-color 0.3s ease',
  },
  li: {
    marginBottom: '10px',
    display: `flex`,
    alignItems: `center`,
    justifyContent: `start`,
  },
};

export default CampaignCreationInstructions;
