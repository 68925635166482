import React from 'react';
import { BudgetReport } from '../../../types';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { syncSuccessSummaryColumns } from './SyncSummaryTableColumns';

interface SyncSuccessSummaryTableProps {
  rows: BudgetReport[];
}

const SyncSuccessSummaryTable: React.FC<SyncSuccessSummaryTableProps> = ({
  rows,
}) => {
  return (
    <DataGridPremium
      rows={rows}
      columns={syncSuccessSummaryColumns}
      autoHeight
      density="compact"
      hideFooterSelectedRowCount
      className={styles.table}
    />
  );
};

export default SyncSuccessSummaryTable;
