import axios from 'axios';
import { Agency, ProfileFormValues } from '../types';
import { apiBaseUrl } from './api';

export const createAgency = async (obj: Agency) => {
  const data = await axios.post(`${apiBaseUrl}/agency/create`, obj);

  return data?.data;
};

export const editAgency = async (agencyId: string, obj: Agency) => {
  const data = await axios.put(`${apiBaseUrl}/agency/edit/${agencyId}`, obj);

  return data?.data;
};

export const editAgencyProfile = async (
  agencyId: string,
  obj: ProfileFormValues,
) => {
  const data = await axios.put(`${apiBaseUrl}/agency/edit/${agencyId}`, obj);

  return data?.data;
};

export const deleteAgency = async (agencyId: string) => {
  try {
    const data = await axios.delete(`${apiBaseUrl}/agency/remove/${agencyId}`);

    return data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAgencies = async (
  pageNum?: number,
  limit?: number,
  sortedField?: string,
  sortType?: string,
  fromSort?: boolean,
  diy?: boolean,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/agency/get${
      pageNum !== undefined && limit !== undefined
        ? `?pageNum=${pageNum}&limit=${limit}`
        : ''
    }${
      sortedField !== undefined
        ? `&sortedField=${sortedField}`
        : '&sortedField=agency'
    }${sortType !== undefined ? `&sortType=${sortType}` : '&sortType=asc'}${
      fromSort ? `&fromSort=${true}` : ''
    }${diy !== undefined ? `&diy=${diy}` : ''}`,
  );

  return data?.data.results;
};

export const getAgenciesAssociatedToPage = async (
  pageId: string,
  pageNum?: number,
  limit?: number,
  sortedField?: string,
  sortType?: string,
  fromSort?: boolean,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/agency/get?pageId=${pageId}${
      pageNum !== undefined && limit !== undefined
        ? `&pageNum=${pageNum}&limit=${limit}`
        : ''
    }${
      sortedField !== undefined
        ? `&sortedField=${sortedField}`
        : '&sortedField=agency'
    }${sortType !== undefined ? `&sortType=${sortType}` : '&sortType=asc'}${
      fromSort ? `&fromSort=${true}` : ''
    }`,
  );

  return data?.data.results;
};

export const getSingleAgency = async (id: string) => {
  const data = await axios.get(`${apiBaseUrl}/agency/get/${id}`);

  return data?.data;
};

export const getAgencyUsers = async (agencyId?: string) => {
  let params: any;

  if (agencyId) {
    params = { ...params, agencyId };
  }

  const data = await axios.get(`${apiBaseUrl}/agency/users`, { params });

  return data?.data;
};
