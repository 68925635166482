export const apiBaseUrl: string = process.env.REACT_APP_BASE_API;
export const webSocketUrl: string = process.env.REACT_APP_API_SERVER_URI;
export const fbPageId: string = process.env.REACT_APP_BASE_FB_PAGE_ID;
export const fbAppId: string = process.env.REACT_APP_FACEBOOK_APP_ID;
export const vapidPublicKey: string = process.env.REACT_APP_VAPID_PUBLIC_KEY;
export const defaultAccessToken: string =
  process.env.REACT_APP_DEFAULT_USER_ACCESS_TOKEN;
export const googleClientId: string = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const stripePublicKey: string = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const stripeSecretKey: string = process.env.REACT_APP_STRIPE_SECRET_KEY;
export const clientBaseUrl: string = process.env.REACT_APP_CLIENT_URL;
export const chatzAppUrl: string = process.env.REACT_APP_CHATZ_APP_URL;
export const diyAdzUrl: string = process.env.REACT_APP_DIY_ADZ_URL;
export const googleMapsApiKey: string =
  process.env.REACT_APP_GOOGLE_MAP_API_KEY;
export const googleMapId: string = process.env.REACT_APP_GOOGLE_MAP_ID;
export const topfireMediaId: string = process.env.REACT_APP_TOPFIRE_MEDIA_ID;
export const reactMuiLicenseKey: string = process.env.REACT_APP_MUI_LICENSE_KEY;
export const hubSpotOauthBaseUrl: string =
  process.env.REACT_APP_HUBSPOT_OAUTH_BASE_URL;
export const hubSpotClientId: string = process.env.REACT_APP_HUBSPOT_CLIENT_ID;
export const hubSpotScope: string = process.env.REACT_APP_HUBSPOT_SCOPE;
export const chatzAppId: string = process.env.REACT_APP_CHATZ_APP_ID;
