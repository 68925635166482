import React, { useContext, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import AgencyListTable from '../../components/Tables/AgencyList/AgencyListTable';
import { DeleteModal } from '../../components/Modal';
import { Agency, InvitationErrors, InviteInfo } from '../../types';
import { deleteAgency, getAgencies } from '../../services/agency';
import { toast } from 'react-toastify';
import InviteModal from '../../components/Modal/InviteModal';
import { inviteAgency } from '../../services/invitation';
import { ADMIN, IS_DIY_ADZ, SUPER_ADMIN } from '../../utils';
import { banUser, unbanUser } from '../../services/user';
import { AuthContext } from '../../context';
import { PrimaryButton } from '../../components/Buttons';
import { useNavigate } from 'react-router-dom';
import styles from '../../assets/styles/pages/Agencies.module.scss';
import PageHeader from '../../components/PageHeader';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../redux/actions';

const Agencies: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useContext(AuthContext);
  const isAdmin = state.role === ADMIN;
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const [agencies, setAgencies] = useState<Agency[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [banLoading, setBanLoading] = useState<boolean>(false);
  const [deleteloading, setDeleteLoading] = useState<boolean>(false);
  const [inviteloading, setInviteLoading] = useState<boolean>(false);
  const [agencyToDeleteId, setAgencyToDeleteId] = useState<string>('');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);
  const [rowData, setRowData] = useState<number>(0);
  const [invitationInfo, setInvitationInfo] = useState<InviteInfo>({
    name: '',
    email: '',
    id: '',
  });
  const [invitationErrors, setInvitationErrors] = useState<InvitationErrors>({
    email: '',
  });

  const [sortType, setSortType] = useState<string>('asc');
  const [sortedField, setSortedField] = useState<string>('agency');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  useEffect(() => {
    allAgencies(
      paginationModel.page + 1,
      paginationModel.pageSize,
      sortedField,
      sortType,
      false,
    );
  }, [paginationModel]);

  const allAgencies = async (
    pageNum: number,
    limit: number,
    sortedField: string,
    sortType: string,
    fromSort: boolean,
  ) => {
    try {
      setLoading(true);
      const response = await getAgencies(
        pageNum,
        limit,
        sortedField,
        sortType,
        fromSort,
        IS_DIY_ADZ,
      );
      setAgencies(response.data);
      setRowData(response.count);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  const handleCloseInviteModal = () => {
    setInvitationErrors({
      ...invitationErrors,
      email: '',
    });
    setOpenInviteModal(false);
  };

  const handleOnSort = async (sortedField: string, sortType: string) => {
    setSortedField(sortedField);
    setSortType(sortType);
    allAgencies(
      paginationModel.page + 1,
      paginationModel.pageSize,
      sortedField,
      sortType,
      true,
    );
  };

  const handleOnClickEdit = (agency: Agency) => {
    navigate(
      `/${IS_DIY_ADZ ? 'agenciez' : 'agencies'}/edit?agencyId=${agency._id}`,
    );
  };

  const handleOnClickInviteBtn = (info: InviteInfo) => {
    setOpenInviteModal(true);
    setInvitationInfo({
      ...invitationInfo,
      name: info.name,
      email: info.email,
      id: info.id,
    });
  };

  const handleInviteAgency = async (email: string) => {
    try {
      setInviteLoading(true);
      const response = await inviteAgency({
        email,
        clientUri: window.location.host,
        diy: IS_DIY_ADZ,
      });

      setOpenInviteModal(false);
      dispatch(
        toggleAlert({
          toggle: true,
          message: response?.message,
        }),
      );
    } catch (error: any) {
      const err = error.response.data;

      setInvitationErrors({
        ...invitationErrors,
        email: err.email,
      });
    } finally {
      setInviteLoading(false);
    }
  };

  const handleOnClickDelete = (id: string) => {
    setOpenDeleteModal(true);
    setAgencyToDeleteId(id);
  };

  const handleDeleteAgency = async () => {
    setDeleteLoading(true);
    try {
      const { id } = await deleteAgency(agencyToDeleteId);

      if (id) {
        setAgencies(agencies.filter((a) => a._id !== id));
        setOpenDeleteModal(false);
        setDeleteLoading(false);
        dispatch(
          toggleAlert({
            toggle: true,
            message: 'Agency deleted successfully',
          }),
        );
      }
    } catch (error: any) {
      console.log(error.message);
      setDeleteLoading(false);
    }
  };

  const handleBanAgency = async (id: string) => {
    setBanLoading(true);
    await banUser(id);
    let temp: Agency[] = [];
    agencies.forEach((a) => {
      if (a._id === id) a.user.banned = true;
      temp.push(a);
    });
    setAgencies(temp);
    toast.warning('Agency has been banned');
    setBanLoading(false);
  };

  const handleUnbanAgency = async (id: string) => {
    setBanLoading(true);
    await unbanUser(id);
    let temp: Agency[] = [];
    agencies.forEach((a) => {
      if (a._id === id) a.user.banned = false;
      temp.push(a);
    });
    setAgencies(temp);
    toast.warning('Agency has been unbanned');
    setBanLoading(false);
  };

  return (
    <div className={styles.agencies}>
      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader title={IS_DIY_ADZ ? 'Agenciez' : 'Agencies'} />

          {isSuperAdmin || isAdmin ? (
            <div className={styles.controls}>
              <PrimaryButton
                type="button"
                handleOnClick={() =>
                  navigate(`/${IS_DIY_ADZ ? 'agenciez' : 'agencies'}/create`)
                }
                title="New Agency"
                startIcon={<AddIcon />}
              />
            </div>
          ) : null}
        </div>

        <div>
          <AgencyListTable
            agencies={agencies}
            handleOnClickEdit={handleOnClickEdit}
            handleOnClickDelete={handleOnClickDelete}
            handleOnClickInviteBtn={handleOnClickInviteBtn}
            loading={loading}
            handleBanAgency={handleBanAgency}
            handleUnbanAgency={handleUnbanAgency}
            rowData={rowData}
            isAdmin={isAdmin || isSuperAdmin}
            onSort={handleOnSort}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        </div>
      </div>

      <DeleteModal
        title="Delete Agency"
        subtitle="Are you want to delete this agency, it will remove all data belongs to it permanently!"
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDeleteAgency}
        loading={deleteloading}
      />

      <InviteModal
        title="Invite as Agency User"
        subtitle={`Are you sure you want to invite ${invitationInfo.name} as agency?`}
        open={openInviteModal}
        handleClose={handleCloseInviteModal}
        invitationInfo={invitationInfo}
        handleInvite={handleInviteAgency}
        loading={inviteloading}
        errors={invitationErrors}
      />
    </div>
  );
};

export default Agencies;
