import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from '../../../assets/styles/components/Forms/Form.module.scss';
import { XsOnly } from '../../../utils/breakpoints';
import PageHeader from '../../PageHeader';
import {
  Brand,
  ChangeEventType,
  FBCarouselTemplate,
  FbAssetFeedSpec,
  FbCampaignAdForm,
  FbCampaignAdSetForm,
  FbCampaignForm,
  FbCreativeForm,
} from '../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton } from '../../Buttons';
import 'react-multi-carousel/lib/styles.css';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CarouselList from '../../List/CarouselList';
import {
  buildDiyCampaignObjectives,
  buildDiyCampaignType,
  buildDiySteps,
} from '../../../utils/helpers/DiyHelpers';
import LinearProgressBarWithLabel from '../../ProgressBar/LinearProgressBarWithLabel';
import DiyAudienceForm from './DiyAudienceForm';
import { AuthContext } from '../../../context';
import {
  ADMIN,
  CUSTOM_FIELD_REGEX,
  IS_PRODUCTION,
  SUPER_ADMIN,
} from '../../../utils';
import {
  createCampaignTemplate,
  fetchSingleCampaignTemplate,
  getSingleDiySetting,
  updateCampaignTemplate,
} from '../../../services/diy';
import { AdsTemplate, DiySettings, TemplateError } from '../../../types/IDiy';
import CreativeForm from '../Ads/CreativeForm';
import {
  CallToActionType,
  Objectives,
} from '../../../utils/constants/facebookAds';
import {
  FBBillingEvent,
  FBCampaignObjective,
  FBDestinationType,
  FBOptimizationGoal,
  FbAdStatus,
} from '../../../utils/helpers/facebookAdsHelper';
import { toggleAlert } from '../../../redux/actions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LinesEllipsis from 'react-lines-ellipsis';
import PopupModal from '../../Modal';
import { BusinessType } from '../../../utils/helpers/BrandHelpers';
import Spinner from '../../Spinner';
import CircularLoading from '../../CircularLoading';
import { DEFAULT_COLOR_THEME } from '../../../utils/Styling';
import { getImproveAdByTemplate } from '../../../services/ai';

const TemplateForm: React.FC = () => {
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const params = useParams();
  const templateId: string = params.templateId;
  const pathLocation = useLocation();
  const pathNames = pathLocation.pathname.split('/');
  const isEdit = pathNames.includes('edit');
  const isDuplicate = pathNames.includes('duplicate');

  const { state } = useContext(AuthContext);
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isAdmin = state.role === ADMIN;
  const [formValues, setFormValues] = useState<AdsTemplate>({
    name: '',
    description: '',
    archived: false,
    category:
      isEdit || isDuplicate
        ? ''
        : (brand?.__type === 'location' ? brand?.franchisor : brand)
            ?.businessType === BusinessType.FRANDEV
        ? 'Promotions'
        : '',
    objective:
      isEdit || isDuplicate
        ? ''
        : (brand?.__type === 'location' ? brand?.franchisor : brand)
            ?.businessType === BusinessType.FRANDEV
        ? 'Get More Leads'
        : '',
    media: '',
    targets: {
      targets: [],
      exclusions: [],
      flexible: [],
    },
    audienceType:
      isEdit || isDuplicate
        ? ''
        : (brand?.__type === 'location' ? brand?.franchisor : brand)
            ?.businessType === BusinessType.FRANDEV
        ? 'custom'
        : 'default',
    mediaEditable: false,
    urlLocalizable: true,
    leadAdTool:
      isEdit || isDuplicate
        ? ''
        : (brand?.__type === 'location' ? brand?.franchisor : brand)
            ?.businessType === BusinessType.FRANDEV
        ? 'instant_form'
        : '',
    aiAnalyze: {
      adCopy: '',
      analysis: '',
      grade: '',
      generatedAdCopy: '',
      archived: false,
      graded: false,
    },
  });
  const [campaignTemplate, setCampaignTemplate] = useState<FbCampaignForm>({
    name: '',
    objective:
      (brand?.__type === 'location' ? brand?.franchisor : brand)
        ?.businessType === BusinessType.FRANDEV
        ? FBCampaignObjective.Leads
        : '',
    status: !IS_PRODUCTION ? FbAdStatus.PAUSED : FbAdStatus.ACTIVE,
    special_ad_categories: [],
  });
  const [adSetTemplate, setAdSetTemplate] = useState<FbCampaignAdSetForm>({
    name: '',
    billing_event: FBBillingEvent.Impressions,
    status: !IS_PRODUCTION ? FbAdStatus.PAUSED : FbAdStatus.ACTIVE,
    targeting: {
      flexible_spec: [],
      genders: [1, 2],
      age_min:
        (brand?.__type === 'location' ? brand?.franchisor : brand)
          ?.businessType === BusinessType.FRANDEV
          ? 25
          : 18,
      age_max:
        (brand?.__type === 'location' ? brand?.franchisor : brand)
          ?.businessType === BusinessType.FRANDEV
          ? 59
          : 64,
    },
    optimization_goal:
      (brand?.__type === 'location' ? brand?.franchisor : brand)
        ?.businessType === BusinessType.FRANDEV
        ? FBOptimizationGoal.LeadGeneration
        : FBOptimizationGoal.Impressions,
    bid_strategy: 'LOWEST_COST_WITHOUT_CAP',
    destination_type: '',
  });
  const [adTemplate, setAdTemplate] = useState<FbCampaignAdForm>({
    name: '',
    status: !IS_PRODUCTION ? FbAdStatus.PAUSED : FbAdStatus.ACTIVE,
  });
  const [creativeTemplate, setCreativeTemplate] = useState<FbCreativeForm>({
    name: '',
    object_story_spec: {
      page_id: '',
    },
  });
  const [carouselData, setCarouselData] = useState<FBCarouselTemplate[]>([]);
  const [format, setFormat] = useState<string>(null);
  const [mediaType, setMediaType] = useState<string>(null);
  const [progress, setProgress] = useState<number>(0);
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const [steps, setSteps] = useState<string[]>([]);
  const [activeStep, setActiveStep] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [openCategoryDetails, setOpenCategoryDetails] =
    useState<boolean>(false);
  const [selectedDetails, setSelectedDetails] = useState<any>(null);
  const [mediaForm, setMediaForm] = useState<FbAssetFeedSpec>({
    bodies: [],
    call_to_action_types: [CallToActionType.LEARN_MORE],
    call_to_actions: [
      {
        type: CallToActionType.LEARN_MORE,
        adlabels: [{ name: 'cta_1' }],
      },
    ],
    titles: [],
    descriptions: [],
    link_urls: [],
    ad_formats: [],
    asset_customization_rules: [
      // SQUARE
      {
        customization_spec: {
          // TODO: Add IG
          publisher_platforms: ['facebook', 'instagram'],
          facebook_positions: ['feed', 'video_feeds', 'marketplace', 'search'],
          instagram_positions: [
            'stream',
            'ig_search',
            'profile_feed',
            'explore',
            'explore_home',
          ],
        },
      },
      // Vertical
      {
        customization_spec: {
          // TODO: Add IG
          publisher_platforms: [
            'facebook',
            'instagram',
            'messenger',
            'audience_network',
          ],
          facebook_positions: ['story', 'facebook_reels'],
          instagram_positions: ['reels', 'story'],
          messenger_positions: ['story'],
        },
      },
      {
        customization_spec: {
          publisher_platforms: ['facebook'],
          facebook_positions: ['right_hand_column'],
        },
      },
    ],
  });
  const [diySettings, setDiySettings] = useState<DiySettings>(null);
  const [templateError, setTemplateError] = useState<TemplateError>({
    customAudienceError: '',
    videosError: '',
    imagesError: '',
    mediaTypeError: '',
    headlineError: '',
    bodyError: '',
    leadFormError: '',
    nameError: '',
    descriptionError: '',
    websiteUrlError: '',
    ctaError: '',
  });
  const [showExclusions, setShowExclusions] = useState<boolean>(false);
  const [showNarrowAudience, setShowNarrowAudience] = useState<boolean>(false);
  const [enableAdvantageLookalike, setEnableAdvantageLookalike] =
    useState<boolean>(true);
  const [enableAdvantageDetailed, setEnableAdvantageDetailed] =
    useState<boolean>(true);
  const [reachError, setReachError] = useState<string>(null);

  const isEmployment =
    campaignTemplate?.special_ad_categories?.includes('EMPLOYMENT');

  useEffect(() => {
    if (brand) getDiySettings(brand);
  }, [brand]);

  useEffect(() => {
    if (templateId && (isEdit || isDuplicate)) {
      getSingleTemplate();
    }
  }, [templateId, isEdit, isDuplicate]);

  useEffect(() => {
    if (brand && diySettings) {
      setSteps(
        buildDiySteps(
          brand?.__type === 'location' ? brand?.franchisor : brand,
          formValues?.category,
        ),
      );
      setMediaForm({
        ...mediaForm,
        link_urls: [
          {
            website_url: isEmployment
              ? diySettings?.defaultEmploymentUrl
              : diySettings?.defaultUrl,
            display_url: isEmployment
              ? diySettings?.defaultEmploymentUrl
              : diySettings?.defaultUrl,
          },
        ],
      });
    }
  }, [formValues?.category, brand, diySettings]);

  useEffect(() => {
    if (steps.length > 0) {
      const step = steps.find((value: string, index: number) => {
        return index === activeStepIndex;
      });

      setActiveStep(step);
    }
  }, [steps, activeStepIndex]);

  useEffect(() => {
    if ((isEdit || isDuplicate) && formValues) {
      if (formValues?.media === 'carousel') {
        setFormat('carousel');
      } else {
        if (formValues?.media === 'image') {
          setFormat('media');
          setMediaType('image');
        } else {
          setFormat('media');
          setMediaType('video');
        }
      }
    }
  }, [isEdit, formValues, isDuplicate]);

  const getSingleTemplate = async () => {
    try {
      setLoading(true);

      let temp: AdsTemplate = { ...formValues };
      const response = await fetchSingleCampaignTemplate(templateId);

      temp = {
        ...temp,
        ...response.data,
        name: isDuplicate
          ? `Copy of ${response.data.name}`
          : response.data.name,
      };

      const improveAd = await getImproveAdByTemplate(
        brand?._id,
        response.data._id,
      );

      if (improveAd.data) {
        temp = {
          ...temp,
          aiAnalyze: {
            ...temp?.aiAnalyze,
            ...improveAd.data,
          },
        };
      }

      setFormValues(temp);
      setCampaignTemplate({ ...response.data.campaignTemplate });
      setAdSetTemplate({ ...response.data.adSetTemplate });
      setAdTemplate({ ...response.data.adTemplate });
      setMediaForm({ ...response.data.creativeTemplate.asset_feed_spec });
      setCreativeTemplate({ ...response.data.creativeTemplate });
      setCarouselData([
        ...(response.data.creativeTemplate?.object_story_spec?.link_data
          ?.child_attachments || []),
      ]);
      setEnableAdvantageLookalike(
        response.data.adSetTemplate?.targeting?.targeting_relaxation_types
          ?.lookalike === 1,
      );
      setEnableAdvantageDetailed(
        response.data.adSetTemplate?.targeting?.targeting_optimization ===
          'expansion_all',
      );
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getDiySettings = async (brand: Brand) => {
    try {
      setLoading(true);
      const response = await getSingleDiySetting(brand?._id);

      setDiySettings(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOnSubmit = async () => {
    if (!formValues?.name) {
      return setTemplateError({ nameError: 'Name is required' });
    } else {
      setTemplateError({ nameError: '' });
    }

    if (!formValues?.description) {
      return setTemplateError({ descriptionError: 'Description is required' });
    } else {
      setTemplateError({ descriptionError: '' });
    }

    setLoading(true);
    const progressPercentage = progress + (1 / steps.length) * 100;

    setProgress((prevProgress) =>
      progressPercentage >= 100 ? 100 : prevProgress + (1 / steps.length) * 100,
    );
    try {
      let assetFeedSpecTemp: FbAssetFeedSpec = { ...mediaForm };

      if (!assetFeedSpecTemp?.descriptions[0]?.text) {
        assetFeedSpecTemp = {
          ...assetFeedSpecTemp,
          descriptions: [{ text: ' ' }],
        };
      }

      assetFeedSpecTemp = {
        ...assetFeedSpecTemp,
        call_to_actions: [
          {
            ...assetFeedSpecTemp?.call_to_actions[0],
            value: {
              ...assetFeedSpecTemp?.call_to_actions[0]?.value,
              lead_gen_form_id:
                campaignTemplate?.objective === Objectives.OUTCOME_TRAFFIC
                  ? null
                  : assetFeedSpecTemp?.call_to_actions[0]?.value
                      ?.lead_gen_form_id,
              link:
                campaignTemplate?.objective === Objectives.OUTCOME_LEADS
                  ? null
                  : assetFeedSpecTemp?.call_to_actions[0]?.value?.link,
            },
          },
        ],
      };

      let tempCreativeTemplate: FbCreativeForm = {
        ...creativeTemplate,
        asset_feed_spec: { ...assetFeedSpecTemp },
        object_story_spec: {
          ...creativeTemplate.object_story_spec,
          page_id: diySettings?.facebookPage?.fbPageId,
        },
        contextual_multi_ads: {
          enroll_status: 'OPT_OUT',
        },
      };

      if (diySettings?.instagramAccount?.instagramId) {
        tempCreativeTemplate = {
          ...tempCreativeTemplate,
          object_story_spec: {
            ...tempCreativeTemplate.object_story_spec,
            instagram_actor_id: diySettings?.instagramAccount?.instagramId,
          },
        };
      }

      if (format === 'carousel') {
      } else if (format === 'media') {
        if (mediaType === 'image') {
        } else if (mediaType === 'video') {
        }
      }

      const matches =
        tempCreativeTemplate?.asset_feed_spec?.bodies[0]?.text.match(
          CUSTOM_FIELD_REGEX,
        );

      let customFields: any[] = [];

      if (matches?.length > 0) {
        matches.forEach((customField: string) => {
          const params: any = { field: customField, value: '' };

          customFields = [...customFields, params];
        });
      }

      let params: AdsTemplate = {
        ...formValues,
        ref: (brand?.__type === 'location' ? brand?.franchisor : brand)?._id,
        docModel: 'Brand',
        campaignTemplate,
        adSetTemplate,
        adTemplate,
        creativeTemplate: tempCreativeTemplate,
        media:
          format === 'carousel'
            ? 'carousel'
            : mediaType === 'image'
            ? 'image'
            : 'video',
        accountId: diySettings?.fbAdAccount?.accountId,
        _id: isDuplicate ? null : formValues?._id,
        customFields,
      };

      switch (formValues?.audienceType) {
        /**
         * Opt-in or Opt-out to Advantage Lookalike
         * @reference https://developers.facebook.com/docs/marketing-api/audiences/reference/targeting-expansion/advantage-lookalike
         */
        case 'custom':
          params = {
            ...params,
            adSetTemplate: {
              ...params?.adSetTemplate,
              targeting: {
                ...params?.adSetTemplate?.targeting,
                targeting_relaxation_types: {
                  lookalike: enableAdvantageLookalike ? 1 : 0,
                  custom_audience: 0,
                },
              },
            },
          };
          break;
        /* TODO: Reactive once the Advantage+ Audience is stable
       * https://developers.facebook.com/community/threads/922471492846116/
       * https://developers.facebook.com/docs/marketing-api/audiences/reference/targeting-expansion/advantage-audience
      case 'ai':
        params = {
          ...params,
          adSetTemplate: {
            ...params?.adSetTemplate,
            targeting: {
              ...params?.adSetTemplate?.targeting,
               targeting_automation?: {
                advantage_audience: 1,
              },
            },
          },
        };
        break;
        */
        /**
         * Opt-in or Opt-out to Advantage Detailed Targeting
         * @reference https://developers.facebook.com/docs/marketing-api/audiences/reference/targeting-expansion/advantage-detailed-targeting
         */
        default:
          params = {
            ...params,
            adSetTemplate: {
              ...params?.adSetTemplate,
              targeting: {
                ...params?.adSetTemplate?.targeting,
                custom_audiences: [],
                targeting_optimization:
                  campaignTemplate?.objective === Objectives.OUTCOME_LEADS &&
                  enableAdvantageDetailed
                    ? 'expansion_all'
                    : 'none',
              },
            },
          };
          break;
      }

      if (isEdit) {
        await updateCampaignTemplate(templateId, params);
      } else {
        await createCampaignTemplate(
          (brand?.__type === 'location' ? brand?.franchisor : brand)?._id,
          params,
        );
      }

      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Ads template created successfully',
        }),
      );
      navigate('/templatez');
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    if (
      activeStep === 'The Audience' &&
      formValues?.audienceType === 'custom' &&
      adSetTemplate?.targeting?.custom_audiences.length === 0
    ) {
      return setTemplateError({
        customAudienceError: 'Please select atleast 1 custom audience',
      });
    } else {
      setTemplateError({
        customAudienceError: '',
      });
    }

    if (activeStep && reachError) {
      return dispatch(
        toggleAlert({
          toggle: true,
          message: `There's an error with your targeted locations: ${reachError}`,
          type: 'error',
        }),
      );
    }

    if (activeStep === 'The Ad' && !mediaType) {
      return setTemplateError({
        mediaTypeError: 'Please select one of the media type.',
      });
    } else if (activeStep === 'The Ad' && mediaType === 'image') {
      if (!formValues?.images?.square || !formValues?.images?.vertical) {
        return setTemplateError({ imagesError: 'Please select an image.' });
      } else {
        setTemplateError({ imagesError: '' });
      }
    } else if (activeStep === 'The Ad' && mediaType === 'video') {
      if (!formValues?.videos?.square || !formValues?.videos?.vertical) {
        return setTemplateError({ videosError: 'Please select a video.' });
      } else {
        setTemplateError({ videosError: '' });
      }
    } else {
      setTemplateError({ mediaTypeError: '' });
    }

    if (activeStep === 'The Ad' && !mediaForm?.bodies[0]?.text) {
      return setTemplateError({ bodyError: 'Body is required' });
    } else {
      setTemplateError({ bodyError: '' });
    }

    if (activeStep === 'The Ad' && !mediaForm?.titles[0]?.text) {
      return setTemplateError({ headlineError: 'Headline is required' });
    } else {
      setTemplateError({ headlineError: '' });
    }

    if (
      activeStep === 'The Ad' &&
      campaignTemplate?.objective === FBCampaignObjective.Traffic &&
      !mediaForm?.link_urls[0]?.website_url
    ) {
      return setTemplateError({
        websiteUrlError: 'Website URL is required',
      });
    } else {
      setTemplateError({ websiteUrlError: '' });
    }

    if (
      activeStep === 'The Ad' &&
      campaignTemplate?.objective === FBCampaignObjective.Leads &&
      !mediaForm?.call_to_actions[0]?.value?.lead_gen_form_id
    ) {
      return setTemplateError({
        leadFormError: 'Please select one (1) lead form',
      });
    } else {
      setTemplateError({ leadFormError: '' });
    }

    if (
      activeStep === 'The Ad' &&
      !(mediaForm?.call_to_action_types?.length > 0)
    ) {
      return setTemplateError({
        ctaError: 'Please select one (1) CTA button type',
      });
    } else {
      setTemplateError({ ctaError: '' });
    }

    setActiveStepIndex((prev) => prev + 1);
    setProgress((prevProgress) => prevProgress + (1 / steps.length) * 100);
  };

  const handleBack = () => {
    setActiveStepIndex((prev) => prev - 1);
    setProgress((prevProgress) => prevProgress - (1 / steps.length) * 100);
  };

  const handleOpenCategoryDetails = (details: any) => {
    setSelectedDetails(details);
    setOpenCategoryDetails(true);
  };

  const handleCloseCategoryDetails = () => {
    setOpenCategoryDetails(false);
  };

  const buildPageheader = () => {
    let title: string = '';
    switch (activeStep) {
      case 'The Objective':
        title = 'PLEASE CHOOSE YOUR OBJECTIVE';
        break;
      case 'The Category':
        title = 'PLEASE CHOOSE YOUR CATEGORY';
        break;
      case 'The Audience':
        title = 'PLEASE CHOOSE YOUR AUDIENCE';
        break;
      case 'The Ad':
        title = 'SELECT THE CREATIVE FOR YOUR AD';
        break;
      case 'The Template':
        title = 'TEMPLATE DETAILS';
        break;
      default:
        break;
    }

    return title;
  };

  const handleSelectObjective = (objective: any) => {
    let temp: FbCampaignForm = {
      ...campaignTemplate,
      special_ad_categories: [],
      objective: objective.value,
    };
    let tempAdSets: FbCampaignAdSetForm = { ...adSetTemplate };

    let tempFormValues: AdsTemplate = {
      ...formValues,
      objective: objective.label,
      category: null,
      leadAdTool: null,
    };

    if (objective.label === 'Employment') {
      temp = {
        ...temp,
        special_ad_categories: [objective.label.toUpperCase()],
      };

      tempFormValues = {
        ...tempFormValues,
        category: objective.label,
      };
    }

    if (objective.value === Objectives.OUTCOME_LEADS) {
      tempFormValues = {
        ...tempFormValues,
        leadAdTool: 'instant_form',
      };
      tempAdSets = {
        ...tempAdSets,
        optimization_goal: FBOptimizationGoal.LeadGeneration,
      };

      setAdSetTemplate(tempAdSets);
    }

    setCampaignTemplate(temp);
    setFormValues(tempFormValues);
  };

  const buildStepsContent = (step: string) => {
    if (step === 'The Objective') {
      return (
        <CarouselList
          showDots
          arrows
          customLeftArrows={
            <ChevronLeftIcon
              sx={{
                position: 'absolute',
                zIndex: 10,
                left: 0,
                fontSize: '30px',
              }}
            />
          }
          customRightArrows={
            <ChevronRightIcon
              sx={{
                position: 'absolute',
                zIndex: 10,
                right: 0,
                fontSize: '30px',
              }}
            />
          }
        >
          {buildDiyCampaignObjectives(diySettings).map(
            (type: any, index: number) => {
              const selected = formValues?.objective === type.label;

              return (
                <Box
                  component="div"
                  sx={{ padding: '20px 20px 0 0', height: '420px' }}
                  key={`campaign-objective-${index + 1}`}
                >
                  {selected ? (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '8px',
                        zIndex: 1,
                        right: xsOnly ? '8px' : '0px',
                        float: 'right',
                        margin: '1px',
                      }}
                    >
                      <CheckCircleIcon
                        sx={{
                          color: DEFAULT_COLOR_THEME,
                          bgcolor: 'InfoBackground',
                          borderRadius: '50%',
                          fontSize: xsOnly ? '30px' : '40px',
                        }}
                      />
                    </Box>
                  ) : null}

                  <Card
                    key={`objective-${index + 1}`}
                    sx={{
                      border: selected ? 'solid' : '',
                      borderColor: selected ? DEFAULT_COLOR_THEME : '',
                      marginBottom: '30px',
                      marginLeft: '10px',
                      background:
                        'linear-gradient(180deg, #FFF 38.7%, #096F4D 113.65%)',
                      height: '100% !important',
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                  >
                    <CardActionArea onClick={() => handleSelectObjective(type)}>
                      <CardMedia
                        image={`${type.image}`}
                        component="img"
                        height="200px"
                        sx={{ objectFit: 'contain' }}
                      />

                      <CardContent
                        sx={{
                          paddingTop: 0,
                          height: '140px',
                        }}
                      >
                        <Typography
                          variant="h6"
                          component="div"
                          fontWeight="bold"
                          mb={1}
                          sx={{ color: '#FFF' }}
                        >
                          {type.label}
                        </Typography>

                        <Typography
                          variant="body1"
                          component="div"
                          sx={{
                            color: '#FFF',
                          }}
                        >
                          <LinesEllipsis
                            text={type.description}
                            maxLine="4"
                            trimRight
                          />
                        </Typography>
                      </CardContent>
                    </CardActionArea>

                    <CardActions>
                      <PrimaryButton
                        title="Read More"
                        type="button"
                        width="100%"
                        handleOnClick={() => handleOpenCategoryDetails(type)}
                      />
                    </CardActions>
                  </Card>
                </Box>
              );
            },
          )}
        </CarouselList>
      );
    }

    if (step === 'The Category') {
      return (
        <>
          <CarouselList
            showDots
            arrows
            customLeftArrows={
              <ChevronLeftIcon
                sx={{
                  position: 'absolute',
                  zIndex: 10,
                  left: 0,
                  fontSize: '30px',
                }}
              />
            }
            customRightArrows={
              <ChevronRightIcon
                sx={{
                  position: 'absolute',
                  zIndex: 10,
                  right: 0,
                  fontSize: '30px',
                }}
              />
            }
          >
            {buildDiyCampaignType(
              (brand?.__type === 'location' ? brand?.franchisor : brand)
                ?.industry,
            ).map((type: any, index: number) => {
              const selected = formValues?.category === type.label;

              return (
                <Box
                  sx={{ padding: '20px 20px 0 0', height: '420px' }}
                  key={`campaign-type-${index + 1}`}
                >
                  {selected ? (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '8px',
                        zIndex: 1,
                        right: xsOnly ? '8px' : '0px',
                        float: 'right',
                        margin: '1px',
                      }}
                    >
                      <CheckCircleIcon
                        sx={{
                          color: DEFAULT_COLOR_THEME,
                          bgcolor: 'InfoBackground',
                          borderRadius: '50%',
                          fontSize: xsOnly ? '30px' : '40px',
                        }}
                      />
                    </Box>
                  ) : null}

                  <Card
                    key={`type-${index + 1}`}
                    sx={{
                      border: selected ? 'solid' : '',
                      borderColor: selected ? DEFAULT_COLOR_THEME : '',
                      marginBottom: '30px',
                      marginLeft: '10px',
                      background:
                        'linear-gradient(180deg, #FFF 38.7%, #096F4D 113.65%)',
                      height: '100% !important',
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                  >
                    <CardActionArea
                      onClick={() => {
                        setFormValues({ ...formValues, category: type.label });
                      }}
                    >
                      <CardMedia
                        image={`${type.image}`}
                        component="img"
                        height="200px"
                        sx={{ objectFit: 'contain' }}
                      />

                      <CardContent
                        sx={{
                          paddingTop: 0,
                          height: '140px',
                        }}
                      >
                        <Typography
                          variant="h6"
                          component="div"
                          fontWeight="bold"
                          mb={1}
                          sx={{ color: '#FFF' }}
                        >
                          {type.label}
                        </Typography>

                        <Typography
                          variant="body1"
                          component="div"
                          sx={{
                            color: '#FFF',
                          }}
                        >
                          <LinesEllipsis
                            text={type.description}
                            maxLine="3"
                            trimRight
                          />
                        </Typography>
                      </CardContent>
                    </CardActionArea>

                    <CardActions>
                      <PrimaryButton
                        title="Read More"
                        type="button"
                        width="100%"
                        handleOnClick={() => handleOpenCategoryDetails(type)}
                      />
                    </CardActions>
                  </Card>
                </Box>
              );
            })}
          </CarouselList>
        </>
      );
    }

    if (step === 'The Audience') {
      return (
        <DiyAudienceForm
          adSetTemplate={adSetTemplate}
          brand={brand}
          setAdSetTemplate={setAdSetTemplate}
          accountId={diySettings?.fbAdAccount?.accountId}
          formValues={formValues}
          setFormValues={setFormValues}
          isEdit={isEdit}
          isDuplicate={isDuplicate}
          hasSpecialCategory={
            campaignTemplate?.special_ad_categories?.length > 0
          }
          diySettings={diySettings}
          setDiySettings={setDiySettings}
          error={templateError}
          showExclusions={showExclusions}
          setShowExclusions={setShowExclusions}
          showNarrowAudience={showNarrowAudience}
          setShowNarrowAudience={setShowNarrowAudience}
          setLoading={setLoading}
          isAdmin={isSuperAdmin || isAdmin}
          enabledAdvantageLookalike={enableAdvantageLookalike}
          setEnabledAdvantageLookalike={setEnableAdvantageLookalike}
          enabledAdvantageDetailed={enableAdvantageDetailed}
          setEnabledAdvantageDetailed={setEnableAdvantageDetailed}
          isLeads={campaignTemplate?.objective === Objectives.OUTCOME_LEADS}
          reachError={reachError}
          setReachError={setReachError}
          loading={loading}
        />
      );
    }

    if (step === 'The Ad') {
      return (
        <CreativeForm
          brand={brand?.__type === 'location' ? brand?.franchisor : brand}
          accountId={diySettings?.fbAdAccount?.accountId}
          isLead={campaignTemplate.objective === Objectives.OUTCOME_LEADS}
          isTraffic={campaignTemplate.objective === Objectives.OUTCOME_TRAFFIC}
          isMessenger={
            campaignTemplate.objective === Objectives.OUTCOME_ENGAGEMENT
          }
          setFormat={setFormat}
          format={format}
          objective={campaignTemplate.objective}
          carouselData={carouselData || []}
          setCarouselData={setCarouselData}
          conversionLocation={FBDestinationType.Undefined}
          mediaType={mediaType}
          setMediaType={setMediaType}
          assetFeedSpec={mediaForm}
          setAssetFeedSpec={setMediaForm}
          template={formValues}
          setTemplate={setFormValues}
          campaignTemplate={campaignTemplate}
          setCampaignTemplate={setCampaignTemplate}
          diySettings={diySettings}
          error={templateError}
          isEdit={isEdit || isDuplicate}
          isEmployment={isEmployment}
          isAdmin={isSuperAdmin || isAdmin}
        />
      );
    }

    if (activeStep === 'The Template') {
      return (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  value={formValues?.name}
                  name="name"
                  label="Template Name"
                  onChange={(e: ChangeEventType) => {
                    setFormValues({ ...formValues, name: e.target.value });
                  }}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  error={templateError?.nameError ? true : false}
                  helperText={
                    templateError?.nameError ? templateError?.nameError : ''
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  value={formValues?.description}
                  name="description"
                  label="Description"
                  multiline
                  onChange={(e: ChangeEventType) => {
                    setFormValues({
                      ...formValues,
                      description: e.target.value,
                    });
                  }}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  error={templateError?.descriptionError ? true : false}
                  helperText={
                    templateError?.descriptionError
                      ? templateError?.descriptionError
                      : ''
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sx={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Grid>
      </Grid>
    );
  };

  return (
    <Box className={`${styles.form} ${XsOnly() ? `${styles['-mobile']}` : ''}`}>
      <CircularLoading loading={loading} />

      <Box
        component="div"
        sx={{
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
          margin: 0,
          marginBottom: '20px',
        }}
      >
        <Box component="div" sx={{ paddingBottom: 0 }}>
          <PageHeader
            title={buildPageheader()}
            textAlign="left"
            marginBottom="0"
          />
        </Box>
      </Box>

      <Grid container spacing={1} sx={{ minHeight: 'calc(100vh - 222px)' }}>
        <Grid item xs={12}>
          {buildStepsContent(activeStep)}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        my={4}
        sx={{
          position: 'sticky',
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: '#FFF',
          paddingBottom: '20px',
          zIndex: 999,
          alignItems: 'flex-end',
        }}
      >
        <Grid item xs={6}>
          <LinearProgressBarWithLabel value={progress} />
        </Grid>

        {campaignTemplate?.objective ? (
          <Grid
            item
            sm={6}
            sx={{
              justifyContent: 'flex-end',
              display: 'flex',
            }}
          >
            {activeStepIndex > 0 ? (
              <PrimaryButton
                title="Back"
                type="button"
                handleOnClick={handleBack}
                marginRight5
                variant="text"
                color="red"
              />
            ) : null}

            {(activeStep === 'The Category' && formValues?.category) ||
            activeStep !== 'The Category' ? (
              <PrimaryButton
                loading={activeStepIndex + 1 === steps.length && loading}
                title={activeStepIndex + 1 === steps.length ? 'Finish' : 'Next'}
                type="button"
                handleOnClick={
                  activeStepIndex + 1 === steps.length
                    ? handleOnSubmit
                    : handleNext
                }
              />
            ) : null}
          </Grid>
        ) : null}
      </Grid>

      <PopupModal
        open={openCategoryDetails}
        handleClose={handleCloseCategoryDetails}
      >
        <Grid
          container
          spacing={2}
          sx={{ width: '280px', borderRadius: '10px' }}
        >
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Box
              component="img"
              src={selectedDetails?.image}
              alt=""
              sx={{ width: '200px', textAlign: 'center' }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="bold">
              {selectedDetails?.label}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              {selectedDetails?.description}
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ textAlign: 'center', position: 'sticky' }}>
            <PrimaryButton
              title="Select"
              type="button"
              handleOnClick={() => {
                if (activeStep === 'The Objective') {
                  setFormValues({
                    ...formValues,
                    objective: selectedDetails?.label,
                  });
                  setCampaignTemplate({
                    ...campaignTemplate,
                    objective: selectedDetails?.value,
                  });
                } else {
                  setFormValues({
                    ...formValues,
                    category: selectedDetails?.label,
                  });
                }

                handleCloseCategoryDetails();
              }}
            />
          </Grid>
        </Grid>
      </PopupModal>
    </Box>
  );
};

export default TemplateForm;
