import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  AdsTemplate,
  DiyError,
  DiySettings,
  MediaVariant,
} from '../../../types/IDiy';
import { FaRegImage, FaVideo } from 'react-icons/fa6';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  constantStringToHumanized,
  humanizeString,
} from '../../../utils/stringModifier';
import { PrimaryButton } from '../../Buttons';
import { AdVideo, Brand, ChangeEventType } from '../../../types';
import { FBCampaignObjective } from '../../../utils/helpers/facebookAdsHelper';
import { AdsPreviewSquare } from './AdsPreview';
import LeadGen from '../Ads/LeadGen';
import { fetchSingleFbAdAccountVideo } from '../../../services/ads';
import { DEFAULT_COLOR_THEME } from '../../../utils/Styling';

interface DiyAdPreviewProps {
  template: AdsTemplate;
  onOpenMediaForm: (mediaType?: string, edit?: boolean, ratio?: string) => void;
  onChange: (e: ChangeEventType) => void;
  onChangeCustomField: (e: ChangeEventType) => void;
  onChangeUrl: (e: ChangeEventType) => void;
  diySettings: DiySettings;
  loading: boolean;
  activeStep: string;
  objective: string;
  isAbTest?: boolean;
  brand: Brand;
  setTemplate: Dispatch<SetStateAction<AdsTemplate>>;
  errors?: DiyError;
  isEditAds?: boolean;
  isFormChanged?: boolean;
  isWebsiteChanged?: boolean;
}

const DiyAdPreview: React.FC<DiyAdPreviewProps> = ({
  template,
  onOpenMediaForm,
  onChange,
  onChangeCustomField,
  diySettings,
  onChangeUrl,
  loading,
  activeStep,
  objective,
  isAbTest = false,
  brand,
  setTemplate,
  errors,
  isEditAds = false,
  isFormChanged = false,
  isWebsiteChanged = false,
}) => {
  const [adVideo, setAdVideo] = useState<AdVideo>(null);

  useEffect(() => {
    if (template?.media === 'video') {
      getVideo();
    }
  }, [template?.media]);

  const getVideo = async () => {
    try {
      const response = await fetchSingleFbAdAccountVideo(
        brand?._id,
        template?.creativeTemplate?.asset_feed_spec?.videos[0]?.video_id,
      );

      setAdVideo(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={2}>
          {template?.mediaEditable ? (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {['image', 'video'].map((media: string, index: number) => {
                  const description =
                    media === 'image'
                      ? 'Single Image'
                      : media === 'video'
                      ? 'Single Video'
                      : '2 or more scrollable images or videos';
                  const logo =
                    media === 'image' ? (
                      <FaRegImage size={60} color="white" />
                    ) : (
                      <FaVideo size={60} color="white" />
                    );
                  const selected = template?.media === media;

                  return (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        lg={4}
                        key={`format-${index + 1}`}
                        sx={{
                          position: 'relative',
                        }}
                      >
                        {selected ? (
                          <Box
                            component="div"
                            sx={{
                              position: 'absolute',
                              top: '8px',
                              zIndex: 1,
                              right: '-10px',
                              float: 'right',
                              margin: '1px',
                            }}
                          >
                            <CheckCircleIcon
                              sx={{
                                color: '#84D1B7',
                                bgcolor: 'InfoBackground',
                                borderRadius: '50%',
                              }}
                            />
                          </Box>
                        ) : null}

                        <Card
                          sx={{
                            backgroundColor: DEFAULT_COLOR_THEME,
                            height: '100%',
                            cursor: 'pointer',
                            borderRadius: '8px',
                            border: selected ? 'solid' : '',
                            borderColor: selected ? '#84D1B7' : '',
                          }}
                          onClick={() => {
                            onOpenMediaForm(media, false, null);
                          }}
                        >
                          <CardHeader
                            sx={{
                              textAlign: 'center',
                              '.css-1ssile9-MuiCardHeader-avatar': {
                                height: '100%',
                              },
                            }}
                            avatar={<Box>{logo}</Box>}
                            title={
                              <Typography
                                variant="body2"
                                sx={{ color: '#FFF', fontWeight: 'bold' }}
                                component="div"
                              >
                                {humanizeString(media)}
                              </Typography>
                            }
                            subheader={
                              <Typography
                                variant="caption"
                                sx={{ color: '#FFF' }}
                                component="div"
                                mt={2}
                              >
                                {description}
                              </Typography>
                            }
                          />
                        </Card>
                      </Grid>
                    </>
                  );
                })}
              </Grid>

              <Grid container spacing={2} mt={2}>
                {template?.media === 'image'
                  ? template?.images
                    ? ['square', 'vertical'].map(
                        (ratio: string, index: number) => {
                          return (
                            <Grid item xs={12} key={`edit-media-${index + 1}`}>
                              <Card>
                                <CardContent>
                                  <Box
                                    component="div"
                                    sx={{
                                      justifyContent: 'space-between',
                                      display: 'flex',
                                      width: '100%',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Box
                                      component="div"
                                      sx={{
                                        flexDirection: 'row',
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Box
                                        component="img"
                                        src={
                                          template?.images[
                                            ratio as keyof MediaVariant
                                          ]
                                        }
                                        alt=""
                                        sx={{
                                          width: '50px',
                                          marginRight: '20px',
                                        }}
                                      />

                                      <Typography variant="body1">
                                        {humanizeString(ratio)}
                                      </Typography>
                                    </Box>

                                    <Box
                                      component="div"
                                      sx={{
                                        justifyContent: 'flex-end',
                                      }}
                                    >
                                      <PrimaryButton
                                        title="Edit Media"
                                        type="button"
                                        width="120px"
                                        handleOnClick={() =>
                                          onOpenMediaForm(
                                            template?.media,
                                            true,
                                            ratio,
                                          )
                                        }
                                      />
                                    </Box>
                                  </Box>
                                </CardContent>
                              </Card>
                            </Grid>
                          );
                        },
                      )
                    : null
                  : template?.videos
                  ? ['square', 'vertical'].map((ratio: string) => {
                      return (
                        <Grid item xs={12}>
                          <Card>
                            <CardContent sx={{ paddingBottom: 0 }}>
                              <Box
                                component="div"
                                sx={{
                                  justifyContent: 'space-between',
                                  display: 'flex',
                                  width: '100%',
                                  alignItems: 'center',
                                }}
                              >
                                <Box
                                  component="div"
                                  sx={{
                                    flexDirection: 'row',
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Box
                                    component="video"
                                    autoPlay
                                    loop
                                    src={
                                      template?.videos[
                                        ratio as keyof MediaVariant
                                      ]
                                    }
                                    sx={{
                                      width: '100px',
                                      marginRight: '20px',
                                      objectFit: 'contain',
                                    }}
                                  />

                                  <Typography variant="body1">
                                    {humanizeString(ratio)}
                                  </Typography>
                                </Box>

                                <Box
                                  component="div"
                                  sx={{
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <PrimaryButton
                                    title="Edit Media"
                                    type="button"
                                    width="120px"
                                    handleOnClick={() =>
                                      onOpenMediaForm(
                                        template?.media,
                                        true,
                                        ratio,
                                      )
                                    }
                                  />
                                </Box>
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                      );
                    })
                  : null}
                <Grid item xs={12} sm={6}></Grid>
              </Grid>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              size="small"
              value={
                template?.creativeTemplate?.asset_feed_spec?.bodies[0].text ||
                ''
              }
              name="body"
              onChange={onChange}
              InputLabelProps={{ shrink: true }}
              multiline
              label="Body"
              minRows={4}
              maxRows={100}
              disabled
            />
          </Grid>

          {template?.customFields?.length > 0 ? (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', color: DEFAULT_COLOR_THEME }}
                  >
                    Custom Fields
                  </Typography>
                </Grid>

                {template?.customFields?.map((custom: any, index: number) => {
                  return (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          required
                          variant="standard"
                          type="text"
                          id={`value-${index + 1}`}
                          name={custom.field}
                          label={custom?.field}
                          onChange={onChangeCustomField}
                          value={custom?.value}
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          autoFocus
                          //error={errors.brand ? true : false}
                          //helperText={errors?.brand ? errors?.brand : ''}
                        />
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Grid>
          ) : null}

          {!isEditAds || (isEditAds && isWebsiteChanged) ? (
            diySettings &&
            template &&
            !loading &&
            activeStep === 'Ad Preview' &&
            !(objective === FBCampaignObjective.Leads) ? (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  variant="standard"
                  type="text"
                  id="link"
                  name="link"
                  label="Website URL"
                  onChange={onChangeUrl}
                  value={
                    template.creativeTemplate.asset_feed_spec.link_urls[0]
                      .website_url
                  }
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  autoFocus
                  disabled={
                    !template?.urlLocalizable && brand?.__type === 'location'
                  }
                  //error={errors.brand ? true : false}
                  //helperText={errors?.brand ? errors?.brand : ''}
                />
              </Grid>
            ) : null
          ) : null}

          {!isEditAds || (isEditAds && isFormChanged) ? (
            objective === FBCampaignObjective.Leads ? (
              <Grid item xs={12}>
                <LeadGen
                  brand={brand}
                  accountId={diySettings?.fbAdAccount?.accountId}
                  assetFeedSpec={template?.creativeTemplate?.asset_feed_spec}
                  setSelectedTemplate={setTemplate}
                  isDiy
                  selectedTemplate={template}
                  diyError={errors}
                  isEditAds={isEditAds}
                  diySettings={diySettings}
                />
              </Grid>
            ) : null
          ) : null}
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        sx={{ justifyContent: 'center', width: '100%', display: 'flex' }}
      >
        <div>
          <AdsPreviewSquare
            page={diySettings?.facebookPage}
            body={template?.creativeTemplate?.asset_feed_spec?.bodies[0]?.text}
            isImage={template?.media === 'image'}
            imageUrl={template?.images?.square}
            websiteUrl={
              template?.creativeTemplate?.asset_feed_spec?.link_urls[0]
                ?.website_url
            }
            headline={
              template?.creativeTemplate?.asset_feed_spec?.titles[0]?.text
            }
            description={
              template?.creativeTemplate?.asset_feed_spec?.descriptions[0]?.text
            }
            cta={constantStringToHumanized(
              template?.creativeTemplate?.asset_feed_spec
                ?.call_to_action_types[0] || '',
            )}
            videoUrl={adVideo?.source}
            objective={template?.campaignTemplate?.objective}
            brand={brand}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default DiyAdPreview;
