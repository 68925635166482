/**
 * Number to Currency Converter
 * @description Convert any number into currency format
 * @example 2000 -> $2,000.00
 * @author Angelo David <angelod@codev.com>
 * @param currency <String> Currency code eg. USD -> US Dollars
 * @param value <Number> Value to convert
 * @param decimal <Number> Number of decimal points
 * @returns <String> Formatted number into currency
 */
export const toCurrency = (currency: string, value: number, decimal = 2) => {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  });

  return currencyFormatter.format(value);
};

/**
 * Number into Shortened Version Converter
 * @description Convert number into its shortened version
 * @example eg. 1000 -> 1K | 1200000 -> 1.2M
 * @author Angelo David <angelod@codev.com>
 * @param value <Number> Value to convert
 * @returns <String> Shortened version of the number
 */
export const numberShortener = (value: number) => {
  const formatter = Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumSignificantDigits: 3,
  });

  return formatter.format(value);
};

/**
 * Decimal Points Formatter
 * @description Assign decimal points to a number.
 *              Rounding off will apply automatically.
 * @example 1.2345 -> 1.23
 * @author Angelo David <angelod@codev.com>
 * @param value <Number> Value to convert
 * @param decimal <Number> Number of decimal points
 * @returns <Number> Number with designated decimal points
 */
export const decimalFormatter = (value: number, decimal = 0) => {
  return Number(value.toFixed(decimal));
};

/**
 * Number with Comma Formatter
 * @description Convert number with comma format
 * @example 2000 -> 2,000
 * @author Angelo David <angelod@codev.com>
 * @param value <Number> Value to convert
 * @param deciman <Number> Number of decimal points
 * @returns <String> Number with comma format
 */
export const numberWithCommaConverter = (value: number, decimal = 2) => {
  const numberFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimal,
  });

  return numberFormatter.format(value);
};
