import { Grid, List, ListItem, ListItemText, Modal } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { ChatUser } from '../../types';
import { messageBuilder } from '../../utils/chatDialogHelper';
import ModalHeader from './ModalHeader';
import styles from '../../assets/styles/components/Modal/CannedResponsesModal.module.scss';
import { DEFAULT_COLOR_THEME } from '../../utils';

interface CannedResponsesModalProps {
  open: boolean;
  handleCannedResponsesModal: (value: boolean) => void;
  chat: ChatUser;
  setMessage: (message: string) => void;
  message: string;
}

const CannedResponsesModal: React.FC<CannedResponsesModalProps> = ({
  open,
  handleCannedResponsesModal,
  chat,
  setMessage,
  message,
}) => {
  const noResponseTemplate = () => {
    return (
      <div>
        <p className={`${styles.label} ${styles['-center']}`}>
          No Canned Response
        </p>
      </div>
    );
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        handleCannedResponsesModal(false);
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: '300px',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '10px',
          },
          '&::-webkit-scrollbar-track': {
            // background: "#F1F0F0",
            borderTopRightRadius: '5px',
            borderBottomRightRadius: '5px',
          },

          '&::-webkit-scrollbar-thumb': {
            background: DEFAULT_COLOR_THEME,
            borderRadius: '18px',

            '&:hover': {
              background: DEFAULT_COLOR_THEME,
            },
          },
        }}
        className={styles.modal}
      >
        <div className={styles.base}>
          <ModalHeader title="Canned Responses" />

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{
              flexDirection: 'column',
            }}
          >
            <p className={styles.label}>Your Canned Responses</p>

            <List>
              {(chat?.brand?.cannedResponses || []).length === 0
                ? noResponseTemplate()
                : (chat?.brand?.cannedResponses || []).map(
                    (response: any, index: number) => {
                      const cannedResponse = messageBuilder(
                        response?.message,
                        chat,
                      );
                      return (
                        <ListItem
                          key={index}
                          onClick={() => {
                            setMessage(`${message} ${cannedResponse}`);
                          }}
                        >
                          <Grid container>
                            <Grid item xs={12}>
                              <ListItemText primary={cannedResponse} />
                            </Grid>
                          </Grid>
                        </ListItem>
                      );
                    },
                  )}
            </List>
          </Grid>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{
              flexDirection: 'column',
            }}
          >
            <p className={styles.label}>Agency's Responses</p>

            <List>
              {(chat?.brand?.agency?.cannedResponses || []).length === 0
                ? noResponseTemplate()
                : (chat?.brand?.agency?.cannedResponses || []).map(
                    (response: any, index: number) => {
                      const cannedResponse = messageBuilder(
                        response?.message,
                        chat,
                      );
                      return (
                        <ListItem
                          key={index}
                          onClick={() => {
                            setMessage(`${message} ${cannedResponse}`);
                          }}
                        >
                          <Grid container>
                            <Grid item xs={12}>
                              <ListItemText primary={cannedResponse} />
                            </Grid>
                          </Grid>
                        </ListItem>
                      );
                    },
                  )}
            </List>
          </Grid>
        </div>
      </Box>
    </Modal>
  );
};

export default CannedResponsesModal;
