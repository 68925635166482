import { Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { ChatUser } from '../../../types';
import styles from '../../../assets/styles/components/Forms/DesktopChatDialog.module.scss';
import { AccountCircle } from '@mui/icons-material';
import momentTz from 'moment-timezone';
import { DEFAULT_COLOR_THEME } from '../../../utils';

interface DesktopChatBoxProps {
  boxRef: any;
  onScroll: (e: any) => void;
  isAgency: boolean;
  isBrandManager: boolean;
  messages: any;
  chat: ChatUser;
  scrollRef: any;
  messageContent: (isOwnMessage: boolean, message: any) => void;
}

const DesktopChatBox: React.FC<DesktopChatBoxProps> = ({
  boxRef,
  onScroll,
  isAgency,
  isBrandManager,
  messages,
  chat,
  scrollRef,
  messageContent,
}) => {
  return (
    <List
      ref={boxRef}
      onScroll={onScroll}
      sx={{
        minHeight: `calc(100vh - ${
          !isAgency && !isBrandManager ? '415px' : '230px'
        })`,
        maxHeight: `calc(100vh - ${
          !isAgency && !isBrandManager ? '415px' : '230px'
        })`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
          width: '10px',
        },
        '&::-webkit-scrollbar-track': {
          // background: "#F1F0F0",
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
        },

        '&::-webkit-scrollbar-thumb': {
          background: DEFAULT_COLOR_THEME,
          borderRadius: '18px',

          '&:hover': {
            background: DEFAULT_COLOR_THEME,
          },
        },
      }}
    >
      {messages && Object.keys(messages).length !== 0 ? (
        Object.keys(messages).map((key) => (
          <div key={key}>
            <Typography
              component="p"
              sx={{
                width: 'fit-content',
                background: '#e8e8e0',
                color: '#484848',
                // mx: 1,
                py: 0.8,
                px: 3,
                margin: '12px auto',
                fontSize: '14px',
                borderRadius: '18px',
              }}
            >
              {/* TODO:need to check if is the date today then show: `Today` */}
              {key}
            </Typography>

            {messages[key].map((message: any, index: number) => {
              const isOwnMessage = message?.sender.id === chat?.page?.fbPageId;
              return (
                <ListItem key={index} ref={scrollRef}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        alignItems: 'right',
                      }}
                    >
                      <ListItemText
                        sx={{
                          textAlign: `${isOwnMessage ? 'right' : 'left'}`,
                        }}
                      >
                        <div
                          className={`${styles['message']} ${
                            isOwnMessage ? styles['-own-message'] : ''
                          }`}
                        >
                          {!isOwnMessage ? (
                            <>
                              {chat?.profilePic ? (
                                <img
                                  className={styles.profile}
                                  src={chat?.profilePic}
                                  alt=""
                                />
                              ) : (
                                <AccountCircle
                                  sx={{
                                    width: '40px',
                                    height: '40px',
                                    marginRight: '8px',
                                  }}
                                />
                              )}
                            </>
                          ) : null}

                          {messageContent(isOwnMessage, message?.message)}
                        </div>
                      </ListItemText>
                    </Grid>

                    <Grid item xs={12}>
                      <ListItemText
                        sx={{
                          textAlign: `${isOwnMessage ? 'right' : 'left'}`,
                        }}
                        secondaryTypographyProps={{
                          sx: { fontSize: '10px' },
                        }}
                        secondary={momentTz(message?.timestamp).format('LT')}
                      ></ListItemText>
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
          </div>
        ))
      ) : (
        <div className={`${styles['no-content']}`}>
          <p className={styles.text}>
            Select a conversation to start messaging
          </p>
        </div>
      )}
    </List>
  );
};

export default DesktopChatBox;
