import AdapterMoment from '@mui/lab/AdapterMoment';
import { Box, Grid, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Moment } from 'moment';
import React, { Fragment } from 'react';
import {
  ChangeEventType,
  StripeCompanyForm,
  StripeIndividualForm,
  StripeSubAccountForm,
} from '../../../../types';
import { stripeBusinessType } from '../../../../utils/constants/stripe';
import AccountActions from '../AccountActions';
import { MuiTelInput } from 'mui-tel-input';
import { HtmlTooltip } from '../../../Tooltip';
import InfoIcon from '@mui/icons-material/Info';

interface StripePersonalDetailsFormProps {
  formValues: StripeSubAccountForm;
  companyForm: StripeCompanyForm;
  individualForm: StripeIndividualForm;
  onChangeIndividual: (e: ChangeEventType) => void;
  onChangeBirthdate: (date: Moment | null) => void;
  onChangeCompany: (e: ChangeEventType) => void;
  onChangeForm: (e: ChangeEventType) => void;
  onNext: () => void;
  activeStep: number;
  onBack: () => void;
  steps: string[];
  onChangeNumber: (newValue: string) => void;
  onClose: (account?: any) => void;
}

const StripePersonalDetailsForm: React.FC<StripePersonalDetailsFormProps> = ({
  formValues,
  companyForm,
  individualForm,
  onChangeIndividual,
  onChangeBirthdate,
  onChangeCompany,
  onChangeForm,
  onNext,
  activeStep,
  onBack,
  steps,
  onChangeNumber,
  onClose,
}) => {
  return (
    <Box component="form" onSubmit={onNext} autoComplete="off">
      {formValues?.business_type === stripeBusinessType.INDIVIDUAL ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              variant="standard"
              type="text"
              id="firstName"
              name="first_name"
              label="First Name"
              onChange={onChangeIndividual}
              value={individualForm?.first_name}
              InputLabelProps={{ shrink: true }}
              size="small"
              autoFocus
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              variant="standard"
              type="text"
              id="lastName"
              name="last_name"
              label="Last Name"
              onChange={onChangeIndividual}
              value={individualForm?.last_name}
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              variant="standard"
              type="text"
              id="email"
              name="email"
              label="Email"
              onChange={(e: any) => {
                onChangeIndividual(e);
                onChangeForm(e);
              }}
              value={individualForm?.email}
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              variant="standard"
              type="text"
              id="phone"
              name="phone"
              label="Phone Number"
              onChange={onChangeIndividual}
              value={individualForm?.phone}
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Grid>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Grid item xs={12} sm={6}>
              <DesktopDatePicker
                label="Birthdate"
                inputFormat="YYYY-MM-DD"
                value={individualForm?.birthdate}
                onChange={onChangeBirthdate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    variant="standard"
                  />
                )}
              />
            </Grid>
          </LocalizationProvider>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              variant="standard"
              type="text"
              id="ssn"
              name="ssn_last_4"
              label="Last 4 Digits of SSN"
              onChange={onChangeIndividual}
              value={individualForm?.ssn_last_4}
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Grid>
        </Grid>
      ) : null}

      {formValues.business_type === stripeBusinessType.COMPANY ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              variant="standard"
              type="text"
              id="name"
              name="name"
              label="Company's Legal Name"
              onChange={onChangeCompany}
              value={companyForm?.name}
              InputLabelProps={{ shrink: true }}
              size="small"
              autoFocus
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <MuiTelInput
              fullWidth
              required
              variant="standard"
              id="phone"
              name="phone"
              label="Phone Number"
              onChange={onChangeNumber}
              value={companyForm?.phone}
              InputLabelProps={{ shrink: true }}
              size="small"
              onlyCountries={['US']}
              defaultCountry="US"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="standard"
              type="text"
              id="registrationNumber"
              name="registration_number"
              label={
                <Fragment>
                  <Box
                    component="div"
                    sx={{
                      lineHeight: '1.4375em',
                      padding: 0,
                      top: 0,
                      display: 'flex',
                      maxWidth: '133%',
                    }}
                  >
                    <Box component="span" sx={{ marginRight: '5px' }}>
                      Registration Number
                    </Box>

                    <HtmlTooltip
                      disableInteractive
                      title={
                        <Fragment>
                          The identification number given to a company when it
                          is registered or incorporated, if distinct from the
                          identification number used for filing taxes. (Examples
                          are the CIN for companies and LLP IN for partnerships
                          in India, and the Company Registration Number in Hong
                          Kong).
                        </Fragment>
                      }
                    >
                      <InfoIcon />
                    </HtmlTooltip>
                  </Box>
                </Fragment>
              }
              onChange={onChangeCompany}
              value={companyForm?.registration_number}
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="standard"
              type="text"
              id="taxId"
              name="tax_id"
              label="Tax ID / Employer ID"
              onChange={onChangeCompany}
              value={companyForm?.tax_id}
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              variant="standard"
              type="email"
              id="email"
              name="email"
              label="Email"
              onChange={onChangeForm}
              value={formValues?.email}
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Grid>
        </Grid>
      ) : null}

      <AccountActions
        activeStep={activeStep}
        onBack={onBack}
        steps={steps}
        marginTop="20px"
        onCancel={onClose}
      />
    </Box>
  );
};

export default StripePersonalDetailsForm;
