import { startRange, endRange, brandTimezone } from './BrandReportDate';
import {
  brands,
  brand,
  brandFilter,
  locations,
  location,
  showAllBrands,
  demoMode,
} from './Brands';
import { campaignProvider, budgetReportProvider } from './Campaigns';
import { toggleAlert } from './Alert';
import { combineReducers } from 'redux';
import { agencies, agency } from './Agencies';

const reducers = combineReducers({
  startRange,
  endRange,
  brandTimezone,
  brands,
  brand,
  toggleAlert,
  campaignProvider,
  agencies,
  agency,
  brandFilter,
  budgetReportProvider,
  location,
  locations,
  showAllBrands,
  demoMode,
});

export default reducers;
