import { Dispatch, SetStateAction } from 'react';
import { fetchAdminData } from '../../services/admin';
import { IS_DIY_ADZ } from '../constants';

export const buildAccessToken = async (
  accessToken: string,
  setDefaultAccessToken: Dispatch<SetStateAction<string>>,
) => {
  try {
    /**
     * TODO: Remove if Role Based Facebook Access Token is implemented
     */
    if (IS_DIY_ADZ) {
      const response = await fetchAdminData();

      setDefaultAccessToken(response?.data.defaultAccessToken);
    } else {
      if (accessToken) {
        setDefaultAccessToken(accessToken);
      } else {
        const response = await fetchAdminData();

        setDefaultAccessToken(response?.data.defaultAccessToken);
      }
    }
  } catch (error: any) {
    console.log(error.message);
  }
};

export const buildRefreshToken = async (
  refreshToken: string,
  setDefaultRefreshToken: Dispatch<SetStateAction<string>>,
) => {
  try {
    if (refreshToken) {
      setDefaultRefreshToken(refreshToken);
    } else {
      const response = await fetchAdminData();

      setDefaultRefreshToken(response?.data.defaultRefreshToken);
    }
  } catch (error: any) {
    console.log(error.message);
  }
};
