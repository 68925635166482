import momentTz from 'moment-timezone';
import { orderArrayOfObject } from './arrayFormatter';
import { Timezone } from '../types';

/**
 * List of Timezones
 * @description List all timezone names using moment-timezone library
 *              then get the offset in hour. Return an array of timezone object
 *              containing it's name and UTC offset in hours.
 * @author Angelo David <angelod@codev.com>
 * @references https://momentjs.com/timezone/docs/#/using-timezones/getting-zone-names/
 * @returns <Array> List of timezone names with UTC offset.
 */
export const getTimezones = (): Timezone[] => {
  const timezones = momentTz.tz.names();

  const buildedTimezones = timezones.map((timezone: string) => {
    const offset = momentTz.tz(timezone).utcOffset() / 60;

    return {
      name: timezone,
      offset,
    };
  });

  return orderArrayOfObject(buildedTimezones, 'offset');
};

/**
 * UTC Offset Hour Label Formatter
 * @description Convert UTC offset hour into string format.
 * @example 0 -> (+00:00) | -4 -> (-04:00) | +8 -> (+08:00)
 * @author Angelo David <angelod@codev.com>
 * @param offset <Number> UTC offset hour
 * @returns <String> Converted UTC offset in hour
 */
export const getOffsetLabel = (offset: number) => {
  if (offset < 0) {
    return `(-${convertHoursToTime(Math.abs(offset))})`;
  }

  return `(+${convertHoursToTime(offset)})`;
};

/**
 * UTC Offset to String Time Formatter
 * @description Convert UTC offset hour into string time format.
 * @example 0 -> 00:00 | -4 -> 04:00 | 8 -> 08:00
 * @author Angelo David <angelod@codev.com>
 * @param hours <Number> UTC offset hour
 * @returns <String> Converted UTC offset in string hour format
 */
export const convertHoursToTime = (hours: number): string => {
  const h = Math.floor(hours);
  const m = Math.round((hours - h) * 60);

  return `${h < 10 ? `0${h}` : h}:${m.toString().padStart(2, '0')}`;
};
