import { Grid, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Moment } from 'moment-timezone';
import React, { Dispatch, Fragment, SetStateAction } from 'react';
import {
  ChangeEventType,
  StripeCompanyForm,
  StripeIndividualForm,
  StripeSubAccountForm,
} from '../../../../types';
import { stripeBusinessTypeSelection } from '../../../../utils/constants/stripe';
import StripeAddressForm from './StripeAddressForm';
import StripeBankAccountForm from './StripeBankAccountForm';
import StripePersonalDetailsForm from './StripePersonalDetailsForm';
import BasicSelectMenu from '../../../Select/BasicSelectMenu';
import { PrimaryButton } from '../../../Buttons';
import CircularLoading from '../../../CircularLoading';
import logo from '../../../../assets/images/onboarding-success.svg';

interface PaymentOnboardingStepsProps {
  formValues: StripeSubAccountForm;
  companyForm: StripeCompanyForm;
  individualForm: StripeIndividualForm;
  onChangeForm: (e: ChangeEventType) => void;
  onChangeIndividual: (e: ChangeEventType) => void;
  onChangeBirthdate: (date: Moment | null) => void;
  onChangeCompany: (e: ChangeEventType) => void;
  onCreate: React.FormEventHandler<HTMLFormElement> | undefined;
  loading: boolean;
  generateLink: () => void;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  onChangeNumber: (newValue: string) => void;
  onCloseForm: (account?: string) => void;
  account: any;
  onChangeBankType: (value: string) => void;
}

const PaymentOnboardingSteps: React.FC<PaymentOnboardingStepsProps> = ({
  formValues,
  companyForm,
  individualForm,
  onChangeForm,
  onChangeIndividual,
  onChangeBirthdate,
  onChangeCompany,
  onCreate,
  loading,
  generateLink,
  activeStep,
  setActiveStep,
  onChangeNumber,
  onCloseForm,
  account,
  onChangeBankType,
}) => {
  const steps = [
    'Personal Details',
    'Billing Address',
    //'Business Support Details',
    'Bank Account Details',
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderSuccessForm = () => {
    return (
      <Grid
        container
        spacing={1}
        sx={{
          diplay: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 0,
          marginTop: '30px',
        }}
      >
        <Grid item xs={12} md={6}>
          <Box component="img" src={logo} alt="" />
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight="bold" color="#096F4D">
                You successfully created a Deposit account
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" color="#096F4D">
                You can start the onboarding process by clicking the button
                below.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <PrimaryButton
                title="Start the Onboarding Process"
                type="button"
                handleOnClick={generateLink}
                rounded
                marginRight5
              />

              <PrimaryButton
                variant="text"
                title="Skip Form Now"
                type="button"
                handleOnClick={() => onCloseForm(account)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderForm = () => {
    if (activeStep === 0) {
      return (
        <StripePersonalDetailsForm
          formValues={formValues}
          companyForm={companyForm}
          individualForm={individualForm}
          onChangeIndividual={onChangeIndividual}
          onChangeBirthdate={onChangeBirthdate}
          onChangeCompany={onChangeCompany}
          onChangeForm={onChangeForm}
          onNext={handleNext}
          onBack={handleBack}
          activeStep={activeStep}
          steps={steps}
          onChangeNumber={onChangeNumber}
          onClose={onCloseForm}
        />
      );
    }

    if (activeStep === 1) {
      return (
        <StripeAddressForm
          companyForm={companyForm}
          individualForm={individualForm}
          onChangeCompany={onChangeCompany}
          onChangeIndividual={onChangeIndividual}
          businessType={formValues?.business_type}
          onNext={handleNext}
          onBack={handleBack}
          activeStep={activeStep}
          steps={steps}
          onClose={onCloseForm}
        />
      );
    }

    if (activeStep === 2) {
      return (
        <StripeBankAccountForm
          onChange={onChangeForm}
          formValues={formValues}
          onSubmit={onCreate}
          onBack={handleBack}
          activeStep={activeStep}
          steps={steps}
          onClose={onCloseForm}
          onChangeBankType={onChangeBankType}
        />
      );
    }
  };

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <CircularLoading loading={loading} />

        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {};

            const labelProps: {
              optional?: React.ReactNode;
            } = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {activeStep === steps.length ? (
          <Fragment>{renderSuccessForm()}</Fragment>
        ) : (
          <Fragment>
            <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2 }}>
              <Box component="div" sx={{ marginBottom: '20px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    {activeStep === 0 ? (
                      <Box component="div" sx={{ flexDirection: 'column' }}>
                        <div>
                          <Typography
                            variant="body1"
                            sx={{ marginBottom: '10px', color: 'grey' }}
                          >
                            Select your business type
                          </Typography>
                        </div>

                        <div>
                          <BasicSelectMenu
                            label="Business Type"
                            id="businessType"
                            name="business_type"
                            value={formValues.business_type}
                            data={stripeBusinessTypeSelection}
                            onChange={onChangeForm}
                            hasMarginRight5
                            fullWidth
                            disabled
                          />
                        </div>
                      </Box>
                    ) : null}

                    <Grid item xs={12} sm={4}></Grid>
                    <Grid item xs={12} sm={3}></Grid>
                  </Grid>
                </Grid>
              </Box>

              {renderForm()}
            </Box>
          </Fragment>
        )}
      </Box>
    </div>
  );
};

export default PaymentOnboardingSteps;
