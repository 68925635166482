import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { PrimaryButton } from '../../../Buttons';
import { CrmSetting } from '../../../../types/ICrm';
import {
  ActionPlan,
  Brand,
  ChangeEventType,
  LeadSource,
} from '../../../../types';
import {
  fetchActionPlans,
  fetchLeadSource,
  syncClientTetherClients,
} from '../../../../services/clientTether';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions';
import { errorMessageParser } from '../../../../utils/helpers/ToastHelper';
import { MdSync } from 'react-icons/md';
import { ScrollStyle } from '../../../../utils';
import { HtmlTooltip } from '../../../Tooltip';
import { constantStringToHumanized } from '../../../../utils/stringModifier';
import PopupModal from '../../../Modal';
import ClientTetherSalesCycleForm from './ClientTetherSalesCycleForm';

interface ClientTetherCrmFormProps {
  formValues: CrmSetting;
  setFormValues: Dispatch<SetStateAction<CrmSetting>>;
  setCrmSettings: Dispatch<SetStateAction<CrmSetting>>;
  brand: Brand;
  register: any;
  clearErrors: any;
  errors: any;
}

const ClientTetherCrmForm: React.FC<ClientTetherCrmFormProps> = ({
  formValues,
  setFormValues,
  setCrmSettings,
  brand,
  register,
  clearErrors,
  errors,
}) => {
  const dispatch = useDispatch();
  const [syncLoading, setSyncLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [leadSources, setLeadSources] = useState<LeadSource[]>([]);
  const [actionPlans, setActionPlans] = useState<ActionPlan[]>([]);
  const [showCTSalesCycleForm, setShowCTSalesCycleForm] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      formValues?.clientTetherSettings?.accessToken &&
      formValues?.clientTetherSettings?.webKey
    ) {
      getLeadSource();
      getActionPlans();
    }
  }, [
    formValues?.clientTetherSettings?.accessToken,
    formValues?.clientTetherSettings?.webKey,
  ]);

  const getLeadSource = async () => {
    try {
      setFetchLoading(true);

      const response = await fetchLeadSource(
        formValues?.clientTetherSettings?.accessToken,
        formValues?.clientTetherSettings?.webKey,
      );

      setLeadSources(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const getActionPlans = async () => {
    try {
      setFetchLoading(true);

      const response = await fetchActionPlans(
        formValues?.clientTetherSettings?.accessToken,
        formValues?.clientTetherSettings?.webKey,
      );

      setActionPlans(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const handleOnSyncClientTetherClients = async () => {
    try {
      setSyncLoading(true);

      const response = await syncClientTetherClients(
        brand?._id,
        formValues?.clientTetherSettings?.accessToken,
        formValues?.clientTetherSettings?.webKey,
      );

      setFormValues({
        ...formValues,
        clientTetherSettings: {
          ...formValues?.clientTetherSettings,
          enableSyncLeads: false,
        },
      });

      dispatch(
        toggleAlert({
          toggle: true,
          message: `Synced ${response.data.length} leads successfully`,
        }),
      );
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setSyncLoading(false);
    }
  };

  const handleOnChangeCT = (e: ChangeEventType) => {
    clearErrors(e.target.name);
    setFormValues({
      ...formValues,
      clientTetherSettings: {
        ...formValues?.clientTetherSettings,
        [e.target.name]: e.target.value,
        leadSourceId: null,
        actionPlanId: null,
      },
    });
  };

  const handleShowCtSalesCycleForm = () => {
    setShowCTSalesCycleForm((prev) => !prev);
  };

  return (
    <Grid item xs={6}>
      <Grid container spacing={2}>
        {(formValues?.clientTetherSettings?.enableSyncLeads || true) &&
        formValues?.clientTetherSettings?.accessToken &&
        formValues?.clientTetherSettings?.webKey ? (
          <Grid item xs={12}>
            <PrimaryButton
              title="Sync Leads"
              type="button"
              handleOnClick={handleOnSyncClientTetherClients}
              loading={syncLoading}
              startIcon={<MdSync />}
            />
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <TextField
            {...register('accessToken', { required: true })}
            autoFocus
            fullWidth
            variant="standard"
            type="text"
            id="clientTetherAccessToken"
            name="accessToken"
            label="Access Token"
            onChange={(e: ChangeEventType) => {
              handleOnChangeCT(e);
            }}
            value={formValues?.clientTetherSettings?.accessToken}
            placeholder="Access Token"
            InputLabelProps={{ shrink: true }}
            size="small"
            multiline
            error={!!errors?.accessToken}
            helperText={errors?.accessToken && 'Access token is required'}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            {...register('webKey', { required: true })}
            fullWidth
            variant="standard"
            type="text"
            id="clientTetherWebkey"
            name="webKey"
            label="Web Key"
            onChange={(e: ChangeEventType) => {
              handleOnChangeCT(e);
            }}
            value={formValues?.clientTetherSettings?.webKey}
            placeholder="Web Key"
            InputLabelProps={{ shrink: true }}
            size="small"
            multiline
            error={!!errors?.webKey}
            helperText={errors?.webKey && 'Web key is required'}
          />
        </Grid>

        <Grid item xs={12}>
          <HtmlTooltip
            disableInteractive
            title={
              <Fragment>
                The X-Access-Token is only available on the API tab in Settings
                at the Enterprise Level. The X-Web-Key is available in each
                Account and by an API call for Subaccounts.
              </Fragment>
            }
          >
            <Typography
              variant="caption"
              sx={{
                textDecoration: 'underline',
                color: 'blue',
                cursor: 'pointer',
              }}
            >
              Where to find your access token and web key?
            </Typography>
          </HtmlTooltip>
        </Grid>

        {formValues?.clientTetherSettings?.accessToken &&
        formValues?.clientTetherSettings?.webKey ? (
          <Grid item xs={12}>
            <Autocomplete
              ListboxProps={{
                sx: ScrollStyle(),
              }}
              id="leadSources"
              renderOption={(props, option) => {
                return (
                  <Box
                    component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                    key={option.lead_source_id}
                  >
                    <Box component="div" sx={{ flexDirection: 'column' }}>
                      <Typography variant="body1">
                        {option.lead_source_name}
                      </Typography>

                      <Typography variant="caption">
                        {option.lead_source_id}
                      </Typography>
                    </Box>
                  </Box>
                );
              }}
              options={leadSources}
              value={
                leadSources?.find(
                  (source: LeadSource) =>
                    source.lead_source_id ===
                    formValues?.clientTetherSettings?.leadSourceId,
                ) || null
              }
              getOptionLabel={(option) => option.lead_source_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Lead Sources"
                  placeholder="Select your lead source"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {fetchLoading ? (
                          <CircularProgress
                            size={20}
                            sx={{ marginRight: '10px' }}
                          />
                        ) : null}
                      </>
                    ),
                  }}
                />
              )}
              onChange={(_, value: LeadSource) => {
                setFormValues({
                  ...formValues,
                  clientTetherSettings: {
                    ...formValues?.clientTetherSettings,
                    leadSourceId: value.lead_source_id,
                  },
                });
              }}
            />
          </Grid>
        ) : null}

        {formValues?.clientTetherSettings?.accessToken &&
        formValues?.clientTetherSettings?.webKey ? (
          <Grid item xs={12}>
            <Autocomplete
              ListboxProps={{
                sx: ScrollStyle(),
              }}
              id="actionPlans"
              renderOption={(props, option) => {
                return (
                  <Box
                    component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                    key={option.action_plan_id}
                  >
                    <Box component="div" sx={{ flexDirection: 'column' }}>
                      <Typography variant="body1">
                        {option.action_plan_name}
                      </Typography>

                      <Typography variant="caption">
                        {option.action_plan_id}
                      </Typography>
                    </Box>
                  </Box>
                );
              }}
              options={actionPlans}
              value={
                actionPlans?.find(
                  (actionPlan: ActionPlan) =>
                    actionPlan.action_plan_id ===
                    formValues?.clientTetherSettings?.actionPlanId,
                ) || null
              }
              getOptionLabel={(option) => option.action_plan_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Action Plan"
                  placeholder="Select your action plan"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {fetchLoading ? (
                          <CircularProgress
                            size={20}
                            sx={{ marginRight: '10px' }}
                          />
                        ) : null}
                      </>
                    ),
                  }}
                />
              )}
              onChange={(_, value: ActionPlan) => {
                setFormValues({
                  ...formValues,
                  clientTetherSettings: {
                    ...formValues?.clientTetherSettings,
                    actionPlanId: value.action_plan_id,
                  },
                });
              }}
              groupBy={(option) => constantStringToHumanized(option.type)}
            />
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <PrimaryButton
            title="Setup Sales Cycle"
            type="button"
            handleOnClick={handleShowCtSalesCycleForm}
          />
        </Grid>
      </Grid>

      <PopupModal
        open={showCTSalesCycleForm}
        handleClose={handleShowCtSalesCycleForm}
      >
        <ClientTetherSalesCycleForm
          formValues={formValues}
          setFormValues={setFormValues}
          setCrmSettings={setCrmSettings}
          brand={brand}
          onClose={handleShowCtSalesCycleForm}
        />
      </PopupModal>
    </Grid>
  );
};

export default ClientTetherCrmForm;
