import { Grid } from '@mui/material';
import React from 'react';
import { AdsAccountFormFields, Brand } from '../../../types';
import { adsProvider } from '../../../utils/constants/facebookAds';
import FacebookAdsAccountForm from './FacebookAdsAccountForm';
import GoogleAdsAccountForm from '../GoogleAdsAccountForm';

interface AdAccountFormProps {
  fbAdsAccounts: AdsAccountFormFields[];
  adAccounts: AdsAccountFormFields[];
  onSelectAdsAccount: (adAccount: AdsAccountFormFields) => void;
  onRemoveAdAccount: (adAccount: AdsAccountFormFields) => void;
  brand: Brand;
  connectedAccountsIds: string[];
  handleSync: (value: any[], brand: Brand) => void;
  handleSyncGoogleCustomerIds: (value: string[], brand: Brand) => void;
  googleAdAccounts: AdsAccountFormFields[];
  isFacebook: boolean;
  isGoogle: boolean;
}

const AdAccountForm: React.FC<AdAccountFormProps> = ({
  fbAdsAccounts,
  adAccounts,
  onSelectAdsAccount,
  onRemoveAdAccount,
  brand,
  connectedAccountsIds,
  handleSync,
  handleSyncGoogleCustomerIds,
  googleAdAccounts,
  isFacebook,
  isGoogle,
}) => {
  return (
    <Grid container spacing={1}>
      {isFacebook ? (
        <Grid item xs={12}>
          <FacebookAdsAccountForm
            brand={brand}
            handleSync={handleSync}
            fbAdsAccounts={fbAdsAccounts}
            adAccounts={adAccounts}
            connectedAccountsIds={connectedAccountsIds}
            onSelectAdsAccount={onSelectAdsAccount}
            onRemoveAdAccount={onRemoveAdAccount}
            provider={adsProvider.FACEBOOK}
          />
        </Grid>
      ) : null}

      {isGoogle ? (
        <Grid item xs={12}>
          <GoogleAdsAccountForm
            brand={brand}
            provider={adsProvider.GOOGLE}
            handleSync={handleSyncGoogleCustomerIds}
            googleAdAccounts={googleAdAccounts}
            adAccounts={adAccounts}
            onSelectAdsAccount={onSelectAdsAccount}
            onRemoveAdAccount={onRemoveAdAccount}
            connectedAccountsIds={connectedAccountsIds}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default AdAccountForm;
