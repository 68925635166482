import {
  DataGridPremiumProps,
  getDataGridUtilityClass,
  GridColDef,
  gridFilteredDescendantCountLookupSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid-premium';
import { defaultProps } from '../../../utils/helpers/TableHelpers';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { toCurrency } from '../../../utils/numberFormatter';
import {
  constantStringToHumanized,
  humanizeString,
} from '../../../utils/stringModifier';
import {
  Box,
  Tooltip,
  Typography,
  unstable_composeClasses,
} from '@mui/material';

export const facebookCampaignReportGroupingColumn = (
  isMobile: boolean,
  onOpenPreview: (adId: string) => void,
) => {
  const gridGroupColumn: DataGridPremiumProps['groupingColDef'] = {
    ...defaultProps(isMobile, 500),
    headerName: 'Campaigns',
    headerClassName: `${styles.header}`,
    sortable: true,
    renderCell: (params) => (
      <CustomGridGroupColDef
        {...params}
        withTooltip
        onClick={() =>
          params.row.level === 'ad' ? onOpenPreview(params.row.id) : null
        }
      />
    ),
  };

  return gridGroupColumn;
};

export const facebookCampaignReportColumns = (
  isLeads: boolean,
  onOpenPreview: (adId: string) => void,
  isFranDev: boolean,
) => {
  let columns: GridColDef[] = [
    {
      field: 'level',
      width: 90,
      headerClassName: `${styles.header}`,
      headerName: 'Level',
      groupingValueGetter: (_, row) => row.clicks,
      renderCell: (params) => {
        const { level, id } = params.row;

        return (
          <div
            className={level === 'ad' ? styles.name : ''}
            onClick={() => (level === 'ad' ? onOpenPreview(id) : null)}
          >
            {params.formattedValue !== null &&
            params.formattedValue !== undefined
              ? formatLevelValue(level)
              : ''}
          </div>
        );
      },
    },
    {
      field: 'clicks',
      width: 80,
      headerClassName: `${styles.header}`,
      headerName: 'Clicks',
      groupingValueGetter: (_, row) => row.clicks,
      renderCell: (params) => {
        const { clicks, level, id } = params.row;

        return (
          <div
            className={level === 'ad' ? styles.name : ''}
            onClick={() => (level === 'ad' ? onOpenPreview(id) : null)}
          >
            {params.formattedValue !== null &&
            params.formattedValue !== undefined
              ? clicks
              : ''}
          </div>
        );
      },
    },
    {
      field: 'cpc',
      width: 70,
      headerClassName: `${styles.header}`,
      headerName: 'CPC',
      groupingValueGetter: (_, row) => row.cpc,
      renderCell: (params) => {
        const { cpc, level, id } = params.row;

        return (
          <div
            className={level === 'ad' ? styles.name : ''}
            onClick={() => (level === 'ad' ? onOpenPreview(id) : null)}
          >
            {params.formattedValue !== null &&
            params.formattedValue !== undefined
              ? toCurrency('USD', cpc || 0)
              : ''}
          </div>
        );
      },
    },
    {
      field: 'impressions',
      width: 100,
      headerClassName: `${styles.header}`,
      headerName: 'Impressions',
      groupingValueGetter: (_, row) => row.impressions,
      renderCell: (params) => {
        const { impressions, level, id } = params.row;

        return (
          <div
            className={level === 'ad' ? styles.name : ''}
            onClick={() => (level === 'ad' ? onOpenPreview(id) : null)}
          >
            {params.formattedValue !== null &&
            params.formattedValue !== undefined
              ? impressions
              : ''}
          </div>
        );
      },
    },
    {
      field: 'cpm',
      width: 70,
      headerClassName: `${styles.header}`,
      headerName: 'CPM',
      groupingValueGetter: (_, row) => row.cpm,
      renderCell: (params) => {
        const { cpm, level, id } = params.row;

        return (
          <div
            className={level === 'ad' ? styles.name : ''}
            onClick={() => (level === 'ad' ? onOpenPreview(id) : null)}
          >
            {params.formattedValue !== null &&
            params.formattedValue !== undefined
              ? toCurrency('USD', cpm || 0)
              : ''}
          </div>
        );
      },
    },
  ];

  if (isLeads) {
    columns = [
      ...columns,
      {
        field: 'leads',
        width: 80,
        headerClassName: `${styles.header}`,
        headerName: 'Leads',
        groupingValueGetter: (_, row) => row.leads,
        renderCell: (params) => {
          const { leads, level, id } = params.row;

          return (
            <div
              className={level === 'ad' ? styles.name : ''}
              onClick={() => (level === 'ad' ? onOpenPreview(id) : null)}
            >
              {params.formattedValue !== null &&
              params.formattedValue !== undefined
                ? leads
                : ''}
            </div>
          );
        },
      },
      {
        field: 'cpl',
        width: 70,
        headerClassName: `${styles.header}`,
        headerName: 'CPL',
        groupingValueGetter: (_, row) => row.cpl,
        renderCell: (params) => {
          const { cpl, level, id } = params.row;

          return (
            <div
              className={level === 'ad' ? styles.name : ''}
              onClick={() => (level === 'ad' ? onOpenPreview(id) : null)}
            >
              {params.formattedValue !== null &&
              params.formattedValue !== undefined
                ? toCurrency('USD', cpl || 0)
                : ''}
            </div>
          );
        },
      },
    ];

    if (isFranDev) {
      columns = [
        ...columns,
        {
          field: 'qualifiedLeads',
          width: 80,
          headerClassName: `${styles.header}`,
          headerName: 'QLeads',
          groupingValueGetter: (_, row) => row.qualifiedLeads,
          renderCell: (params) => {
            const { qualifiedLeads, level, id } = params.row;

            return (
              <div
                className={level === 'ad' ? styles.name : ''}
                onClick={() => (level === 'ad' ? onOpenPreview(id) : null)}
              >
                {params.formattedValue !== null &&
                params.formattedValue !== undefined &&
                !['region', 'dma'].includes(level)
                  ? qualifiedLeads
                  : ''}
              </div>
            );
          },
        },
        {
          field: 'cpql',
          width: 70,
          headerClassName: `${styles.header}`,
          headerName: 'CPQL',
          groupingValueGetter: (_, row) => row.cpql,
          renderCell: (params) => {
            const { cpql, level, id } = params.row;

            return (
              <div
                className={level === 'ad' ? styles.name : ''}
                onClick={() => (level === 'ad' ? onOpenPreview(id) : null)}
              >
                {params.formattedValue !== null &&
                params.formattedValue !== undefined &&
                !['region', 'dma'].includes(level)
                  ? toCurrency('USD', cpql || 0)
                  : ''}
              </div>
            );
          },
        },
      ];
    }
  }

  if (!isLeads) {
    columns = [
      ...columns,
      {
        field: 'results',
        width: 80,
        headerClassName: `${styles.header}`,
        headerName: 'Results',
        groupingValueGetter: (_, row) => row.results,
        renderCell: (params) => {
          const { results, level, id } = params.row;

          return (
            <div
              className={level === 'ad' ? styles.name : ''}
              onClick={() => (level === 'ad' ? onOpenPreview(id) : null)}
            >
              {params.formattedValue !== null &&
              params.formattedValue !== undefined
                ? results
                : ''}
            </div>
          );
        },
      },
      {
        field: 'cpr',
        width: 70,
        headerClassName: `${styles.header}`,
        headerName: 'CPR',
        groupingValueGetter: (_, row) => row.cpr,
        renderCell: (params) => {
          const { cpr, level, id } = params.row;

          return (
            <div
              className={level === 'ad' ? styles.name : ''}
              onClick={() => (level === 'ad' ? onOpenPreview(id) : null)}
            >
              {params.formattedValue !== null &&
              params.formattedValue !== undefined
                ? toCurrency('USD', cpr || 0)
                : ''}
            </div>
          );
        },
      },
    ];
  }

  columns = [
    ...columns,
    {
      field: 'spend',
      width: 90,
      headerClassName: `${styles.header}`,
      headerName: 'Cost',
      groupingValueGetter: (_, row) => row.spend,
      renderCell: (params) => {
        const { spend, id, level } = params.row;

        return (
          <div
            className={level === 'ad' ? styles.name : ''}
            onClick={() => (level === 'ad' ? onOpenPreview(id) : null)}
          >
            {params.formattedValue !== null &&
            params.formattedValue !== undefined
              ? toCurrency('USD', spend || 0)
              : ''}
          </div>
        );
      },
    },
  ];

  return columns;
};

type OwnerState = { classes: DataGridPremiumProps['classes'] };

const useUtilityClasses = (ownerState: OwnerState) => {
  const { classes } = ownerState;

  const slots = {
    root: ['treeDataGroupingCell'],
    toggle: ['treeDataGroupingCellToggle'],
  };

  return unstable_composeClasses(slots, getDataGridUtilityClass, classes);
};

export const CustomGridGroupColDef = (props: any) => {
  const {
    id,
    field,
    formattedValue,
    rowNode,
    offsetMultiplier = 2,
    withTooltip = false,
    onClick,
    row,
  } = props;

  const rootProps = useGridRootProps();
  const apiRef = useGridApiContext();
  const ownerState = { classes: rootProps.classes };
  const classes = useUtilityClasses(ownerState);
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  );

  const filteredDescendantCount =
    filteredDescendantCountLookup[rowNode.id] ?? 0;

  const Icon = (rowNode as any).childrenExpanded
    ? rootProps.slots.treeDataCollapseIcon
    : rootProps.slots.treeDataExpandIcon;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    apiRef.current.setRowChildrenExpansion(
      id,
      !(rowNode as any).childrenExpanded,
    );
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  const value =
    formattedValue === undefined
      ? (rowNode as any).groupingKey
      : formattedValue;

  const removeLastParenthesisContent = (str: string): string => {
    return str.replace(/\s*\([^)]*\)\s*$/, '');
  };

  const isAdsLevel = (level: string) => {
    return ['campaign', 'adset', 'ad'].includes(level);
  };

  return (
    <Box className={classes.root} sx={{ ml: rowNode.depth * offsetMultiplier }}>
      <div className={classes.toggle}>
        {filteredDescendantCount > 0 && (
          <rootProps.slots.baseIconButton
            size="small"
            onClick={handleClick}
            tabIndex={-1}
            aria-label={
              (rowNode as any).childrenExpanded
                ? apiRef.current.getLocaleText('treeDataCollapse')
                : apiRef.current.getLocaleText('treeDataExpand')
            }
            {...rootProps?.slotProps?.baseIconButton}
          >
            <Icon fontSize="inherit" />
          </rootProps.slots.baseIconButton>
        )}
      </div>

      <div
        className={`${styles.name} ${
          row.status !== 'ACTIVE' &&
          !['region', 'dma', 'platform', 'objective'].includes(row.level) &&
          !['Region', 'Objective', 'DMA', 'Platform'].includes(
            rowNode.groupingKey,
          )
            ? `${styles['-error']}`
            : ''
        }`}
        onClick={onClick}
      >
        {withTooltip ? (
          <Tooltip
            title={
              <Typography variant="body2">{`${
                isAdsLevel(row.level)
                  ? removeLastParenthesisContent(value)
                  : value
              }${
                row.status ? ` (${constantStringToHumanized(row.status)})` : ''
              }`}</Typography>
            }
            disableInteractive
          >
            {
              (isAdsLevel(row.level)
                ? removeLastParenthesisContent(value)
                : value) as any
            }
          </Tooltip>
        ) : isAdsLevel(row.level) ? (
          removeLastParenthesisContent(value)
        ) : (
          value
        )}
      </div>
    </Box>
  );
};

const formatLevelValue = (level: string) => {
  if (level === 'dma') {
    return 'DMA';
  }

  return humanizeString(level);
};
