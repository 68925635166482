import { useMediaQuery, useTheme } from '@mui/material';

export const DEFAULT_COLOR_THEME: string = '#096f4d';

export const StyledDataGrid = () => {
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));

  let baseStyle = {
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: `${DEFAULT_COLOR_THEME} !important`,
      color: 'white',
    },
    width: '100%',
  };

  if (!xsOnly) {
    return baseStyle;
  }

  return {
    ...baseStyle,
    overflow: 'hidden',
  };
};

export const StyledPaper = () => {
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));

  if (xsOnly) {
    return { maxWidth: '330px', overflow: 'hidden' };
  }

  return {};
};

export const ScrollStyle = () => {
  return {
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
    },

    '&::-webkit-scrollbar-thumb': {
      background: DEFAULT_COLOR_THEME,
      borderRadius: '18px',

      '&:hover': {
        background: DEFAULT_COLOR_THEME,
      },
    },
  };
};

export const AIADZ_YELLOW_HEX: string = '#d8a800';
export const RED_HEX: string = '#FF0000';
export const AIADZ_GREEN_HEX: string = '#006344';
export const AIADZ_LIGHT_GREEN_HEX: string = '#b9d870';
export const AIADZ_DARK_GREEN_HEX: string = '#B6c61a';
export const DANGER_RED_HEX: string = '#DC3545';
export const SUCCESS_GREEN_HEX: string = '#198754';
