import momentTz from 'moment-timezone';
import {
  SET_BRAND_TIMEZONE,
  SET_END_RANGE,
  SET_START_RANGE,
} from '../ActionNames';

const startRange = (
  state: any = { date: momentTz().subtract(30, 'days') },
  action: any,
) => {
  switch (action.type) {
    case SET_START_RANGE:
      return {
        ...state,
        date: action.payload,
      };
    default:
      return state;
  }
};

const endRange = (state: any = { date: momentTz() }, action: any) => {
  switch (action.type) {
    case SET_END_RANGE:
      return {
        ...state,
        date: action.payload,
      };
    default:
      return state;
  }
};

const brandTimezone = (state: any = { timezone: 'Etc/UTC' }, action: any) => {
  switch (action.type) {
    case SET_BRAND_TIMEZONE:
      return {
        ...state,
        timezone: action.payload,
      };
    default:
      return state;
  }
};

export { startRange, endRange, brandTimezone };
