import { DataGridPremiumProps, GridColDef } from '@mui/x-data-grid-premium';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { defaultProps } from '../../../utils/helpers/TableHelpers';
import { humanizeString } from '../../../utils/stringModifier';

export const updateAdBudgetGroupingColumn = (isMobile: boolean) => {
  const gridGroupColumn: DataGridPremiumProps['groupingColDef'] = {
    width: 500,
    headerName: 'Campaigns',
    headerClassName: `${styles.header}`,
    sortable: true,
  };

  return gridGroupColumn;
};

export const updateAdBudgetColumns = (isMobile: boolean) => {
  const columns: GridColDef[] = [
    {
      field: 'level',
      width: 100,
      headerClassName: `${styles.header}`,
      headerName: 'Level',
      renderCell: (params) => {
        const { level } = params.row;

        return <div>{humanizeString(level)}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'january',
      headerClassName: `${styles.header}`,
      headerName: 'Jan',
      valueGetter: (_, row) => row.january,
      editable: true,
      renderCell: (params) => {
        const { january } = params.row;

        return <div>{january}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'february',
      headerClassName: `${styles.header}`,
      headerName: 'Feb',
      editable: true,
      valueGetter: (_, row) => row.february,
      renderCell: (params) => {
        const { february } = params.row;

        return <div>{february}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'march',
      headerClassName: `${styles.header}`,
      headerName: 'Mar',
      editable: true,
      valueGetter: (_, row) => row.march,
      renderCell: (params) => {
        const { march } = params.row;

        return <div>{march}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'april',
      headerClassName: `${styles.header}`,
      headerName: 'Apr',
      editable: true,
      valueGetter: (_, row) => row.april,
      renderCell: (params) => {
        const { april } = params.row;

        return <div>{april}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'may',
      headerClassName: `${styles.header}`,
      headerName: 'May',
      editable: true,
      valueGetter: (_, row) => row.may,
      renderCell: (params) => {
        const { may } = params.row;

        return <div>{may}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'june',
      headerClassName: `${styles.header}`,
      headerName: 'Jun',
      editable: true,
      valueGetter: (_, row) => row.june,
      renderCell: (params) => {
        const { june } = params.row;

        return <div>{june}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'july',
      headerClassName: `${styles.header}`,
      headerName: 'Jul',
      editable: true,
      valueGetter: (_, row) => row.july,
      renderCell: (params) => {
        const { july } = params.row;

        return <div>{july}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'august',
      headerClassName: `${styles.header}`,
      headerName: 'Aug',
      editable: true,
      valueGetter: (_, row) => row.august,
      renderCell: (params) => {
        const { august } = params.row;

        return <div>{august}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'september',
      headerClassName: `${styles.header}`,
      headerName: 'Sept',
      editable: true,
      valueGetter: (_, row) => row.september,
      renderCell: (params) => {
        const { september } = params.row;

        return <div>{september}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'october',
      headerClassName: `${styles.header}`,
      headerName: 'Oct',
      editable: true,
      valueGetter: (_, row) => row.october,
      renderCell: (params) => {
        const { october } = params.row;

        return <div>{october}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'november',
      headerClassName: `${styles.header}`,
      headerName: 'Nov',
      editable: true,
      valueGetter: (_, row) => row.november,
      renderCell: (params) => {
        const { november } = params.row;

        return <div>{november}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'december',
      headerClassName: `${styles.header}`,
      headerName: 'Dec',
      editable: true,
      valueGetter: (_, row) => row.december,
      renderCell: (params) => {
        const { december } = params.row;

        return <div>{december}</div>;
      },
    },
  ];

  return columns;
};
