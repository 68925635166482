import { GridColDef } from '@mui/x-data-grid-premium';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { snakeCaseToHumanized } from '../../../utils/stringModifier';
import { Chip, Typography } from '@mui/material';
import { renderCCIcon } from '../../Cards/Billing/PaymentMethodHelpers';

export const subscriptionTableColumns: GridColDef[] = [
  {
    field: 'startDate',
    headerName: 'Start Date',
    headerClassName: styles.header,
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params) => {
      const { startDate } = params.row;

      return <div className={styles.name}>{startDate}</div>;
    },
  },
  {
    field: 'subscriptionPeriod',
    headerClassName: styles.header,
    headerName: 'Subscription Period',
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params) => {
      const { periodStart, periodEnd } = params.row;

      return (
        <div
          className={`${styles.name} ${styles['-break']}`}
        >{`${periodStart} - ${periodEnd}`}</div>
      );
    },
  },
  {
    field: 'paymentMethod',
    headerClassName: styles.header,
    headerName: 'Payment Method',
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params) => {
      const { paymentMethod } = params.row;

      return (
        <div className={styles.image}>
          {paymentMethod?.card
            ? renderCCIcon(paymentMethod?.card?.brand, 20)
            : null}{' '}
          <div className={styles.label}>{`${
            paymentMethod?.card?.last4
              ? `●●●● ${paymentMethod?.card?.last4}`
              : ''
          }`}</div>
        </div>
      );
    },
  },
  {
    field: 'status',
    headerClassName: styles.header,
    headerName: 'Status',
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params) => {
      const { status, formattedCancelAt, paymentIntent, invoicePageUrl } =
        params.row;

      const requiresAction = paymentIntent?.status === 'requires_action';

      return (
        <div
          className={styles.name}
          onClick={() =>
            requiresAction ? window.open(invoicePageUrl, '_blank') : null
          }
        >
          {requiresAction ? (
            <Typography
              variant="body2"
              sx={{ color: 'red', fontWeight: 'bold' }}
            >
              Requires Authentication
            </Typography>
          ) : (
            <>
              {status === 'trialing' ? 'Pending' : snakeCaseToHumanized(status)}
              {formattedCancelAt ? (
                <Chip
                  label={`Cancels ${formattedCancelAt}`}
                  size="small"
                  color="warning"
                  sx={{ marginLeft: '5px' }}
                />
              ) : null}
            </>
          )}
        </div>
      );
    },
  },
];
