import React, { FormEvent, useContext, useEffect, useState } from 'react';
import styles from '../../assets/styles/pages/Billings.module.scss';
import PageHeader from '../../components/PageHeader';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Tab,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ChangeEventType,
  StripeAddressForm,
  StripeCanceledSubscriptionForm,
  StripeCustomerForm as CustomerForm,
  StripeInvoiceBreakdown,
  StripeCustomerFormErrors,
  StripePaymentMethodForm,
  StripeCreateSetupIntentForm,
  Brand,
  Fee,
  StripeUpdateAccountForm,
  StripeCustomerBalanceCredit,
} from '../../types';
import {
  attachCreditCard,
  createOrUpdateStripeCustomer,
  fetchSingleStripeCustomer,
  getCustomerCreditBalances,
  getCustomerInvoices,
  getCustomerPaymentMethods,
  getCustomerSubscriptions,
  setDefaultPaymentMethod,
} from '../../services/stripe/customer';
import CircularLoading from '../../components/CircularLoading';
import BillingInvoiceListTable from '../../components/Tables/BillingList/BillingInvoiceListTable';
import PaymentMethodCard from '../../components/Cards/Billing/PaymentMethodCard';
import StripePaymentUIModal from '../../components/Modal/StripePaymentUIModal';
import PopupModal, { DeleteModal } from '../../components/Modal';
import SubscriptionCancellationForm from '../../components/Forms/Payment/Subscription/SubscriptionCancellationForm';
import {
  cancelStripeSubscription,
  renewStripeSubscription,
} from '../../services/stripe/subscription';
import { DrawerMenu } from '../../components/Menu';
import BillingBreakdownDetails from '../../components/Details/BillingBreakdownDetails';
import BillingProfileCard from '../../components/Cards/Billing/BillingProfileCard';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import StripeCustomerForm from '../../components/Forms/Payment/StripeCustomerForm';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { PrimaryButton } from '../../components/Buttons';
import { updateStripePaymentMethod } from '../../services/stripe/paymentMethod';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { renderCCIcon } from '../../components/Cards/Billing/PaymentMethodHelpers';
import { createStripeSetupIntent } from '../../services/stripe/paymentIntent';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../../context';
import {
  ADMIN,
  IS_DEVELOPMENT,
  IS_DIY_ADZ,
  IS_STAGING,
  SUPER_ADMIN,
} from '../../utils';
import { fetchAllFeesByBrand, updateFees } from '../../services/fee';
import { FEE_TYPES } from '../../utils/helpers/stripeHelper';
import { getArrayTotalByObjKeys } from '../../utils/arrayFormatter';
import { humanizeString } from '../../utils/stringModifier';
import {
  advanceStripeTestClock,
  getStripeTestClock,
} from '../../services/stripe/testClock';
import StripeTestClockForm from '../../components/Forms/Payment/StripeTestClockForm';
import StripeAccountDetails from '../../components/Details/StripeAccountDetails';
import StripeAccountForm from '../../components/Forms/Payment/Onboarding/StripeAccountForm';
import { useSearchParams } from 'react-router-dom';
import { fetchSingleStripeAccountByBrand } from '../../services/stripe/account';
import SubscriptionFees from '../../components/Forms/Payment/SubscriptionFees';
import SubscriptionForm from '../../components/Forms/Payment/Subscription/SubscriptionForm';
import { setBrand, setLocation, toggleAlert } from '../../redux/actions';
import logo from '../../assets/images/powered-by-stripe.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SubscriptionsListTable from '../../components/Tables/BillingList/SubscriptionsListTable';
import CountUp from 'react-countup';
import CreditBalanceListTable from '../../components/Tables/BillingList/CreditBalanceListTable';
import { MuiTelInput } from 'mui-tel-input';
import CountrySelectMenu from '../../components/Select/CountrySelectMenu';
import AddCardIcon from '@mui/icons-material/AddCard';
import StripePaymentTermsModal from '../../components/Modal/StripePaymentTermsModal';
import { errorMessageParser } from '../../utils/helpers/ToastHelper';
import { FaLink } from 'react-icons/fa';
import LinkCardForm from '../../components/Forms/Payment/LinkCardForm';
import { DEFAULT_COLOR_THEME } from '../../utils/Styling';

const Billings: React.FC = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMdAndDown = useMediaQuery(theme.breakpoints.down('md'));
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const brandId: string = searchParams.get('brandId') || null;
  const fromAccountDashboard = searchParams.get('accountDashboard') === 'true';
  const fromCardPayment = searchParams.get('cardPayment') === 'true';
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const { state } = useContext(AuthContext);
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isAdmin = state.role === ADMIN;
  const [tabValue, setTabValue] = useState<string>('1');
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [customer, setCustomer] = useState<any>(null);
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] =
    useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<any[]>([]);
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [clientSecret, setClientSecret] = useState<string>('');
  const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false);
  const [cancellationForm, setCancellationForm] =
    useState<StripeCanceledSubscriptionForm>({
      cancellation_details: {
        comment: '',
        feedback: '',
      },
    });
  const [openCancelationForm, setOpenCancelationForm] =
    useState<boolean>(false);
  const [selectedSubscription, setSelectedSubscription] =
    useState<string>(null);
  const [cancelLoading, setCancelLoading] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openInvoiceBreakdown, setOpenInvoiceBreakdown] =
    useState<boolean>(false);
  const [invoiceBreakdown, setInvoiceBreakdown] =
    useState<StripeInvoiceBreakdown>(null);
  const [customerForm, setCustomerForm] = useState<CustomerForm>({
    name: '',
    email: '',
    phone: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postal_code: '',
      country: 'US',
    },
    metadata: {
      user_id: '',
      role: '',
    },
  });
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [fieldToEdit, setFieldToEdit] = useState<string>('');
  const [editCustomerLoading, setEditCustomerLoading] =
    useState<boolean>(false);
  const [customerErrors, setCustomerErrors] =
    useState<StripeCustomerFormErrors>({
      address: {
        city: '',
        state: '',
        postal_code: '',
        country: '',
      },
      name: '',
      email: '',
      phone: '',
    });
  const [isEditPaymentMethod, setIsEditPaymentMethod] =
    useState<boolean>(false);
  const [paymentMethodForm, setPaymentMethodForm] =
    useState<StripePaymentMethodForm>(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string>('');
  const [loadingPaymentMethod, setLoadingPaymentMethod] =
    useState<boolean>(false);
  const [transactionType, setTransactionType] = useState<string>(null);
  const [setupIntentLoading, setSetupIntentLoading] = useState<boolean>(false);
  const [brandFees, setBrandFees] = useState<Fee[]>([]);
  const [locationFees, setLocationFees] = useState<Fee[]>([]);
  const [brandFeesForm, setBrandFeesForm] = useState<Fee[]>([]);
  const [locationFeesForm, setLocationFeesForm] = useState<Fee[]>([]);
  const [feesTotal, setFeesTotal] = useState<number>(0);
  const [testClock, setTestClock] = useState<any>(null);
  const [advanceDate, setAdvanceDate] = useState<Moment | null>(null);
  const [minDate, setMinDate] = useState<Moment | null>(null);
  const [showStripeAccountForm, setShowStripeAccountForm] =
    useState<boolean>(false);
  const [tabs, setTabs] = useState<string[]>([]);
  const [account, setAccount] = useState<any>(null);
  const [accountFormValues, setAccountFormValues] =
    useState<StripeUpdateAccountForm>({
      business_profile: {
        support_email: '',
        name: '',
        support_address: {
          city: '',
          country: 'US',
          line1: '',
          line2: '',
          postal_code: '',
          state: '',
        },
        support_phone: '',
        support_url: '',
        url: '',
      },
    });
  const [showSubscriptionForm, setShowSubscriptionForm] =
    useState<boolean>(false);
  const [openAdBudgetModal, setOpenAdBudgetModal] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<string>('panel1');
  const [creditBalances, setCreditBalances] = useState<
    StripeCustomerBalanceCredit[]
  >([]);
  const [creditBalance, setCreditBalance] =
    useState<StripeCustomerBalanceCredit>(null);
  const [openTermsModal, setOpenTermsModal] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [tab, setTab] = useState<string>(null);
  const [selectedBrand, setSelectedBrand] = useState<Brand>(null);
  const [openAttachCardModal, setOpenAttachCardModal] =
    useState<boolean>(false);
  const [attachLoading, setAttachLoading] = useState<boolean>(false);
  const [linkCardError, setLinkCardError] = useState<string>(null);

  useEffect(() => {
    if (brand && !location) {
      handleOnCloseCreateStripeAccount();
      resetState();
      getSingleStripeCustomer(brand);
      setTabValue('1');
      getBrandFees(brand, 'brand');
      getStripeAccount();

      if (brand?.allowLocations) {
        getBrandFees(brand, 'location');
      }
    }

    if (brand && location) {
      handleOnCloseCreateStripeAccount();
      resetState();
      getSingleStripeCustomer(location);
      setTabValue('1');
      getBrandFees(location, 'brand');
    }
  }, [brand, location]);

  useEffect(() => {
    if (location || brand) buildTabs();
  }, [subscriptions, brand, location, feesTotal, customer]);

  useEffect(() => {
    if (customer) {
      setDefaultPaymentMethodId(
        customer.invoice_settings?.default_payment_method,
      );
      setCustomerForm({
        name: customer.name,
        address: customer.address,
        email: customer.email,
        phone: customer.phone,
        metadata: customer.metadata,
      });
    }
  }, [customer]);

  useEffect(() => {
    if (customer) {
      if (tab === 'Payment Methods') {
        getPaymentMethods(location || brand);
      }

      if (tab === 'Payment History') {
        getStripeCustomerInvoices(location || brand);
      }

      if (tab === 'Credit Balance') {
        getCustomerCredits(location || brand);
      }

      if (tab === 'Subscriptions') {
        getSubscriptions(location || brand);
      }

      if (tab === 'Test Clock') {
        getSingleStripeTestClock(location || brand);
      }
    }
  }, [customer, tab, openPaymentModal]);

  useEffect(() => {
    if (isEdit && tabValue !== '1') {
      handleCancelEdit();
    }

    if (isEditPaymentMethod && tabValue !== '3') {
      setIsEditPaymentMethod(false);
    }
  }, [isEdit, tabValue, isEditPaymentMethod, showSubscriptionForm]);

  useEffect(() => {
    if (brandId !== undefined && fromAccountDashboard && tabs?.length > 0) {
      const index = tabs?.findIndex((tab: string) => tab === 'Deposit Account');

      setTabValue(`${index + 1}`);
    }

    if (brandId !== undefined && fromCardPayment) {
      const index = tabs?.findIndex((tab: string) => tab === 'Subscriptions');

      setTabValue(`${index + 1}`);
    }
  }, [brandId, fromAccountDashboard, tabs, fromCardPayment]);

  useEffect(() => {
    if (brandFees.length > 0) {
      const total = getArrayTotalByObjKeys(brandFees, 'amount');

      setFeesTotal(total);
    }
  }, [brandFees]);

  const handleChangeTabValue = (
    event: React.SyntheticEvent,
    newValue: string,
  ) => {
    setTabValue(newValue);
  };

  const resetState = () => {
    setSubscriptions([]);
    setCustomer(null);
    setAccount(null);
    setFeesTotal(0);
  };

  const getPaymentMethods = async (brand: Brand) => {
    setLoading(true);
    try {
      const response = await getCustomerPaymentMethods(brand?._id);
      let temp: any = [...response.data];
      temp = temp.sort((a: any) => {
        if (customer.invoice_settings?.default_payment_method === a.id) {
          return -1;
        }

        return 1;
      });

      setPaymentMethods(temp);
    } catch (error: any) {
      setPaymentMethods([]);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getStripeAccount = async () => {
    try {
      setLoading(true);
      const response = await fetchSingleStripeAccountByBrand(brand?._id);

      setAccount(response?.data);
      setAccountFormValues({
        business_profile: {
          support_email: response?.data?.business_profile?.support_email,
          name: response?.data?.business_profile?.name,
          support_address: response?.data?.business_profile?.support_address,
          support_phone: response?.data?.business_profile?.support_phone,
          support_url: response?.data?.business_profile?.support_url,
          url: response?.data?.business_profile?.url,
        },
      });
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getSubscriptions = async (brand: Brand) => {
    setLoading(true);
    try {
      const response = await getCustomerSubscriptions(brand?._id);

      setSubscriptions(response.data);
    } catch (error: any) {
      setSubscriptions([]);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getBrandFees = async (brand: Brand, entity?: string) => {
    try {
      setLoading(true);
      let payloads: Fee[] = [];

      const response = await fetchAllFeesByBrand(brand?._id, entity);

      FEE_TYPES.forEach((feeType: string, index: number) => {
        const data = response.data.find((data: Fee) => {
          return data.type === feeType;
        });

        if (data) {
          payloads = [...payloads, data];
        } else {
          const payload: Fee = {
            name:
              feeType === 'ad-budget' ? 'Ad Budget' : humanizeString(feeType),
            ref: location?._id || brand?._id,
            docModel: 'Brand',
            oneTime: feeType === 'setup',
            type: feeType,
            amount: (data?.amount || 0) === 0 ? 0 : data?.amount || 0,
            provider: 'stripe',
            enabled: true,
            entity,
            position: index + 1,
          };

          payloads = [...payloads, payload];
        }
      });

      if (entity === 'brand') {
        setBrandFees(payloads);
        setBrandFeesForm(payloads);
        const total = getArrayTotalByObjKeys(payloads, 'amount');

        setFeesTotal(total);
      } else if (entity === 'location') {
        setLocationFees(payloads);
        setLocationFeesForm(payloads);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getSingleStripeTestClock = async (brand: Brand) => {
    setLoading(true);
    try {
      const response = await getStripeTestClock(brand?._id);

      setTestClock(response?.data);
      const convertedUnix = moment.unix(response?.data.frozen_time).utc();
      setAdvanceDate(convertedUnix);
      setMinDate(convertedUnix);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getCustomerCredits = async (brand: Brand) => {
    setLoading(true);
    try {
      const response = await getCustomerCreditBalances(brand?._id);

      if (response.data.length > 0) {
        setCreditBalance(response.data[0]);
      }

      setCreditBalances(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getSingleStripeCustomer = async (brand: Brand) => {
    setFetchLoading(true);
    try {
      const response = await fetchSingleStripeCustomer(brand?._id, 'brand');

      setCustomer(response.data);
    } catch (error: any) {
      setCustomer(null);
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const getStripeCustomerInvoices = async (brand: Brand) => {
    setLoading(true);
    try {
      const response = await getCustomerInvoices(brand?._id);

      setInvoices(response.data);
    } catch (error: any) {
      setInvoices([]);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelSubscription = async (e: FormEvent) => {
    e.preventDefault();
    setCancelLoading(true);
    try {
      const response = await cancelStripeSubscription(
        location?._id || brand?._id,
        selectedSubscription,
        cancellationForm,
      );

      let temp: any[] = [];
      subscriptions.forEach((subscription: any) => {
        if (subscription.id === response.data.id) {
          subscription = response.data;
          temp.push(subscription);
        }
      });
      setSubscriptions(temp);
      setCancelLoading(false);
      handleCloseCancellationForm();
    } catch (error: any) {
      setCancelLoading(false);
      console.log(error);
    }
  };

  const handleRenewSubscription = async () => {
    setLoading(true);
    try {
      const response = await renewStripeSubscription(
        location?._id || brand?._id,
        selectedSubscription,
        cancellationForm,
      );

      let temp: any[] = [];
      subscriptions.forEach((subscription: any) => {
        if (subscription.id === response.data.id) {
          subscription = response.data;
          temp.push(subscription);
        }
      });
      setSubscriptions(temp);
      handleCloseConfirmRenew();
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAdvanceTestClock = async () => {
    setLoading(true);
    try {
      const unix = advanceDate.unix();
      const response = await advanceStripeTestClock(
        location?._id || brand?._id,
        unix,
      );

      setTestClock(response?.data);
      const convertedUnix = moment.unix(response?.data.frozen_time).utc();
      setMinDate(convertedUnix);
      getSubscriptions(location || brand);
    } catch (error: any) {
      console.log(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOnChangeFees = (
    e: ChangeEventType,
    index: number,
    entity: string,
  ) => {
    const IS_BRAND = entity === 'brand';
    const IS_LOCATION = entity === 'location';

    const temp = [...(IS_BRAND ? brandFeesForm : locationFeesForm)];
    temp[index].amount = parseFloat(e.target.value);

    if (IS_BRAND) {
      setBrandFeesForm(temp);
    } else if (IS_LOCATION) {
      setLocationFeesForm(temp);
    }
  };

  const handleSetDefaultPaymentMethod = async (paymentMethodId: string) => {
    setLoading(true);
    try {
      const response = await setDefaultPaymentMethod(
        location?._id || brand?._id,
        paymentMethodId,
      );
      let temp: any[] = [...paymentMethods];
      temp = temp.sort((a: any) => {
        if (response.data.invoice_settings?.default_payment_method === a.id) {
          return -1;
        }

        return 1;
      });

      setPaymentMethods(temp);
      setCustomer(response.data);
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Default payment method has been set successfully.',
        }),
      );
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOnClickEditPaymentMethod = (paymentMethod: any) => {
    setIsEditPaymentMethod(true);
    setSelectedPaymentMethod(paymentMethod.id);
    setPaymentMethodForm({
      card: {
        exp_month: paymentMethod.card.exp_month,
        exp_year: paymentMethod.card.exp_year,
      },
    });
  };

  const handleUpdatePaymentMethod = async () => {
    setLoadingPaymentMethod(true);
    try {
      const response = await updateStripePaymentMethod(
        selectedPaymentMethod,
        paymentMethodForm,
      );

      let temp: any[] = [];
      paymentMethods.forEach((paymentMethod: any) => {
        if (paymentMethod.id === response.data.id) {
          paymentMethod = response.data;
        }

        temp = [...temp, paymentMethod];
      });

      setPaymentMethods(temp);
      setLoadingPaymentMethod(false);
      setIsEditPaymentMethod(false);
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Payment method has been updated successfully.',
        }),
      );
    } catch (error: any) {
      setLoadingPaymentMethod(false);
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.response.data.message,
          type: 'error',
        }),
      );
    }
  };

  const handleCreateSetupIntent = async () => {
    setSetupIntentLoading(true);
    try {
      const params: StripeCreateSetupIntentForm = {
        customer: customer.id,
        payment_method_types: ['card'],
        metadata: {
          user_id: location?._id || brand?._id,
          role: 'brand',
          action: 'payment_method',
        },
      };

      const response = await createStripeSetupIntent(
        params,
        location?._id || brand?._id,
      );
      handleOpenPaymentModal(response.data.client_secret, 'setup');
      setSetupIntentLoading(false);
    } catch (error: any) {
      console.log(error);
      setSetupIntentLoading(false);
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.response.data.message,
          type: 'error',
        }),
      );
    }
  };

  const handleAttachCreditCard = async () => {
    if (!selectedBrand) {
      setLinkCardError(
        `${humanizeString((location || brand)?.__type)} is required`,
      );

      return;
    }

    setAttachLoading(true);

    try {
      const response = await attachCreditCard(
        (location || brand)?._id,
        selectedBrand?._id,
      );

      if (location) {
        let temp: Brand = {
          ...location,
          stripe: response.data.stripe,
        };

        dispatch(setLocation(temp));
      } else {
        let temp: Brand = {
          ...brand,
          stripe: response.data.stripe,
        };

        dispatch(setBrand(temp));
      }

      dispatch(
        toggleAlert({
          toggle: true,
          message: 'You successfully attach the credit card',
        }),
      );

      handleCloseAttachCard();
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setAttachLoading(false);
    }
  };

  const handleOpenPaymentModal = async (
    clientSecret: string,
    transactionType: string,
  ) => {
    setClientSecret(clientSecret);
    setOpenPaymentModal(true);
    setTransactionType(transactionType);
  };

  const handleClosePaymentModal = () => {
    setOpenPaymentModal(false);
  };

  const handleOnChangeCancellationForm = (e: ChangeEventType) => {
    setCancellationForm({
      ...cancellationForm,
      cancellation_details: {
        ...cancellationForm.cancellation_details,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleOpenCancellationForm = (subscriptionId: string) => {
    setSelectedSubscription(subscriptionId);
    setOpenCancelationForm(true);
  };

  const handleCloseCancellationForm = () => {
    setOpenCancelationForm(false);
  };

  const handleOpenConfirmRenew = (subscriptionId: string) => {
    setSelectedSubscription(subscriptionId);
    setOpenConfirm(true);
  };

  const handleCloseConfirmRenew = () => {
    setOpenConfirm(false);
  };

  const handleOpenInvoiceBreakdowns = (breakdown: StripeInvoiceBreakdown) => {
    setInvoiceBreakdown(breakdown);
    setOpenInvoiceBreakdown(true);
  };

  const handleCloseInvoiceBreakdowns = () => {
    setOpenInvoiceBreakdown(false);
  };

  const buildCustomerAddress = (address: StripeAddressForm) => {
    if (!address) return '';

    return `${address?.line1 ? address.line1 : ''} ${
      address?.line2 ? `${address?.line2},` : ''
    } ${address?.city ? `${address?.city},` : ''} ${
      address?.state ? `${address?.state},` : ''
    } ${address?.postal_code ? `${address?.postal_code},` : ''} ${
      address?.country
    }`;
  };

  const handleOnClickProfile = (field: string) => {
    setFieldToEdit(field);
    setIsEdit(true);
  };

  const handleOnChangeCustomerForm = (e: ChangeEventType) => {
    const isAddress = [
      'line1',
      'line2',
      'city',
      'state',
      'postal_code',
    ].includes(e.target.name);

    if (isAddress) {
      setCustomerForm({
        ...customerForm,
        address: { ...customerForm.address, [e.target.name]: e.target.value },
      });
    } else {
      setCustomerForm({ ...customerForm, [e.target.name]: e.target.value });
    }
  };

  const handleOnChangeNumber = (newValue: string) => {
    setCustomerForm({ ...customerForm, phone: newValue.split(' ').join('') });
  };

  const handleShowSubscriptionForm = () => {
    setShowSubscriptionForm(true);
  };

  const handleCloseSubscriptionForm = () => {
    setShowSubscriptionForm(false);
  };

  const handleCancelEdit = () => {
    setIsEdit(false);
    setCustomerForm({
      name: customer.name,
      email: customer.email,
      phone: customer.phone,
      address: customer.address,
      metadata: customer.metadata,
    });
    setCustomerErrors({
      address: {
        city: '',
        state: '',
        postal_code: '',
        country: '',
      },
      name: '',
      email: '',
      phone: '',
    });
  };

  const handleUpdateCustomer = async (e: FormEvent) => {
    e.preventDefault();
    setEditCustomerLoading(true);
    try {
      const response = await createOrUpdateStripeCustomer(
        customerForm,
        location?._id || brand?._id,
        'brand',
      );

      setCustomer(response.data);
      setIsEdit(false);
      setEditCustomerLoading(false);
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Customer profile has been updated successfully.',
        }),
      );
    } catch (error: any) {
      setEditCustomerLoading(false);
      const err = error.response.data;
      setCustomerErrors({
        name: err.name,
        email: err.email,
        phone: err.phone,
        address: {
          city: err['address.city'],
          state: err['address.state'],
          postal_code: err['address.postal_code'],
          country: err['address.country'],
        },
      });
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.response.data.message,
          type: 'error',
        }),
      );
    }
  };

  const handleOnExpand = (panel: any) => (event: any, newExpanded: any) => {
    setExpanded(newExpanded ? panel : false);
  };

  const buildTabs = () => {
    let tabs: string[] = [];

    if (!customer) {
      tabs = [...tabs, 'Add Information'];
    }

    if (customer) {
      tabs = [
        ...tabs,
        'Billing Profile',
        'Subscriptions',
        'Payment Methods',
        'Payment History',
        'Credit Balance',
      ];
    }

    if (!location && brand?.allowSetupDepositAccount) {
      tabs = [...tabs, 'Deposit Account'];
    }

    if (
      (isSuperAdmin || isAdmin) &&
      (IS_DEVELOPMENT || IS_STAGING) &&
      customer
    ) {
      tabs = [...tabs, 'Test Clock'];
    }

    setTabs(tabs);
  };

  const handleOpenBudgetModal = () => {
    setOpenAdBudgetModal(true);
  };

  const handleCloseBudgetModal = () => {
    setOpenAdBudgetModal(false);
  };

  const handleUpdateAdBudget = async (e: FormEvent) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await updateFees(
        location?._id || brand?._id,
        brandFeesForm,
      );

      let temp: Fee[] = [];
      brandFeesForm.forEach((fee: Fee) => {
        const budget = response.data.find((data: Fee) => {
          return fee.type === data.type;
        });
        if (fee.type === budget?.type) fee = budget;
        temp.push(fee);
      });

      setBrandFees(temp);
      setBrandFeesForm(temp);
      handleCloseBudgetModal();
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Ad Budget Update Successfully.',
        }),
      );
    } catch (error: any) {
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.message,
          type: 'error',
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const buildStripeCustomerName = () => {
    if ((location || brand)?.__type === 'location') {
      return `${(location || brand)?.franchisor?.brand}-${
        (location || brand)?.brand
      }`;
    }

    return (location || brand)?.brand;
  };

  const handleCreateCustomer = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const params: CustomerForm = {
        ...customerForm,
        name: buildStripeCustomerName(),
        metadata: {
          user_id: (location || brand)?._id,
          role: 'brand',
          brand: `${(location || brand)?.brand}`,
        },
      };

      const customerData = await createOrUpdateStripeCustomer(
        params,
        (location || brand)?._id,
        'brand',
      );

      setCustomer(customerData.data);

      if (customerData?.data) {
        let temp: Brand = {
          ...(location || brand),
          stripe: {
            ...(location || brand)?.stripe,
            customerId: customerData.data.id,
          },
        };

        if (location) {
          dispatch(setLocation(temp));
        } else {
          dispatch(setBrand(temp));
        }

        const params: StripeCreateSetupIntentForm = {
          customer: customerData.data.id,
          payment_method_types: ['card'],
          metadata: {
            user_id: (location || brand)?._id,
            role: 'brand',
            action: 'payment_method',
          },
        };

        const setupIntent = await createStripeSetupIntent(
          params,
          (location || brand)?._id,
        );

        handleOpenPaymentModal(setupIntent.data.client_secret, 'setup');
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      handleCloseTermsModal();
      setLoading(false);
    }
  };

  const handleOpenTermsModal = () => {
    setOpenTermsModal(true);
  };

  const handleCloseTermsModal = () => {
    setOpenTermsModal(false);
  };

  const handleOpenAttachCard = () => {
    setOpenAttachCardModal(true);
  };

  const handleCloseAttachCard = () => {
    setSelectedBrand(null);
    setLinkCardError(null);
    setOpenAttachCardModal(false);
  };

  const renderTabContent = (tab: string) => {
    if (tab === 'Add Information') {
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', color: '#096F4D' }}
            >
              Contact Details
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              variant="standard"
              type="email"
              id="name"
              name="email"
              label="Email"
              onChange={handleOnChangeCustomerForm}
              value={customerForm?.email}
              InputLabelProps={{ shrink: true }}
              size="small"
              autoFocus
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <MuiTelInput
              fullWidth
              required
              variant="standard"
              id="phone"
              name="phone"
              label="Phone Number"
              onChange={(newValue: any) =>
                setCustomerForm({ ...customerForm, phone: newValue })
              }
              value={customerForm?.phone}
              InputLabelProps={{ shrink: true }}
              size="small"
              onlyCountries={['US']}
              defaultCountry="US"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', color: '#096F4D' }}
            >
              Address Details
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="standard"
              type="text"
              id="line1"
              name="line1"
              label="Company Name, Street, PO Box"
              onChange={handleOnChangeCustomerForm}
              value={customerForm?.address?.line1}
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              fullWidth
              type="text"
              id="line2"
              name="line2"
              label="Apartment, Suite, Unit, Building"
              onChange={handleOnChangeCustomerForm}
              value={customerForm?.address?.line2}
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="standard"
              required
              type="text"
              id="city"
              name="city"
              label="City"
              onChange={handleOnChangeCustomerForm}
              value={customerForm?.address?.city}
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              variant="standard"
              type="text"
              id="state"
              name="state"
              label="State"
              onChange={handleOnChangeCustomerForm}
              value={customerForm?.address?.state}
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CountrySelectMenu
              value={{
                code: 'US',
                label: 'United States',
                phone: '1',
                suggested: true,
              }}
              disabled
              shrink
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              variant="standard"
              type="text"
              id="postalCode"
              name="postal_code"
              label="Postal Code"
              onChange={handleOnChangeCustomerForm}
              value={customerForm?.address?.postal_code}
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              justifyContent: 'center',
              display: 'flex',
              width: '100%',
            }}
          >
            <PrimaryButton
              title="Add Credit Card"
              type="button"
              startIcon={<AddCardIcon />}
              handleOnClick={handleOpenTermsModal}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              justifyContent: 'center',
              display: 'flex',
              width: '100%',
            }}
          >
            <PrimaryButton
              title="Link Credit Card"
              type="button"
              startIcon={<FaLink />}
              handleOnClick={handleOpenAttachCard}
            />
          </Grid>
        </Grid>
      );
    }

    if (tab === 'Billing Profile') {
      return (
        <>
          {isEdit ? (
            <Grid container spacing={1} justifyContent="center" mt={2}>
              <Grid item>
                <StripeCustomerForm
                  formValues={customerForm}
                  onChange={handleOnChangeCustomerForm}
                  field={fieldToEdit}
                  onChangeNumber={handleOnChangeNumber}
                  onCancel={handleCancelEdit}
                  onSubmit={handleUpdateCustomer}
                  loading={editCustomerLoading}
                  errors={customerErrors}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <BillingProfileCard
                  title="Brand Name"
                  value={customer?.name}
                  icon={<AccountCircleOutlinedIcon />}
                  field="name"
                />
              </Grid>

              <Grid item xs={12}>
                <BillingProfileCard
                  title="Address"
                  value={buildCustomerAddress(customer?.address) || ''}
                  icon={<LocationOnOutlinedIcon />}
                  field="address"
                  onClick={handleOnClickProfile}
                />
              </Grid>

              <Grid item xs={12}>
                <BillingProfileCard
                  title="Email"
                  value={customer?.email}
                  icon={<EmailOutlinedIcon />}
                  field="email"
                  onClick={handleOnClickProfile}
                />
              </Grid>

              <Grid item xs={12}>
                <BillingProfileCard
                  title="Phone"
                  value={customer?.phone}
                  icon={<LocalPhoneOutlinedIcon />}
                  field="phone"
                  onClick={handleOnClickProfile}
                />
              </Grid>
            </Grid>
          )}
        </>
      );
    }

    if (tab === 'Subscriptions') {
      return (
        <>
          {IS_DIY_ADZ ? (
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <SubscriptionsListTable
                  subscriptions={subscriptions}
                  loading={loading}
                  onOpenCancel={handleOpenCancellationForm}
                />
              </Grid>
            </Grid>
          ) : subscriptions.length > 0 ? (
            <SubscriptionFees
              brandFees={brandFees}
              total={feesTotal}
              onClickSubscribe={handleShowSubscriptionForm}
              subscriptions={subscriptions}
              onOpenPayment={handleOpenPaymentModal}
              onOpenRenew={handleOpenConfirmRenew}
              onOpenCancel={handleOpenCancellationForm}
              isAdmin={isSuperAdmin || isAdmin}
              onOpenAdBudget={handleOpenBudgetModal}
            />
          ) : showSubscriptionForm ? (
            <SubscriptionForm
              brandFees={brandFees}
              locationFees={locationFees}
              feeTotal={feesTotal}
              setBrandFees={setBrandFees}
              setLocationFees={setLocationFees}
              onClose={handleCloseSubscriptionForm}
              setBrandFeesForm={setBrandFeesForm}
            />
          ) : (
            <SubscriptionFees
              brandFees={brandFees}
              total={feesTotal}
              onClickSubscribe={handleShowSubscriptionForm}
            />
          )}
        </>
      );
    }

    if (tab === 'Payment Methods') {
      return (
        <>
          {isEditPaymentMethod ? (
            <Grid container spacing={2} justifyContent="center" mt={2}>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    minDate={moment().utc()}
                    views={['month', 'year']}
                    label="Select Expiration Date"
                    value={moment()
                      .set('month', paymentMethodForm.card.exp_month - 1)
                      .set('year', paymentMethodForm.card.exp_year)
                      .utc()
                      .local()}
                    onChange={(newValue) => {
                      setPaymentMethodForm({
                        ...paymentMethodForm,
                        card: {
                          ...paymentMethodForm.card,
                          exp_month: newValue.month() + 1,
                          exp_year: newValue.year(),
                        },
                      });
                    }}
                    renderInput={(params) => {
                      params.inputProps.value = `${paymentMethodForm.card.exp_month}/${paymentMethodForm.card.exp_year}`;
                      return <TextField {...params} size="small" />;
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item>
                <PrimaryButton
                  title="Save"
                  handleOnClick={() => handleUpdatePaymentMethod()}
                  type="button"
                  marginRight5
                  loading={loadingPaymentMethod}
                />

                <PrimaryButton
                  title="Cancel"
                  handleOnClick={() => setIsEditPaymentMethod(false)}
                  type="button"
                  theme="red"
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              {paymentMethods.map((payment: any, index: number) => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <PaymentMethodCard
                      payment={payment}
                      defaultPaymentMethodId={defaultPaymentMethodId}
                      onSetDefault={handleSetDefaultPaymentMethod}
                      customer={customer}
                      index={index}
                      onEdit={handleOnClickEditPaymentMethod}
                    />
                  </Grid>
                );
              })}

              <Grid item xs={12} sm={6} md={4} sx={{ minHeight: '100%' }}>
                <Card sx={{ height: '100%' }}>
                  <CardContent
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      height: '100%',
                      flexDirection: 'column',
                    }}
                  >
                    <Grid
                      container
                      spacing={1}
                      sx={{ justifyContent: 'center' }}
                    >
                      {[
                        'visa',
                        'mastercard',
                        'jcb',
                        'amex',
                        'diners',
                        'discover',
                      ].map((value, index) => (
                        <Grid item key={index}>
                          {renderCCIcon(value, 30)}
                        </Grid>
                      ))}
                    </Grid>

                    <div>
                      <PrimaryButton
                        title="Add Payment Method"
                        startIcon={<AddOutlinedIcon />}
                        handleOnClick={handleCreateSetupIntent}
                        type="button"
                        loading={setupIntentLoading}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </>
      );
    }

    if (tab === 'Payment History') {
      return (
        <BillingInvoiceListTable
          loading={loading}
          invoices={invoices}
          openBreakdown={handleOpenInvoiceBreakdowns}
        />
      );
    }

    if (tab === 'Credit Balance') {
      return (
        <>
          <Grid container spacing={1} justifyContent="center">
            <Grid
              item
              xs={12}
              sx={{ justifyContent: 'flex-end', display: 'flex' }}
            >
              <Card sx={{ width: { xs: '100%', sm: '200px' } }}>
                <CardHeader
                  title="Ending Balance"
                  subheader={
                    <CountUp
                      end={Math.abs((creditBalance?.ending_balance || 0) / 100)}
                      duration={3}
                      separator=","
                      prefix="$"
                      decimals={2}
                    />
                  }
                  titleTypographyProps={{
                    sx: {
                      color: '#096F4D',
                      fontSize: '14px',
                      paddingLeft: '0',
                      textAlign: 'center',
                    },
                  }}
                  subheaderTypographyProps={{
                    sx: {
                      color: '#096F4D',
                      fontWeight: 'bold',
                      fontSize: xsOnly ? '16px' : '19px',
                      textAlign: 'center',
                    },
                  }}
                  sx={{ padding: '10px' }}
                />
              </Card>
            </Grid>

            <Grid item xs={12}>
              <CreditBalanceListTable
                loading={loading}
                creditBalances={creditBalances}
              />
            </Grid>
          </Grid>
        </>
      );
    }

    if (tab === 'Deposit Account') {
      return (
        <>
          {showStripeAccountForm && !brand?.stripe?.accountId ? (
            <StripeAccountForm
              onCloseForm={handleOnCloseCreateStripeAccount}
              setAccount={setAccount}
            />
          ) : (
            <StripeAccountDetails
              brand={brand}
              onClickCreate={handleOnOpenCreateStripeAccount}
              formValues={accountFormValues}
              setFormValues={setAccountFormValues}
              account={account}
              setAccount={setAccount}
            />
          )}
        </>
      );
    }

    return (
      <StripeTestClockForm
        testClock={testClock}
        advanceDate={advanceDate}
        minDate={minDate}
        loading={loading}
        setAdvanceDate={setAdvanceDate}
        onAdvanceTestClock={handleAdvanceTestClock}
      />
    );
  };

  const handleOnOpenCreateStripeAccount = () => {
    setShowStripeAccountForm(true);
  };

  const handleOnCloseCreateStripeAccount = (account?: any) => {
    if (account) {
      let temp: Brand;
      temp = {
        ...brand,
        stripe: {
          ...brand?.stripe,
          accountId: account.id,
          businessType: 'company',
          completedOnboarding: false,
        },
      };

      dispatch(setBrand(temp));
    }

    setShowStripeAccountForm(false);
  };

  return (
    <div className={styles.billings}>
      <div className={styles.base}>
        <CircularLoading loading={loading} />

        <div className={styles.header}>
          <PageHeader title={IS_DIY_ADZ ? 'Billingz' : 'Billings'} />
        </div>

        <div>
          {!isMdAndDown ? (
            <TabContext value={tabValue}>
              <TabList
                onChange={handleChangeTabValue}
                variant="scrollable"
                allowScrollButtonsMobile
                scrollButtons
                textColor="secondary"
                TabIndicatorProps={{ style: { backgroundColor: 'white' } }}
                TabScrollButtonProps={{
                  style: {
                    backgroundColor: DEFAULT_COLOR_THEME,
                    color: 'white',
                  },
                }}
                sx={{
                  backgroundColor: DEFAULT_COLOR_THEME,
                  '.MuiTab-root': {
                    top: 5,
                    color: 'white',
                  },
                  '.Mui-selected': {
                    color: `${DEFAULT_COLOR_THEME} !important`,
                    backgroundColor: 'white',
                    top: 5,
                    borderRadius: 2,
                  },
                }}
              >
                {tabs?.map((tab: string, index: number) => (
                  <Tab
                    label={tab}
                    value={`${index + 1}`}
                    onClick={() => setTab(tab)}
                  />
                ))}
              </TabList>

              {tabs?.map((tab: string, index: number) => (
                <TabPanel
                  value={`${index + 1}`}
                  sx={{
                    backgroundColor: '#d9d9d933',
                    minHeight: 'calc(100vh - 310px)',
                  }}
                  key={`billing-tab-${index}`}
                >
                  {renderTabContent(tab)}
                </TabPanel>
              ))}
            </TabContext>
          ) : (
            <Box component="div" sx={{ marginTop: '10px' }}>
              {tabs?.map((tab: string, index: number) => {
                return (
                  <Accordion
                    expanded={expanded === `panel${index + 1}`}
                    onChange={handleOnExpand(`panel${index + 1}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                      aria-controls={`panel${index + 1}a-content`}
                      id={`panel${index + 1}a-header`}
                      sx={{ backgroundColor: DEFAULT_COLOR_THEME }}
                    >
                      <Typography sx={{ color: 'white' }}>{tab}</Typography>
                    </AccordionSummary>

                    <AccordionDetails sx={{ backgroundColor: '#d9d9d933' }}>
                      {renderTabContent(tab)}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          )}
        </div>

        <Grid container spacing={1} mt={1}>
          <Grid
            item
            xs={12}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: xsOnly ? 'center' : 'flex-end',
            }}
          >
            <img src={logo} alt="" width={150} />
          </Grid>
        </Grid>
      </div>

      <StripePaymentUIModal
        open={openPaymentModal}
        onClose={handleClosePaymentModal}
        clientSecret={clientSecret}
        transactionType={transactionType}
        allowRedirect={false}
      />

      <PopupModal
        open={openCancelationForm}
        handleClose={handleCloseCancellationForm}
      >
        <SubscriptionCancellationForm
          formValues={cancellationForm}
          setFormValues={setCancellationForm}
          onChange={handleOnChangeCancellationForm}
          onSubmit={handleCancelSubscription}
          loading={cancelLoading}
        />
      </PopupModal>

      <StripePaymentTermsModal
        open={openTermsModal}
        onClose={handleCloseTermsModal}
        onAgree={handleCreateCustomer}
        title="Advertising Services Agreement"
      />

      <DeleteModal
        open={openConfirm}
        handleClose={handleCloseConfirmRenew}
        title="Confirm Subscription Renewal"
        subtitle="Are you sure you want to resume this subscription?"
        loading={loading}
        handleDelete={handleRenewSubscription}
      />

      <DrawerMenu
        anchor="right"
        open={openInvoiceBreakdown}
        onClose={handleCloseInvoiceBreakdowns}
      >
        <BillingBreakdownDetails
          breakdown={invoiceBreakdown}
          onClose={handleCloseInvoiceBreakdowns}
        />
      </DrawerMenu>

      <PopupModal
        open={openAttachCardModal}
        handleClose={handleCloseAttachCard}
      >
        <LinkCardForm
          brand={location || brand}
          isAdmin={isSuperAdmin || isAdmin}
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
          onAttach={handleAttachCreditCard}
          attachLoading={attachLoading}
          error={linkCardError}
          setError={setLinkCardError}
        />
      </PopupModal>
    </div>
  );
};

export default Billings;
