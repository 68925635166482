import React, { useContext, useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DeleteModal } from '../../components/Modal';
import { Brand, ChangeEventType } from '../../types';
import {
  deleteBrand,
  getBrandsAssociatedLocations,
} from '../../services/brand';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../context';
import {
  ADMIN,
  AGENCY,
  BRAND_MANAGER,
  IS_DIY_ADZ,
  SALESPERSON,
  SUPER_ADMIN,
} from '../../utils';
import { PrimaryButton } from '../../components/Buttons';
import styles from '../../assets/styles/pages/Brands.module.scss';
import PageHeader from '../../components/PageHeader';
import { useDispatch, useSelector } from 'react-redux';
import LocationListTable from '../../components/Tables/BrandList/LocationListTable';
import { toggleAlert } from '../../redux/actions';

const Locations: React.FC = () => {
  const dispatch = useDispatch();
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const { state } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const agencyId: string = searchParams.get('agencyId');
  const agencyName: string = searchParams.get('agency');
  const [rowData, setRowData] = useState<number>(0);
  const isAdmin = state.role === ADMIN;
  const isSalesperson = state.role === SALESPERSON;
  const isAgency = state.role === AGENCY;
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isBrandManager = state.role === BRAND_MANAGER;
  const navigate = useNavigate();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [locations, setLocations] = useState<Brand[]>([]);
  const [locationToDelete, setLocationToDelete] = useState<string>('');
  const [locationFilter, setLocationFilter] = useState<string>('active');
  const [sortedFields, setSortedField] = useState<string>('brand');
  const [sortType, setSortType] = useState<string>('asc');
  const [loading, setLoading] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  useEffect(() => {
    if (brand) {
      setLocations([]);
      getAllLocations(
        paginationModel.page + 1,
        paginationModel.pageSize,
        locationFilter,
        sortedFields,
        sortType,
      );
    }
  }, [brand, paginationModel]);

  const getAllLocations = async (
    pageNum: number,
    limit: number,
    filter: string,
    sortedField: string,
    sortType: string,
  ) => {
    try {
      setLoading(true);

      const response = await getBrandsAssociatedLocations(
        brand?._id,
        pageNum,
        limit,
        filter,
        sortedField,
        sortType,
      );

      setLocations(response.data);
      setRowData(response.count);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleOnClickDelete = (id: string) => {
    setOpenDeleteModal(true);
    setLocationToDelete(id);
  };

  const handleDeleteLocation = async () => {
    setDeleteLoading(true);
    const { id } = await deleteBrand(locationToDelete);
    if (id) {
      setLocations(locations.filter((s) => s._id !== id));
      setOpenDeleteModal(false);
      setDeleteLoading(false);
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Brand deleted successfully',
        }),
      );
    }
  };

  const setFilter = async (filter: string) => {
    setLocationFilter(filter);
    setPaginationModel({ page: 0, pageSize: 25 });
    getAllLocations(1, 25, filter, sortedFields, sortType);
  };

  const setSort = async (sortedField: string, sortType: string) => {
    setSortedField(sortedField);
    setSortType(sortType);
    getAllLocations(
      paginationModel.page + 1,
      paginationModel.pageSize,
      locationFilter,
      sortedField,
      sortType,
    );
  };

  const handleClickEdit = (locationId: string) => {
    navigate(
      `/${
        IS_DIY_ADZ ? 'locationz' : 'locations'
      }/edit?locationId=${locationId}`,
    );
  };

  return (
    <div className={styles.brand}>
      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader title="Locations" />

          <div className={styles.controls}>
            <RadioGroup
              row
              value={locationFilter}
              onChange={(e: ChangeEventType) => setFilter(e.target.value)}
            >
              <FormControlLabel
                value="active"
                control={<Radio size="small" />}
                label="Active"
              />

              <FormControlLabel
                value="inactive"
                control={<Radio size="small" />}
                label="Inactive"
              />
            </RadioGroup>

            <div
              className={`${
                !isSuperAdmin && !isAdmin ? styles['buttons'] : ''
              }`}
            >
              <PrimaryButton
                type="button"
                handleOnClick={() =>
                  navigate(`/${IS_DIY_ADZ ? 'locationz' : 'locations'}/create`)
                }
                title="Add New Location"
                startIcon={<AddIcon />}
              />
            </div>
          </div>
        </div>

        <div>
          <LocationListTable
            locations={locations}
            isAdmin={isAdmin || isSuperAdmin}
            isAgency={isAgency}
            isSalesperson={isSalesperson}
            isBrandManager={isBrandManager}
            handleOnClickDelete={handleOnClickDelete}
            loading={loading}
            rowData={rowData}
            onSort={setSort}
            agencyId={agencyId}
            agencyName={agencyName}
            onClickEdit={handleClickEdit}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        </div>

        <DeleteModal
          title="Delete Location"
          subtitle="Are you want to delete this location, It'll remove all data belongs to it permanently!"
          open={openDeleteModal}
          handleClose={handleCloseDeleteModal}
          handleDelete={handleDeleteLocation}
          loading={deleteLoading}
        />
      </div>
    </div>
  );
};

export default Locations;
