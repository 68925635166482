import { Box, Grid, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from '../../assets/styles/pages/Dashboard.module.scss';
import CircularLoading from '../../components/CircularLoading';
import moment, { Moment } from 'moment';
import DateRangePicker from '../../components/DateAndTimePickers/DateRangePicker';
import ScatterChart from '../../components/Dashboard/ScatterChart';
import TemplateOverviewTable from '../../components/Tables/ReportList/TemplateOverviewTable';
import { NoData } from '../NoContent';
import { Brand, ScoreboardScatter, ScoreboardTemplateData } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDiyScoreboardScatterData,
  getDiyScoreboardTemplateData,
} from '../../services/report';
import { AnalyticTools } from '../../utils/helpers/BrandHelpers';
import { useNavigate } from 'react-router-dom';
import { IS_DIY_ADZ } from '../../utils';
import { setEndRange, setStartRange } from '../../redux/actions';
import { DEFAULT_COLOR_THEME } from '../../utils/Styling';

const Scoreboard: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const theme = useTheme();
  const xsOnly = theme.breakpoints.only('xs');
  const startRange = useSelector((state: any) => state?.startRange?.date);
  const endRange = useSelector((state: any) => state?.endRange?.date);

  const [loading, setLoading] = useState<boolean>(false);
  const [leadsScatterChartData, setLeadsScatterChartData] = useState<any>(null);
  const [visitorsScatterChartData, setVisitorsScatterChartData] =
    useState<any>(null);
  const [templateAnalytics, setTemplateAnalytics] = useState<
    ScoreboardTemplateData[]
  >([]);
  const [scatterData, setScatterData] = useState<ScoreboardScatter>({
    leads: [],
    visitors: [],
  });

  useEffect(() => {
    if ((location || brand) && IS_DIY_ADZ) {
      navigate('/reportz/kpiz');
    }
  }, [location, brand, IS_DIY_ADZ]);

  useEffect(() => {
    if ((location || brand) && startRange && endRange) {
      getScatterData();
      getTemplateAnalytics();

      setLeadsScatterChartData(null);
      setVisitorsScatterChartData(null);
    }
  }, [location, brand, startRange, endRange]);

  useEffect(() => {
    if (scatterData?.leads?.length > 0) {
      let datasets: any[] = [];
      if ((location || brand)?.__type === 'location') {
        let count: number = 0;

        scatterData?.leads?.forEach((lead: any) => {
          let params: any = {
            data: [
              { x: lead.leadCount, y: lead.spend, breakdown: lead.breakdown },
            ],
            pointRadius: xsOnly ? 6 : 8,
            pointHoverRadius: xsOnly ? 4 : 6,
          };
          if (lead._id === (location || brand)?._id) {
            params = {
              ...params,
              label: lead.location,
              backgroundColor: '#BD3B1B',
            };
          } else {
            count++;
            params = {
              ...params,
              label: `Location ${count}`,
              backgroundColor: '#D8A800',
            };
          }

          datasets = [...datasets, params];
        });
      } else {
        scatterData?.leads?.forEach((lead: any) => {
          let params: any = {
            data: [
              { x: lead.leadCount, y: lead.spend, breakdown: lead.breakdown },
            ],
            label: lead.location,
            backgroundColor: '#D8A800',
            pointRadius: xsOnly ? 6 : 8,
            pointHoverRadius: xsOnly ? 4 : 6,
          };

          datasets = [...datasets, params];
        });
      }

      setLeadsScatterChartData({ datasets });
    }

    if (scatterData?.visitors?.length > 0) {
      let datasets: any[] = [];
      if ((location || brand)?.__type === 'location') {
        let count: number = 0;

        scatterData?.visitors?.forEach((visitor: any) => {
          let params: any = {
            data: [
              {
                x: visitor.visitorCount,
                y: visitor.spend,
                breakdown: visitor.breakdown,
              },
            ],
            pointRadius: xsOnly ? 6 : 8,
            pointHoverRadius: xsOnly ? 4 : 6,
          };
          if (visitor._id === (location || brand)?._id) {
            params = {
              ...params,
              label: visitor.location,
              backgroundColor: '#BD3B1B',
            };
          } else {
            count++;
            params = {
              ...params,
              label: `Location ${count}`,
              backgroundColor: '#D8A800',
            };
          }

          datasets = [...datasets, params];
        });
      } else {
        scatterData?.visitors?.forEach((visitor: any) => {
          let params: any = {
            data: [
              {
                x: visitor.visitorCount,
                y: visitor.spend,
                breakdown: visitor.breakdown,
              },
            ],
            label: visitor.location,
            backgroundColor: '#D8A800',
            pointRadius: xsOnly ? 6 : 8,
            pointHoverRadius: xsOnly ? 4 : 6,
          };

          datasets = [...datasets, params];
        });
      }

      setVisitorsScatterChartData({ datasets });
    }
  }, [scatterData]);

  const getScatterData = async () => {
    setLoading(true);
    try {
      const response = await getDiyScoreboardScatterData(
        (location || brand)?.__type === 'location'
          ? (location || brand)?.franchisor?._id
          : (location || brand)?._id,
        startRange,
        endRange,
      );

      setScatterData(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getTemplateAnalytics = async () => {
    setLoading(true);
    try {
      const response = await getDiyScoreboardTemplateData(
        (location || brand)?.__type === 'location'
          ? (location || brand)?.franchisor?._id
          : (location || brand)?._id,
        startRange,
        endRange,
      );

      setTemplateAnalytics(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeDateRange = (dates: Moment[] | []) => {
    dispatch(setStartRange(dates[0]));
    dispatch(setEndRange(dates[1]));
  };

  return (
    <Box
      sx={{
        minHeight: 'calc(100vh - 100px)',
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '10px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#F1F0F0',
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
        },

        '&::-webkit-scrollbar-thumb': {
          background: DEFAULT_COLOR_THEME,
          borderRadius: '18px',

          '&:hover': {
            background: DEFAULT_COLOR_THEME,
          },
        },
      }}
    >
      <div className={styles.dashboard}>
        <CircularLoading loading={loading} />

        <Grid container spacing={1} justifyContent="center" columns={14}>
          <Grid item xs={14} justifyContent="center">
            <Typography variant="body2" fontWeight="bold" textAlign="center">
              {`${startRange?.format('LL')} - ${endRange?.format('LL')}`}
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
            <DateRangePicker
              toDate={endRange}
              fromDate={startRange}
              onChange={handleChangeDateRange}
              maxDate={moment()}
              separator="-"
            />
          </Grid>

          <Grid container spacing={2} justifyContent="center" mt={1}>
            {leadsScatterChartData ? (
              <Grid item xs={12}>
                <ScatterChart
                  scatterChartData={leadsScatterChartData}
                  yIsCurrency
                  xLabel="Leads"
                  yLabel="Spend"
                  title="Leads"
                  showLegend={false}
                  subtitle="Leads from past 30 days"
                  titleFontSize={16}
                  isLead
                />
              </Grid>
            ) : null}

            {visitorsScatterChartData ? (
              <Grid item xs={12}>
                <ScatterChart
                  scatterChartData={visitorsScatterChartData}
                  yIsCurrency
                  xLabel="Visitors"
                  yLabel="Spend"
                  title="Visitors"
                  showLegend={false}
                  subtitle="Visitors from past 30 days"
                  titleFontSize={16}
                />
              </Grid>
            ) : null}

            {templateAnalytics.length > 0 && !loading ? (
              <Grid item xs={12}>
                <TemplateOverviewTable templateAnalytics={templateAnalytics} />
              </Grid>
            ) : null}

            {!visitorsScatterChartData &&
            !leadsScatterChartData &&
            !(templateAnalytics.length > 0) &&
            !loading ? (
              <Grid item xs={12}>
                <NoData />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default Scoreboard;
