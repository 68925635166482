import { GridColDef } from '@mui/x-data-grid-premium';

export const budgetChatzTableColumns: GridColDef[] = [
  {
    field: 'brand',
    headerName: 'Brand',
    width: 150,
    valueGetter: (_, row) => row.brandName,
    renderCell: (params) => {
      const { brandName } = params.row;

      return <div>{brandName}</div>;
    },
  },
  {
    field: 'january',
    headerName: 'Jan',
    flex: 2,
    type: 'number',
    editable: true,
    valueFormatter: (value) => {
      if (!value) {
        return '';
      }

      return value;
    },
  },
  {
    field: 'february',
    headerName: 'Feb',
    flex: 2,
    type: 'number',
    editable: true,
    valueFormatter: (value) => {
      if (!value) {
        return '';
      }

      return value;
    },
  },
  {
    field: 'march',
    headerName: 'Mar',
    flex: 2,
    type: 'number',
    editable: true,
    valueFormatter: (value) => {
      if (!value) {
        return '';
      }

      return value;
    },
  },
  {
    field: 'april',
    headerName: 'Apr',
    flex: 2,
    type: 'number',
    editable: true,
    valueFormatter: (value) => {
      if (!value) {
        return '';
      }

      return value;
    },
  },
  {
    field: 'may',
    headerName: 'May',
    flex: 2,
    type: 'number',
    editable: true,
    valueFormatter: (value) => {
      if (!value) {
        return '';
      }

      return value;
    },
  },
  {
    field: 'june',
    headerName: 'Jun',
    flex: 2,
    type: 'number',
    editable: true,
    valueFormatter: (value) => {
      if (!value) {
        return '';
      }

      return value;
    },
  },
  {
    field: 'july',
    headerName: 'Jul',
    flex: 2,
    type: 'number',
    editable: true,
    valueFormatter: (value) => {
      if (!value) {
        return '';
      }

      return value;
    },
  },
  {
    field: 'august',
    headerName: 'Aug',
    flex: 2,
    type: 'number',
    editable: true,
    valueFormatter: (value) => {
      if (!value) {
        return '';
      }

      return value;
    },
  },
  {
    field: 'september',
    headerName: 'Sept',
    flex: 2,
    type: 'number',
    editable: true,
    valueFormatter: (value) => {
      if (!value) {
        return '';
      }

      return value;
    },
  },
  {
    field: 'october',
    headerName: 'Oct',
    flex: 2,
    type: 'number',
    editable: true,
    valueFormatter: (value) => {
      if (!value) {
        return '';
      }

      return value;
    },
  },
  {
    field: 'november',
    headerName: 'Nov',
    flex: 2,
    type: 'number',
    editable: true,
    valueFormatter: (value) => {
      if (!value) {
        return '';
      }

      return value;
    },
  },
  {
    field: 'december',
    headerName: 'Dec',
    flex: 2,
    type: 'number',
    editable: true,
    valueFormatter: (value) => {
      if (!value) {
        return '';
      }

      return value;
    },
  },
];
