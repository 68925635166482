import { Box } from '@mui/system';
import React, { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { Brand } from '../../../types';
import { XsOnly } from '../../../utils/breakpoints';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import {
  locationAdminColumns,
  locationMobileColumns,
} from './LocationListTableColumn';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { CustomLoadingOverlay } from '../../Loading';

interface LocationListTableProps {
  locations: Brand[];
  handleOnClickDelete: (id: string) => void;
  loading: boolean;
  isAdmin: boolean;
  isAgency: boolean;
  isSalesperson: boolean;
  rowData: number;
  isBrandManager: boolean;
  onSort: (sortedField: string, sortType: string) => void;
  agencyId: string;
  agencyName: string;
  onClickEdit: (locationId: string) => void;
  paginationModel: any;
  setPaginationModel: Dispatch<SetStateAction<any>>;
}

const LocationListTable: React.FC<LocationListTableProps> = ({
  locations,
  handleOnClickDelete,
  loading,
  isAdmin,
  isAgency,
  isSalesperson,
  rowData,
  isBrandManager,
  onSort,
  agencyId,
  agencyName,
  onClickEdit,
  paginationModel,
  setPaginationModel,
}) => {
  const navigate = useNavigate();

  const renderColumns = () => {
    if (XsOnly()) {
      return locationMobileColumns;
    } else {
      return locationAdminColumns;
    }
  };

  const rows = locations.slice().map((location: Brand, index: number) => {
    return {
      brand: location.brand,
      agency: location.agency,
      investmentRequirement: location.investmentRequirement,
      allLeads: location.allLeads,
      zipcodereq: location.zipcodereq,
      financialreq: location.financialreq,
      redirect: location.redirect,
      redirectlink: location.redirectlink,
      redirecttext: location.redirecttext,
      industryRequirement: location.industryRequirement,
      industryQuestion: location.industryQuestion,
      schedulecall: location.schedulecall,
      calendly: location.calendly,
      salesperson: location.salesperson,
      manager: location.manager,
      salespersonTitle: location.salespersonTitle,
      _id: location._id,
      id: location.brandId,
      phoneNumReq: location.phoneNumReq,
      budgetOffset: location.budgetOffset || 100,
      isAdmin,
      isSalesperson,
      isBrandManager,
      isAgency,
      cannedResponses: location.cannedResponses,
      active: location.active,
      paused: location.paused,
      lastPausedAt: location.lastPausedAt,
      page: {
        _id: location.page?._id,
        name: location.page?.name,
        accessToken: location.page?.accessToken,
        fbPageId: location.page?.fbPageId,
        imageUrl: location.page?.imageUrl,
      },
      facebookBusinessIds: location.facebookBusinessIds,
      googleCustomerIds: location.googleCustomerIds,
      allowLocations: location.allowLocations,
      franchisor: location.franchisor,
      allowSetupDepositAccount: location.allowSetupDepositAccount,
      allowSetupPayment: location.allowSetupPayment,
      allowOptions: location.allowOptions,
      __type: location.__type,
      stripe: location.stripe,
      handleOnClickDelete: handleOnClickDelete,
      navigate: navigate,
      agencyId: agencyId,
      agencyName: agencyName,
      onClickEdit,
    };
  });

  return (
    <div>
      <Box>
        <DataGridPremium
          className={styles.table}
          density="compact"
          rows={rows}
          columns={renderColumns()}
          autoHeight
          hideFooterSelectedRowCount
          rowCount={rowData}
          pageSizeOptions={[25, 50, 100]}
          slots={{
            loadingOverlay: CustomLoadingOverlay,
          }}
          loading={loading}
          onSortModelChange={(e: any) => {
            onSort(e[0]?.field || 'brand', e[0]?.sort || 'asc');
          }}
          sortingMode="server"
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
      </Box>
    </div>
  );
};

export default LocationListTable;
