import * as React from 'react';
import { Box, Button } from '@mui/material';
import { GoogleAssetsMainMenu as GoogleAssetsMainMenuTabs } from '../../utils/constants/google';

import { DEFAULT_COLOR_THEME } from '../../utils';

interface GoogleAssetsMainMenuProps {
  setGoogleAssetzMainMenu: any;
  selectedMainmenu: any;
  checkDirty: any;
}

const GoogleAssetsMainMenu: React.FC<GoogleAssetsMainMenuProps> = ({
  setGoogleAssetzMainMenu,
  selectedMainmenu,
  checkDirty,
}) => {
  const isSelected = (at: any) => {
    if (at === selectedMainmenu) {
      return {
        fontWeight: 800,
        color: `#ffffff`,
        pointerEvents: `none`,
        paddingBottom: 1,
      };
    } else {
      return {
        color: DEFAULT_COLOR_THEME,
        bgcolor: `#ffffff`,
        paddingBottom: 1,
      };
    }
  };
  return (
    <Box
      sx={{ width: `100%` }}
      bgcolor={`#ffffff`}
      padding={0}
      margin={0}
      overflow={`hidden`}
      paddingTop={GoogleAssetsMainMenuTabs.length <= 1 ? 2 : 0}
    >
      <Box
        sx={{
          gap: 0,
          color: DEFAULT_COLOR_THEME,
          bgcolor: `#ffffff`,
          paddingBottom: 0,
          paddingTop: 1,
          paddingX: 4,
          width: `100%`,
          marginBottom: -1,
          display: GoogleAssetsMainMenuTabs.length <= 1 ? 'none' : 'flex',
        }}
      >
        {GoogleAssetsMainMenuTabs.map((at) => {
          return (
            <Button
              sx={isSelected(at)}
              onClick={() => {
                checkDirty(() => {
                  setGoogleAssetzMainMenu(at);
                });
              }}
              variant="contained"
            >
              {at}
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};
export default GoogleAssetsMainMenu;
