import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Modal,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PrimaryButton } from '../Buttons';
import { DEFAULT_COLOR_THEME } from '../../utils';

interface StripePaymentTermsModalProps {
  open: boolean;
  onClose: () => void;
  onAgree: React.FormEventHandler<HTMLFormElement> | undefined;
  title?: string;
}

const StripePaymentTermsModal: React.FC<StripePaymentTermsModalProps> = ({
  open,
  onClose,
  onAgree,
  title = 'Payment Terms and Service',
}) => {
  const [iAgree, setIAgree] = useState<boolean>(false);

  useEffect(() => {
    if (!open) setIAgree(false);
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        component="form"
        onSubmit={onAgree}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: '500px',
          maxWidth: '600px',
        }}
      >
        <Container
          sx={{
            maxHeight: '350px',
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              width: '10px',
            },
            '&::-webkit-scrollbar-track': {
              // background: "#F1F0F0",
              borderTopRightRadius: '5px',
              borderBottomRightRadius: '5px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: DEFAULT_COLOR_THEME,
              borderRadius: '18px',

              '&:hover': {
                background: DEFAULT_COLOR_THEME,
              },
            },
            bgcolor: 'lightgrey',
            border: '1px',
          }}
        >
          <Typography
            component="div"
            variant="h6"
            sx={{ textAlign: 'center', fontWeight: 'bold', marginTop: '10px' }}
          >
            {title}
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginTop: '10px', fontWeight: 'bold' }}
          >
            Last Modified:
            <Box
              component="span"
              sx={{ fontWeight: 'normal', marginLeft: '5px' }}
            >
              Test
            </Box>
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px' }}
          >
            This Advertising Services Agreement (“Agreement”) is a binding
            contract between you ("
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Customer
            </Box>
            ," "
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              you
            </Box>
            ," or "
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              your
            </Box>
            ") and Sales Chatz LLC (“
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Company
            </Box>
            ,” "
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              we
            </Box>
            ," or "
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              us
            </Box>
            "). This Agreement governs your access to and use of the Company's
            advertising management platform and related services (“
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Services
            </Box>
            ”).
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px' }}
          >
            THIS AGREEMENT TAKES EFFECT WHEN YOU CLICK THE "I ACCEPT" BUTTON OR
            BY ACCESSING OR USING THE SERVICES (the "
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Effective Date
            </Box>
            "). By clicking "I Accept" on the Company's Platform, you represent
            and warrant that: (i) You have read, understand, and agree to be
            bound by the terms of this Agreement; and (ii) You have the
            authority to enter into this Agreement. This Agreement will not take
            effect until you click the "I Accept" button or by using or
            accessing the Services. If you do not agree to these terms, do not
            click the "I Accept" button or access any Services.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px' }}
          >
            IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE SERVICES. IF YOU
            DO NOT ACCEPT THESE TERMS, YOU MAY NOT ACCESS OR USE THE SERVICES.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '20px' }}
          >
            1. Definitions.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (a) "
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Authorized User
            </Box>
            " means Customer and Customer's employees, consultants, contractors,
            and agents (i) who are authorized by Customer to access and use the
            Services under the rights granted to Customer pursuant to this
            Agreement and (ii) for whom access to the Services has been
            purchased hereunder.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (b) "
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Services
            </Box>
            " means the services provided by Company under this Agreement that
            are detailed on Company's website available at
            https://saleschatz.com/ and reflected in this Agreement.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (c) "
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Customer Data
            </Box>
            " means, other than Aggregated Statistics, information, data, and
            other content, in any form or medium, that is submitted, posted, or
            otherwise transmitted by or on behalf of Customer or any other
            Authorized User through the Services.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (d) "
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Documentation
            </Box>
            " means Company's user manuals, handbooks, and guides relating to
            the Services provided by Company to Customer either electronically
            or in hard copy form/end user documentation relating to the
            Services.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (e) "
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Company IP
            </Box>
            " means the Services, the Documentation, and all intellectual
            property provided to Customer or any other Authorized User in
            connection with the foregoing. For the avoidance of doubt, Company
            IP includes Aggregated Statistics and any information, data, or
            other content derived from Company's monitoring of Customer's access
            to or use of the Services, but does not include Customer Data.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (f) "
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Third-Party Products
            </Box>
            " means any products, content, services, information, websites, or
            other materials that are owned by third parties and are incorporated
            into or accessible through the Services.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (g) "
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Franchisor
            </Box>
            " means a company that owns and provides the rights to its business
            brand and methods to others and is designated in the Platform as
            such.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (h) "
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Franchisor Ad Account
            </Box>
            " Deposit account designated by Franchisor on the Platform and used
            to capture Net Media Spend.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (i) "
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Total Media Spend
            </Box>
            " means the total amount in USD that a Franchisee spends on
            advertisements.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (j) "
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Net Media Spend
            </Box>
            " means the Total Media Spend less the Admin Fee.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (k) "
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Franchisee
            </Box>
            " means a person or company that licenses from the Franchisor to run
            a business using the Franchisor's brand and methods and is
            designated in the Platform as such.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (l) "
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Platform
            </Box>
            " means the software dashboard that enables Customer and Authorized
            Users to access and use Services.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            {`(m) `}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Clarity of Naming
            </Box>
            . For the sake of clarity, when we refer to the “Customer” in this
            Agreement we are referring to both the Franchisor and the
            Franchisee. When we refer to the Franchisee or the Franchisor, we
            are specifically referring to the party referenced.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '20px' }}
          >
            2. Services.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (a) Company will provide the following Services to Franchisors
            subject to the terms and conditions of this Agreement: (i) Company
            will provide Platform access that enables franchisees to select
            desired franchisor ad templates and parameters on Company's
            platform; (ii) Company will provide Platform access that enables
            Franchisor and Franchisee ad budget processing and tracking; (iii)
            Company will enable depositing net ad spend amounts into
            Franchisor's account subject to the various Fees outlined in this
            Agreement; and (iv)Company will provide reporting and analytics to
            Franchisor on overall ad performance.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (b) Company will provide the following Services to Franchisees
            subject to the terms and conditions of this Agreement: (i) Select
            desired ad templates created by Franchisor; (ii) Input information
            on budget, dates, locations/geography; (iii) Make limited edits to
            templates based on options set by Franchisor; (iv) Company will
            facilitate payment processing and campaign tracking; and (v)
            Franchisees must submit ad campaign parameters at least 2 weeks
            prior to the intended campaign launch date. Franchisors may reject
            any Franchisee proposed ad campaigns.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (c){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Right to Modify or Discontinue Services
            </Box>
            . Company reserves the right, at its sole discretion, to modify,
            enhance, or discontinue any of its services, in whole or in part, at
            any time, with or without notice. Company will not be liable to the
            Customer or any third party for any modification, suspension, or
            discontinuance of the services. It is the Customer’s responsibility
            to ensure that they are aware of any such changes, which may be
            communicated through the Company’s website or other means.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (d){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Service Interruption
            </Box>
            . In the event of a system downtime or service interruption causing
            temporary unavailability of the advertising platform, Company shall
            not be liable for any loss or damage arising from such interruption.
            Reasonable efforts will be made to promptly restore the service to
            full capacity within a reasonable time for the severity of the
            outage. The Customer acknowledges that temporary interruptions may
            occur as normal events in the provision of the Services and agrees
            not to hold Company liable for such interruptions.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '20px' }}
          >
            3. Access and Use.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (a){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Provision of Access
            </Box>
            . Subject to and conditioned on your payment of Fees and compliance
            with all the terms and conditions of this Agreement, Company hereby
            grants you a revocable, non-exclusive, non-transferable,
            non-sublicensable, limited right to access and use the Services
            during the Term solely for your internal business operations by
            Authorized Users in accordance with the terms and conditions herein.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (b){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Documentation License
            </Box>
            . Subject to the terms and conditions contained in this Agreement,
            Company hereby grants you a non-exclusive, non-sublicensable,
            non-transferable license for Authorized Users to use the
            Documentation during the Term solely for your internal business
            purposes in connection with use of the Services.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (c){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Use Restrictions
            </Box>
            . You shall not, and shall not permit any Authorized Users to, use
            the Services, any software component of the Services, or
            Documentation for any purposes beyond the scope of the access
            granted in this Agreement. You shall not at any time, directly or
            indirectly, and shall not permit any Authorized Users to: (i) copy,
            modify, or create derivative works of the Services, any software
            component of the Services, or Documentation, in whole or in part;
            (ii) rent, lease, lend, sell, license, sublicense, assign,
            distribute, publish, transfer, or otherwise make available the
            Services or Documentation except as expressly permitted under this
            Agreement; (iii) reverse engineer, disassemble, decompile, decode,
            adapt, or otherwise attempt to derive or gain access to any software
            component of the Services, in whole or in part; (iv) remove any
            proprietary notices from the Services or Documentation; or (v) use
            the Services or Documentation in any manner or for any purpose that
            infringes, misappropriates, or otherwise violates any intellectual
            property right or other right of any person, or that violates any
            applicable law, regulation, or rule.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (d){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Aggregated Statistics
            </Box>
            . Notwithstanding anything to the contrary in this Agreement,
            Company may monitor Customer's use of the Services and collect and
            compile data and information related to Customer's use of the
            Services to be used by Company in an aggregated and anonymized
            manner, including to compile statistical and performance information
            related to the provision and operation of the Services ("Aggregated
            Statistics"). As between Company and Customer, all right, title, and
            interest in Aggregated Statistics, and all intellectual property
            rights therein, belong to and are retained solely by Company. You
            acknowledge that the Company may compile Aggregated Statistics based
            on Customer Data input into the Services. You agree that the Company
            may (i) make Aggregated Statistics publicly available in compliance
            with applicable law, and (ii) use Aggregated Statistics to the
            extent and in the manner permitted under applicable law.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (e){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Reservation of Rights
            </Box>
            . Company reserves all rights not expressly granted to Customer in
            this Agreement. Except for the limited rights and licenses expressly
            granted under this Agreement, nothing in this Agreement grants, by
            implication, waiver, estoppel, or otherwise, to Customer or any
            third party, any intellectual property rights or other right, title,
            or interest in or to the Company IP.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (f){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Suspension
            </Box>
            . Notwithstanding anything to the contrary in this Agreement,
            Company may temporarily suspend Customer's and any other Authorized
            User's access to any portion or all of the Services if: (i) Company
            reasonably determines that (A) there is a threat or attack on any of
            the Company IP; (B) Customer's or any other Authorized User's use of
            the Company IP disrupts or poses a security risk to the Company IP
            or to any other customer or vendor of Company; (C) Customer or any
            other Authorized User is using the Company IP for fraudulent or
            illegal activities; (D) subject to applicable law, Customer has
            ceased to continue its business in the ordinary course, made an
            assignment for the benefit of creditors or similar disposition of
            its assets, or become the subject of any bankruptcy, reorganization,
            liquidation, dissolution, or similar proceeding; or (E) Company's
            provision of the Services to Customer or any other Authorized User
            is prohibited by applicable law; (ii) any vendor of Company has
            suspended or terminated Company's access to or use of any
            third-party services or products required to enable Customer to
            access the Services; or (iii) in accordance with 6 (any such
            suspension described in subclause (i), (ii), or (iii), a "
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Service Suspension
            </Box>
            "). Company shall use commercially reasonable efforts to provide
            written notice of any Service Suspension to Customer and to provide
            updates regarding resumption of access to the Services following any
            Service Suspension. Company shall use commercially reasonable
            efforts to resume providing access to the Services as soon as
            reasonably possible after the event giving rise to the Services
            Suspension is cured. Company will have no liability for any damage,
            liabilities, losses (including any loss of or profits), or any other
            consequences that Customer or any other Authorized User may incur as
            a result of a Service Suspension.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (g){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Account Disablement
            </Box>
            . Company is not responsible for any loss, damage, or liability
            resulting from the suspension or disablement of the Customer’s or
            any Authorized User's ad account by third-party platforms. It is the
            Customer’s responsibility to maintain their ad account in good
            standing according to the terms and policies of such third-party
            platforms. Company Name shall not be obligated to provide refunds or
            credits for the period during which the ad account remains disabled
            or suspended.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{
              marginBottom: '10px',
              marginLeft: '20px',
            }}
          >
            4.{' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Customer Responsibilities.
            </Box>
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (a){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Acceptable Use
            </Box>
            . The Services may not be used for unlawful, fraudulent, offensive,
            or obscene activity. You will comply with all terms and conditions
            of this Agreement, all applicable laws, rules, and regulations, and
            all guidelines, Content Standards, and requirements listed below.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '60px' }}
          >
            (i){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Prohibited Uses
            </Box>
            . You may use the Services only for lawful purposes and in
            accordance with this Agreement. You agree not to use the Services:
            (1) In any way that violates any applicable federal, state, local,
            or international law or regulation (including, without limitation,
            any laws regarding the export of data or software to and from the US
            or other countries); (2) For the purpose of exploiting, harming, or
            attempting to exploit or harm minors in any way by exposing them to
            inappropriate content, asking for personally identifiable
            information, or otherwise; (3) To send, knowingly receive, upload,
            download, use, or re-use any material that does not comply with this
            Agreement; (4) To transmit, or procure the sending of any "junk
            mail," "chain letter," "spam," or any other similar solicitation;
            (5) To impersonate or attempt to impersonate the Company, a Company
            employee, another user, or any other person or entity (including,
            without limitation, by using email addresses associated with any of
            the foregoing); or (6) To engage in any other conduct that restricts
            or inhibits anyone's use of the Services, or which, as determined by
            us, may harm the Company or users of the Services, or expose them to
            liability.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '60px' }}
          >
            (ii){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Additional Prohibited Uses
            </Box>
            . Additionally, you agree not to: (1) Use the Services in any manner
            that could disable, overburden, damage, or impair the site or
            interfere with any other party's use of the Services; (2) Use any
            robot, spider, or other automatic device, process, or means to
            access the Services for any purpose, including monitoring or copying
            any of the material on the Platform; (3) Use any manual process to
            monitor or copy any of the material on the Platform, or for any
            other purpose not expressly authorized in this Agreement, without
            our prior written consent; (4) Use any device, software, or routine
            that interferes with the proper working of the Platform or Services;
            (5) Introduce any viruses, Trojan horses, worms, logic bombs, or
            other material that is malicious or technologically harmful; (6)
            Attempt to gain unauthorized access to, interfere with, damage, or
            disrupt any parts of the Platform, the server on which the Platform
            is stored, or any server, computer, or database connected to the
            Platform; (7) Attack the Platform via a denial-of-service attack or
            a distributed denial-of-service attack; or (8) Otherwise attempt to
            interfere with the proper working of the Platform.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '60px' }}
          >
            (iii){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Content Standards
            </Box>
            . These content standards apply to any and all advertising content
            uploaded by any Franchisor or Franchisee in the Platform (the
            “Advertising Content”) and use of the Services. Advertising Content
            must not: (1) Contain any material that is defamatory, obscene,
            indecent, abusive, offensive, harassing, violent, hateful,
            inflammatory, or otherwise objectionable; (2) Promote sexually
            explicit or pornographic material, violence, or discrimination based
            on race, sex, religion, nationality, disability, sexual orientation,
            or age; (3) Infringe any patent, trademark, trade secret, copyright,
            or other intellectual property or other rights of any other person;
            (4) Violate the legal rights (including the rights of publicity and
            privacy) of others or contain any material that could give rise to
            any civil or criminal liability under applicable laws or regulations
            or that otherwise may be in conflict with this Agreement and our
            Privacy Policy; (5) Be likely to deceive any person; (6) Promote any
            illegal activity, or advocate, promote, or assist any unlawful act;
            (7) Cause annoyance, inconvenience, or needless anxiety or be likely
            to upset, embarrass, alarm, or annoy any other person; (8)
            Impersonate any person or misrepresent your identity or affiliation
            with any person or organization; or (9) Give the impression that
            they emanate from or are endorsed by us or any other person or
            entity, if this is not the case.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (b){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Account Use
            </Box>
            . You are responsible and liable for all uses of the Services
            resulting from access provided by you, directly or indirectly,
            whether such access or use is permitted by or in violation of this
            Agreement. Without limiting the generality of the foregoing, you are
            responsible for all acts and omissions of Authorized Users, and any
            act or omission by an Authorized User that would constitute a breach
            of this Agreement if taken by you will be deemed a breach of this
            Agreement by you. You shall use reasonable efforts to make all
            Authorized Users aware of this Agreement's provisions as applicable
            to such Authorized User's use of the Services and shall cause
            Authorized Users to comply with such provisions.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (c){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Customer Data
            </Box>
            . You hereby grant to Company a non-exclusive, royalty-free,
            worldwide license to reproduce, distribute, and otherwise use and
            display the Customer Data and perform all acts with respect to the
            Customer Data as may be necessary for Company to provide the
            Services to you, and a non-exclusive, perpetual, irrevocable,
            royalty-free, worldwide license to reproduce, distribute, modify,
            and otherwise use and display Customer Data incorporated within the
            Aggregated Statistics. You will ensure that Customer Data and any
            Authorized User's use of Customer Data will not violate any policy
            or terms referenced in or incorporated into this Agreement or any
            applicable law. You are solely responsible for the development,
            content, operation, maintenance, and use of Customer Data.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (d){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Passwords and Access Credentials
            </Box>
            . You are responsible for keeping your passwords and access
            credentials associated with the Services confidential. You will not
            sell or transfer them to any other person or entity. You will
            promptly notify us about any unauthorized access to your passwords
            or access credentials.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (e){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Third-Party Products
            </Box>
            . The Services may permit access to Third-Party Products. For
            purposes of this Agreement, such Third-Party Products are subject to
            their own terms and conditions. If you do not agree to abide by the
            applicable terms for any such Third-Party Products, then you should
            not install, access, or use such Third-Party Products.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (f){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Violation of Acceptable Use
            </Box>
            . A violation of section 4(a), shall be deemed a material breach.
            You will forfeit any right to refund upon the Termination for a
            violation of Acceptable Use outlined in section 4(a).
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{
              marginBottom: '10px',
              marginLeft: '20px',
            }}
          >
            {`5. `}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Fees and Payment
            </Box>
            {`. Customer`} shall pay Company the Fees as described below (the "
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Fees
            </Box>
            ")
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (a){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Payments
            </Box>
            . Franchisee authorizes Company to collect the following fees:
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '60px' }}
          >
            (i) Admin Fee; and
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '60px' }}
          >
            (i) Total Media Spend.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (b){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Admin Fee
            </Box>
            . Franchisor agrees and authorizes Company to charge and withhold
            the percentage from the Total Media Spend (the “Admin Fee”) that is
            designated in the Platform, and received from Franchisee before
            depositing the Net Media Spend into the Franchisor Ad Account for
            the management and use of the Services and Platform.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (b){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Chargebacks
            </Box>
            . If a Franchisee charges back any Media Spend for which Company has
            already deposited the Net Media Spend into Franchisor's account,
            Franchisor shall, immediately and without any delay, reimburse
            Company for the chargeback amount subject to the general payment
            terms below.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{
              marginBottom: '10px',
              marginLeft: '20px',
            }}
          >
            {`6. `}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              General Payment Terms.
            </Box>
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (a){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Payment Timeline
            </Box>
            . All payments for Fees described above shall be due and payable no
            later than 30 days from an invoice date (if applicable with no
            credit card on file) without offset or deduction.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (b){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Currency and Interest
            </Box>
            . Customer shall make all payments for Fees hereunder in US dollars
            on or before the due date. If Customer fails to make any payment
            when due, without limiting Company's other rights and remedies:
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '60px' }}
          >
            (i) Company may charge interest on the past due amount at the rate
            of 1.5% per month calculated daily and compounded monthly or, if
            lower, the highest rate permitted under applicable law;
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '60px' }}
          >
            (ii) Customer shall reimburse Company for all reasonable costs
            incurred by Company in collecting any late payments or interest,
            including attorneys' fees, court costs, and collection agency fees;
            and
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '60px' }}
          >
            (iii) If such failure continues for 7 days or more, Company may
            suspend, Customer's and all other Authorized Users' access to any
            portion or all of the Services until such amounts are paid in full.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (c){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Update of Payment Methods
            </Box>
            . Franchisees are responsible for updating their payment methods on
            file. Any failure to do so, leading to payment delays or failures,
            will be deemed a breach of this agreement.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (d){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Exclusions
            </Box>
            . All Fees and other amounts payable by Customer under this
            Agreement are exclusive of taxes and similar assessments. Customer
            is responsible for all sales, use, and excise taxes, and any other
            similar taxes, duties, and charges of any kind imposed by any
            federal, state, or local governmental or regulatory authority on any
            amounts payable by Customer hereunder, other than any taxes imposed
            on Company's income.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{
              marginBottom: '10px',
              marginLeft: '20px',
            }}
          >
            {`7. `}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Refund Policy.
            </Box>
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (a){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Unused Ad Budget
            </Box>
            . No refunds or credits will be granted for any unused or remaining
            Media Spend amounts.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (b){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Rollover Budget
            </Box>
            . Any leftover or remaining Media Spend will be automatically
            applied to the Franchisee's subsequent ad campaigns within the
            platform. It is the franchisee's responsibility to monitor and
            allocate their budgets appropriately.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (c){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Cancellation or Modification of Ad Campaigns
            </Box>
            . Any leftover or remaining Media Spend will be automatically
            applied to the Franchisee's subsequent ad campaigns within the
            platform. It is the franchisee's responsibility to monitor and
            allocate their budgets appropriately.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '60px' }}
          >
            (i) Once an ad campaign is launched or activated, the allocated
            budget for that specific campaign is deemed committed and cannot be
            refunded.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '60px' }}
          >
            (ii) If a franchisee wishes to modify or adjust their budget for a
            specific campaign, such modifications are subject to the Platform's
            campaign management guidelines and may not result in a refund or
            credit.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (d){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              No refund for Fees
            </Box>
            . There are no refunds for any Fees unless explicitly stated in this
            Agreement.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{
              marginBottom: '10px',
              marginLeft: '20px',
            }}
          >
            {`8. `}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Confidential Information
            </Box>
            . From time to time during the Term, Company and Customer may
            disclose or make available to the other party information about its
            business affairs, products, confidential intellectual property,
            trade secrets, third-party confidential information, and other
            sensitive or proprietary information, whether orally or in written,
            electronic, or other form or media and whether or not marked,
            designated, or otherwise identified as "confidential" at the time of
            disclosure (collectively, "
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Confidential Information
            </Box>
            "). Confidential Information does not include information that, at
            the time of disclosure is: (a) in the public domain; (b) known to
            the receiving party; (c) rightfully obtained by the receiving party
            on a non-confidential basis from a third party; or (d) independently
            developed by the receiving party. The receiving party shall not
            disclose the disclosing party's Confidential Information to any
            person or entity, except to the receiving party's employees, agents,
            or subcontractors who have a need to know the Confidential
            Information for the receiving party to exercise its rights or
            perform its obligations hereunder and who are required to protect
            the Confidential Information in a manner no less stringent than
            required under this Agreement. Notwithstanding the foregoing, each
            party may disclose Confidential Information to the limited extent
            required (i) to comply with the order of a court or other
            governmental body, or as otherwise necessary to comply with
            applicable law, provided that the party making the disclosure
            pursuant to the order shall first have given written notice to the
            other party and made a reasonable effort to obtain a protective
            order; or (ii) to establish a party's rights under this Agreement,
            including to make required court filings. Each party's obligations
            of non-disclosure with regard to Confidential Information are
            effective as of the date such Confidential Information is first
            disclosed to the receiving party and will expire five years
            thereafter; provided, however, with respect to any Confidential
            Information that constitutes a trade secret (as determined under
            applicable law), such obligations of non-disclosure will survive the
            termination or expiration of this Agreement for as long as such
            Confidential Information remains subject to trade secret protection
            under applicable law.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{
              marginBottom: '10px',
              marginLeft: '20px',
            }}
          >
            {`9. `}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Privacy Policy
            </Box>
            . Company complies with its privacy policy, available at
            https://saleschatz.com/privacy/ ("
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Privacy Policy
            </Box>
            "), in providing the Services. The Privacy Policy is subject to
            change as described therein. By accessing, using, and providing
            information to or through the Services, you acknowledge that you
            have reviewed and accepted our Privacy Policy, and you consent to
            all actions taken by us with respect to your information in
            compliance with the then-current version of our Privacy Policy.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{
              marginBottom: '10px',
              marginLeft: '20px',
            }}
          >
            {`10. `}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Intellectual Property Ownership; Feedback
            </Box>
            . As between you and us, (a) we own all right, title, and interest,
            including all intellectual property rights, in and to the Services
            and (b) you own all right, title, and interest, including all
            intellectual property rights, in and to Customer Data. If you or any
            of your employees, contractors, or agents sends or transmits any
            communications or materials to us by mail, email, telephone, or
            otherwise, suggesting or recommending changes to the Services,
            including without limitation, new features or functionality relating
            thereto, or any comments, questions, suggestions, or the like ("
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Feedback
            </Box>
            "), we are free to use such Feedback irrespective of any other
            obligation or limitation between you and us governing such Feedback.
            All Feedback is and will be treated as non-confidential. You hereby
            assign to us on your behalf, and shall cause your employees,
            contractors, and agents to assign, all right, title, and interest
            in, and we are free to use, without any attribution or compensation
            to you or any third party, any ideas, know-how, concepts,
            techniques, or other intellectual property rights contained in the
            Feedback, for any purpose whatsoever, although we are not required
            to use any Feedback.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{
              marginBottom: '10px',
              marginLeft: '20px',
            }}
          >
            {`11. `}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Customer Warranty and Warranty Disclaimer
            </Box>
            .
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (a){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Customer Warranty
            </Box>
            . You warrant that you own all right, title, and interest, including
            all intellectual property rights, in and to Customer Data and that
            both the Customer Data and your use of the Services are in
            compliance with Acceptable Use.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (b) THE SERVICES ARE PROVIDED "AS IS" AND COMPANY SPECIFICALLY
            DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR
            OTHERWISE. COMPANY SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
            NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF DEALING,
            USAGE, OR TRADE PRACTICE. COMPANY MAKES NO WARRANTY OF ANY KIND THAT
            THE SERVICES, OR ANY PRODUCTS OR RESULTS OF THE USE THEREOF, WILL
            MEET YOUR OR ANY OTHER PERSON'S OR ENTITY'S REQUIREMENTS, OPERATE
            WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR
            WORK WITH ANY OF YOUR OR ANY THIRD PARTY'S SOFTWARE, SYSTEM, OR
            OTHER SERVICES, OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL
            CODE, OR ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE
            CORRECTED.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{
              marginBottom: '10px',
              marginLeft: '20px',
            }}
          >
            {`12. `}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Indemnification
            </Box>
            .
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (a){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Customer Indemnification
            </Box>
            . Customer shall indemnify, hold harmless, and, at Company's option,
            defend Company and its officers, directors, employees, agents,
            affiliates, successors, and assigns from and against any and all
            Losses arising from or relating to any Third-Party Claim (i) that
            the Customer Data, or any use of the Customer Data in accordance
            with this Agreement, infringes or misappropriates such third party's
            intellectual property rights; or (ii) based on Customer's or any
            Authorized User's negligence or willful misconduct or use of the
            Services in a manner not authorized by this Agreement; provided that
            Customer may not settle any Third-Party Claim against Company unless
            Company consents to such settlement, and further provided that
            Company will have the right, at its option, to defend itself against
            any such Third-Party Claim or to participate in the defense thereof
            by counsel of its own choice.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{
              marginBottom: '10px',
              marginLeft: '20px',
            }}
          >
            {`13. `}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Limitations of Liability
            </Box>
            . IN NO EVENT WILL COMPANY BE LIABLE UNDER OR IN CONNECTION WITH
            THIS AGREEMENT UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH
            OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR
            OTHERWISE, FOR ANY: (a) CONSEQUENTIAL, INCIDENTAL, INDIRECT,
            EXEMPLARY, SPECIAL, ENHANCED, OR PUNITIVE DAMAGES; (b) INCREASED
            COSTS, DIMINUTION IN VALUE OR LOST BUSINESS, PRODUCTION, REVENUES,
            OR PROFITS; (c) LOSS OF GOODWILL OR REPUTATION; (d) USE, INABILITY
            TO USE, LOSS, INTERRUPTION, SERVICE OUTAGE, DELAY OR RECOVERY OF ANY
            DATA, OR BREACH OF DATA OR SYSTEM SECURITY; OR (e) COST OF
            REPLACEMENT GOODS OR SERVICES, IN EACH CASE REGARDLESS OF WHETHER
            COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR
            SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE. IN NO EVENT WILL
            COMPANY’S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS
            AGREEMENT UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF
            CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR
            OTHERWISE EXCEED THE TOTAL AMOUNTS PAID TO COMPANY UNDER THIS
            AGREEMENT IN THE TWELVE (12) MONTH PERIOD PRECEDING THE EVENT GIVING
            RISE TO THE CLAIM.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{
              marginBottom: '10px',
              marginLeft: '20px',
            }}
          >
            {`14. `}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Term and Termination
            </Box>
            .
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (a){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Term
            </Box>
            . The term of this Agreement begins on the Effective Date and
            continues until terminated. Services that are specified to
            automatically renew will renew for up to 3 additional successive
            1-year term[s] unless earlier terminated pursuant to this
            Agreement's express provisions or either party gives the other party
            written notice of non-renewal at least 60 days prior to the
            expiration of the then-current services period.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (b){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Termination
            </Box>
            . In addition to any other express termination right set forth in
            this Agreement:
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '60px' }}
          >
            (i) Either party may terminate this Agreement, effective on written
            notice to the other party, if the other party breaches this
            Agreement, and such breach: (A) is incapable of cure; or (B) being
            capable of cure, remains uncured 30 days after the non-breaching
            party provides the breaching party with written notice of such
            breach.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '60px' }}
          >
            (ii) Company may terminate this Agreement immediately for non
            payment of Fees or Material Breach by Customer.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '60px' }}
          >
            (iii) Either party may terminate this Agreement, effective
            immediately upon written notice to the other party, if the other
            party: (A) becomes insolvent or is generally unable to pay, or fails
            to pay, its debts as they become due; (B) files, or has filed
            against it, a petition for voluntary or involuntary bankruptcy or
            otherwise becomes subject, voluntarily or involuntarily, to any
            proceeding under any domestic or foreign bankruptcy or insolvency
            law; (C) makes or seeks to make a general assignment for the benefit
            of its creditors; or (D) applies for or has appointed a receiver,
            trustee, custodian, or similar agent appointed by order of any court
            of competent jurisdiction to take charge of or sell any material
            portion of its property or business.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (c){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Effect of Termination
            </Box>
            . Upon termination of this Agreement, Customer shall immediately
            discontinue use of the Company IP, the Platform, and all ads will
            stop running subject to our refund policy in Section 7. No
            expiration or termination of this Agreement will affect Customer's
            obligation to pay all Fees that may have become due before such
            expiration or termination, or entitle Customer to any refund.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            (d){' '}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Survival
            </Box>
            . This Section 12(d), Sections 5, 6, 8, 10, 11, 12, 13, 15, 16, and
            18, and any right, obligation, or required performance of the
            parties in this Agreement which, by its express terms or nature and
            context is intended to survive termination or expiration of this
            Agreement, will survive any such termination or expiration.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{
              marginBottom: '10px',
              marginLeft: '20px',
            }}
          >
            {`15. `}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Modifications
            </Box>
            . You acknowledge and agree that we have the right, in our sole
            discretion, to modify this Agreement from time to time, and that
            modified terms become effective on posting. You will be notified of
            modifications through direct email communication from us. You are
            responsible for reviewing and becoming familiar with any such
            modifications. Your continued use of the Services after the
            effective date of the modifications will be deemed acceptance of the
            modified terms.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{
              marginBottom: '10px',
              marginLeft: '20px',
            }}
          >
            {`16. `}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Governing Law and Jurisdiction
            </Box>
            . This agreement is governed by and construed in accordance with the
            internal laws of the State of Texas without giving effect to any
            choice or conflict of law provision or rule that would require or
            permit the application of the laws of any jurisdiction other than
            those of the State of Texas. Any legal suit, action, or proceeding
            arising out of or related to this agreement or the rights granted
            hereunder will be instituted in the federal courts of the United
            States or the courts of the State of Texas in each case located in
            the city of Waco and County of McLennan, and each party irrevocably
            submits to the jurisdiction of such courts in any such suit, action,
            or proceeding.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{
              marginBottom: '10px',
              marginLeft: '20px',
            }}
          >
            {`17. `}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Force Majure
            </Box>
            . Neither party shall be liable for any failure or delay in
            performing its obligations under this Agreement if such failure or
            delay is due to a Force Majeure Event. A Force Majeure Event
            includes widespread internet outages, natural disasters (e.g.,
            floods, earthquakes), war, terrorism, riots, embargoes, acts of
            civil or military authorities, fire, strikes, or shortages of
            transportation facilities, fuel, energy, labor or materials. Upon
            occurrence of any Force Majeure Event, the affected party shall
            promptly notify the other party of the inability to perform and the
            reasons for it. During the duration of any such event, the
            obligations of the affected party under this Agreement will be
            suspended and will resume as soon as the event ceases to occur.
          </Typography>

          <Typography
            component="div"
            variant="body2"
            sx={{
              marginBottom: '10px',
              marginLeft: '20px',
            }}
          >
            {`18. `}
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              Miscellaneous
            </Box>
            . This Agreement constitutes the entire agreement and understanding
            between the parties hereto with respect to the subject matter hereof
            and supersedes all prior and contemporaneous understandings,
            agreements, representations, and warranties, both written and oral,
            with respect to such subject matter. Any notices to us must be sent
            to our corporate headquarters address available at
            https://saleschatz.com and must be delivered either in person, by
            certified or registered mail, return receipt requested and postage
            prepaid, or by recognized overnight courier service, and are deemed
            given upon receipt by us. Notices may also be sent via email
            communication to your dedicated account contact. Notwithstanding the
            foregoing, you hereby consent to receiving electronic communications
            from us. These electronic communications may include notices about
            applicable fees and charges, transactional information, and other
            information concerning or related to the Services. You agree that
            any notices, agreements, disclosures, or other communications that
            we send to you electronically will satisfy any legal communication
            requirements, including that such communications be in writing. The
            invalidity, illegality, or unenforceability of any provision herein
            does not affect any other provision herein or the validity,
            legality, or enforceability of such provision in any other
            jurisdiction. Any failure to act by us with respect to a breach of
            this Agreement by you or others does not constitute a waiver and
            will not limit our rights with respect to such breach or any
            subsequent breaches. This Agreement is personal to you and may not
            be assigned or transferred for any reason whatsoever without our
            prior written consent and any action or conduct in violation of the
            foregoing will be void and without effect. We expressly reserve the
            right to assign this Agreement and to delegate any of its
            obligations hereunder.
          </Typography>

          <Box component="div" sx={{ marginBottom: '10px' }}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label={
                  <Typography
                    variant="subtitle2"
                    sx={{ color: 'green', fontWeight: 'bold' }}
                  >
                    I agree to the Terms of Service
                  </Typography>
                }
                onChange={(e: any) => {
                  setIAgree(e.target.checked);
                }}
                value={iAgree}
              />
            </FormGroup>
          </Box>
        </Container>

        <Box component="div" sx={{ marginTop: '10px', textAlign: 'center' }}>
          <PrimaryButton title="Agree" disabled={!iAgree} />
        </Box>
      </Box>
    </Modal>
  );
};

export default StripePaymentTermsModal;
