import { Snackbar } from '@mui/material';
import React from 'react';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAlert } from '../../redux/actions';

interface ToastProps {
  autohideTime?: number;
}

const Toast: React.FC<ToastProps> = ({ autohideTime = 3000 }) => {
  const toggleObj: any = useSelector(
    (state: any) => state?.toggleAlert?.toggle,
  );
  const dispatch = useDispatch();

  const renderAlert = () => {
    return (
      <MuiAlert
        severity={toggleObj?.type as any}
        variant="filled"
        elevation={6}
        sx={{ whiteSpace: 'pre-line' }}
      >
        {toggleObj?.message}
      </MuiAlert>
    );
  };

  const handleClose = () => {
    dispatch(toggleAlert({ toggle: false, message: '', type: 'success' }));
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: (toggleObj?.vertical as any) || 'bottom',
        horizontal: (toggleObj?.horizontal as any) || 'left',
      }}
      open={toggleObj?.toggle}
      key={toggleObj?.vertical || 'bottom' + toggleObj?.horizontal || 'left'}
      autoHideDuration={autohideTime}
      sx={{ textAlign: 'center' }}
      onClose={handleClose}
    >
      {renderAlert()}
    </Snackbar>
  );
};

export default Toast;
