import {
  Card,
  CardHeader,
  IconButton,
  Box,
  Typography,
  Button,
} from '@mui/material';
import React, { ReactNode } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { DEFAULT_COLOR_THEME } from '../../../utils';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { FcGoogle } from 'react-icons/fc';

const getRatio = (width: number, height: number) => {
  let biggernumber = width > height ? width : height;
  let smallernumber = width < height ? width : height;

  let ratio = biggernumber / smallernumber;

  return `${ratio} : 1`;
};

interface GoogleImageCardProps {
  imgData: {
    url: string;
    height: number;
    width: number;
    resource_type?: string;
    asset_id?: string;
    public_id?: string;
    resourceName?: string;
  };
  hidden: boolean;
  selected: boolean;
  text?: string;
  onClick?: (data: any) => void;
}

const GoogleImageCard: React.FC<GoogleImageCardProps> = ({
  onClick,
  imgData,
  selected,
  text,
  hidden,
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        padding: 1,
        width: 'fit-content',
        border: `4px solid ${selected ? DEFAULT_COLOR_THEME : '#f0f0f0'}`,
        borderRadius: 4,
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
        cursor: 'pointer',
        transition: 'all 0.2s ease',
        order: hidden ? 1 : 0,
        opacity: hidden ? 0.2 : 1,
        '&:hover': hidden
          ? ''
          : {
              backgroundColor: 'rgb(9, 111, 77, 0.2)',
            },
      }}
      onClick={() => {
        if (hidden) return;
        if (onClick) onClick(imgData);
      }}
    >
      {selected ? (
        <Box
          sx={{
            position: 'absolute',
            top: -10,
            right: -10,
            zIndex: 1,
            backgroundColor: 'white',
            borderRadius: '50%',
            padding: 0,
            margin: 0,
            minWidth: 0,
            display: 'flex',
            // '&:first-child': {
            //   display: 'block',
            // },
            // '&:last-child': {
            //   display: 'none',
            // },
            // '&:hover': {
            //   '&:first-child': {
            //     display: 'none',
            //   },
            //   '&:last-child': {
            //     display: 'block',
            //   },
            // },
          }}
          className="checkmark"
        >
          <IoIosCheckmarkCircle size={25} color={DEFAULT_COLOR_THEME} />
        </Box>
      ) : null}
      <Box
        sx={{
          backgroundColor: `#eeeeee`,
          backgroundImage: `url(${imgData.url})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: 200,
          width: 200,
          borderRadius: 4,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            display: imgData.resourceName ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            width: 30,
            height: 30,
            borderRadius: '50%',
            backgroundColor: '#f0f0f0',
          }}
        >
          <FcGoogle size={24} />
        </Box>
        {text ? (
          <Typography variant="body2">{text}</Typography>
        ) : (
          <>
            <Typography variant="body2">
              {imgData.width} x {imgData.height}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: '0.6rem',
              }}
            >
              {hidden
                ? imgData.width < 314 || imgData.height < 314
                  ? 'Image resolution too low'
                  : 'Image ratio is not accepted'
                : getRatio(imgData.width, imgData.height)}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default GoogleImageCard;
