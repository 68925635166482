import { TextField } from '@mui/material';
import React, { ReactNode } from 'react';
import { NumericFormat } from 'react-number-format';

interface NumericCustomFieldProps {
  value: number;
  onChangeValue: (values: any) => void;
  id: string;
  name: string;
  label: string;
  prefix?: string;
  fullWidth?: boolean;
  variant?: string;
  type?: string;
  placeholder?: string;
  helperText?: ReactNode | null;
  withDials?: boolean;
  decimalPlaces?: number;
  fixedDecimal?: boolean;
  disabled?: boolean;
  endIcon?: ReactNode | null;
}

/**
 * Numeric Custom Field
 * @description Reusable component to format React MUI text field into any number format.
 *              As suggested by React MUI library, you can use 3rd-party libraries
 *              to format an input.
 * @author Angelo David <adavid@saleschatz.com>
 * @reference https://v5.mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
 *            https://s-yadav.github.io/react-number-format/
 * @param param0
 * @returns
 */
export const NumericCustomField: React.FC<NumericCustomFieldProps> = ({
  value,
  onChangeValue,
  id,
  name,
  label,
  prefix = '$',
  fullWidth = false,
  variant = 'standard',
  type = 'text',
  placeholder = '0',
  helperText = null,
  withDials = false,
  decimalPlaces = 2,
  fixedDecimal = false,
  disabled = false,
  endIcon = null,
}) => {
  const removeDialsStyles = {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
  };

  return (
    <NumericFormat
      fixedDecimalScale={fixedDecimal}
      decimalScale={decimalPlaces}
      thousandSeparator
      valueIsNumericString
      prefix={prefix}
      value={value}
      onValueChange={onChangeValue}
      customInput={TextField}
      id={id}
      name={name}
      label={label}
      fullWidth={fullWidth}
      variant={variant as any}
      type={type as any}
      placeholder={placeholder}
      InputLabelProps={{ shrink: true }}
      size="small"
      helperText={helperText}
      sx={{ ...(!withDials ? removeDialsStyles : {}) }}
      disabled={disabled}
      InputProps={{ endAdornment: endIcon ? endIcon : null }}
    />
  );
};
