import React, { FormEvent, useState } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { ChangeEventType } from '../../types';
import { Link } from 'react-router-dom';
import { forgotPassword } from '../../services/user';
import { PrimaryButton } from '../../components/Buttons';
import styles from '../../assets/styles/pages/ForgotPassword.module.scss';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../redux/actions';
import { IS_DIY_ADZ } from '../../utils';

const ForgotPassword: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [errors, setErrors] = useState({ email: '' });

  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = await forgotPassword({
        email,
        clientUri: window.location.host,
        diy: IS_DIY_ADZ,
      });
      if (data) {
        setLoading(false);
        dispatch(
          toggleAlert({
            toggle: true,
            message: data.message,
          }),
        );
      }
    } catch (error: any) {
      const err = error.response.data;
      setErrors({ ...errors, email: err?.email });
      dispatch(
        toggleAlert({
          toggle: true,
          message: err?.message,
          type: 'error',
        }),
      );
      setLoading(false);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleOnSubmit}
      sx={{ width: '100%', p: '20px' }}
    >
      <Typography sx={{ mb: 3, textAlign: 'center' }} variant="h6" mt={2}>
        Enter the email you have signed up with
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            size="small"
            type="email"
            id="minAmountExcluded"
            name="minAmountExcluded"
            label="Your email"
            onChange={(e: ChangeEventType) => setEmail(e.target.value)}
            value={email}
            InputLabelProps={{ shrink: true }}
            error={errors.email ? true : false}
            helperText={errors.email}
            sx={{
              '& .MuiInputBase-root': {
                '&:has(> input:-webkit-autofill)': {
                  backgroundColor: 'rgb(232, 240, 254)',
                },
              },
            }}
            InputProps={{ sx: { borderRadius: '49px' } }}
          />
        </Grid>
      </Grid>

      <div className={styles.link}>
        <small>
          Want to Login? <Link to="/login">Click here</Link>
        </small>
      </div>

      <div className={styles.action}>
        <PrimaryButton title="Send" loading={loading} rounded size="large" />
      </div>
    </Box>
  );
};

export default ForgotPassword;
