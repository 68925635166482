/**
 * React Hook Form Custom Validations
 * @author Angelo David
 * @reference https://www.react-hook-form.com/
 */
export const securedUrlPattern = {
  value: /^https:\/\/([a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+.*)$/,
  message: 'Invalid URL format, only https URLs are allowed',
};

export const validZapierWebhookUrl = {
  value: /^https:\/\/hooks\.zapier\.com\/hooks\/catch/,
  message:
    'Invalid Zapier Webhook URL format, URL should include: https://hooks.zapier.com/hooks/catch/',
};

export const rawPhoneNumberPattern = {
  value: /^\d{10,}$/,
  message:
    'Invalid phone number format, only digits are allowed and atleast 10-digit number: 1234567890',
};

export const emailAddressPattern = {
  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  message: 'Invalid email format',
};

export const validateEmailList = (emailList: string) => {
  const emails = emailList.split(',');

  for (const email of emails) {
    if (!emailAddressPattern.value.test(email.trim())) {
      return emailAddressPattern.message;
    }
  }

  return null;
};
