import { Grid, TextField, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { ChangeEventType, FBLeadGenForm } from '../../../../../types';
import { securedUrlPattern } from '../../../../../utils/validation';

interface LeadFormPrivacyPolicyFormProps {
  formValues: FBLeadGenForm;
  setFormValues: Dispatch<SetStateAction<FBLeadGenForm>>;
  errors: any;
  clearErrors: any;
  register: any;
}

const LeadFormPrivacyPolicyForm: React.FC<LeadFormPrivacyPolicyFormProps> = ({
  formValues,
  setFormValues,
  errors,
  clearErrors,
  register,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1">
          Because you will be collecting customer information, you need to
          include a link to your company's privacy policy. Your link will appear
          with Facebook's default privacy disclaimer.
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              {...register('link_text', { required: true })}
              fullWidth
              required
              variant="standard"
              type="text"
              name="link_text"
              label="Link Text"
              onChange={(e: ChangeEventType) => {
                clearErrors('link_text');
                setFormValues({
                  ...formValues,
                  privacy_policy: {
                    ...formValues?.privacy_policy,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
              value={formValues?.privacy_policy?.link_text}
              InputLabelProps={{ shrink: true }}
              size="small"
              error={!!errors.link_text}
              helperText={
                errors.link_text && 'Privacy policy link text is required'
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              {...register('url', {
                required: true,
                pattern: securedUrlPattern,
              })}
              fullWidth
              required
              variant="standard"
              type="text"
              name="url"
              label="URL"
              onChange={(e: ChangeEventType) => {
                clearErrors('url');
                setFormValues({
                  ...formValues,
                  privacy_policy: {
                    ...formValues?.privacy_policy,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
              value={formValues?.privacy_policy?.url}
              InputLabelProps={{ shrink: true }}
              size="small"
              error={!!errors.url}
              helperText={
                errors?.url?.message ||
                (errors.url && 'Privacy policy URL is required')
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LeadFormPrivacyPolicyForm;
