import { GridColDef } from '@mui/x-data-grid-premium';
import { toCurrency } from '../../../utils/numberFormatter';

const defaultProps = (
  isMobile: boolean,
  width: number,
  defaultWidth?: number,
) => {
  if (isMobile) return { width };

  if (defaultWidth) return { flex: 1, minWidth: defaultWidth };

  return { flex: 2 };
};

export const budgetReportChatzColumns = (isMobile: boolean, isDiy: boolean) => {
  let columns: GridColDef[] = [
    {
      ...defaultProps(isMobile, 150, 150),
      field: 'brand',
      headerName: isDiy ? 'Location' : 'Brand',
      renderCell: (params) => {
        const { brand } = params.row;

        return <div>{brand}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 90),
      field: 'status',
      headerName: 'Status',
      renderCell: (params) => {
        const { status } = params.row;

        return <div>{status}</div>;
      },
    },
  ];

  if (!isDiy) {
    columns = [
      ...columns,
      {
        ...defaultProps(isMobile, 90),
        field: 'budget',
        headerName: 'Budget',
        valueGetter: (_, row) => parseFloat(row.budget),
        renderCell: (params) => {
          const { budget } = params.row;

          return <div>${budget}</div>;
        },
      },
    ];
  }

  columns = [
    ...columns,
    {
      ...defaultProps(isMobile, 100),
      field: 'spend',
      headerName: 'Spend',
      valueFormatter: (value) => {
        if (!value) {
          return toCurrency('USD', 0);
        }

        return toCurrency('USD', value as number);
      },
    },
  ];

  if (!isDiy) {
    columns = [
      ...columns,
      {
        ...defaultProps(isMobile, 80),
        field: 'percentage',
        headerName: '%',
        valueGetter: (_, row) => parseFloat(row.percentage),
        renderCell: (params) => {
          const { percentage } = params.row;

          return <div>{percentage}</div>;
        },
      },
      {
        ...defaultProps(isMobile, 80),
        field: 'daily',
        headerName: 'Daily',
        valueGetter: (_, row) => row.daily,
        renderCell: (params) => {
          const { daily } = params.row;

          return <div>{toCurrency('USD', daily)}</div>;
        },
      },
    ];
  }

  columns = [
    ...columns,
    {
      ...defaultProps(isMobile, 120),
      field: 'impressions',
      headerName: 'Impressions',
      valueGetter: (_, row) => row.impressions,
      renderCell: (params) => {
        const { impressions } = params.row;

        return <div>{impressions}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'm2Lead',
      headerName: 'M2Lead',
      valueGetter: (_, row) => row.m2Lead,
      renderCell: (params) => {
        const { m2Lead } = params.row;

        return <div>{m2Lead}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpm',
      headerName: 'CPM',
      valueFormatter: (value) => {
        if (!value) {
          return toCurrency('USD', 0);
        }

        return toCurrency('USD', value as number);
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'clicks',
      headerName: 'Clicks',
      valueGetter: (_, row) => row.clicks,
      renderCell: (params) => {
        const { clicks } = params.row;

        return <div>{clicks}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'c2Lead',
      headerName: 'C2Lead',
      valueGetter: (_, row) => row.c2Lead,
      renderCell: (params) => {
        const { c2Lead } = params.row;

        return <div>{c2Lead}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpc',
      headerName: 'CPC',
      valueGetter: (_, row) => row.cpc,
      renderCell: (params) => {
        const { cpc } = params.row;

        return <div>${cpc}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'leads',
      headerName: 'Leads',
      valueGetter: (_, row) => row.leads,
      renderCell: (params) => {
        const { leads } = params.row;

        return <div>{leads}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpl',
      headerName: 'CPL',
      valueGetter: (_, row) => {
        const { leads, spend } = row;

        return leads > 0 ? spend / leads : 0;
      },
      renderCell: (params) => {
        const { leads, spend } = params.row;

        const cpl = leads > 0 ? spend / leads : 0;

        return <div>{toCurrency('USD', cpl)}</div>;
      },
    },
  ];

  return columns;
};
