import axios from 'axios';
import { PageFormFields } from '../types';
import { apiBaseUrl } from './api';

export const getAllPages = async () => {
  const data = await axios.get(`${apiBaseUrl}/page`);

  return data?.data;
};

export const getAllPagesWithStatus = async (active: boolean) => {
  const data = await axios.get(`${apiBaseUrl}/page?active=${active}`);

  return data?.data;
};

export const connectFbPage = async (obj: PageFormFields) => {
  const data = await axios.post(`${apiBaseUrl}/page/connect`, obj);

  return data?.data;
};

export const disconnectPage = async (obj: PageFormFields) => {
  const data = await axios.post(`${apiBaseUrl}/page/disconnect`, obj);

  return data?.data;
};

export const getSpecificPage = async (pageId: string) => {
  const data = await axios.get(`${apiBaseUrl}/page/${pageId}`);

  return data?.data;
};

export const getSubscribedApps = async (brandId: string, pageId: string) => {
  const data = await axios.get(`${apiBaseUrl}/page/subscribed-apps`, {
    params: { brandId, pageId },
  });

  return data?.data;
};

export const subscribeToApp = async (brandId: string, pageId: string) => {
  const data = await axios.post(
    `${apiBaseUrl}/page/subscribed-apps`,
    {},
    {
      params: { brandId, pageId },
    },
  );

  return data?.data;
};
