import HTMLReactParser from 'html-react-parser';

/**
 * Error Message Parser
 * @description Parse error message for API calls and other functionality.
 *              Add this to the catch block and pass the error object.
 *              Render if there is an error message from API calls, otherwise
 *              use the generic error message.
 * @author Angelo David <angelod@codev.com>
 * @param error
 * @returns <String> Error message
 */
export const errorMessageParser = (error: any) => {
  let errorMsg = '';
  const err = error?.response?.data;

  const keys = Object.keys(error?.response?.data || {}).map(
    (key: string) => key,
  );
  if (!keys.includes('message')) {
    errorMsg = parseValidationError(err);
  } else if (err?.message) {
    errorMsg = err?.message;
  } else {
    errorMsg = error?.message;
  }

  return HTMLReactParser(errorMsg);
};

const parseValidationError = (errors: any) => {
  let errorMsg: string = '';
  for (const key in errors) {
    if (errors.hasOwnProperty(key)) {
      errorMsg += `${errors[key]}\n`;
    }
  }

  return errorMsg;
};
