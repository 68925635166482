import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, Fragment, SetStateAction } from 'react';
import { Brand, ChangeEventType } from '../../../types';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import AdapterMoment from '@mui/lab/AdapterMoment';
import momentTz, { Moment } from 'moment-timezone';
import { AdsTemplate } from '../../../types/IDiy';
import { useSelector } from 'react-redux';

interface DiyBudgetFormProps {
  template: AdsTemplate;
  setTemplate: Dispatch<SetStateAction<AdsTemplate>>;
  onSetEndDate: (newValue: Moment | null) => void;
  onSetStartDate: (newValue: Moment | null) => void;
  onChangeBudget: (e: ChangeEventType) => void;
  brand: Brand;
}

const DiyBudgetForm: React.FC<DiyBudgetFormProps> = ({
  template,
  setTemplate,
  onSetStartDate,
  onSetEndDate,
  onChangeBudget,
  brand,
}) => {
  const timezone: string = useSelector(
    (state: any) => state?.brandTimezone?.timezone,
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body1">Start Date/Time</Typography>
      </Grid>

      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePicker
            label="Start Date/Time"
            value={momentTz
              .unix(template?.adSetTemplate?.start_time)
              .tz(timezone)}
            onChange={(newValue) => {
              onSetStartDate(newValue);
            }}
            minDate={momentTz.tz(timezone)}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  size="small"
                  variant="standard"
                  helperText={
                    <Fragment>
                      <Typography
                        variant="caption"
                        fontWeight="bold"
                        component="div"
                      >
                        Timezone:
                        <Typography variant="caption" display="inline" ml={1}>
                          {timezone}
                        </Typography>
                      </Typography>
                    </Fragment>
                  }
                  name="start_date"
                />
              );
            }}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item xs={12}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="schedule"
            name="schedule"
            value={template?.limited ? 'yes' : 'no'}
            onChange={(e: ChangeEventType) => {
              setTemplate({ ...template, limited: e.target.value === 'yes' });
            }}
            sx={{ flexDirection: 'column' }}
          >
            <FormControlLabel
              value="no"
              control={<Radio size="small" />}
              label="Run campaign every month"
            />

            <FormControlLabel
              value="yes"
              control={<Radio size="small" />}
              label="Stop campaign on"
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      {template?.limited ? (
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              label="End Date/Time"
              value={momentTz
                .unix(template?.adSetTemplate?.end_time)
                .tz('US/Eastern')}
              onChange={(newValue) => {
                onSetEndDate(newValue);
              }}
              minDate={momentTz
                .unix(template?.adSetTemplate?.start_time)
                .tz('US/Eastern')
                .add(1, 'day')}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    size="small"
                    variant="standard"
                    helperText="Eastern Time"
                    name="end_date"
                  />
                );
              }}
              disabled={!template?.adSetTemplate?.start_time}
            />
          </LocalizationProvider>
        </Grid>
      ) : null}

      <Grid item xs={12} sm={8}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              variant="standard"
              type="number"
              id={template?.limited ? 'lifetime_budget' : 'totalBudget'}
              name={template?.limited ? 'lifetime_budget' : 'totalBudget'}
              label={`${
                template?.limited ? 'Total' : `Current Month's`
              } Budget`}
              onChange={onChangeBudget}
              value={
                template?.limited
                  ? template?.adSetTemplate?.lifetime_budget
                  : template?.adSetTemplate?.totalBudget
              }
              InputLabelProps={{ shrink: true }}
              size="small"
              autoFocus
              sx={{
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                  {
                    display: 'none',
                  },
                '& input[type=number]': {
                  MozAppearance: 'textfield',
                },
              }}
            />
          </Grid>

          {!template?.limited ? (
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                variant="standard"
                type="number"
                id="futureBudget"
                name="futureBudget"
                label="Future Monthly Budget"
                onChange={onChangeBudget}
                value={template?.adSetTemplate?.futureBudget}
                InputLabelProps={{ shrink: true }}
                size="small"
                sx={{
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                      display: 'none',
                    },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                }}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      {!template?.limited && !brand?.allowSetupPayment ? (
        <Grid item xs={12} sm={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                variant="standard"
                type="number"
                id="daily_budget"
                name="daily_budget"
                label="Daily Budget"
                onChange={onChangeBudget}
                value={template?.adSetTemplate?.daily_budget}
                InputLabelProps={{ shrink: true }}
                size="small"
                sx={{
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                      display: 'none',
                    },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default DiyBudgetForm;
