import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  CircularProgress,
  Tooltip,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IoMdLink, IoMdAdd } from 'react-icons/io';
import { HiBuildingOffice2 } from 'react-icons/hi2';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  formatNumber,
  GoogleAssetsContainer,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import { FloatingButton } from '../../../Buttons';
import {
  fetchAssetKeywordSets,
  fetchGoogleKeywordIdeas,
  saveAssetKeywordSet,
} from '../../../../services/googleAds';
import { toggleAlert } from '../../../../redux/actions';
import { useDispatch } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import Spinner from '../../../Spinner';

interface AssetGoogleKeywordsSetProps {
  brandId: string;
}

const AssetGoogleKeywordsSet: React.FC<AssetGoogleKeywordsSetProps> = ({
  brandId,
}) => {
  const [keywordPage, setKeywordPage] = useState(2);
  const [suggestedKeywords, setSuggestedKeywords] = useState<
    {
      keyword: string;
      avg_monthly_searches: string;
      competition: string;
      high_top_of_page_bid_micros: string;
      low_top_of_page_bid_micros: string;
      concepts: string;
    }[]
  >([]);
  const [textboxKeywords, setTextboxKeywords] = useState<string>('');
  const [keywordSetName, setKeywordSetName] = useState<string>('');
  const [keywords, setKeywords] = useState<
    {
      exact: boolean;
      phrase: boolean;
      broad: boolean;
      keyword: string;
    }[]
  >([]);
  const [keywordUrl, setKeywordUrl] = useState<string>('');
  const [isGeneratingKeywords, setIsGeneratingKeywords] =
    useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedProductAndService, setSelectedProductAndService] =
    useState<string>('');
  const dispatch = useDispatch();
  const [keywordSets, setKeywordSets] = useState<any[]>([]);
  const [displayedKeywordSets, setDisplayedKeywordSets] = useState<any[]>([]);
  const [page, setPage] = useState('loading');
  const [negativeKeywords, setNegativeKeywords] = useState(false);
  const [keywordResults, setKeywordResults] = useState<any>('');
  const [errorGeneratingKeywords, setErrorGeneratingKeywords] = useState(false);

  const generateKeywords = async (keyword: string = '') => {
    setErrorGeneratingKeywords(false);
    setIsGeneratingKeywords(true);
    if (!selectedProductAndService) {
      setIsGeneratingKeywords(false);
      dispatch(
        toggleAlert({
          toggle: true,
          message: `Please enter a product or service to generate keywords for`,
          type: 'error',
        }),
      );
      return;
    }
    const ideas = await fetchGoogleKeywordIdeas({
      keywords: [...selectedProductAndService.split(' ')],
      brandId: brandId,
      url: keywordUrl || '',
    });
    try {
      console.log(`ideas`);
      console.log(ideas);
      if (ideas?.keywordIdeas) {
        setKeywordResults(`${selectedProductAndService} & ${keywordUrl}`);
        const kis = ideas.keywordIdeas.map((k: any) => {
          return {
            keyword: k.text,
            avg_monthly_searches: formatNumber(
              k?.keywordIdeaMetrics?.avgMonthlySearches,
            ),
            competition: k?.keywordIdeaMetrics?.competition,
            high_top_of_page_bid_micros: formatNumber(
              k?.keywordIdeaMetrics?.highTopOfPageBidMicros,
            ),
            low_top_of_page_bid_micros: formatNumber(
              k?.keywordIdeaMetrics?.lowTopOfPageBidMicros,
            ),
            concepts: k?.keywordAnnotations?.concepts
              ?.map((c: any) => c?.name)
              ?.join(', '),
          };
        });

        setSuggestedKeywords([
          ...kis.filter(
            (k: any) =>
              !keywords.map((keyword) => keyword.keyword).includes(k.keyword),
          ),
        ]);
      } else {
        setIsGeneratingKeywords(false);
        return dispatch(
          toggleAlert({
            toggle: true,
            message:
              'Google did generate keywords at this time, please try again',
            type: 'error',
          }),
        );
      }
    } catch (error) {
      console.log(error);
      setErrorGeneratingKeywords(true);
    }

    setIsGeneratingKeywords(false);
  };

  const filterKeywords = (extra: string[] = []) => {
    const kw = extra
      ? [...extra, ...textboxKeywords.split(/,|\n/)]
      : textboxKeywords.split(/,|\n/);
    const newkeywords = [
      ...keywords.filter(
        (keyword) => keyword.keyword !== '' && kw.includes(keyword.keyword),
      ),
      ...kw
        .filter(
          (keyword) =>
            keyword !== '' &&
            !keywords
              .map((keyword) => keyword.keyword)
              .includes(keyword.toLowerCase()),
        )
        .map((keyword) => {
          return {
            exact: false,
            phrase: false,
            broad: false,
            keyword: keyword.toLowerCase(),
          };
        }),
    ];
    return newkeywords;
  };

  const submitCheck = () => {
    const newkeywords = [...keywords];

    let errors = 0;
    newkeywords.forEach((keyword) => {
      if (!keyword.exact && !keyword.phrase && !keyword.broad) {
        errors++;
      }
    });

    if (errors > 0) {
      return dispatch(
        toggleAlert({
          toggle: true,
          message: 'Please select at least one match type for each keyword',
          type: 'error',
        }),
      );
    }

    if (keywordSetName === '') {
      return dispatch(
        toggleAlert({
          toggle: true,
          message: 'Please enter a name for the keyword set',
          type: 'error',
        }),
      );
    }
    setPage('loading');
    saveAssetKeywordSet({
      brandId: brandId,
      keywordSet: {
        brandId: brandId,
        name: keywordSetName,
        productandservice: selectedProductAndService,
        negativeset: negativeKeywords,
        keywords: newkeywords,
      },
      isEditing: isEditing,
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          syncKeywordSets();
        } else {
          setPage('matchtypes');
        }

        return dispatch(
          toggleAlert({
            toggle: true,
            message:
              res.status === 200
                ? 'Keyword set saved successfully'
                : res.message,
            type: res.status === 200 ? 'success' : 'error',
          }),
        );
      })
      .catch((err) => {
        setPage('matchtypes');
      });
  };

  const saveBulkKeywords = () => {
    const newkeywords = [
      ...textboxKeywords
        .split(/,|\n/)
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter((keyword) => keyword !== '' && keyword.length <= 80)
        .sort(
          (a, b) => a.localeCompare(b), // sort by dictionary order
        )
        .map((keyword) => {
          return {
            exact: false,
            phrase: false,
            broad: false,
            keyword: keyword.toLowerCase(),
          };
        }),
    ];

    if (keywords.length > 0) {
      newkeywords.map((keyword) => {
        const existingKeyword = keywords.find(
          (k) => k.keyword === keyword.keyword,
        );
        if (existingKeyword) {
          keyword.exact = existingKeyword.exact;
          keyword.phrase = existingKeyword.phrase;
          keyword.broad = existingKeyword.broad;
        }
      });
    }

    console.log(`newkeywords`);
    console.log(newkeywords);

    if (newkeywords.length === 0) {
      return dispatch(
        toggleAlert({
          toggle: true,
          message: 'Please enter a keyword',
          type: 'error',
        }),
      );
    }

    console.log(`saveBulkKeywords`);
    console.log(newkeywords);
    setKeywords(newkeywords);
    setPage('matchtypes');
    return false;
  };

  const syncKeywordSets = async () => {
    console.log(`syncKeywordSets`);
    const newkeywordset = await fetchAssetKeywordSets({ brandId: brandId });

    console.log(`syncKeywordSets2`);
    setKeywordSets(newkeywordset);
    setPage('summary');
    console.log(`syncKeywordSets3`);
    setDisplayedKeywordSets(
      newkeywordset.filter(
        (keywordSet: any) => keywordSet.negativeset === negativeKeywords,
      ),
    );
    return;
  };

  useEffect(() => {
    syncKeywordSets();
  }, []);

  return (
    <Box
      sx={{
        height: 'calc(100vh - 260px);',
        overflowY: 'auto',
        overflowX: 'hidden',
        ...scrollBarGreen,
      }}
    >
      {page === 'loading' && (
        <Box
          sx={{
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
            height: `100%`,
          }}
        >
          <CircularProgress color={'success'} size={40} />
        </Box>
      )}
      {page === 'summary' && (
        <Box sx={{ paddingX: 2 }}>
          <Box
            sx={{
              display: `flex`,
              justifyContent: `space-between`,
              gap: 4,
              width: `100%`,
              marginBottom: 2,
            }}
          >
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={negativeKeywords}
                onChange={(e) => {
                  setNegativeKeywords(e.target.value === 'true');
                  setDisplayedKeywordSets(
                    keywordSets.filter(
                      (keywordSet) =>
                        keywordSet.negativeset === (e.target.value === 'true'),
                    ),
                  );
                }}
              >
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Positive Keywords"
                />
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Negative Keywords"
                />
              </RadioGroup>
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              sx={{
                paddingY: 0,
                paddingX: 2,
                height: 30,
                alignItems: 'center',
              }}
              onClick={() => {
                setKeywords([]);
                setTextboxKeywords('');
                setKeywordSetName('');
                setKeywordUrl('');
                setSelectedProductAndService('');
                setIsGeneratingKeywords(false);
                setSuggestedKeywords([]);
                setKeywordResults('');
                setIsEditing(false);
                setPage('creation');
              }}
            >
              <MdAdd size={20} />
              Create new set of{' '}
              {negativeKeywords ? 'Negative Keywords' : 'Keywords'}
            </Button>
          </Box>
          {displayedKeywordSets.length > 0 ? (
            <>
              <Box>
                {displayedKeywordSets.map((keywordSet) => (
                  <>
                    <Box
                      sx={{
                        paddingX: 4,
                        border: `2px solid ${DEFAULT_COLOR_THEME}`,
                        borderRadius: 2,
                        paddingY: 2,
                        marginBottom: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: `flex`,
                          justifyContent: `space-between`,
                          gap: 4,
                        }}
                      >
                        <Box>
                          <Typography
                            variant="h6"
                            fontWeight={800}
                            color={DEFAULT_COLOR_THEME}
                            sx={{
                              textTransform: 'uppercase',
                            }}
                          >
                            {keywordSet.name}
                          </Typography>

                          <Typography
                            fontWeight={800}
                            mb={1}
                            color={DEFAULT_COLOR_THEME}
                            sx={{ fontStyle: 'italic' }}
                          >
                            {keywordSet.productandservice}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: `flex`,
                            gap: 1,
                          }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{ paddingY: 0, paddingX: 2, height: 30 }}
                            onClick={() => {
                              setTextboxKeywords(
                                keywordSet.keywords
                                  .map((k: any) => k.keyword)
                                  .join('\n'),
                              );
                              setIsGeneratingKeywords(false);
                              setSuggestedKeywords([]);
                              setKeywords(keywordSet.keywords);
                              setKeywordSetName(keywordSet.name);
                              setKeywordUrl(keywordSet.url || '');
                              setSelectedProductAndService(
                                keywordSet.productandservice,
                              );
                              setIsEditing(true);
                              setPage('creation');
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{ paddingY: 0, paddingX: 2, height: 30 }}
                            onClick={() => {
                              setTextboxKeywords(
                                keywordSet.keywords
                                  .map((k: any) => k.keyword)
                                  .join('\n'),
                              );
                              setIsGeneratingKeywords(false);
                              setSuggestedKeywords([]);
                              setKeywords(keywordSet.keywords);
                              setKeywordSetName(keywordSet.name);
                              setKeywordUrl(keywordSet.url || '');
                              setSelectedProductAndService(
                                keywordSet.productandservice,
                              );
                              setIsEditing(false);
                              setPage('creation');
                            }}
                          >
                            Duplicate
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{ paddingY: 0, paddingX: 2, height: 30 }}
                          >
                            Delete
                          </Button>
                        </Box>
                      </Box>

                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          {keywordSet.keywords.length > 0 ? (
                            <Table>
                              <TableBody>
                                <TableRow
                                  sx={{
                                    backgroundColor: DEFAULT_COLOR_THEME,
                                    color: '#fff',
                                  }}
                                >
                                  <TableCell sx={{ padding: 1, color: `#fff` }}>
                                    {negativeKeywords
                                      ? 'Negative Keywords'
                                      : 'Keywords'}
                                    : {keywordSet.keywords.length}
                                  </TableCell>
                                  <TableCell sx={{ padding: 1, color: `#fff` }}>
                                    Exact
                                  </TableCell>
                                  <TableCell sx={{ padding: 1, color: `#fff` }}>
                                    Phrase
                                  </TableCell>
                                  <TableCell sx={{ padding: 1, color: `#fff` }}>
                                    Broad
                                  </TableCell>
                                </TableRow>
                                {keywordSet.keywords.map(
                                  (
                                    keyword: {
                                      keyword: string;
                                      exact: boolean;
                                      phrase: boolean;
                                      broad: boolean;
                                    },
                                    i: number,
                                  ) => (
                                    <>
                                      {i <= 4 || keywordSet.length === 5 ? (
                                        <TableRow>
                                          <TableCell
                                            sx={{ paddingY: 0, paddingLeft: 2 }}
                                          >
                                            <Typography
                                              sx={{
                                                display: `inline`,
                                                fontSize: 8,
                                              }}
                                            >
                                              {i + 1}.
                                            </Typography>{' '}
                                            {keyword.keyword}
                                          </TableCell>
                                          <TableCell sx={{ padding: 0 }}>
                                            <Checkbox
                                              color="primary"
                                              inputProps={{
                                                'aria-label':
                                                  'secondary checkbox',
                                              }}
                                              checked={keyword.exact}
                                              disabled
                                            />
                                          </TableCell>
                                          <TableCell sx={{ padding: 0 }}>
                                            <Checkbox
                                              color="primary"
                                              inputProps={{
                                                'aria-label':
                                                  'secondary checkbox',
                                              }}
                                              checked={keyword.phrase}
                                              disabled
                                            />
                                          </TableCell>
                                          <TableCell sx={{ padding: 0 }}>
                                            <Checkbox
                                              color="primary"
                                              inputProps={{
                                                'aria-label':
                                                  'secondary checkbox',
                                              }}
                                              checked={keyword.broad}
                                              disabled
                                            />
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                    </>
                                  ),
                                )}

                                {keywordSet.keywords.length > 5 ? (
                                  <>
                                    <TableRow>
                                      <TableCell
                                        colSpan={4}
                                        sx={{ paddingY: 0, paddingLeft: 2 }}
                                      >
                                        + {keywordSet.keywords.length - 4} more
                                      </TableCell>
                                    </TableRow>
                                  </>
                                ) : null}
                              </TableBody>
                            </Table>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                ))}
              </Box>
            </>
          ) : (
            <>No {negativeKeywords ? 'Negative Keywords' : 'Keywords'} Yet</>
          )}
        </Box>
      )}

      {page === 'creation' && (
        <Box
          sx={{
            paddingX: 2,
          }}
        >
          <Box
            sx={{
              display: `flex`,
              justifyContent: `space-between`,
              gap: 4,
              width: `100%`,
              marginBottom: 2,
            }}
          >
            <Typography
              variant="h6"
              fontWeight={800}
              color={DEFAULT_COLOR_THEME}
            >
              New {negativeKeywords ? 'Negative Keywords' : 'Keywords'} Set
              Creation
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              sx={{ paddingY: 0, paddingX: 2, height: 30 }}
              onClick={() => setPage('summary')}
            >
              Cancel
            </Button>
          </Box>
          <Grid
            sx={{
              width: `100%`,
            }}
            container
            spacing={2}
          >
            <Grid item sm={12} md={6}>
              <Box
                sx={{
                  backgroundColor: `#fafafa`,
                  border: `2px solid ${DEFAULT_COLOR_THEME}`,
                  borderRadius: 2,
                  padding: 2,
                }}
              >
                <Typography fontWeight={800} color={DEFAULT_COLOR_THEME}>
                  {negativeKeywords ? 'Negative ' : ''}Keywords
                </Typography>
                <TextField
                  id="input-with-sx"
                  variant="standard"
                  placeholder="Enter keywords separated by a new line"
                  fullWidth
                  multiline
                  rows={20}
                  value={textboxKeywords}
                  sx={{
                    backgroundColor: `#ffffff`,
                    paddingX: 2,
                  }}
                  onChange={(e) => {
                    setTextboxKeywords(e.target.value);
                  }}
                />
              </Box>
            </Grid>
            <Grid item sm={12} md={6}>
              <Box
                padding={1}
                borderRadius={2}
                //border={`2px solid ${DEFAULT_COLOR_THEME}`}
                sx={{
                  width: `100%`,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
                  <Box
                    bgcolor={DEFAULT_COLOR_THEME}
                    borderRadius={100}
                    height={32}
                    width={32}
                    display={`flex`}
                    justifyContent={`center`}
                    alignItems={`center`}
                    flexShrink={0}
                  >
                    <IoMdLink size={24} color="#ffffff" />
                  </Box>
                  <TextField
                    id="input-with-sx"
                    label="Enter a related website"
                    variant="standard"
                    fullWidth
                    value={keywordUrl}
                    onChange={(e) => {
                      setKeywordUrl(e.target.value);
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    gap: 2,
                    my: 1,
                  }}
                >
                  <Box
                    bgcolor={DEFAULT_COLOR_THEME}
                    borderRadius={100}
                    height={32}
                    width={32}
                    display={`flex`}
                    justifyContent={`center`}
                    alignItems={`center`}
                    flexShrink={0}
                  >
                    <HiBuildingOffice2 size={24} color="#ffffff" />
                  </Box>
                  <TextField
                    id="input-with-sx"
                    label="Enter your product or service"
                    variant="standard"
                    value={selectedProductAndService}
                    onChange={(e) => {
                      setSelectedProductAndService(e.target.value);
                    }}
                    fullWidth
                  />
                </Box>

                <Box>
                  Suggested keywords for{' '}
                  <Typography
                    sx={{ color: DEFAULT_COLOR_THEME, display: 'inline' }}
                  >
                    {keywordResults}
                  </Typography>
                </Box>

                <Box
                  bgcolor={`#e5e5e5`}
                  my={2}
                  borderRadius={2}
                  maxHeight={`245px`}
                  overflow={`auto`}
                  sx={{
                    '&::-webkit-scrollbar': {
                      width: '10px',
                    },
                    '&::-webkit-scrollbar-track': {
                      // background: "#F1F0F0",
                      borderTopRightRadius: '5px',
                      borderBottomRightRadius: '5px',
                    },

                    '&::-webkit-scrollbar-thumb': {
                      background: DEFAULT_COLOR_THEME,
                      borderRadius: '18px',

                      '&:hover': {
                        background: DEFAULT_COLOR_THEME,
                      },
                    },
                    padding: '5px',
                  }}
                >
                  {suggestedKeywords.length === 0 ? (
                    isGeneratingKeywords ? (
                      <CircularProgress />
                    ) : errorGeneratingKeywords ? (
                      <Typography>
                        Google cannot generate keywords at this time, please try
                        again
                      </Typography>
                    ) : (
                      <Typography>No Keywords Yet</Typography>
                    )
                  ) : (
                    <>
                      {suggestedKeywords.map((k, i) => {
                        return (
                          <Box key={i + 1}>
                            <Button
                              variant="text"
                              type="button"
                              size="small"
                              sx={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                gap: 1,
                                width: `100%`,
                                justifyContent: 'left',
                                backgroundColor: `#ffffff`,
                              }}
                              onClick={() => {
                                setTextboxKeywords(
                                  textboxKeywords + '\n' + k.keyword,
                                );
                                setSuggestedKeywords(
                                  suggestedKeywords.filter(
                                    (kw) => kw.keyword !== k.keyword,
                                  ),
                                );
                                //submitForm([...filterKeywords([k.keyword])]);
                              }}
                            >
                              <Box
                                borderRadius={100}
                                height={24}
                                width={24}
                                display={`flex`}
                                justifyContent={`center`}
                                alignItems={`center`}
                                flexShrink={0}
                              >
                                <IoMdAdd
                                  size={24}
                                  color={DEFAULT_COLOR_THEME}
                                />
                              </Box>
                              <Tooltip
                                disableInteractive
                                title={
                                  true == true ? (
                                    <Box>
                                      <Box>
                                        Avg Monthly Searches:{' '}
                                        {k.avg_monthly_searches}
                                      </Box>
                                      <Box>Competition: {k.competition}</Box>
                                      <Box>
                                        High Top of Page Bid:{' '}
                                        {k.high_top_of_page_bid_micros}
                                      </Box>
                                      <Box>
                                        Low Top of Page Bid:{' '}
                                        {k.low_top_of_page_bid_micros}
                                      </Box>
                                      <Box>Concepts: {k.concepts}</Box>
                                    </Box>
                                  ) : null
                                }
                              >
                                <Typography color={`#000`} fontSize={12}>
                                  {k.keyword}
                                </Typography>
                              </Tooltip>
                            </Button>
                          </Box>
                        );
                      })}
                    </>
                  )}
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 2,
                    width: `100%`,
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{ width: `50%` }}
                    onClick={() => {
                      setTextboxKeywords(
                        textboxKeywords +
                          '\n' +
                          suggestedKeywords
                            .map((k) => k.keyword)
                            .join('\n')
                            .replace(/,/g, ''),
                      );
                      setSuggestedKeywords([]);
                      generateKeywords();
                      // submitForm([
                      //   ...filterKeywords(
                      //     suggestedKeywords.map((k) => k.keyword),
                      //   ),
                      // ]);
                      //setKeywordPage(1);
                    }}
                  >
                    Add All Keyword Suggestions
                  </Button>
                  <Button
                    disabled={isGeneratingKeywords}
                    variant="contained"
                    sx={{ width: `50%` }}
                    onClick={() => {
                      generateKeywords();
                    }}
                  >
                    {isGeneratingKeywords
                      ? 'Generating Keywords'
                      : 'Regenerate Keyword Suggestions'}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      {page === 'matchtypes' && (
        <Box
          sx={{
            paddingX: 2,
          }}
        >
          <Box
            sx={{
              display: `flex`,
              justifyContent: `space-between`,
              gap: 4,
              width: `100%`,
              marginBottom: 2,
            }}
          >
            <Typography
              variant="h6"
              fontWeight={800}
              color={DEFAULT_COLOR_THEME}
            >
              New {negativeKeywords ? 'Negative Keywords' : 'Keywords'} Set
              Creation
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              sx={{ paddingY: 0, paddingX: 2, height: 30 }}
              onClick={() => setPage('summary')}
            >
              Cancel
            </Button>
          </Box>

          <Grid
            sx={{
              width: `100%`,
              padding: 0,
              margin: 0,
            }}
            container
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                height: 'calc(100vh - 400px);',
                maxHeight: 'calc(100vh - 400px);',
                overflowY: 'auto',
                ...scrollBarGreen,
                padding: 0,
                margin: 0,
              }}
            >
              {keywords.length > 0 ? (
                <Table sx={{ padding: 0, margin: 0 }}>
                  <TableBody sx={{ padding: 0, margin: 0 }}>
                    <TableRow
                      sx={{
                        marginTop: 0,
                        paddingTop: 0,
                        backgroundColor: DEFAULT_COLOR_THEME,
                        color: '#fff',
                        position: `sticky`,
                        top: 0,
                        zIndex: 1,
                      }}
                    >
                      <TableCell sx={{ padding: 1, color: `#fff` }}>
                        {negativeKeywords ? 'Negative Keywords' : 'Keywords'}:{' '}
                        {keywords.length}
                      </TableCell>
                      <TableCell sx={{ padding: 1, color: `#fff` }}>
                        Exact
                      </TableCell>
                      <TableCell sx={{ padding: 1, color: `#fff` }}>
                        Phrase
                      </TableCell>
                      <TableCell sx={{ padding: 1, color: `#fff` }}>
                        Broad
                      </TableCell>
                    </TableRow>
                    {keywords.map((keyword: any, i: number) => (
                      <>
                        <TableRow>
                          <TableCell sx={{ paddingY: 0, paddingLeft: 2 }}>
                            <Typography
                              sx={{
                                display: `inline`,
                                fontSize: 8,
                              }}
                            >
                              {i + 1}.
                            </Typography>{' '}
                            {keyword.keyword}
                          </TableCell>
                          <TableCell sx={{ padding: 0 }}>
                            <Checkbox
                              color="primary"
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                              checked={keyword.exact}
                              onChange={(e) => {
                                const newKeywords = [...keywords];
                                newKeywords[i].exact = e.target.checked;
                                setKeywords(newKeywords);
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ padding: 0 }}>
                            <Checkbox
                              color="primary"
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                              checked={keyword.phrase}
                              onChange={(e) => {
                                const newKeywords = [...keywords];
                                newKeywords[i].phrase = e.target.checked;
                                setKeywords(newKeywords);
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ padding: 0 }}>
                            <Checkbox
                              color="primary"
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                              checked={keyword.broad}
                              onChange={(e) => {
                                const newKeywords = [...keywords];
                                newKeywords[i].broad = e.target.checked;
                                setKeywords(newKeywords);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              ) : null}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                padding: 1,
                borderRadius: 2,
                //border: `2px solid ${DEFAULT_COLOR_THEME}`,
                width: `100%`,
              }}
            >
              <TextField
                id="outlined-basic"
                label="Keyword Set Name"
                variant="outlined"
                value={keywordSetName}
                onChange={(e) => {
                  setKeywordSetName(e.target.value);
                }}
                fullWidth
                disabled={isEditing ? true : false}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {(page === 'creation' || page === 'matchtypes') && (
        <Box
          sx={{
            position: `sticky`,
            bgcolor: `#fafafa`,
            bottom: 0,
            display: `flex`,
            justifyContent: `flex-end`,
            gap: 2,
            width: `100%`,
            padding: 2,
            marginTop: 2,
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            sx={{
              paddingY: 0,
              paddingX: 2,
              height: 30,
              alignItems: 'center',
              display: page === 'creation' ? 'none' : 'block',
            }}
            onClick={() => {
              if (page === 'matchtypes') {
                setPage('creation');
              }
            }}
          >
            Back
          </Button>

          <Button
            variant="contained"
            color="primary"
            sx={{
              paddingY: 0,
              paddingX: 2,
              height: 30,
              alignItems: 'center',
            }}
            onClick={() => {
              if (page === 'creation') {
                saveBulkKeywords();
              }

              if (page === 'matchtypes') {
                submitCheck();
              }
            }}
          >
            {page === 'creation' ? 'Continue to match types' : 'Save Keywords'}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default AssetGoogleKeywordsSet;
