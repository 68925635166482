import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import ModalHeader from '../../Modal/ModalHeader';
import { sendLeadSummaryColumns } from './SendLeadSummaryTableColumns';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';

interface SendLeadSummaryTableProps {
  summary: any[];
}

const SendLeadSummaryTable: React.FC<SendLeadSummaryTableProps> = ({
  summary,
}) => {
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ModalHeader title="Send Lead Summary" />
      </Grid>

      <Grid item xs={12}>
        <DataGridPremium
          className={styles.table}
          initialState={{
            sorting: {
              sortModel: [{ field: 'createdAt', sort: 'desc' }],
            },
          }}
          density="compact"
          rows={summary}
          autoHeight
          hideFooterSelectedRowCount
          columns={sendLeadSummaryColumns(xsOnly)}
        />
      </Grid>
    </Grid>
  );
};

export default SendLeadSummaryTable;
