/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import {
  updateAdBudgetColumns,
  updateAdBudgetGroupingColumn,
} from '../Tables/BudgetList/BudgetTableColumn';
import { MenuItem, TextField, useMediaQuery, useTheme } from '@mui/material';
import styles from '../../assets/styles/pages/Pages.module.scss';
import tableStyles from '../../assets/styles/components/Tables/Tables.module.scss';
import { AdBudget, Agency } from '../../types';
import { ChangeEventType } from '../../types';
import momentTz from 'moment-timezone';
import { humanizeString } from '../../utils/stringModifier';
import { AuthContext } from '../../context';
import {
  createBudget,
  fetchAiAllBudgets,
  fetchAiAllBudgetsByAgency,
} from '../../services/budget';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAlert } from '../../redux/actions';
import PageHeader from '../PageHeader';
import CircularLoading from '../CircularLoading';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridCellEditStopParams,
  GridGroupNode,
} from '@mui/x-data-grid-premium';
import { toCurrency } from '../../utils/numberFormatter';
import { errorMessageParser } from '../../utils/helpers/ToastHelper';
import { CustomLoadingOverlay } from '../Loading';
import { arrayUniqueFilter } from '../../utils/arrayFormatter';
import { FbAdStatus } from '../../utils/helpers/facebookAdsHelper';
import { GoogleCampaignStatus } from '../../utils/constants/google';

const AddBudgetForm: React.FC = () => {
  const agency: Agency = useSelector((state: any) => state?.agency?.agency);
  const timezone: string = useSelector(
    (state: any) => state?.brandTimezone?.timezone,
  );
  const theme = useTheme();
  const isMdAndDown = useMediaQuery(theme.breakpoints.down('md'));
  const campaignProvider = useSelector(
    (state: any) => state?.budgetReportProvider?.provider,
  );
  useContext(AuthContext);
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState<AdBudget>({
    campaign: '',
    adsetId: '',
    totalBudget: 0,
    dailyBudget: null,
    year: null,
    month: null,
    provider: campaignProvider,
    level: '',
    monthName: '',
  });
  const [years, setYears] = useState([]);
  const [budgets, setBudgets] = useState<any[]>([]);
  const [year, setYear] = useState<string>(
    momentTz().tz(timezone).year().toString(),
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  const getTreeDataPath: DataGridPremiumProps['getTreeDataPath'] = (row) =>
    row.group;

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (agency) {
        getBrandBudgetsByAgency();
      } else {
        getBrandBudgets();
      }
    }, 1000);

    buildYears();

    return () => clearTimeout(delayDebounceFn);
  }, [year, campaignProvider, agency]);

  const getBrandBudgets = async () => {
    try {
      setLoading(true);

      const response = await fetchAiAllBudgets(year, campaignProvider);

      setBudgets(response.data);
    } catch (error: any) {
      dispatch(
        toggleAlert({ toggle: true, type: 'error', message: error.message }),
      );
    } finally {
      setLoading(false);
    }
  };

  const getBrandBudgetsByAgency = async () => {
    try {
      setLoading(true);

      const response = await fetchAiAllBudgetsByAgency(
        agency?._id,
        year,
        campaignProvider,
      );

      setBudgets(response.data);
    } catch (error: any) {
      dispatch(
        toggleAlert({ toggle: true, type: 'error', message: error.message }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleOnSubmit = async (params: any) => {
    try {
      setSubmitLoading(true);

      let temp: AdBudget = { ...formValues };
      const budget: number = parseFloat(params[temp.monthName]);
      const tempParams = { ...params, [temp.monthName]: budget };

      temp = { ...temp, totalBudget: budget };

      const response = await createBudget(temp);

      let budgetTemp: any[] = [];
      budgets.forEach((budget: any) => {
        const id =
          response.data.level === 'brand'
            ? response.data.brand
            : response.data.level === 'campaign'
            ? response.data.campaign
            : response.data.adsetId;
        if (budget.id === id) {
          budget[response.data.month] = response.data.totalBudget;
        }

        budgetTemp = [...budgetTemp, budget];
      });

      dispatch(
        toggleAlert({
          toggle: true,
          message: `${toCurrency('USD', budget)} ${
            temp.level
          } budget is set for ${humanizeString(temp.monthName)} ${year}`,
        }),
      );

      setBudgets(budgetTemp);

      return tempParams;
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(
        toggleAlert({
          toggle: true,
          message: errorMsg,
          type: 'error',
        }),
      );
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleSetYear = async (year: string) => {
    setYear(year);
  };

  const buildYears = () => {
    const startDate = momentTz.tz('2022-01-01', timezone);
    const endDate = momentTz.tz(momentTz(), timezone);
    let years: string[] = [];

    while (startDate.year() <= endDate.year()) {
      years.push(startDate.year().toString());
      startDate.add(1, 'year');
    }

    setYears(years);
  };

  const buildFormValues = (params: GridCellEditStopParams) => {
    const month = parseInt(
      momentTz().tz(timezone).month(params.field).format('M'),
    );

    let temp: AdBudget = {
      level: params.row.level,
      month,
      year: parseInt(year),
      provider: campaignProvider,
      monthName: params.field,
    };

    if (params.row.level === 'adset') {
      temp = { ...temp, adsetId: params.row.id };
    } else if (params.row.level === 'campaign') {
      temp = { ...temp, campaign: params.row.id };
    } else {
      temp = { ...temp, brand: params.row.id };
    }

    setFormValues(temp);
  };

  const rows = budgets?.map((row: any) => {
    return {
      id: row.id,
      group: row.group,
      january: row['1'],
      february: row['2'],
      march: row['3'],
      april: row['4'],
      may: row['5'],
      june: row['6'],
      july: row['7'],
      august: row['8'],
      september: row['9'],
      october: row['10'],
      november: row['11'],
      december: row['12'],
      level: row.level,
      status: row.status,
    };
  }, []);

  const cellBgFormatter = (params: any) => {
    const { status } = params.row;

    if (
      ['campaign', 'adset'].includes(params.row.level) &&
      status &&
      params.field === '__tree_data_group__'
    ) {
      if (status === 'IN_PROCESS') return 'primary';

      if (![FbAdStatus.ACTIVE, GoogleCampaignStatus.ENABLED].includes(status))
        return 'red';
    }

    return '';
  };

  return (
    <Box className={styles.page}>
      <CircularLoading loading={submitLoading} />

      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader
            title={`${humanizeString(campaignProvider)} Ads Budget Set Up`}
          />

          <div className={styles.controls}>
            <div className={styles.buttons}>
              <div>
                <TextField
                  id="year"
                  name="year"
                  select
                  sx={{ width: '100px' }}
                  label="Year"
                  value={year}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  onChange={(e: ChangeEventType) => {
                    handleSetYear(e.target.value);
                  }}
                >
                  {years.map((year: number) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
          </div>
        </div>

        <Box
          sx={{
            '& .green': {
              backgroundColor: 'green',
              fontWeight: 'bold',
            },
            '& .yellow': {
              backgroundColor: 'yellow',
              fontWeight: 'bold',
            },
            '& .red': {
              backgroundColor: 'red',
              fontWeight: 'bold',
            },
            '& .primary': {
              backgroundColor: '#1976d2',
              fontWeight: 'bold',
            },
          }}
        >
          <DataGridPremium
            treeData
            groupingColDef={updateAdBudgetGroupingColumn(isMdAndDown)}
            getTreeDataPath={getTreeDataPath}
            rows={loading ? [] : rows}
            columns={updateAdBudgetColumns(isMdAndDown)}
            autoHeight
            density="compact"
            hideFooterSelectedRowCount
            loading={loading}
            className={tableStyles.table}
            onCellEditStop={buildFormValues}
            processRowUpdate={async (updated) => {
              await handleOnSubmit(updated);

              return updated;
            }}
            slots={{ loadingOverlay: CustomLoadingOverlay }}
            slotProps={{
              baseIconButton: {
                onClick: () => {
                  return false;
                },
              },
            }}
            onRowClick={(params, event: any) => {
              if (event.target?.className?.animVal) {
                let groups: string[] = [...expandedKeys];

                const existingKey = groups.find(
                  (key: any) =>
                    key === params.row.group[params.row.group.length - 1],
                );

                if (existingKey) {
                  groups = groups.filter((key: string) => key !== existingKey);
                } else {
                  budgets.forEach((budget: any) => {
                    if (budget.group.includes(params.row.group[0])) {
                      groups = [...groups, ...budget.group];
                    }
                  });
                }

                setExpandedKeys(arrayUniqueFilter(groups));
              }
            }}
            onProcessRowUpdateError={(error) => console.log(error)}
            isGroupExpandedByDefault={(row: GridGroupNode) =>
              expandedKeys.includes(row.groupingKey as string)
            }
            getCellClassName={(params: any) => {
              return cellBgFormatter(params);
            }}
          />
        </Box>
      </div>
    </Box>
  );
};

export default AddBudgetForm;
