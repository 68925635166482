import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styles from '../../assets/styles/components/Forms/Form.module.scss';
import { PrimaryButton } from '../Buttons';
import FacebookIcon from '@mui/icons-material/Facebook';
import FormHeader from './FormHeader';
import { connectFacebookAccount, getUserByRoleId } from '../../services/user';
import { IFacebookUser } from '../../types';
import { signIn } from '../Auth/FacebookLoginProvider';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../redux/actions';
import CircularLoading from '../CircularLoading';
import { DiySettings } from '../../types/IDiy';
import { IS_DIY_ADZ } from '../../utils';

interface ConnectFacebookAccountFormProps {
  userId?: string;
  role?: string;
  isBrand?: boolean;
  setUser?: Dispatch<SetStateAction<IFacebookUser>>;
  diySettings: DiySettings;
  setDiySettings: Dispatch<SetStateAction<DiySettings>>;
}

const ConnectFacebookAccountForm: React.FC<ConnectFacebookAccountFormProps> = ({
  userId,
  role,
  isBrand = false,
  setUser,
  diySettings,
  setDiySettings,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [facebookAccount, setFacebookAccount] = useState<IFacebookUser>(null);

  useEffect(() => {
    if (userId) {
      getFacebookUser();
    }
  }, [userId]);

  const getFacebookUser = async () => {
    try {
      setFetchLoading(true);

      const response = await getUserByRoleId(userId, 'facebook');

      setFacebookAccount(response?.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const handleConnectFbAccount = () => {
    signIn()
      .then(async (response: any) => {
        setLoading(true);
        if (response.status === 'connected') {
          try {
            const token = response.authResponse.accessToken;

            const data = await connectFacebookAccount(
              token,
              role,
              userId,
              IS_DIY_ADZ,
            );
            if (data?.data) {
              if (isBrand) {
                setDiySettings({
                  ...diySettings,
                  defaultUser: data.salesperson,
                });

                setUser(data?.data);
                setFacebookAccount(data?.data);
              } else {
                setFacebookAccount(data?.data);
              }
            } else {
              dispatch(
                toggleAlert({
                  toggle: true,
                  message: 'Failed to connect your Facebook account',
                  type: 'error',
                }),
              );
            }
          } catch (error: any) {
            setLoading(false);
            dispatch(
              toggleAlert({
                toggle: true,
                message: 'Failed to connect your Facebook account',
                type: 'error',
              }),
            );
          }
        }

        setLoading(false);
      })
      .catch((error: any) => {
        // TODO: Add Facebook Login Error Parser
        dispatch(
          toggleAlert({
            toggle: true,
            message: 'Failed to connect your Facebook account',
            type: 'error',
          }),
        );
      });
  };

  return (
    <Box
      sx={{ width: '100%', justifyContent: 'center', display: 'flex' }}
      className={`${styles.form} ${xsOnly ? `${styles['-mobile']}` : ''}`}
    >
      <CircularLoading loading={fetchLoading} />

      <Grid container spacing={2}>
        {!isBrand ? (
          <Grid item xs={12}>
            <FormHeader title="Connected Accounts" />
          </Grid>
        ) : null}

        {facebookAccount && !isBrand ? (
          <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
            <Card sx={{ width: xsOnly ? '100%' : '300px' }}>
              <CardHeader
                avatar={
                  <Avatar
                    src={facebookAccount?.imageUrl}
                    alt={facebookAccount?.firstName}
                  />
                }
                title={`${facebookAccount?.firstName} ${facebookAccount?.lastName}`}
                titleTypographyProps={{
                  sx: {
                    fontWeight: 'bold',
                  },
                }}
                subheader={facebookAccount?.facebookUid}
              />
            </Card>
          </Grid>
        ) : null}

        <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
          <PrimaryButton
            type="button"
            title={`${facebookAccount ? 'Change' : 'Connect'} Facebook Account`}
            handleOnClick={handleConnectFbAccount}
            startIcon={loading ? null : <FacebookIcon />}
            theme="primary"
            loading={loading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConnectFacebookAccountForm;
