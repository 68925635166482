import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from '../../assets/styles/pages/Reports.module.scss';
import PageHeader from '../../components/PageHeader';
import DateRangePicker from '../../components/DateAndTimePickers/DateRangePicker';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setEndRange, setStartRange, toggleAlert } from '../../redux/actions';
import {
  Box,
  Divider,
  Grid,
  useMediaQuery,
  useTheme,
  Typography,
} from '@mui/material';
import { Agency, AiInsight, AIReport, Brand } from '../../types';
import {
  getAiInsightsData,
  getAiReportData,
  getAllBrandsAiReportData,
} from '../../services/report';
import { AuthContext } from '../../context';
import { ADMIN, DEFAULT_COLOR_THEME, SUPER_ADMIN } from '../../utils';
import AIReportTable from '../../components/Tables/ReportList/AIReportTable';
import { errorMessageParser } from '../../utils/helpers/ToastHelper';
import { getAdCopyAnalysis, getAiInsightzAnalysis } from '../../services/ai';
import HTMLReactParser from 'html-react-parser';
import FormHeader from '../../components/Forms/FormHeader';
import Spinner from '../../components/Spinner';
import FacebookAdDetails from '../../components/Details/Ads/FacebookAdDetails';
import { DrawerMenu } from '../../components/Menu';
import AllBrandAIReportTable from '../../components/Tables/ReportList/AllBrandAIReportTable';
import { BrandIndustry } from '../../utils/helpers/BrandHelpers';
import { getSingleBrand } from '../../services/brand';
import CircularLoading from '../../components/CircularLoading';

const AiReport: React.FC = () => {
  const boxRef: any = useRef();
  const scrollRef: any = useRef();
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const dispatch = useDispatch();
  const startRange = useSelector((state: any) => state.startRange.date);
  const endRange = useSelector((state: any) => state.endRange.date);
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const agency: Agency = useSelector((state: any) => state?.agency?.agency);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const showAllBrands: boolean = useSelector(
    (state: any) => state?.showAllBrands,
  );
  const { state } = useContext(AuthContext);
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isAdmin = state.role === ADMIN;
  const roleBasedId: string = state.roleBasedId;

  const [insightzReport, setInsightzReport] = useState<AIReport[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedInsights, setSelectedInsights] = useState<AIReport>(null);
  const [openInsightz, setOpenInsightz] = useState<boolean>(false);
  const [acquisitionAnalysis, setAcquisitionAnalysis] = useState<string>('');
  const [audienceAnalysis, setAudienceAnalysis] = useState<string>('');
  const [acquisitionLoading, setAcquisitionLoading] = useState<boolean>(false);
  const [audienceLoading, setAudienceLoading] = useState<boolean>(false);
  const [adAnalysisLoading, setAdAnalysisLoading] = useState<boolean>(false);
  const [adAnalysis, setAdAnalysis] = useState<string>('');
  const [selectedAd, setSelectedAd] = useState<string>('');
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [acquisitionPrompts, setAcquisitionPrompts] = useState<string>(null);
  const [audiencePrompts, setAudiencePrompts] = useState<string>(null);
  const [showPrompts, setShowPrompts] = useState<boolean>(false);
  const [insightzDataLoading, setInsightzDataLoading] =
    useState<boolean>(false);
  const [selectedBrand, setSelectedBrand] = useState<Brand>(null);
  const [brandLoading, setBrandLoading] = useState<boolean>(false);

  useEffect(() => {
    setInsightzReport([]);
    resetAnalysis();
  }, [showAllBrands]);

  useEffect(() => {
    if ((location || brand) && startRange && endRange) {
      if (showAllBrands) {
        getAllBrandReport();
      } else {
        getReport();
      }
    }
  }, [location, brand, startRange, endRange, showAllBrands, agency]);

  const getReport = async () => {
    try {
      setLoading(true);

      const response = await getAiReportData(
        (location || brand)?._id,
        startRange,
        endRange,
        'facebook',
      );

      setInsightzReport(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getAllBrandReport = async () => {
    try {
      setLoading(true);

      const response = await getAllBrandsAiReportData(
        startRange,
        endRange,
        'facebook',
        isSuperAdmin || isAdmin ? null : roleBasedId,
        agency?._id,
      );

      setInsightzReport(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeDateRange = (dates: Moment[] | []) => {
    dispatch(setStartRange(dates[0]));
    dispatch(setEndRange(dates[1]));

    resetAnalysis();
  };

  const resetAnalysis = () => {
    setAdAnalysis('');
    setAudienceAnalysis('');
    setAcquisitionAnalysis('');
    setAcquisitionPrompts(null);
    setAudiencePrompts(null);
    setShowPrompts(false);
    setOpenInsightz(false);
  };

  const handleOpenAnalysis = async (insight: AIReport) => {
    try {
      setSelectedInsights(insight);
      setInsightzDataLoading(true);
      setAcquisitionPrompts(null);
      setAudiencePrompts(null);
      await setOpenInsightz(true);
      // Await for open insightz to calculate the new
      // client height
      scrollToBottom();

      const insightsData = await getAiInsightsData(
        insight.id,
        startRange,
        endRange,
      );

      const data: AiInsight = insightsData.data;

      if (data.adScore === 'TBD') {
        getAdScore(
          data,
          showAllBrands ? data.brandId : (location || brand)?._id,
        );
      } else {
        setAdAnalysis(data.adAnalysis);
      }

      getAiInsightz(data, 'audience');

      getAiInsightz(data, 'acquisition');
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setInsightzDataLoading(false);
    }
  };

  const getAiInsightz = async (insight: AiInsight, analysisType: string) => {
    try {
      if (analysisType === 'acquisition') {
        setAcquisitionLoading(true);
      } else {
        setAudienceLoading(true);
      }
      const response = await getAiInsightzAnalysis(
        insight,
        analysisType,
        (location || brand)?._id,
      );
      const prompts = buildAiInsightzPrompts(response.data.prompts);

      if (analysisType === 'acquisition') {
        setAcquisitionPrompts(prompts);
      } else {
        setAudiencePrompts(prompts);
      }

      if (analysisType === 'acquisition') {
        setAcquisitionAnalysis(response.data.analysis);
      } else {
        setAudienceAnalysis(response.data.analysis);
      }
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      if (analysisType === 'acquisition') {
        setAcquisitionLoading(false);
      } else {
        setAudienceLoading(false);
      }
    }
  };

  const getAdScore = async (insight: AiInsight, brandId: string) => {
    try {
      setAdAnalysisLoading(true);

      const response = await getAdCopyAnalysis(
        insight.adCopy,
        brandId,
        insight.template,
      );

      setAdAnalysis(response.data.analysis);

      let temp: AIReport[] = [];
      insightzReport.forEach((data: AIReport) => {
        if (data.id === insight.id) {
          const adScoreValue =
            (response.data.grade || 'TBD') === 'Effective' ? 5 : 0;
          const performanceValue =
            adScoreValue + data.acquisitionScore + data.audienceScore;
          const performanceTScore = getPerformanceTValue(
            location || brand,
            performanceValue,
          );
          const performanceScore = getPerformanceScore(performanceTScore);

          data.adScore = response.data.grade;
          data.performanceScore = performanceScore;
        }

        temp = [...temp, data];
      });

      setInsightzReport(temp);
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setAdAnalysisLoading(false);
    }
  };

  const getBrand = async (brandId: string) => {
    try {
      setBrandLoading(true);
      const response = await getSingleBrand(brandId);

      return response;
    } catch (error: any) {
      console.log(error);
    } finally {
      setBrandLoading(false);
    }
  };

  const buildAiInsightzPrompts = (prompt: any) => {
    const prompts = Object.keys(prompt).map((key: string) => {
      return `${key}: ${prompt[key]}`;
    });

    return prompts.join('\n');
  };

  const handleOpenPreview = async (insight: AIReport) => {
    let tempBrand: Brand = location || brand;
    if (showAllBrands) {
      const response = await getBrand(
        insight.type === 'location' ? insight.locationId : insight.brandId,
      );

      if (!response) {
        return dispatch(
          toggleAlert({
            toggle: true,
            message: 'No data to show',
            type: 'error',
          }),
        );
      }

      tempBrand = response;
    }

    setSelectedBrand(tempBrand);
    setSelectedAd(insight.id);
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const getPerformanceTValue = (brand: Brand, performanceValue: number) => {
    if (brand.industry === BrandIndustry.RESTAURANT) {
      return (performanceValue - 13.29) / 4.39;
    }

    return (performanceValue - 13.76) / 3.94;
  };

  const getPerformanceScore = (performanceTScore: number) => {
    return parseFloat(
      (10 / (1 + Math.pow(2.71, performanceTScore * -1))).toFixed(2),
    );
  };

  const buildAdNameLabel = (insight: AIReport) => {
    let text: string = '';

    if (showAllBrands) {
      text = `${insight.brand}`;

      if (insight.type === 'location') {
        text = `${text} (${insight.location})`;
      }
    }

    text = `${text} ${insight.adName}`;

    return text;
  };

  const scrollToBottom = () => {
    boxRef.current.scrollTo({
      top: scrollRef.current.clientHeight,
      behavior: 'smooth',
    });
  };

  return (
    <Box
      className={styles.report}
      ref={boxRef}
      sx={{
        minHeight: 'calc(100vh - 100px)',
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '10px',
        },
        '&::-webkit-scrollbar-track': {
          // background: "#F1F0F0",
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
        },

        '&::-webkit-scrollbar-thumb': {
          background: DEFAULT_COLOR_THEME,
          borderRadius: '18px',

          '&:hover': {
            background: DEFAULT_COLOR_THEME,
          },
        },
      }}
    >
      <CircularLoading loading={brandLoading} />

      <div className={styles.base} ref={scrollRef}>
        <div className={styles.header}>
          <PageHeader title="AI Insightz" />

          <div className={styles.controls}>
            <DateRangePicker
              toDate={endRange}
              fromDate={startRange}
              onChange={handleChangeDateRange}
              maxDate={moment()}
              separator="-"
            />
          </div>
        </div>

        {/**
         * Temporary Disabled
         * TODO: Re-enable when needed. Create a new endpoint to build Export Data
         * !loading ? (
          <Grid container spacing={1} sx={{ flexGrow: 1, mb: 2 }}>
            <Grid
              item
              xs={12}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {isSuperAdmin || isAdmin ? (
                <CSVLink
                  data={[]}
                  filename={`${exportFileName()}`}
                  target="_blank"
                  style={{
                    textDecoration: 'none',
                    marginLeft: xsOnly ? 0 : '5px',
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      cursor: 'pointer',
                      width: '100px',
                    }}
                  >
                    <Typography variant="body1" sx={{ color: 'white' }}>
                      Export
                    </Typography>
                  </Box>
                </CSVLink>
              ) : null}
            </Grid>
          </Grid>
        ) : null**/}

        {showAllBrands ? (
          <AllBrandAIReportTable
            insights={insightzReport}
            loading={loading}
            onOpenInsightz={handleOpenAnalysis}
            onOpenPreview={handleOpenPreview}
          />
        ) : (
          <AIReportTable
            insights={insightzReport}
            loading={loading}
            onOpenInsightz={handleOpenAnalysis}
            onOpenPreview={handleOpenPreview}
          />
        )}

        {openInsightz ? (
          <Grid
            container
            spacing={1}
            justifyContent="center"
            columns={14}
            mb={2}
          >
            {(isSuperAdmin || isAdmin) &&
            audiencePrompts &&
            acquisitionPrompts ? (
              <Grid
                item
                xs={12}
                sx={{ justifyContent: 'flex-end', display: 'flex' }}
              >
                <Box
                  component="div"
                  sx={{ width: '100px', cursor: 'pointer' }}
                  onClick={() => setShowPrompts(true)}
                >
                  <Typography variant="body1" sx={{ color: 'white' }}>
                    Show Prompts
                  </Typography>
                </Box>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <FormHeader title={buildAdNameLabel(selectedInsights)} />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <PageHeader title="Ad Insightz" />
            </Grid>

            <Grid item xs={12}>
              {adAnalysisLoading || insightzDataLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Spinner />
                </Box>
              ) : (
                HTMLReactParser(adAnalysis)
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <PageHeader title="Audience Insightz" />
            </Grid>

            <Grid item xs={12}>
              {audienceLoading || insightzDataLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Spinner />
                </Box>
              ) : (
                HTMLReactParser(audienceAnalysis)
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <PageHeader title="Acquisition Insightz" />
            </Grid>

            <Grid item xs={12}>
              {acquisitionLoading || insightzDataLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Spinner />
                </Box>
              ) : (
                HTMLReactParser(acquisitionAnalysis)
              )}
            </Grid>

            {showPrompts && (isSuperAdmin || isAdmin) ? (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <PageHeader title="Prompts" />
                </Grid>

                {audiencePrompts ? (
                  <Grid item xs={12}>
                    <Typography variant="body1" fontWeight="bold">
                      Audience Insightz Prompts
                    </Typography>

                    <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                      {audiencePrompts}
                    </Typography>
                  </Grid>
                ) : null}

                {acquisitionPrompts ? (
                  <Grid item xs={12}>
                    <Typography variant="body1" fontWeight="bold">
                      Acquisition Insightz Prompts
                    </Typography>

                    <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                      {acquisitionPrompts}
                    </Typography>
                  </Grid>
                ) : null}
              </>
            ) : null}
          </Grid>
        ) : null}

        <DrawerMenu
          anchor="right"
          open={openPreview}
          onClose={handleClosePreview}
        >
          <FacebookAdDetails
            brand={selectedBrand}
            adId={selectedAd}
            onClose={handleClosePreview}
            isMobile={xsOnly}
          />
        </DrawerMenu>
      </div>
    </Box>
  );
};

export default AiReport;
