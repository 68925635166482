import React from 'react';
import Box from '@mui/material/Box';
import { Modal, TextField, useTheme, useMediaQuery } from '@mui/material';
import { InvitationErrors, InviteInfo } from '../../types';
import ModalHeader from './ModalHeader';
import styles from '../../assets/styles/components/Modal/Modal.module.scss';
import { PrimaryButton } from '../Buttons';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

interface InviteModalModalProps {
  open: boolean;
  handleClose: () => void;
  children?: React.ReactNode;
  invitationInfo: InviteInfo;
  handleInvite: (email: string) => void;
  title: string;
  subtitle: string;
  loading?: boolean;
  errors: InvitationErrors;
}

const InviteModal: React.FC<InviteModalModalProps> = ({
  open,
  handleClose,
  invitationInfo,
  handleInvite,
  title,
  subtitle,
  loading,
  errors,
}) => {
  const theme = useTheme();
  const isXsOnly = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition={true}
    >
      <Box sx={style}>
        <div
          className={`${styles.modal} ${
            isXsOnly ? `${styles['-mobile']}` : ''
          }`}
        >
          <ModalHeader title={title} />

          <p className={styles.subtitle}>{subtitle}</p>

          <div className={styles.field}>
            <TextField
              fullWidth
              required
              variant="standard"
              type="email"
              id="email"
              name="email"
              label="Email"
              value={invitationInfo.email}
              disabled
              InputLabelProps={{ shrink: true }}
              size="small"
              error={errors?.email ? true : false}
              helperText={errors?.email ? errors.email : ''}
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#000000',
                },
              }}
            />
          </div>

          <div>
            <PrimaryButton
              title="Invite"
              type="button"
              loading={loading}
              handleOnClick={() =>
                errors?.email ? null : handleInvite(invitationInfo.email)
              }
              marginRight5
            />

            <PrimaryButton
              title="Cancel"
              type="button"
              loading={loading}
              handleOnClick={handleClose}
              theme="red"
            />
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default InviteModal;
