import { Grid, Typography } from '@mui/material';
import React from 'react';
import ModalHeader from './ModalHeader';
import { RiRefund2Line } from 'react-icons/ri';
import { toCurrency } from '../../utils/numberFormatter';

interface BudgetPreviewProps {
  amount: number;
}

const BudgetPreview: React.FC<BudgetPreviewProps> = ({ amount }) => {
  return (
    <Grid container spacing={2} sx={{ width: '280px', borderRadius: '10px' }}>
      <Grid item xs={12}>
        <ModalHeader title="Campaign Spend Limit" />
      </Grid>

      <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
        <RiRefund2Line size={100} />
      </Grid>

      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography variant="body1">
          Your campaign spending limit has been changed to
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {toCurrency('USD', amount)}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography variant="body1">
          META may spend this amount for this month for your campaign.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BudgetPreview;
