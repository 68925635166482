import { CircularProgress, Divider, Grid, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { generateAdCopy } from '../../../services/ai';
import HTMLReactParser from 'html-react-parser';
import { PrimaryButton } from '../../Buttons';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { copyToClipboard } from '../../../utils/stringModifier';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../redux/actions';
import { AdsTemplate } from '../../../types/IDiy';
import { errorMessageParser } from '../../../utils/helpers/ToastHelper';

interface AnalyzeAdCopyProps {
  adCopy: string;
  template: AdsTemplate;
  setTemplate: Dispatch<SetStateAction<AdsTemplate>>;
  loading: boolean;
}

const AnalyzeAdCopy: React.FC<AnalyzeAdCopyProps> = ({
  adCopy,
  template,
  setTemplate,
  loading,
}) => {
  const dispatch = useDispatch();
  const [generateLoading, setGenerateLoading] = useState<boolean>(false);

  const handleGenerateAdCopy = async () => {
    setGenerateLoading(true);
    try {
      const prompt = `${template?.aiAnalyze?.adCopy}</b>${template?.aiAnalyze?.analysis}`;
      const response = await generateAdCopy(template?._id, prompt);

      setTemplate({
        ...template,
        aiAnalyze: {
          ...template?.aiAnalyze,
          generatedAdCopy: response.data.generatedAdCopy,
          adCopy,
        },
      });

      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Re-written ad copy generated successfully',
        }),
      );
    } catch (error: any) {
      console.log(error);
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setGenerateLoading(false);
    }
  };

  const handleOnCopy = () => {
    const text = window.getSelection().toString();

    if (!text) {
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Highlight the text you want to copy',
          horizontal: 'center',
          vertical: 'top',
          type: 'error',
        }),
      );
    } else {
      copyToClipboard(text);
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Copied to clipboard',
          horizontal: 'center',
          vertical: 'top',
        }),
      );
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          fontWeight="bold"
          component="div"
          sx={{ marginBottom: '20px', textAlign: 'center' }}
        >
          Ad Copy Analysis
        </Typography>
      </Grid>

      {loading ? (
        <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
          <CircularProgress color="inherit" />
        </Grid>
      ) : (
        <>
          {template?.aiAnalyze?.analysis ? (
            <Grid item xs={12}>
              {HTMLReactParser(template?.aiAnalyze?.analysis)}
            </Grid>
          ) : null}

          {template?.aiAnalyze?.generatedAdCopy ? (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  textAlign="center"
                >
                  Re-written Ad Copy
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid item xs={12}>
                  {HTMLReactParser(template?.aiAnalyze?.generatedAdCopy)}
                </Grid>
              </Grid>
            </>
          ) : null}

          {!loading ? (
            <Grid item xs={12}>
              <PrimaryButton
                title="Copy to Clipboard"
                type="button"
                handleOnClick={handleOnCopy}
                startIcon={<ContentCopyOutlinedIcon />}
                marginRight10
              />

              {!template?.aiAnalyze?.generatedAdCopy ? (
                <PrimaryButton
                  title="Rewrite Ad"
                  type="button"
                  handleOnClick={handleGenerateAdCopy}
                  loading={generateLoading}
                />
              ) : null}
            </Grid>
          ) : null}
        </>
      )}
    </Grid>
  );
};

export default AnalyzeAdCopy;
