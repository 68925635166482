import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';
import { CrmSetting } from '../../types/ICrm';
import { AdLead, Brand } from '../../types';
import {
  getHubSpotPipelineReportAds,
  getHubSpotPipelineReportStage,
} from '../../services/hubspot';
import {
  HubSpotPipelineReport,
  HubSpotPipelineReportData,
} from '../../types/IHubSpot';
import HubSpotPipelineReportTable from '../../components/Tables/Pipelinez/HubSpotPipelineReportTable';
import { PrimaryButton } from '../../components/Buttons';
import { DrawerMenu } from '../../components/Menu';
import LeadDetails from '../../components/Details/LeadDetails';
import FacebookAdDetails from '../../components/Details/Ads/FacebookAdDetails';
import { editLeads } from '../../services/lead';
import { errorMessageParser } from '../../utils/helpers/ToastHelper';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../redux/actions';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import { PiHandCoins } from 'react-icons/pi';
import { TbCreditCardPay } from 'react-icons/tb';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import { BsGraphUpArrow } from 'react-icons/bs';
import DashboardItem from '../../components/Cards/Billing/DashboardItem';
import { FaRegHandshake } from 'react-icons/fa';

interface PipelinezDashboardItem {
  value: number;
  label: string;
  isCurrency: boolean;
  isPercentage: boolean;
  icon: ReactNode;
}

interface HubSpotReportProps {
  crmSettings: CrmSetting;
  brand: Brand;
  provider: string;
}

const HubSpotReport: React.FC<HubSpotReportProps> = ({
  crmSettings,
  brand,
  provider,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const [pipelinezItems, setPipelinezItems] = useState<
    PipelinezDashboardItem[]
  >([
    {
      value: 0,
      label: 'Lifetime Value',
      isCurrency: true,
      isPercentage: false,
      icon: <AttachMoneyOutlinedIcon sx={{ fontSize: '30px' }} />,
    },
    {
      value: 0,
      label: 'Deal Value',
      isCurrency: true,
      isPercentage: false,
      icon: <FaRegHandshake size={30} />,
    },
    {
      value: 0,
      label: 'Commission',
      isCurrency: true,
      isPercentage: false,
      icon: <PiHandCoins size={30} />,
    },
    {
      value: 0,
      label: 'Spend',
      isCurrency: true,
      isPercentage: false,
      icon: <TbCreditCardPay size={30} />,
    },
    {
      value: 0,
      label: 'Leads',
      isCurrency: false,
      isPercentage: false,
      icon: <GroupAddOutlinedIcon sx={{ fontSize: '30px' }} />,
    },
    {
      value: 0,
      label: 'ROI',
      isCurrency: false,
      isPercentage: true,
      icon: <CurrencyExchangeOutlinedIcon sx={{ fontSize: '30px' }} />,
    },
    {
      value: 0,
      label: 'Cost-per-Sale',
      isCurrency: true,
      isPercentage: false,
      icon: <BsGraphUpArrow size={30} />,
    },
  ]);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [pipelineReports, setPipelineReports] =
    useState<HubSpotPipelineReport>(null);
  const [selectedLead, setSelectedLead] = useState<AdLead>(null);
  const [dealId, setDealId] = useState<string>(null);
  const [openLeadDetails, setOpenLeadDetails] = useState<boolean>(false);
  const [showByAds, setShowByAds] = useState<boolean>(false);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [selectedAdId, setSelectedAdId] = useState<string>(null);
  const [openAdDetails, setOpenAdDetails] = useState<boolean>(false);
  const [commissionMode, setCommissionMode] = useState<boolean>(false);
  const [updateLeadLoading, setUpdateLeadLoading] = useState<boolean>(false);

  useEffect(() => {
    if (brand && provider) {
      if (showByAds) {
        getSalesCycleReportByAds();
      } else {
        getPipelineReportByStage();
      }
    }
  }, [brand, provider, showByAds]);

  const getPipelineReportByStage = async () => {
    try {
      setFetchLoading(true);
      const response = await getHubSpotPipelineReportStage(
        brand?._id,
        provider,
      );

      setPipelineReports(response);

      let tempDashboardItems: PipelinezDashboardItem[] = [];
      pipelinezItems.forEach((item: PipelinezDashboardItem) => {
        item.value = getDashboardItemValue(item.label, response);

        tempDashboardItems = [...tempDashboardItems, item];
      });

      setPipelinezItems(tempDashboardItems);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const getSalesCycleReportByAds = async () => {
    try {
      setFetchLoading(true);
      const response = await getHubSpotPipelineReportAds(brand?._id, provider);

      setPipelineReports(response);

      let tempDashboardItems: PipelinezDashboardItem[] = [];
      pipelinezItems.forEach((item: PipelinezDashboardItem) => {
        item.value = getDashboardItemValue(item.label, response);

        tempDashboardItems = [...tempDashboardItems, item];
      });

      setPipelinezItems(tempDashboardItems);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const handleOnUpdateLead = async (
    leadId: string,
    brandId: string,
    formValues: AdLead,
  ) => {
    try {
      setUpdateLeadLoading(true);

      const response = await editLeads(leadId, brandId, formValues);

      let tempReport: HubSpotPipelineReport = { ...pipelineReports };
      let temp: HubSpotPipelineReportData[] = [];
      let currentTotalCommission: number = pipelineReports.totalCommission;
      let currentTotalLeads: number = pipelineReports.totalLeads;
      let currentTotalSales: number = pipelineReports.totalSales;
      let currentTotalValue: number = pipelineReports.totalValue;
      let currentTotalDealValue: number = pipelineReports.totalDealValue;

      pipelineReports?.data?.forEach((data: HubSpotPipelineReportData) => {
        if (
          data.id.split('-')[0] === response.data.id &&
          data.level === 'lead'
        ) {
          // Re-calculate Leads
          const oldTotalLeads = data.leads;
          currentTotalLeads -= oldTotalLeads;
          data.lead = response.data;
          data.leads = response.data.units;
          currentTotalLeads += data.leads;

          // Re-calculate Sales
          if (data.finalStage) {
            currentTotalSales -= oldTotalLeads;
            currentTotalSales += data.leads;
          }

          // Re-calculate Value
          const oldValue = data.value;
          currentTotalValue -= oldValue;
          const valuePerUnit =
            response.data.lifetimeValue * (data.closeRatio / 100);
          const newTotalValue = valuePerUnit * response.data.units;
          data.value = newTotalValue;
          currentTotalValue += newTotalValue;

          // Re-calculate Deal Value
          const oldDealValue = data?.dealValue || 0;
          currentTotalDealValue -= oldDealValue;
          const dealValuePerUnit =
            response.data.dealValue * (data.closeRatio / 100);
          const newTotalDealValue = dealValuePerUnit * response.data.units;
          data.dealValue = newTotalDealValue;
          currentTotalDealValue += newTotalDealValue;

          // Re-calculate Commission
          const oldCommission = data.commission;
          currentTotalCommission -= oldCommission;
          const commissionPerUnit =
            response.data.commission * (data.closeRatio / 100);
          const newTotalCommission =
            commissionPerUnit *
            (response.data.units || crmSettings?.averageUnitSold);
          currentTotalCommission += newTotalCommission;
          data.commission = newTotalCommission;

          if (showByAds) {
            const adLevelIndex = pipelineReports.data.findIndex(
              (o: HubSpotPipelineReportData) =>
                o.id === `${data.id.split('-')[1]}`,
            );
            const pipelineStageIndex = pipelineReports.data.findIndex(
              (o: HubSpotPipelineReportData) => {
                const idParts = data.id.split('-');
                return o.id === `${idParts[3]}-${idParts[1]}`;
              },
            );

            const currentPipelineValue =
              tempReport.data[pipelineStageIndex].value;
            const currentPipelineLeads =
              tempReport.data[pipelineStageIndex].leads;
            const currentPipelineDealValue =
              tempReport.data[pipelineStageIndex].dealValue;

            const pipelineValue =
              currentPipelineValue - oldValue + newTotalValue;
            const pipelineLeads =
              currentPipelineLeads - oldTotalLeads + response.data.units;
            const pipelineDealValue =
              currentPipelineDealValue - oldDealValue + newTotalDealValue;

            tempReport.data[pipelineStageIndex].value = pipelineValue;
            tempReport.data[pipelineStageIndex].leads = pipelineLeads;
            tempReport.data[pipelineStageIndex].dealValue = pipelineDealValue;

            const currentAdValue = tempReport.data[adLevelIndex].value;
            const currentAdLeads = tempReport.data[adLevelIndex].leads;
            const currentAdDealValue = tempReport.data[adLevelIndex].dealValue;

            const adValue = currentAdValue - oldValue + newTotalValue;
            const adLeads =
              currentAdLeads - oldTotalLeads + response.data.units;
            const adDealValue =
              currentAdDealValue - oldDealValue + newTotalDealValue;

            tempReport.data[adLevelIndex].value = adValue;
            tempReport.data[adLevelIndex].leads = adLeads;
            tempReport.data[adLevelIndex].dealValue = adDealValue;
          } else {
            const index = pipelineReports.data.findIndex(
              (o: HubSpotPipelineReportData) => o.id === data.id.split('-')[2],
            );

            const currentPipelineValue = tempReport.data[index].value;
            const currentPipelineLeads = tempReport.data[index].leads;
            const currentPipelineDealValue = tempReport.data[index].dealValue;

            const pipelineValue =
              currentPipelineValue - oldValue + newTotalValue;
            const currentPipelineCommission = tempReport.data[index].commission;
            const pipelineDealValue =
              currentPipelineDealValue - oldDealValue + newTotalDealValue;

            const pipelineCommission =
              currentPipelineCommission - oldCommission + newTotalCommission;
            const pipelineLeads =
              currentPipelineLeads - oldTotalLeads + response.data.units;

            tempReport.data[index].value = pipelineValue;
            tempReport.data[index].leads = pipelineLeads;
            tempReport.data[index].commission = pipelineCommission;
            tempReport.data[index].dealValue = pipelineDealValue;
          }
        }

        temp = [...temp, data];
      });

      tempReport = {
        ...tempReport,
        data: temp,
        totalValue: currentTotalValue,
        totalCommission: currentTotalCommission,
        totalLeads: currentTotalLeads,
        totalSales: currentTotalSales,
        totalDealValue: currentTotalDealValue,
        roi:
          pipelineReports.totalSpend > 0
            ? ((currentTotalValue - pipelineReports.totalSpend) /
                pipelineReports.totalSpend) *
              100
            : 0,
      };

      setPipelineReports(tempReport);

      let tempDashboardItems: PipelinezDashboardItem[] = [];
      pipelinezItems.forEach((item: PipelinezDashboardItem) => {
        item.value = getDashboardItemValue(item.label, tempReport);

        tempDashboardItems = [...tempDashboardItems, item];
      });

      setPipelinezItems(tempDashboardItems);

      dispatch(
        toggleAlert({
          toggle: true,
          message: `Lead Updated Successfully`,
        }),
      );
    } catch (error: any) {
      console.log(error);
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setUpdateLeadLoading(false);
    }
  };

  const handleOpenLeadDetails = (lead: AdLead, dealId: string) => {
    setSelectedLead(lead);
    setDealId(dealId);
    setOpenLeadDetails(true);
  };

  const handleChangeTableView = () => {
    setShowByAds((prev) => !prev);
    setExpandedKeys([]);
  };

  const handleCloseLeadDetails = () => {
    setDealId(null);
    setSelectedLead(null);
    setOpenLeadDetails(false);
  };

  const handleCloseAdDetails = () => {
    setOpenAdDetails(false);
    setSelectedAdId(null);
  };

  const getDashboardItemValue = (label: any, report: HubSpotPipelineReport) => {
    if (label === 'Lifetime Value') return report.totalValue;

    if (label === 'Spend') return report.totalSpend;

    if (label === 'Leads') return report.totalLeads;

    if (label === 'ROI') return report.roi >= 0 ? report.roi : 0;

    if (label === 'Commission') return report.totalCommission;

    if (label === 'Deal Value') return report.totalDealValue || 0;

    return report.totalSales > 0 ? report.totalSpend / report.totalSales : 0;
  };

  return (
    <Box>
      {!showByAds ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
            <Box
              component="div"
              sx={{
                width: '200px',
                height: '20px',
                cursor: 'pointer',
              }}
              onClick={() => setCommissionMode((prev) => !prev)}
            />
          </Grid>
        </Grid>
      ) : null}

      <Grid container spacing={1} justifyContent="center" columns={12} mb={2}>
        {pipelinezItems.map((item: PipelinezDashboardItem, index: number) => {
          if (!commissionMode && item.label === 'Commission') {
            return <Box key={`pipeline-item-${index + 1}`} />;
          }

          if (!commissionMode && item.label === 'Deal Value') {
            return <Box key={`pipeline-item-${index + 1}`} />;
          }

          if (commissionMode && item.label === 'Lifetime Value') {
            return <Box key={`pipeline-item-${index + 1}`} />;
          }

          return (
            <Grid item xs={12} sm={'auto'} key={`pipeline-item-${index + 1}`}>
              <DashboardItem
                endingValue={item.value}
                label={item.label}
                isMobile={xsOnly}
                icon={item.icon}
                isCurrency={item.isCurrency}
                isPercentage={item.isPercentage}
                decimals={0}
              />
            </Grid>
          );
        })}
      </Grid>

      <Grid container spacing={2} mb={1}>
        <Grid item xs={12} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
          <PrimaryButton
            title={`Group By ${showByAds ? 'Stage' : 'Ads'}`}
            type="button"
            handleOnClick={handleChangeTableView}
          />
        </Grid>
      </Grid>

      <HubSpotPipelineReportTable
        data={pipelineReports}
        loading={fetchLoading}
        onOpenLeadDetails={handleOpenLeadDetails}
        isByAds={showByAds}
        expandedKeys={expandedKeys}
        setExpandedKeys={setExpandedKeys}
        setSelectedAdId={setSelectedAdId}
        setOpenAdDetails={setOpenAdDetails}
        commissionMode={commissionMode}
      />

      <DrawerMenu
        anchor="right"
        open={openLeadDetails}
        onClose={handleCloseLeadDetails}
      >
        <LeadDetails
          lead={selectedLead}
          onClose={handleCloseLeadDetails}
          crm="hubspot"
          brand={brand}
          commissionMode={commissionMode}
          onUpdate={handleOnUpdateLead}
          updateLoading={updateLeadLoading}
          dealId={dealId}
        />
      </DrawerMenu>

      <DrawerMenu
        anchor="right"
        open={openAdDetails}
        onClose={handleCloseAdDetails}
      >
        <FacebookAdDetails
          brand={brand}
          adId={selectedAdId}
          onClose={handleCloseAdDetails}
          isMobile={xsOnly}
        />
      </DrawerMenu>
    </Box>
  );
};

export default HubSpotReport;
