import {
  CircleF,
  GoogleMap,
  Marker,
  StandaloneSearchBox,
  useJsApiLoader,
} from '@react-google-maps/api';
import React, {
  CSSProperties,
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Brand } from '../../types';
import { googleMapId, googleMapsApiKey } from '../../services/api';
import { AdsTemplate } from '../../types/IDiy';
import { buildAddress } from '../../utils/helpers/DiyHelpers';
import { Box } from '@mui/material';
import { DEFAULT_LAT, DEFAULT_LNG } from '../../utils';

interface MapsProps {
  brand?: Brand;
  setBrand?: Dispatch<SetStateAction<Brand>>;
  isAudience?: boolean;
  template?: AdsTemplate;
  markers: any[];
  setMarkers: Dispatch<SetStateAction<any[]>>;
  center: any;
  setCenter: Dispatch<SetStateAction<any>>;
  setParentMap: Dispatch<SetStateAction<any>>;
}

/**
 * Google Maps Component
 * @description Render Google Map's vector map. We are using this to
 *              show a visual representation of the ad's targeted location
 *              and the brand/location address location.
 * @author Angelo David
 * @reference https://developers.google.com/maps
 */
const Maps: React.FC<MapsProps> = ({
  brand,
  setBrand,
  isAudience = false,
  template,
  markers,
  setMarkers,
  center,
  setCenter,
  setParentMap,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey,
    libraries: ['places', 'maps'],
    mapIds: [googleMapId],
  });

  const inputStyle: CSSProperties = {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `240px`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: 'absolute',
    top: '10px',
    left: '10px',
  };

  const containerStyle = {
    width: '100%',
    height: '400px',
  };

  const [map, setMap] = useState<any>(null);
  const [searchBox, setSearchBox] = useState<any>(null);

  useEffect(() => {
    if (!isAudience && brand) {
      setCenter({
        lat: brand?.address?.lat || DEFAULT_LAT,
        lng: brand?.address?.lng || DEFAULT_LNG,
      });

      setMarkers([
        {
          lat: brand?.address?.lat || DEFAULT_LAT,
          lng: brand?.address?.lng || DEFAULT_LNG,
        },
      ]);
    }
  }, [!isAudience, brand]);

  useEffect(() => {
    // https://developers.google.com/maps/documentation/javascript/dds-boundaries/style-polygon#overview
    if (map && template) {
      /**
       * Location Boundary Line Style
       * @description Draw a line for the location boundaries. Included location boundary lines are in blue
       *              and excluded location are in red
       * @author Angelo David <angelod@codev.com>
       * @param excluded
       * @returns <Object> Style object for the feature style options
       */
      const boundaryOptions = (excluded: boolean) => {
        const data: google.maps.FeatureStyleOptions = {
          strokeColor: excluded ? '#FF0000' : '#810FCB',
          strokeOpacity: 1.0,
          strokeWeight: 3.0,
          fillOpacity: 0.5,
        };

        return data;
      };

      const { cities, regions, zips, countries } =
        template?.adSetTemplate?.targeting?.geo_locations;

      const {
        cities: excludedCities,
        regions: excludedRegions,
        zips: excludedZips,
      } = template?.adSetTemplate?.targeting?.excluded_geo_locations;

      // https://developers.google.com/maps/documentation/javascript/reference/data-driven-styling#FeatureType
      let countryLayer: any = map.getFeatureLayer(
        google.maps.FeatureType.COUNTRY,
      );

      let stateLayer: any = map.getFeatureLayer(
        google.maps.FeatureType.ADMINISTRATIVE_AREA_LEVEL_1,
      );

      let zipLayer: any = map.getFeatureLayer(
        google.maps.FeatureType.POSTAL_CODE,
      );

      let countyLayer: any = map.getFeatureLayer(
        google.maps.FeatureType.ADMINISTRATIVE_AREA_LEVEL_2,
      );

      let locality: any = map.getFeatureLayer(google.maps.FeatureType.LOCALITY);

      const placeIds = [
        ...(cities || []),
        ...(regions || []),
        ...(zips || []),
        ...(countries || []),
      ].map((location: any) => {
        return location.placeId;
      });

      const excludedPlaceIds = [
        ...(excludedCities || []),
        ...(excludedRegions || []),
        ...(excludedZips || []),
      ].map((location: any) => {
        return location.placeId;
      });

      countryLayer.style = (options: { feature: { placeId: string } }) => {
        if (placeIds.includes(options.feature.placeId)) {
          const excluded = excludedPlaceIds.includes(options.feature.placeId);

          return boundaryOptions(excluded);
        }
      };

      stateLayer.style = (options: { feature: { placeId: string } }) => {
        if (
          [...placeIds, ...excludedPlaceIds].includes(options.feature.placeId)
        ) {
          const excluded = excludedPlaceIds.includes(options.feature.placeId);

          return boundaryOptions(excluded);
        }
      };

      zipLayer.style = (options: { feature: { placeId: string } }) => {
        if (
          [...placeIds, ...excludedPlaceIds].includes(options.feature.placeId)
        ) {
          const excluded = excludedPlaceIds.includes(options.feature.placeId);

          return boundaryOptions(excluded);
        }
      };

      countyLayer.style = (options: { feature: { placeId: string } }) => {
        if (
          [...placeIds, ...excludedPlaceIds].includes(options.feature.placeId)
        ) {
          const excluded = excludedPlaceIds.includes(options.feature.placeId);

          return boundaryOptions(excluded);
        }
      };

      locality.style = (options: { feature: { placeId: string } }) => {
        if (
          [...placeIds, ...excludedPlaceIds].includes(options.feature.placeId)
        ) {
          const excluded = excludedPlaceIds.includes(options.feature.placeId);

          return boundaryOptions(excluded);
        }
      };
    }
  }, [
    map,
    template?.adSetTemplate?.targeting?.geo_locations,
    template?.adSetTemplate?.targeting?.excluded_geo_locations,
  ]);

  useEffect(() => {
    if (brand?.address && isLoaded && !isAudience) {
      const { lat = 0, lng = 0 } = brand?.address;

      if (lat && lng) {
        setCenter({ lat: lat || DEFAULT_LAT, lng: lng || DEFAULT_LNG });
      }
    }
  }, [brand?.address, isLoaded, isAudience]);

  useEffect(() => {
    if (template && isLoaded && isAudience) {
      let temp: any[] = [];
      const { latitude, longitude } =
        (template?.adSetTemplate?.targeting?.geo_locations?.custom_locations ||
          [])[0] || {};

      const { custom_locations, cities, regions, zips, countries } =
        template?.adSetTemplate?.targeting?.geo_locations;

      [
        ...(custom_locations || []),
        ...(cities || []),
        ...(regions || []),
        ...(zips || []),
        ...(countries || []),
      ].forEach((location: any) => {
        const { latitude, longitude, radius } = location;

        const address = buildAddress(location);

        if (location.type === 'city' || !location.type) {
          temp = [
            ...temp,
            {
              lat: latitude,
              lng: longitude,
              radius,
              address,
              type: location.type,
            },
          ];
        } else {
          temp = [
            ...temp,
            { lat: latitude, lng: longitude, address, type: location.type },
          ];
        }
      });

      if (latitude && longitude) {
        setCenter({
          lat: latitude || DEFAULT_LAT,
          lng: longitude || DEFAULT_LNG,
        });
      } else {
        setCenter({
          lat: temp[0]?.lat || DEFAULT_LAT,
          lng: temp[0]?.lng || DEFAULT_LNG,
        });
      }

      setMarkers(temp);
    }
  }, [template, isLoaded, isAudience]);

  useEffect(() => {
    if (isLoaded && isAudience) {
      extendZoom();
    }
  }, [markers, isLoaded, isAudience]);

  const onLoad = useCallback((map) => {
    setMap(map);
    setParentMap(map);
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
    setParentMap(null);
  }, []);

  const handleSearchPlace = () => {
    let temp = [
      {
        lat: searchBox.getPlaces()[0].geometry.location.lat(),
        lng: searchBox.getPlaces()[0].geometry.location.lng(),
      },
    ];

    setCenter({
      lat: searchBox.getPlaces()[0].geometry.location.lat(),
      lng: searchBox.getPlaces()[0].geometry.location.lng(),
    });

    setMarkers(temp);
    setBrand({
      ...brand,
      address: {
        ...brand?.address,
        lat: searchBox.getPlaces()[0].geometry.location.lat(),
        lng: searchBox.getPlaces()[0].geometry.location.lng(),
      },
    });
  };

  const onLoadSearch = (ref: any) => {
    setSearchBox(ref);
  };

  const buildZoom = (marker: any) => {
    let zoom: number = 4;

    switch (marker.type) {
      case 'region':
        zoom = 6;
        break;
      case 'zip':
        zoom = 12;
        break;
      case 'city':
        zoom = 8;
        break;
      case 'country':
        zoom = 3;
        break;
      default:
        zoom = 10;
        break;
    }

    return zoom;
  };

  const extendZoom = () => {
    const bounds = new window.google.maps.LatLngBounds();
    if (markers.length > 0) {
      markers.forEach((item: any) => {
        bounds.extend({ lat: item.lat, lng: item.lng });
      });

      (map as any)?.fitBounds(bounds);

      if (markers.length === 1) {
        (map as any)?.setZoom(buildZoom(markers[0]));
      }
    }
  };

  // https://stackoverflow.com/a/74121736
  const handleOnClickMap = (e: any) => {
    let temp: any = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    setMarkers([{ ...temp }]);
    setCenter(temp);
    setBrand({
      ...brand,
      address: {
        ...brand?.address,
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      },
    });
  };

  const circleOptions = (marker: any) => {
    let circleOptions: google.maps.CircleOptions = {
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      center: {
        lat: marker.lat,
        lng: marker.lng,
      },
      radius: marker.radius * 1609.34,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      zIndex: 1,
    };

    return circleOptions;
  };

  return (
    <div>
      {isLoaded ? (
        <GoogleMap
          id="search-box-example"
          mapContainerStyle={containerStyle}
          center={center ? center : { lat: DEFAULT_LAT, lng: DEFAULT_LNG }}
          zoom={4}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onIdle={extendZoom}
          onClick={isAudience ? null : handleOnClickMap}
          options={{
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            zoomControl: !isAudience,
            mapId: googleMapId,
          }}
        >
          <Fragment>
            {markers.length > 0
              ? markers?.map((marker: any, index: number) => {
                  return (
                    <Box key={`map-component-${index + 1}`}>
                      <Marker
                        position={{ lat: marker.lat, lng: marker.lng }}
                        title={marker.address}
                        key={`marker-${index + 1}`}
                      />

                      {isAudience && marker.radius ? (
                        <CircleF
                          options={circleOptions(marker)}
                          key={`radius-${index + 1}`}
                        />
                      ) : null}
                    </Box>
                  );
                })
              : null}

            {!isAudience ? (
              <StandaloneSearchBox
                onLoad={onLoadSearch}
                onPlacesChanged={handleSearchPlace}
              >
                <input
                  type="text"
                  placeholder="Search your location"
                  style={inputStyle}
                />
              </StandaloneSearchBox>
            ) : null}
          </Fragment>
        </GoogleMap>
      ) : null}
    </div>
  );
};

export default Maps;
